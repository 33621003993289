<div class="card-body table-responsive nooverflow" style="padding: 0; overflow-x: hidden; height: 80vh; position: relative;">
    <div class="row" style="margin-top: 1%;">
        <div class="col-2">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="form_email">Seleziona utente</label>
                    <select class="form-select form-control" aria-label=".form-select-sm example"  [(ngModel)]="SelectedUser" (ngModelChange)="Filter()">
                        <option class="form-control" [ngValue]="null">TUTTI I LOG</option>
                        <option class="form-control" *ngFor="let user of AllUsers" [ngValue]="user">{{user.Username.toUpperCase()}}</option>
                    </select>
                </div>
                <div class="form-group col-md-2"></div>
                <div class="form-group col-md-4">
                    <input type="checkbox" class="form-check-input" [(ngModel)]="this.IsLogin" (ngModelChange)="this.FilterPage(0); this.ActivePage = 0"/>
                <label class="form-check-label" for="defaultCheck1">Includi Login</label>
                </div>
            </div>
        </div>
        <div class="col-6"></div>
        <div class="col-2">
            <div class="form-group">
                <label for="form_email">Dal</label>
                <input id="data" [max]="this.TodayString" type="date" name="data" class="form-control" [ngModel]="this.StartDate | date:'yyyy-MM-dd'" (ngModelChange)="this.StartDate = $event; this.Filter()"/>
            </div>
        </div>
        <div class="col-2">
            <div class="form-group">
                <label for="form_email">Al</label>
                <input id="data" [max]="this.TodayString" type="date" name="data" class="form-control" [ngModel]="this.EndDate | date:'yyyy-MM-dd'" (ngModelChange)="this.EndDate = $event; this.Filter()"/>
            </div>
        </div>
    </div>
    <div *ngIf="this.LoadingLocal" style="position: absolute; left: 50%; top: 50%">
        <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
        </div>
    </div>
    <table class="table table-hover table-striped fixed" id="table" *ngIf="!this.LoadingLocal">
        <thead>
            <tr>
                <th>UTENTE</th>
                <th>DATA</th>
                <th>ORA</th>
                <th>IP PUBBLICO</th>
                <th>OPERAZIONE</th>
            </tr>
        </thead>
        <tbody class="sortable">
            <tr *ngFor="let log of this.Logs">
                <td>{{log.User}}</td>
                <td>{{log.Date}}</td>
                <td>{{log.Time}}</td>
                <td>{{log.IP}}</td>
                <td>{{log.OP}} <fa-icon [icon]="faInfo" *ngIf="log.License !== null" [tooltip]="log.License"></fa-icon></td>
            </tr>
        </tbody>
    </table>
</div>
<div class="row" style="position: absolute; bottom: 0;">
    <div class="col">
        <nav>
            <ul class="pagination">
                <li class="page-item" *ngFor="let number of [].constructor(this.TotalPages); let i = index">
                    <a class="page-link" role="button" (click)="FilterPage(i); this.ActivePage = i" [style.font-weight]="this.ActivePage === i ? '800' : '100'">{{i + 1}}</a>
                </li>
            </ul>
        </nav>
    </div>
</div>