import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { LicenseService } from 'src/app/Services/LicenseService/license.service';

import { faPen, faSave, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-censimento-info',
  templateUrl: './censimento-info.component.html',
  styleUrls: ['./censimento-info.component.scss']
})
export class CensimentoInfoComponent implements OnInit {

  @Input() censimentoInfo: any;
  public editMacAddress = false;
  public canSave = true;

  // ICONS
  public faPen: IconDefinition = faPen;
  public faSave: IconDefinition = faSave;

  constructor(public modalService: BsModalService, private alertService: AlertService, public licenseService: LicenseService) { }

  ngOnInit(): void {
  }

  
  public async SaveModalMACAddress() {
    // CONTROLLO CHE IL MAC ADDRESS SIA FORMATTATO CORRETTAMENTE
    const regex = new RegExp("^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$");

    if (regex.test(this.censimentoInfo.MAC) || this.censimentoInfo.MAC === "") {
      const change: boolean = await this.licenseService.ChangeMacAddress(this.censimentoInfo.MAC, this.censimentoInfo.licence);
      if (change) {
        this.alertService.success('MAC Address modificato');
        this.editMacAddress = false;
      } else {
        this.alertService.danger("Impossibile modificare il MAC Address")
      }
    }
  }

  public CheckMacAddress() {
    const regex = new RegExp("^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$");
    
    if (regex.test(this.censimentoInfo.MAC) || this.censimentoInfo.MAC === "") {
      this.canSave = true;
      return;
    }
    this.canSave = false;
    return;
  }

}
