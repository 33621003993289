import { CensisService } from './../Services/CensisService/censis.service';
import { filter } from 'rxjs/operators';
import { UserService } from './../Services/UserService/user.service';
import { IsDev } from './../global';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { AuthService } from '../Services/AuthService/auth.service';
import { LicenseService } from '../Services/LicenseService/license.service';

import { faInfo, faAddressBook, faPen, faSave, faClipboard, faSync, faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import {LicenseStat, LicenseInfo, License, LicenseClient} from '../Models/LicenzeStat';

import { ShopService } from '../Services/ShopService/shop.service';
import { AlertService } from 'ngx-alerts';
import Censimento from '../Models/Censimento';
import { User } from '../Models/User';


@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LicensesComponent implements OnInit {

  public date : number;
  public modalRef: BsModalRef; //Modal
  public imagePathList: string; //ImagePath list

  public list: boolean = true;

  // JSON for Visual and Modules
  public AllLicenses: LicenseInfo[] = [];
  public Licenses: LicenseInfo[] = [];
  public licenzeStats: LicenseStat[] = [];

  //GENERAZIONE LICENZE


  //LICENSE STATS
  public StatsModal: string = "";

  public LicenzeAcquistate: number = 0;
  public LicenzeCanone: number = 0;
  public generated: boolean = false;
  public popupInfo: string = ""
  public censimentoInfo: any = {
    esiste: false,
    licence: "",
    PIVA: "",
    RAGSOC: "",
    NMAG: "",
    NCASSA: "",
    MAC: "",
  };

  public faInfo: IconDefinition = faInfo;
  public faAddress: IconDefinition = faAddressBook;
  public faPen: IconDefinition = faPen;
  public faUp: IconDefinition = faChevronUp;
  public faDown: IconDefinition = faChevronDown;
  public faSync: IconDefinition = faSync;
  public faSave: IconDefinition = faSave;
  public faClipboard: IconDefinition = faClipboard;

  //LICENZE UPGRADE
  public UpgradeLicenze: LicenseInfo;
  public CanRetail: any =  {
    CanRetail: false,
    Tipo: {
      Start: false,
      Pro: false,
      Canone: false
    }
  }
  public IsClient: boolean;

  public Filtri: any = {
    Canone: true,
    Spot: true,
    Gab: true,
    Concessionari: false
  }

  // 0 -->
  // 1 --> Crescente
  // 2 --> Descrescente
  public Sort: any = {
    DataCreazione: 2,
    DataAttivazione: 0,
    DataScadenza: 0,
    DataLimite: 0,
  }

  public Loading = false;

  //CASHMATIC FIELD
  public cashmaticSelected: boolean = false;
  public cashmaticGeneratedLicense: string = "";
  public alertOpen: boolean = false;
  public alertMsg: string = "";
  public UserConcessionari: User[] = [];
  public SelectedConcessionarioID: number = 0;


  constructor(private http: HttpClient, public auth: AuthService,
    private router: Router, public modalService: BsModalService, private alertService: AlertService, private censisService: CensisService,
    public licenseService: LicenseService, private clipboard: ClipboardService, private ShopService: ShopService, private userService: UserService) {
    this.date = new Date().getFullYear();
  }

  private async Init() {
    this.auth.Ready = false;

    this.Loading = true;
    await this.LoadLicenses();
    await this.LoadStats();
    this.Loading = false;

    this.LicenzeAcquistate = this.LicenzeAcquistateCount();
    this.LicenzeCanone = this.LicenzeCanoneCount();


    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }

    this.auth.Ready = true;

    //AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName("li");
      for (let i = 0; i < sidebarElements.length; ++i) {
        if (sidebarElements[i].id !== 'licenses') {
          sidebarElements[i].classList.remove('active');
        } else {
          sidebarElements[i].classList.add('active');
        }
      }
  }


  async ngOnInit() {
    await this.Init();
  }

  public async LoadLicenses() {

    this.AllLicenses = await this.licenseService.GetLicenses();

    // SE CASHSERVIZI, INSERISCO SOLO LE LICENZE CON VISUAL KIOSK LITE
    if (this.auth.IsCashServizi) {
      this.Licenses = this.AllLicenses.filter((value) => {
        return value.License.Modules[3] === 'S';
      })
    } else {
      this.Licenses = [...this.AllLicenses];
    }

    if (this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role === 4) {
      this.Filtro();
    }

    return;
  }

  /*******LICENZE STATS*****/



  public ApriDettagli(template: TemplateRef<any>, tipo: string) {
    this.StatsModal = tipo;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }


  private async LocalValidation() {
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));

      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if(valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.Ready = false;
        this.auth.IsLogin = true;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }

  public Cerca() {

    this.Filtro();

    const input: string = (document.getElementById("search") as HTMLInputElement).value;

    let t0 = performance.now();
    // LISTA DI TUTTE LE LICENZE, COMPRESE LE CLIENT
    const filteredList: LicenseInfo[] = this.Licenses.filter((l) => {

      if (l.LicenseClient.length > 0) {
        for (let i = 0; i < l.LicenseClient.length; ++i) {
          if (l.LicenseClient[i].License.License.toUpperCase().includes(input.toUpperCase())) {
            return true;
          }
        }
      }

      return l.License.License.toUpperCase().includes(input.toUpperCase());
    });

    let t1 = performance.now();


    this.Licenses = filteredList;
  }
  private Filtro() {
    this.Licenses = [];

    for (let i = 0; i < this.AllLicenses.length; ++i) {

      if (this.Filtri.Canone) {
        if (this.AllLicenses[i].ExpirationDate !== 'SPOT' && !this.Licenses.includes(this.AllLicenses[i])) {
          this.Licenses.push(this.AllLicenses[i]);
          continue;
        }
      }

      if (this.Filtri.Spot) {
        if (this.AllLicenses[i].ExpirationDate === 'SPOT' && !this.Licenses.includes(this.AllLicenses[i])) {
          this.Licenses.push(this.AllLicenses[i]);
          continue;
        }
      }
    }


    for (let i = 0; i < this.AllLicenses.length; ++i) {

      if (this.Filtri.Gab && !this.Filtri.Concessionari) {

        if (this.auth.IsCashServizi) {

          if (this.AllLicenses[i].Creatore !== 'cashmatic') {
            const index: number = this.Licenses.indexOf(this.AllLicenses[i]);
            if (index !== -1) {
              this.Licenses.splice(index, 1);
            }
            continue;
          }

        } else {

          if (this.AllLicenses[i].Creatore !== this.auth.AuthUser.Username) {
            const index: number = this.Licenses.indexOf(this.AllLicenses[i]);
            if (index !== -1) {
              this.Licenses.splice(index, 1);
            }
            continue;
          }

        }
      }
      if (!this.Filtri.Gab && this.Filtri.Concessionari) {
        if (this.AllLicenses[i].Creatore === 'gab') {
          const index: number = this.Licenses.indexOf(this.AllLicenses[i]);
          if (index !== -1) {
            this.Licenses.splice(index, 1);
          }
          continue;
        }
      }
      if (this.Filtri.Gab && this.Filtri.Concessionari) {
        continue;
      }
      if (!this.Filtri.Gab && !this.Filtri.Concessionari) {
        const index: number = this.Licenses.indexOf(this.AllLicenses[i]);
        if (index !== -1) {
          this.Licenses.splice(index, 1);
        }
        continue;
      }
    }
  }

  private StringToDate(date: string): Date {

    if (date === "N/D") {
      return new Date("2001-01-01");
    }
    if (date === "SPOT") {
      return new Date("2099-01-01");
    }

    const stringFormat: string[] = date.split('/');

    const yyyymmdd = stringFormat[2] + '-' + stringFormat[1] + '-' + stringFormat[0];

    return new Date(yyyymmdd);
  }

  /*
  public Filter() {
    const input: string = (document.getElementById("search") as HTMLInputElement).value;

    const table: HTMLTableElement = document.getElementById("table") as HTMLTableElement;
    console.log(table)
    const tr: HTMLCollectionOf<HTMLTableRowElement> = table.getElementsByTagName("tr");

    for (let i = 0; i < tr.length; i++) {
      const td: HTMLTableDataCellElement = tr[i].getElementsByTagName("td")[0];

      if (td) {
          const txtValue: string = td.innerHTML;

          if (txtValue.toUpperCase().includes(input.toUpperCase())) {
              tr[i].style.display = "";
          } else {
              tr[i].style.display = "none";
          }
      }
    }
  }
  */



  public async PanelCreation(template?: TemplateRef<any>) {

    if (this.auth.IsCashServizi) {

      const users: User[] = await this.userService.LoadUsers(this.auth.AuthUser.Username);

      for (let i = 0; i < users.length; ++i) {
        if (users[i].Role === 1 || users[i].Role2 === 1) {
          this.UserConcessionari.push(users[i]);
        }
      }

      this.modalRef = this.modalService.show(template);

      this.modalRef.onHide.subscribe(r => {
        this.LoadLicenses();
        this.cashmaticGeneratedLicense = "";
        this.UserConcessionari = [];
        this.SelectedConcessionarioID = 0;
        this.cashmaticSelected = false;
      })


      return;
    }

    this.list = !this.list;
  }


  public async Back() {

    this.generated = false;
    //this.LicenseGenerationCompleted = false;
    //this.reset();
    this.list = true;
  }
  public OpenModalInfo(licenseInfo: License, template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  public async GetCensimento(licenza: string) {


    let result: any = await this.censisService.SingleCensimento(licenza);


    try {
      this.censimentoInfo.esiste = true;
      this.censimentoInfo.licence = licenza;
      this.censimentoInfo.PIVA = result.piva;
      this.censimentoInfo.RAGSOC = result.ragsoc;
      this.censimentoInfo.NMAG = result.nmag;
      this.censimentoInfo.NCASSA = result.ncassa;
      this.censimentoInfo.MAC = (result.mac as string).substring(0, result.mac.length - 1);

      return true;
    } catch {
      this.censimentoInfo.esiste = false;
      this.censimentoInfo.PIVA = "";
      this.censimentoInfo.RAGSOC = "";
      this.censimentoInfo.NMAG = "";
      this.censimentoInfo.NCASSA = "";
      this.censimentoInfo.MAC = "";

      return false;
    }

  }

  public GetTooltip(modules: string) {

    let string: string = "";
    const submod: string = modules.substr(5);

    if (submod.length === 0) {
      return "Versione Demo"
    } else {
      for(let i = 0; i < submod.length; ++i) {
        switch(i) {
          case 0:
            {
              if(submod[i] === "1") {
                string = string + "Collegamento Cassetto Automatico, "
              }
              break;
            }
          case 1:
            {
              if(submod[i] === "1") {
                string = string +  "Promo Cassa, "
              }
              break;
            }
          case 2:
            {
                if(submod[i] === "1") {
                  string = string +  "Celiachia, "
                }
                break;
            }
          case 3:
            {
                if(submod[i] === "1") {
                  string = string +  "Modulo 730, "
                }
                break;
            }
          case 4:
            {
                if(submod[i] === "1") {
                  string = string +  "Tabacchi, "
                }
                break;
            }
          case 5:
            {
              if(submod[i] === "1") {
                string = string +  "Cooki, "
              }
              break;
            }
          case 6:
            {
              if(submod[i] === "1") {
                string = string + "Keenon, "
              }
              break;
            }
          case 7:
            {
              if(submod[i] === "1") {
                string = string +  "Visual B2B, "
              }
              break;
            }
        }

      }

      return string.substring(0, string.length - 2);
    }
  }


  public async LoadStats() {

    //GAB
    this.licenzeStats = [];

    const result = await this.licenseService.GetLicenseCount();

    const licenseStats: LicenseStat[] = [];

    for (let i = 0; i < result.length; ++i) {
      const licenzeStat: LicenseStat = new LicenseStat();
      licenzeStat.CodiceArticolo = result[i].codiceArticolo;
      licenzeStat.NumeroLicenze = result[i].numeroLicenze;
      licenzeStat.LicenzeGenerate = result[i].licenzeGenerate;
      licenzeStat.Data = result[i].dataAcquisto;
      licenzeStat.GetDescrizione(this.ShopService.ListinoSoftware);

      if (licenzeStat.Descrizione.toUpperCase().includes("VISUAL") || licenzeStat.CodiceArticolo === "CANONE") {
        licenseStats.push(licenzeStat);
      }

    }

    this.licenzeStats = licenseStats;

    return;
  }

  public LicenzeAcquistateCount() {
    let count: number = 0;
    for (let i = 0; i < this.licenzeStats.length; ++i) {
      if (this.licenzeStats[i].CodiceArticolo !== "CANONE") {
        count += this.licenzeStats[i].NumeroLicenze;
      }
    }

    return count;
  }

  public LicenzeCanoneCount() {
    let count: number = 0;
    for (let i = 0; i < this.licenzeStats.length; ++i) {
      if (this.licenzeStats[i].CodiceArticolo === "CANONE") {
        return this.licenzeStats[i].NumeroLicenze;
      }
    }
    return count;
  }

  public async OpenModalCensimento(template: TemplateRef<any>, licenza: string) {
    await this.GetCensimento(licenza);
    this.modalService.show(template);
  }

  public OpenModalUpgrade(licenseInfo: LicenseInfo, upgradeLicenza: TemplateRef<any>, client: boolean) {

    this.UpgradeLicenze = licenseInfo;

    this.IsClient = client;

    this.CanRetail.CanRetail = false;

    if (licenseInfo.License.Modules[4] === '0') {
      //SE ESISTE, SCALO DAL CANONE
      if (this.licenzeStats.find(stat => stat.CodiceArticolo === "CANONE") && this.auth.AuthUser.Tipo.includes('C')) {
        this.CanRetail.CanRetail = true;
        this.CanRetail.Tipo.Canone = true;
      } else {
        for (let i = 0; i < this.licenzeStats.length; ++i) {
          if (this.licenzeStats[i].CodiceArticolo === 'SWG000001001' && this.licenzeStats[i].LicenzeGenerate < this.licenzeStats[i].NumeroLicenze) {
            this.CanRetail.CanRetail = true;
            this.CanRetail.Tipo.Start = true;
          }
          if (this.licenzeStats[i].CodiceArticolo === 'SWG000001002' && this.licenzeStats[i].LicenzeGenerate < this.licenzeStats[i].NumeroLicenze) {
            this.CanRetail.CanRetail = true;
            this.CanRetail.Tipo.Pro = true;
          }
        }
      }
    }


    this.modalRef = this.modalService.show(upgradeLicenza);
  }

  public async handleLicense() {
    await this.LoadLicenses();
  }

  public async GenerateLicenseCashmatic() {

    let result: any;

    if (this.auth.AuthUser.ID !== 44) {
      this.SelectedConcessionarioID = this.auth.AuthUser.ID;
    }


    if (this.SelectedConcessionarioID !== 0) {
      result = await this.licenseService.GenerateLicenseCashmatic(this.SelectedConcessionarioID)
    } else {
      result = await this.licenseService.GenerateLicenseCashmatic()
    }

    this.cashmaticGeneratedLicense = result.license;

    return;

  }

  public SortLicenses(tipo: string) {
    if (tipo === "Creazione") {
      this.Sort.DataAttivazione = 0;
      this.Sort.DataScadenza = 0;
      this.Sort.DataLimite = 0;

      if (this.Sort.DataCreazione == 2) this.Sort.DataCreazione = 1;
      else this.Sort.DataCreazione++;

      this.Licenses.sort((a: LicenseInfo, b: LicenseInfo) => {
        const creationDateA: Date = this.StringToDate(a.CreationDate);
        const creationDateB: Date = this.StringToDate(b.CreationDate);

        // CRESCENTE --> 1
        if (this.Sort.DataCreazione === 1) {
          if (creationDateA.getTime() < creationDateB.getTime()) {
            return -1;
          }
          if (creationDateA.getTime() > creationDateB.getTime()) {
            return 1;
          }
          return 0;
        }
        // DECRESCENTE --> 2
        if (this.Sort.DataCreazione === 2) {
          if (creationDateA.getTime() < creationDateB.getTime()) {
            return 1;
          }
          if (creationDateA.getTime() > creationDateB.getTime()) {
            return -1;
          }
          return 0;
        }
      });
    }

    if (tipo === "Attivazione") {
      this.Sort.DataCreazione = 0;
      this.Sort.DataScadenza = 0;
      this.Sort.DataLimite = 0;

      if (this.Sort.DataAttivazione == 2) this.Sort.DataAttivazione = 1;
      else this.Sort.DataAttivazione++;

      this.Licenses.sort((a: LicenseInfo, b: LicenseInfo) => {
        const creationDateA: Date = this.StringToDate(a.ActivationDate);
        const creationDateB: Date = this.StringToDate(b.ActivationDate);

        // CRESCENTE --> 1
        if (this.Sort.DataAttivazione === 1) {
          if (creationDateA.getTime() < creationDateB.getTime()) {
            return -1;
          }
          if (creationDateA.getTime() > creationDateB.getTime()) {
            return 1;
          }
          return 0;
        }
        // DECRESCENTE --> 2
        if (this.Sort.DataAttivazione === 2) {
          if (creationDateA.getTime() < creationDateB.getTime()) {
            return 1;
          }
          if (creationDateA.getTime() > creationDateB.getTime()) {
            return -1;
          }
          return 0;
        }
      });
    }

    if (tipo === "Scadenza") {
      this.Sort.DataAttivazione = 0;
      this.Sort.DataCreazione = 0;
      this.Sort.DataLimite = 0;

      if (this.Sort.DataScadenza == 2) this.Sort.DataScadenza = 1;
      else this.Sort.DataScadenza++;

      this.Licenses.sort((a: LicenseInfo, b: LicenseInfo) => {
        const creationDateA: Date = this.StringToDate(a.ExpirationDate);
        const creationDateB: Date = this.StringToDate(b.ExpirationDate);

        // CRESCENTE --> 1
        if (this.Sort.DataScadenza === 1) {
          if (creationDateA.getTime() < creationDateB.getTime()) {
            return -1;
          }
          if (creationDateA.getTime() > creationDateB.getTime()) {
            return 1;
          }
          return 0;
        }
        // DECRESCENTE --> 2
        if (this.Sort.DataScadenza === 2) {
          if (creationDateA.getTime() < creationDateB.getTime()) {
            return 1;
          }
          if (creationDateA.getTime() > creationDateB.getTime()) {
            return -1;
          }
          return 0;
        }
      });
    }

    if (tipo === "Limite") {
      this.Sort.DataAttivazione = 0;
      this.Sort.DataScadenza = 0;
      this.Sort.DataCreazione = 0;

      if (this.Sort.DataLimite == 2) this.Sort.DataLimite = 1;
      else this.Sort.DataLimite++;

      this.Licenses.sort((a: LicenseInfo, b: LicenseInfo) => {
        const creationDateA: Date = this.StringToDate(a.ActivationDateLimit);
        const creationDateB: Date = this.StringToDate(b.ActivationDateLimit);

        // CRESCENTE --> 1
        if (this.Sort.DataLimite === 1) {
          if (creationDateA.getTime() < creationDateB.getTime()) {
            return -1;
          }
          if (creationDateA.getTime() > creationDateB.getTime()) {
            return 1;
          }
          return 0;
        }
        // DECRESCENTE --> 2
        if (this.Sort.DataLimite === 2) {
          if (creationDateA.getTime() < creationDateB.getTime()) {
            return 1;
          }
          if (creationDateA.getTime() > creationDateB.getTime()) {
            return -1;
          }
          return 0;
        }
      });
    }


  }

  public copyClickboard() {
    this.clipboard.copyFromContent(this.cashmaticGeneratedLicense);
    this.alertService.info("Copiato nella clipboard");
  }

  public CheckExpired(license: LicenseInfo) {

    if (license.ExpirationDate === "N/D") {
      return false;
    }

    const date: string[] = license.ExpirationDate.split('/');
    const dateNumber: number[] = date.map(date => {return Number(date)});

    const expirationDate = new Date(dateNumber[2], dateNumber[1] - 1, dateNumber[0]);
    expirationDate.setDate(expirationDate.getDate() + 7);
    expirationDate.setHours(0);
    expirationDate.setMinutes(0);
    expirationDate.setSeconds(0);

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    return today.getTime() >= expirationDate.getTime() && license.Stato === 2;
  }
}






