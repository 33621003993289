import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StatsComponent } from './stats/stats.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { LicensesComponent } from './licenses/licenses.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ManualsComponent } from './manuals/manuals.component';
import { ListsComponent } from './lists/lists.component';
import { UpdatesComponent } from './updates/updates.component';
import { UsersComponent } from './users/users.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CensusComponent } from './census/census.component';
import { ShopComponent } from './shop/shop.component';
import { ShopAcquistoComponent } from './shop/shop-acquisto/shop-acquisto.component';
import { CartComponent } from './shop/cart/cart.component';

import { AlertModule as AM } from 'ngx-alerts';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';

import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs';

import { CensurCardComponent } from './censur-card/censur-card.component';
import { LicenseUpgradeComponent } from './licenses/license-upgrade/license-upgrade.component';
import { UserSoftwareConfigComponent } from './users/user-software-config/user-software-config.component';
import { StoreManagerComponent } from './admin/store-manager/store-manager.component';
import { ImgViewerComponent } from './admin/store-manager/img-viewer/img-viewer.component';
import { DettaglioProdottoComponent } from './shop/dettaglio-prodotto/dettaglio-prodotto.component';
import { BuysinglelicenseComponent } from './shop/buysinglelicense/buysinglelicense.component';
import { BuypackagelicenseComponent } from './shop/buypackagelicense/buypackagelicense.component';
import { ChoosepackageComponent } from './shop/choosepackage/choosepackage.component';
import { ShopheaderComponent } from './shop/shopheader/shopheader.component';
import { TipologialicenzaComponent } from './shop/tipologialicenza/tipologialicenza.component';
import { CheckoutComponent } from './shop/checkout/checkout.component';

import { defineLocale, itLocale } from 'ngx-bootstrap/chronos';

import { StripePaymentComponent } from './payment/stripe-payment/stripe-payment.component';
import { PaypalPaymentComponent } from './payment/paypal-payment/paypal-payment.component';
import { UserPersonalComponent } from './user-personal/user-personal.component';
import { SignupComponent } from './signup/signup.component';
import { CreaElementoComponent } from './admin/store-manager/crea-elemento/crea-elemento.component';
import { ModificaElementoComponent } from './admin/store-manager/modifica-elemento/modifica-elemento.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UserAdminPermissionConfigComponent } from './users/user-admin-permission-config/user-admin-permission-config.component';
import { LicenseGeneraComponent } from './licenses/license-genera/license-genera.component';
import { ChangelogComponent } from './sidebar/changelog/changelog.component';

import { IsDev } from './global';
import { CensimentoInfoComponent } from './licenses/censimento-info/censimento-info.component';
import { UserCreateCashmaticComponent } from './users/cashmatic/user-create-cashmatic/user-create-cashmatic.component';
import { UserCfgEditComponent } from './users/user-cfg-edit/user-cfg-edit.component';

import { AddHeaderInterceptor } from './Services/HTTPHeader';
import { AdminComponent } from './admin/admin.component';
import { LogComponent } from './admin/log/log.component';
import { GroupManagerComponent } from './cloud/group-manager/group-manager.component';
import { CenterManagerComponent } from './cloud/group-manager/center-manager/center-manager.component';
import { CenterformComponent } from './cloud/group-manager/center-manager/centerform/centerform.component';
import { SellpointformComponent } from './cloud/group-manager/center-manager/sellpointform/sellpointform.component';
import { BusinessManagerComponent } from './cloud/business-manager/business-manager.component';
import { CloudComponent } from './cloud/cloud.component';
import { LicensesExpiringComponent } from './stats/licenses-expiring/licenses-expiring.component';
import { LicenseManagerComponent } from './cloud/license-manager/license-manager.component';

defineLocale('it', itLocale);

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: (IsDev.active) ? 'localhost' : 'gabservizi.it'
  },
  position: "bottom-left",
  theme: "edgeless",
  palette: {
    popup: {
      background: "#3472F7",
      text: "#ffffff",
      link: "#ffffff"
    },
    button: {
      background: "#f1d600",
      text: "#000000",
      border: "transparent"
    }
  },
  type: "info",
  content: {
    message: "Questo sito utilizza cookie al fine di consentire la normale navigazione e fruizione del sito stesso e per tracciare informazioni statistiche in forma anonima e aggregata. La prosecuzione della normale navigazione comporta la prestazione del consenso all'uso dei cookie.",
    allow: "Ok",
    dismiss: "Ok",
    link: "Cookie Policy",
    href: "https://www.gabtamagnini.it/cookie-policy/",
    policy: "Cookie Policy"
  }
};


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    StatsComponent,
    LoginComponent,
    LicensesComponent,
    ManualsComponent,
    ListsComponent,
    UpdatesComponent,
    UsersComponent,
    CensusComponent,
    ShopComponent,
    ShopAcquistoComponent,
    CartComponent,
    CensurCardComponent,
    LicenseUpgradeComponent,
    UserSoftwareConfigComponent,
    DettaglioProdottoComponent,
    StoreManagerComponent,
    ImgViewerComponent,
    BuysinglelicenseComponent,
    BuypackagelicenseComponent,
    ChoosepackageComponent,
    ShopheaderComponent,
    TipologialicenzaComponent,
    CheckoutComponent,
    ImgViewerComponent,
    StripePaymentComponent,
    PaypalPaymentComponent,
    UserPersonalComponent,
    SignupComponent,
    CreaElementoComponent,
    ModificaElementoComponent,
    UserCreateComponent,
    UserEditComponent,
    UserAdminPermissionConfigComponent,
    LicenseGeneraComponent,
    ChangelogComponent,
    CensimentoInfoComponent,
    UserCreateCashmaticComponent,
    UserCfgEditComponent,
    AdminComponent,
    LogComponent,
    GroupManagerComponent,
    CenterManagerComponent,
    CenterformComponent,
    SellpointformComponent,
    BusinessManagerComponent,
    CloudComponent,
    LicensesExpiringComponent,
    LicenseManagerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    ClipboardModule,
    TooltipModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    AM.forRoot({maxMessages:5,timeout:1000,positionX:"right",positionY:'bottom'}),
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
      TabsetConfig,
      CookieService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AddHeaderInterceptor,
        multi: true,
      }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
