import { IsDev } from './../global';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../Services/AuthService/auth.service';
import { ShopService } from '../Services/ShopService/shop.service';
import {UserStoreService} from '../Services/Admin/user-store.service';

import {IconDefinition, faShoppingCart, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import Acquisto from '../Models/Acquisto';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from 'ngx-alerts';
import SoftwareListino from '../Models/SoftwareListino';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

  // ICONS
  public faPen: IconDefinition = faPen;
  public faTrash: IconDefinition = faTrash;
  //CATEGORIE
  public categorie: any;

  // FLAG
  public NuovoOrdine = false;


  //FLAG DI SELEZIONE LICENZA

  // MODAL
  public modalRef: BsModalRef;
  public modalRef2: BsModalRef;
  constructor(private http: HttpClient, public auth: AuthService, private router: Router,
              public ShopService: ShopService, public modalService: BsModalService, private alertService: AlertService, public userStoreService:UserStoreService) {

  }

  private async Init() {
    this.auth.Ready = false;
    // AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName('li');
    for (let i = 0; i < sidebarElements.length; ++i) {
      if (sidebarElements[i].id !== 'shop') {
        sidebarElements[i].classList.remove('active');
      } else {
        sidebarElements[i].classList.add('active');
      }
    }
    if (this.ShopService.IsInit === false) {
      await this.ShopService.Init();
      this.ShopService.IsInit = true;
    }
    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }

    this.auth.Ready = true;
    this.ShopService.ShowTipologiaLicenza = true;
  }

  private async LocalValidation() {

    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));

      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if (valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.Ready = false;
        this.auth.IsLogin = true;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }



  async ngOnInit() {
    /*
    if (!(this.auth.AuthUser.Tipo.includes('S') || this.auth.AuthUser.Tipo.includes('P'))) {
      this.router.navigateByUrl('');
      return;
    }
    */

    if (this.auth.AuthUser.Role === 2) {
      this.router.navigate(["shop/acquisto-licenza-singola"]);
      this.ShopService.ShowTipologiaLicenza = false;
    }

    await this.Init();


  }

}
