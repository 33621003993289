
    <section *ngIf="ShopService.ShowSelezioneQuantitaPacchetti==true" style="margin-bottom: 30px;">
      <h1 class="quantita-selection-title">Seleziona la quantità desiderata</h1>
      <div fxLayout="row" fxLayout.lg="row" fxLayout.lt-lg="column" fxLayoutGap="16px">
        <div fxFlex="25%" fxFlex.lg="25%" fxFlex.lt-lg="100%">
          <div class="owl-item active" class="PacchettiQuantita" >
            <div class="single-product">
              <div class="product-f-image">
                <img src="./../../../assets/img/5pacchetti.png"class="PacchettiQuantita-img" alt="">
                <div class="product-hover">
                  <a class="view-details-link" (click)="ContinueAcquistoPacchetti(5)">Continua</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="25%" fxFlex.lt-lg="100%">
          <div class="owl-item active" class="PacchettiQuantita">
            <div class="single-product">
              <div class="product-f-image">
                <img src="./../../../assets/img/10pacchetti.png" class="PacchettiQuantita-img" alt="">
                <div class="product-hover">
                  <a  class="view-details-link" (click)="ContinueAcquistoPacchetti(10 )">Continua</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="25%" fxFlex.lt-lg="100%">
          <div class="owl-item active" class="PacchettiQuantita">
            <div class="single-product">
              <div class="product-f-image">
                <img src="./../../../assets/img/15pacchetti.png" class="PacchettiQuantita-img" alt="">
                <div class="product-hover">
                  <a  class="view-details-link" (click)="ContinueAcquistoPacchetti(15)">Continua</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="25%" fxFlex.lt-lg="100%">
          <div class="owl-item active" class="PacchettiQuantita">
            <div class="single-product">
              <div class="product-f-image">
                <img src="./../../../assets/img/20pacchetti.png" class="PacchettiQuantita-img" alt="">
                <div class="product-hover">
                  <a  class="view-details-link" (click)="ContinueAcquistoPacchetti(20)">Continua</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
