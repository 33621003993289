import { AlertService } from 'ngx-alerts';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from './../Services/UserService/user.service';
import { AuthService } from './../Services/AuthService/auth.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Tipo, Payment } from '../Models/Payment';
import { User } from '../Models/User';
import {faCheck, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-personal',
  templateUrl: './user-personal.component.html',
  styleUrls: ['./user-personal.component.scss']
})
export class UserPersonalComponent implements OnInit {

  public pagamenti: Payment[] = [];
  public Storico = []; //PUO' ESSERE SIA UN ARRAY DI ACQUISTI O UN ARRAY DI ORDINI

  public userToShow: User;

  public PasswordDate = {
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: ''
  };

  public ErrorPassword = {
    passwordOld: false,
    passwordSpace: false,
    passwordRepeat: false,
  }

  // ICONS
  public faCheck: IconDefinition = faCheck;

  constructor(public auth: AuthService, private router: Router, private user: UserService, public modalService: BsModalService, private alert: AlertService) { 
    this.userToShow = this.auth.AuthUser;
  }

  private async Init() {
    this.auth.Ready = false;

    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }

    await this.auth.CompleteUserInfo();

    this.auth.Ready = true;

    //AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName("li");
      for (let i = 0; i < sidebarElements.length; ++i) {
        if (sidebarElements[i].id !== 'userpersonal') {
          sidebarElements[i].classList.remove('active');
        } else {
          sidebarElements[i].classList.add('active');
        }
      }
  }


  async ngOnInit() {
    await this.Init();
  }

  private async LocalValidation() {
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));

      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if(valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.Ready = false;
        this.auth.IsLogin = true;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }

  public async GetPagamenti(template: TemplateRef<any>) {
    this.pagamenti = await this.user.GetPagamenti(this.auth.AuthUser);
    this.modalService.show(template, {class: "modal-lg"});
  }

  public async GetStorico(template: TemplateRef<any>) {
    this.Storico = await this.user.GetStorico(this.auth.AuthUser);

    this.modalService.show(template, {class: "modal-lg"});
  }

  public async UpdatePassword() {
    this.ErrorPassword = {
      passwordOld: false,
      passwordSpace: false,
      passwordRepeat: false,
    }
    
    if (this.PasswordDate.newPassword.includes(' ')) {
      this.ErrorPassword.passwordSpace = true;
      return;
    }

    if (this.PasswordDate.newPassword !== this.PasswordDate.repeatNewPassword) {
      this.ErrorPassword.passwordRepeat = true;
      return;
    }

    const result = await this.user.UpdatePassword(this.auth.AuthUser.ID, this.PasswordDate.oldPassword, this.PasswordDate.newPassword);

    if (result) {
      this.modalService.hide();
      this.alert.success("Password modificata con successo!");
      return;
    } else {
      this.ErrorPassword.passwordOld = true
    }


  }


}

