import { AuthService } from './../../../../Services/AuthService/auth.service';
import { AlertService } from 'ngx-alerts';
import { CenterService } from 'src/app/Services/Cloud/center.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BusinessService } from './../../../../Services/Cloud/business.service';
import { CompanyBusiness } from 'src/app/Models/Cloud/CompanyBusiness';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Center } from 'src/app/Models/Cloud/Center';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'center-form',
  templateUrl: './centerform.component.html',
  styleUrls: ['./centerform.component.scss']
})
export class CenterformComponent implements OnInit {

  @Input() public center: Center;
  @Output() public UpdateGroup: EventEmitter<void> = new EventEmitter();

  public companyBusinesses: CompanyBusiness[];
  public SelectedBusiness: CompanyBusiness;

  constructor(private businessService: BusinessService, public centerService: CenterService, public modalService: BsModalService, private alertService: AlertService, public auth: AuthService) { }

  async ngOnInit(): Promise<void> {
    this.companyBusinesses = await lastValueFrom(this.businessService.GetBusiness());
    if (this.center.business === null) {
      this.center.business = this.companyBusinesses[0];
    }
  }

  public async EditCenter() {

    if ((this.center.companyName === undefined || this.center.companyName === "") || (this.center.vatNumber === undefined || this.center.vatNumber === "")) {
      this.alertService.danger("Impossibile ragione sociale e IVA per continuare");
      return;
    }

    const success = this.center.id === undefined ? await this.centerService.CreateCenter(this.center) : await this.centerService.EditCenter(this.center)

    if (success !== null) {
      this.modalService.hide();
      this.alertService.success("Centro modificato con successo");
    } else {
      this.modalService.hide();
      this.alertService.danger("Impossibile modificare il centro");
    }

    this.UpdateGroup.emit();
  }

  compareByID(businessOne: CompanyBusiness, businessTwo: CompanyBusiness) {
    return businessOne && businessTwo && businessOne.id == businessTwo.id;
  }

}
