import { lastValueFrom } from 'rxjs';
import { AuthService } from './../AuthService/auth.service';
import { LicenseInfo, LicenseClient, License } from './../../Models/LicenzeStat';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShopService } from '../ShopService/shop.service';
import { Selected } from 'src/app/licenses/license-genera/license-genera.component';
import { ModuleInfo } from 'src/app/licenses/license-upgrade/license-upgrade.component';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {


  public async ChangeLicenzeState(licenseInfo: LicenseInfo) {
    
    // const id = await this.auth.GetIdByUsername();
    
    const result: any = await this.http.post('/api/license/state', {license: licenseInfo.License.License, stato: licenseInfo.Stato}, {}).toPromise();

    if (result.response === "ok") {

      if (licenseInfo.Stato != 1) {
        for (let i = 0; i < licenseInfo.LicenseClient.length; ++i) {
          licenseInfo.LicenseClient[i].Stato = licenseInfo.Stato;
        }

      }
      return true;
    }

    return false;
  }

  public async ChangeLicenseStateClient(licenseClient: LicenseClient) {

    // const id = await this.auth.GetIdByUsername();

    const result: any = await this.http.post('/api/license/state', {license: licenseClient.License.License, stato: licenseClient.Stato, id: this.auth.AuthUser.ID}, {}).toPromise();

    return result.response === "ok";
  }

  public async ChangeMacAddress(MacAddress: string, license: string) {

    // const id = await this.auth.GetIdByUsername();

    const result: any = await this.http.post('/api/license/mac', {mac: MacAddress, license}, {}).toPromise();

    return result.response === "ok";
  }

  public async SendMailClient(License: LicenseInfo, quantita: number) {
    //SALVO LA NUOVA MAIL
    await this.http.post('/api/license/client/mail',
    {licenza: License.License.License, creazione: License.CreationDate, attivazione: License.ActivationDate, scadenza: License.ExpirationDate, quantita,
      id: this.auth.AuthUser.ID, username: this.auth.AuthUser.Username}).toPromise();
  }
  
  public async GenerateLicense(selected: Selected, TipoGenerazione: string, UserIDLicenza?: number, UserIDSegnalatore?: number, ScadenzaLicenza?: Date) {
    
    if (UserIDLicenza && UserIDSegnalatore && ScadenzaLicenza) {
      return this.http.post('/api/license/master/gen',
         {Modules: selected.Modules, Visual: selected.Visual, UserID: selected.UserID, Username: selected.Username,
            TipoGenerazione, CodiceArticolo: selected.CodiceArticolo, UserIDLicenza: UserIDLicenza, UserIDSegnalatore: UserIDSegnalatore, ScadenzaLicenza: ScadenzaLicenza}, {}).toPromise(); 
    }
    
    return await this.http.post('/api/license/master/gen',
    {Modules: selected.Modules, Visual: selected.Visual, UserID: selected.UserID, Username: selected.Username, TipoGenerazione, CodiceArticolo: selected.CodiceArticolo}, {}).toPromise();
    
  }

  public async GenerateLicenseClient(id: number, license: string) {
    return await this.http.post('/api/license/client/gen', {id, license}, {responseType: 'text'}).toPromise();

  }

  public async UpgradeLicense(Upgradable: any, moduli: ModuleInfo[], license: string, id: number, CanRetail: any): Promise<string> {
    const result: any = await this.http.post('/api/license/upgrade',
     {upgradable: Upgradable, moduli, license, id, canone: CanRetail.Tipo.Canone}).toPromise();

     return result.response;
  }

  

  public async GenerateLicenseDemo() {
    return await this.http.post('/api/license/demo/gen',
         {UserID: this.auth.AuthUser.ID, Username: this.auth.AuthUser.Username}, {}).toPromise();

  }

  public async GenerateLicenseCashmatic(id?: number) {
    return this.http.post('/api/license/cashmatic', id ? {id} : {}, {}).toPromise();
  }

  public async GetLicenses(): Promise<LicenseInfo[]> {
    const params: HttpParams = new HttpParams()
    .set("id", String(this.auth.AuthUser.ID));

    let result: any = await this.http.get('/api/license', {params}).toPromise();

    while (result === null) {
      result = await this.http.get('/api/license', {params}).toPromise();
    }

    const licenses: LicenseInfo[] = [];

    for (let i = 0; i < result.length; ++i) {

      const licenseInfo: LicenseInfo = await this.GenerateLicenseInfo(result[i]);

      licenses.push(licenseInfo)
    }

    return licenses;
  }

  public async GetExpiringLicenses(): Promise<LicenseInfo[]> {
    const params: HttpParams = new HttpParams()
    .set("id", String(this.auth.AuthUser.ID));

    let result: any = await lastValueFrom(this.http.get('/api/license/expiring', {params}));


    const licenses: LicenseInfo[] = [];

    for (let i = 0; i < result.length; ++i) {

      const licenseInfo: LicenseInfo = await this.GenerateLicenseInfo(result[i]);

      licenses.push(licenseInfo)
    }

    return licenses;
  }



    //Numero degli elementi selezionati

    private async GenerateLicenseInfo(result: any) {
      const licenseInfo: LicenseInfo = new LicenseInfo();
  
      let resultValue: string = result.activationDate;
      licenseInfo.ActivationDate = resultValue.substr(8,2) + "/" + resultValue.substr(5,2) + "/" + resultValue.substr(0,4);
      if (resultValue.substr(0,4) === "2001") {
        licenseInfo.ActivationDate = "N/D"
      }
  
  
      resultValue = result.creationDate;
      licenseInfo.CreationDate = resultValue.substr(8,2) + "/" + resultValue.substr(5,2) + "/" + resultValue.substr(0,4);
      if (resultValue.substr(0,4) === "2001") {
        licenseInfo.CreationDate = "N/D"
      }
      
  
      resultValue = result.expirationDate;
      licenseInfo.ExpirationDate = resultValue.substr(8,2) + "/" + resultValue.substr(5,2) + "/" + resultValue.substr(0,4);
      if (resultValue.substr(0,4) === "2001") {
        licenseInfo.ExpirationDate = "N/D"
      }
      if (resultValue.substr(0,4) === "2099") {
        licenseInfo.ExpirationDate = "SPOT"
      }
  
      resultValue = result.activationDateLimit;
      licenseInfo.ActivationDateLimit = resultValue.substr(8,2) + "/" + resultValue.substr(5,2) + "/" + resultValue.substr(0,4);
      if (resultValue.substr(0,4) === "2001") {
        licenseInfo.ActivationDateLimit = "N/D"
      }
      if (result.expirationDate.substr(0,4) === "2099") {
        licenseInfo.ActivationDateLimit = "SPOT"
      }
  
      //STATO
      resultValue = result.statoLic;
      licenseInfo.Stato = Number(resultValue);
  
      //CREATORE
      licenseInfo.Creatore = result.creatore;
  
      //SEGNALATORE
      licenseInfo.Segnalatore = result.segnalatore;
  
      //LICENZE CLIENT
      for (let i = 0; i < result.licenseClient.length; ++i) {
        const licenseClient: LicenseClient = new LicenseClient(result.licenseClient[i].license, result.licenseClient[i].creationDate, result.licenseClient[i].stato);
        licenseClient.ActivationDate = result.licenseClient[i].activationDate;
        licenseClient.ExpirationDate = result.licenseClient[i].expirationDate;

        licenseClient.CreationDate = licenseClient.CreationDate.substr(8,2) + "/" + licenseClient.CreationDate.substr(5,2) + "/" + licenseClient.CreationDate.substr(0,4);
        licenseClient.ActivationDate = licenseClient.ActivationDate.substr(8,2) + "/" + licenseClient.ActivationDate.substr(5,2) + "/" + licenseClient.ActivationDate.substr(0,4);
        licenseClient.ExpirationDate = licenseClient.ExpirationDate.substr(8,2) + "/" + licenseClient.ExpirationDate.substr(5,2) + "/" + licenseClient.ExpirationDate.substr(0,4);

        licenseInfo.LicenseClient.push(licenseClient);
      };
  
  
      //licenseInfo.Client = result.client;
      const license: License = new License(result.license.license, result.license.module);
      licenseInfo.License = license;
  
      const descrizioneObject = {
        primo: (result.license.module as string).charAt(1),
        secondo: (result.license.module as string).charAt(2),
        terzo: (result.license.module as string).charAt(3),
        quarto: (result.license.module as string).charAt(4),
      };
  
      licenseInfo.Descrizione = ''
  
      if (license.Modules === "DZZZZ") {
        licenseInfo.Descrizione = "DEMO"
      } else {
        switch(descrizioneObject.primo) {
          case 'A': {
            licenseInfo.Descrizione += "Visual Food Start, ";
            break;
          }
          case 'B': {
            licenseInfo.Descrizione += "Visual Food Pro, ";
            break;
          }
          case 'C': {
            licenseInfo.Descrizione += "Visual Food Gold, ";
            break;
          }
        }
  
  
        switch(descrizioneObject.secondo) {
          case 'A': {
            licenseInfo.Descrizione += "Visual Shop Start, ";
            break;
          }
          case 'B': {
            licenseInfo.Descrizione += "Visual Shop Pro, ";
            break;
          }
        }
  
  
        switch(descrizioneObject.terzo) {
          case 'A': {
            licenseInfo.Descrizione += "Visual Kiosk Start, ";
            break;
          }
          case 'B': {
            licenseInfo.Descrizione += "Visual Kiosk Pro, ";
            break;
          }
          case 'C': {
            licenseInfo.Descrizione += "Visual Kiosk Gold, ";
            break;
          }
  
          case 'G': {
            licenseInfo.Descrizione += "Visual Self Start, ";
            break;
          }
          case 'H': {
            licenseInfo.Descrizione += "Visual Self Pro, ";
            break;
          }
          case 'I': {
            licenseInfo.Descrizione += "Visual Self Gold, ";
            break;
          }
  
          case 'M': {
            licenseInfo.Descrizione += "Visual Beach Start, ";
            break;
          }
          case 'N': {
            licenseInfo.Descrizione += "Visual Beach Pro, ";
            break;
          }
          case 'O': {
            licenseInfo.Descrizione += "Visual Beach Gold, ";
            break;
          }
          case 'S': {
            licenseInfo.Descrizione += "APPagato, ";
            break;
          }
        }
  
  
        switch(descrizioneObject.quarto) {
          case 'A': {
            licenseInfo.Descrizione += "Visual Retail Start, ";
            break;
          }
          case 'B': {
            licenseInfo.Descrizione += "Visual Retail Pro, ";
            break;
          }
        }
        const lengthDescrizione: number = licenseInfo.Descrizione.length;
        licenseInfo.Descrizione = licenseInfo.Descrizione.substr(0, lengthDescrizione - 2);
      }
  
      return licenseInfo;
    }


  public async GetLicenseCount(): Promise<any> {
    const params: HttpParams = new HttpParams()
    .set("id", String(this.auth.AuthUser.ID));

    return await this.http.get('/api/license/count', {params}).toPromise();
  }

  public async GetLicenseDemoCount() {
    const params: HttpParams = new HttpParams().set('id', String(this.auth.AuthUser.ID));
    return await this.http.get('/api/license/demo/count', {params}).toPromise();
  }

  public async RenewLicense(username: string, license: string) {

    const licenseObj = {
      username, license
    }

    return await lastValueFrom(this.http.post<boolean>('/api/license/renew', licenseObj, {}));
  }

  constructor(private shopService: ShopService, private http: HttpClient, private auth: AuthService) {
    if (shopService.IsInit === false) {
      this.shopService.Init();
      this.shopService.IsInit = true;
    }
  }
}
