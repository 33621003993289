<div class="content" style="padding-top: 1%;">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col">
                    <h3>Il mio account   -  {{this.auth.AuthUser.Role === 0 && this.auth.AdminUsername !== "" ? this.auth.AdminUsername : this.userToShow.Username}}</h3>
                </div>
            </div>
            <div class="row">
                <div class="card" style="width: 100%;">
                    <div class="card-body">
                        <div class="row card-body">
                            <div class="col-6">
                                Partita IVA: <b>{{this.userToShow.PIVA !== '' ? this.userToShow.PIVA : 'Nessun dato disponibile'}}</b>
                            </div>
                            <div class="col-6">
                                Ragione Sociale: <b>{{this.userToShow.RagioneSociale !== '' ? this.userToShow.RagioneSociale : 'Nessun dato disponibile'}}</b>
                            </div>
                        </div>
                        <div class="row card-body">
                            <div class="col-6">
                                Città: <b>{{this.userToShow.Citta !== '' ? this.userToShow.Citta + ',' : ''}} {{this.userToShow.Via !== '' ? this.userToShow.Via  : ''}}</b>
                            </div>
                        </div>
                        <div class="row card-body">
                            <div class="col-6">
                                Indirizzo Mail: <b>{{this.userToShow.Email !== '' ? this.userToShow.Email : 'Nessun dato disponibile'}}</b>
                            </div>
                            <div class="col-6">
                                Indirizzo PEC: <b>{{this.userToShow.PEC !== '' ? this.userToShow.PEC : 'Nessun dato disponibile'}}</b>
                            </div>
                        </div>
                        <div class="row card-body">
                            <div class="col-6">
                                IBAN: <b>{{this.userToShow.IBAN !== '' ? this.userToShow.IBAN : 'Nessun dato disponibile'}}</b>
                            </div>
                            <div class="col-6">
                                SDI: <b>{{this.userToShow.SDI !== '' ? this.userToShow.SDI : 'Nessun dato disponibile'}}</b>
                            </div>
                        </div>
                        <div class="row card-body" *ngIf="(!(this.auth.AuthUser.Role === 0))">
                            <div class="col-6">
                                <small>Per richiedere la modifica di eventuali dati, inviare una mail a <a href="mailto:store@gabtamagnini.it?subject=Richiesta modifica credenziali">store@gabtamagnini.it</a></small>
                            </div>
                            <div class="col-6">
                                <a role="button" class="btn btn-danger" (click)="this.modalService.show(updatePassword)">Modifica Password</a>
                            </div>
                        </div>
                    </div>
                  </div>
            </div>


            <div class="row" *ngIf="(this.auth.AuthUser.Role === 1 || this.auth.AuthUser.Role === 2 || this.auth.AuthUser.Role === 4)">
                <!--
                <div [ngClass]="{col: this.auth.AuthUser.Role !== 2, 'col-6': this.auth.AuthUser.Role === 2}">
                    <div class="row">
                        <div class="card" style="width: 100%;">
                            <div class="card-body" style="text-align: center;">
                                <a role="button" class="btn btn-primary">Storico Acquisti</a>
                            </div>
                          </div>
                    </div>
                </div>
                -->
                <div class="col-6">
                    <div class="row">
                        <div class="card" style="width: 100%;">
                            <div class="card-body" style="text-align: center;">
                                <a role="button" class="btn btn-primary btn-lg" (click)="this.GetStorico(storicoacquisti)">{{this.auth.AuthUser.Role === 2 ? 'Storico Ordini' : 'Storico Acquisti'}}</a>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="card" style="width: 100%;">
                            <div class="card-body" style="text-align: center;">
                                <a role="button" class="btn btn-primary btn-lg" (click)="this.GetPagamenti(storicopagamenti)">Storico Pagamenti</a>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
            <ng-container  *ngIf="this.auth.AuthUser.Role === 0">
                <div class="row">
                    <div class="col">
                        <h3>Permessi Gab Servizi</h3>
                    </div>
                </div>
                <div class="card" style="width: 50%;">
                    <table>
                        <thead>
                            <th width="60%">Licenze</th>
                            <th class="th-table" width="20%">Consenti</th>
                            <th class="th-table" width="20%">Nega</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Generazione</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Licenze.Generazione"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione"></fa-icon></td>
                            </tr>
                            <tr>
                                <td>Upgrade</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Licenze.Upgrade"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Licenze.Upgrade"></fa-icon></td>
                            </tr>
                            <tr>
                                <td>Stato</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Licenze.Stato"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Licenze.Stato"></fa-icon></td>
                            </tr>
                            <tr>
                                <td>Rinnovo</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Licenze.Rinnovo"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Licenze.Rinnovo"></fa-icon></td>
                            </tr>
                            <tr>
                                <td>Attivazione</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Licenze.Attivazione"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Licenze.Attivazione"></fa-icon></td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead>
                            <th width="60%">Shop</th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Inserimento</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Inserimento"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Shop.Inserimento"></fa-icon></td>
                            </tr>
                            <tr>
                                <td>Modifica</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Modifica"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Shop.Modifica"></fa-icon></td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead>
                            <th width="60%">Manuali</th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Inserimento</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Manuali.Inserimento"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Manuali.Inserimento"></fa-icon></td>
                            </tr>
                            <tr>
                                <td>Eliminazione</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Manuali.Eliminazione"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Manuali.Eliminazione"></fa-icon></td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead>
                            <th width="60%">Listini</th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Inserimento</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Listini.Inserimento"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Listini.Inserimento"></fa-icon></td>
                            </tr>
                            <tr>
                                <td>Eliminazione</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Listini.Eliminazione"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Listini.Eliminazione"></fa-icon></td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead>
                            <th width="60%">Aggiornamenti</th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Inserimento</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Aggiornamenti.Inserimento"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Aggiornamenti.Inserimento"></fa-icon></td>
                            </tr>
                            <tr>
                                <td>Eliminazione</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Aggiornamenti.Eliminazione"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Aggiornamenti.Eliminazione"></fa-icon></td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead>
                            <th width="60%">Utenti</th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Creazione</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Utenti.Creazione"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Utenti.Creazione"></fa-icon></td>
                            </tr>
                            <tr>
                                <td>Modifica</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Utenti.Modifica"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Utenti.Modifica"></fa-icon></td>
                            </tr>
                            <tr>
                                <td>Eliminazione</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Utenti.Eliminazione"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Utenti.Eliminazione"></fa-icon></td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead>
                            <th width="60%">Censimenti</th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Aggiornamento</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Censimenti.Aggiornamento"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Censimenti.Aggiornamento"></fa-icon></td>
                            </tr>
                            <tr>
                                <td>Eliminazione</td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="this.auth.AuthUser.SettingsPermessi.Censimenti.Eliminazione"></fa-icon></td>
                                <td class="td-table"><fa-icon [icon]="faCheck" *ngIf="!this.auth.AuthUser.SettingsPermessi.Censimenti.Eliminazione"></fa-icon></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </div>
</div>


<ng-template #storicopagamenti>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Storico Pagamenti</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="this.modalService.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="this.pagamenti.length === 0">
            <div class="col">
                <h5>Nessun pagamento registrato</h5>
            </div>
        </div>
        <table class="table table-hover" *ngIf="this.pagamenti.length > 0">
            <thead>
                <tr>
                    <th>DATA</th>
                    <th>TOTALE</th>
                    <th>IVA sul Totale</th>
                    <th>TIPO</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of this.pagamenti">
                    <td>{{payment.DateToString()}}</td>
                    <td>{{payment.Totale.toFixed(2)}}€</td>
                    <td>{{(payment.Totale - (payment.Totale/1.22)).toFixed(2)}}€</td>
                    <td>{{payment.TipoToString()}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #storicoacquisti>
    <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="this.auth.AuthUser.Role === 2">Storico Ordini</h4>
        <h4 class="modal-title pull-left" *ngIf="this.auth.AuthUser.Role !== 2">Storico Acquisti</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="this.modalService.hide()"> 
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="row" *ngIf="this.Storico.length === 0">
            <div class="col">
                <h5>Nessun acquisto/ordine registrato</h5>
            </div>
        </div>

        <table class="table table-hover" *ngIf="this.auth.AuthUser.Role === 2 && this.Storico.length > 0"> 
            <thead>
                <tr>
                    <th>CODICE ORDINE</th>
                    <th style="text-align: center !important">CODICE ARTICOLO</th>
                    <th style="text-align: center !important">DESCRIZIONE</th>
                    <th>PREZZO TOTALE</th>
                    <th>NUMERO LICENZE</th>
                    <th style="text-align: center !important">DATA</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ordine of this.Storico" style="text-align: center;">
                    <td>{{ordine.CodiceOrdine}}</td>
                    <td>{{ordine.CodiceArticolo}}</td>
                    <td>{{ordine.Descrizione}}</td>
                    <td>{{ordine.Totale.toFixed(2)}}€</td>
                    <td>{{ordine.NumeroLicenze}}</td>
                    <td>{{ordine.DateToString()}}</td>
                </tr>
            </tbody>
        </table>
        <table class="table table-hover" *ngIf="this.auth.AuthUser.Role !== 2 && this.Storico.length > 0">
            <thead>
                <tr>
                    <th style="text-align: center !important">CODICE ARTICOLO</th>
                    <th style="text-align: center !important">DESCRIZIONE</th>
                    <th>PREZZO TOTALE</th>
                    <th>PREZZO SCONTATO</th>
                    <th>NUMERO LICENZE</th>
                    <th style="text-align: center !important">DATA</th>
                    <th>TIPO</th>
                </tr>
            </thead>
                <tbody>
                    <tr *ngFor="let acquisto of this.Storico">
                        <td>{{acquisto.CodiceArticolo}}</td>
                        <td>{{acquisto.Descrizione}}</td>
                        <td>{{acquisto.PrezzoTotale.toFixed(2)}}€</td>
                        <td>{{acquisto.PrezzoScontato.toFixed(2)}}€</td>
                        <td>{{acquisto.NumeroLicenze}}</td>
                        <td>{{acquisto.DateToString()}}</td>
                        <td>{{acquisto.Tipo}}</td>
                    </tr>
                </tbody>
        </table>
    </div>
</ng-template>



<ng-template #updatePassword>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Modifica Password</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="this.modalService.hide()"> 
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
            <div class="form-group">
                <label>Vecchia Password</label>
                <input type="password" class="form-control" placeholder="Inserisci vecchia password" [(ngModel)]="PasswordDate.oldPassword">
                <small class="form-text text-muted" *ngIf="this.ErrorPassword.passwordOld" style="color: red !important">La password inserita non è corretta</small>
            </div>
            <div class="form-group">
                <label>Nuova Password</label>
                <input type="password" class="form-control" placeholder="Inserisci nuova password" [(ngModel)]="PasswordDate.newPassword">
                <small class="form-text text-muted" *ngIf="this.ErrorPassword.passwordSpace" style="color: red !important">La password non può contenere spazi</small>
            </div>
            <div class="form-group">
                <label>Ripeti Nuova Password</label>
                <input type="password" class="form-control" placeholder="Ripeti nuova password" [(ngModel)]="PasswordDate.repeatNewPassword">
                <small class="form-text text-muted" *ngIf="this.ErrorPassword.passwordRepeat" style="color: red !important">La password inserita non coincide con quella precedentemente inserita</small>
            </div>
            <button type="submit" class="btn btn-primary" (click)="UpdatePassword()">Modifica</button>
    </div>
</ng-template>
