<div class="card-header" style="border-bottom: 0; background-color: #f5f5f5;">
    <div class="row">
        <div class="col-1" (click)="this.router.navigate(['/cloud'])">
            <fa-icon [icon]="faChevronCircleLeft" size="2x" style="cursor: pointer;"></fa-icon>
        </div>
        <div class="col-9">
            <h4 class="card-title" style="margin-top: 0;">Configurazione licenze</h4>
        </div>
        <div class="col-2">
            <div [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione" class="btn btn-primary" style="float: right;" (click)="OpenNewLicense(Licenza)">Aggiungi</div>
        </div>
    </div>
</div>
<div class="card-body" style="padding-top: 0;">
    <table class="table table-hover table-striped " id="table">
        <thead>
            <th>Nome</th>
            <th>Cabine Max</th>
            <th>Operatori Max</th>
            <th>Descrizione</th>
            <th>Data</th>
            <th></th>
        </thead>
        <tbody>
            <tr *ngFor="let license of this.cloudLicenses">
                <td><label>{{license.name}}</label></td>
                <td><label>{{license.maxCabins}}</label></td>
                <td><label>{{license.maxOperators}}</label></td>
                <td><label>{{license.description}}</label></td>
                <td><label>{{license.createdAt | date: 'dd/MM/yyyy'}}</label></td>

                <td>
                    <div class="row" style="float: right;" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione">
                        <div class="col">
                            <fa-icon [icon]="faTrash" style="cursor: pointer;" tooltip="Elimina" (click)="this.selectedLicense = license; this.modalService.show(EliminaLicenza);"></fa-icon>
                        </div>
                        <div class="col">
                            <fa-icon [icon]="faPen" style="cursor: pointer;" (click)="OpenEditLicense(Licenza, license)" tooltip="Modifica licenza"></fa-icon>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #Licenza>
    <div class="modal-content">
        <div class="modal-body" style="max-height: calc(100% - 3.5rem);">
            <div class="modal-title">
                <h4>Configurazione Licenza</h4>
            </div>
            <div class="form-group">
                <label>Nome</label>
                <input type="text" class="form-control" placeholder="Inserire nome *" required="required" [(ngModel)]="this.selectedLicense.name"/>
            </div>
            <div class="form-group">
                <label>Descrizione</label>
                <input type="text" class="form-control" placeholder="Inserire descrizione" [(ngModel)]="this.selectedLicense.description"/>
            </div>
            <div class="form-group row">
                <div class="col-3">
                    <label>Operatori max</label>
                    <input type="number" min="0" class="form-control" placeholder="Operatori *" [(ngModel)]="this.selectedLicense.maxOperators"/>
                </div>
                <div class="col-3">
                    <label>Cabine max</label>
                    <input type="number" min="0" class="form-control" placeholder="Cabine *" [(ngModel)]="this.selectedLicense.maxCabins"/>
                </div>
            </div>
            <hr>
            <div class="form-group row">
                <div class="col-3">
                    <label>Quota mese</label>
                    <input class="form-control" type="number" min="0.00" max="10000.00" step="0.01" [(ngModel)]="this.selectedLicense.licenseCloudValue.month"/>
                </div>
                <div class="col-3">
                    <label>Quota trimestre</label>
                    <input class="form-control" type="number" min="0.00" max="10000.00" step="0.01" [(ngModel)]="this.selectedLicense.licenseCloudValue.quarter"/>
                </div>
                <div class="col-3">
                    <label>Quota anno</label>
                    <input class="form-control" type="number" min="0.00" max="10000.00" step="0.01" [(ngModel)]="this.selectedLicense.licenseCloudValue.year"/>
                </div>
            </div>
            <hr>
            <!--DASHBOARD-->
            <div class="row">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('DASHBOARD')" (change)="EditAuthority('DASHBOARD')">
                        <label class="form-check-label left">Dashboard</label>
                    </div>
                </div>
            </div>
            <!--PLANNING-->
            <div class="row">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('PLANNING')" (change)="EditAuthority('PLANNING')">
                        <label class="form-check-label left">Planning</label>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom: 2%;">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('PAYMENT')" (change)="EditAuthority('PAYMENT')">
                        <label class="form-check-label left">Vendita al banco</label>
                    </div>
                </div>
            </div>
            <!--CONTACTS-->
            <div class="row" style="margin-bottom: 2%;">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('CLIENT')" (change)="EditAuthority('CLIENT')">
                        <label class="form-check-label left">Clienti</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('COMPANY')" (change)="EditAuthority('COMPANY')">
                            <label class="form-check-label left">Azienda</label>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('PROVIDER')" (change)="EditAuthority('PROVIDER')"> 
                            <label class="form-check-label left">Fornitori</label>
                        </div>
                    </div>
                </div>
            </div>
            <!--BACKOFFICE-->    
            <div class="row">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('ARTICLE')" (change)="EditAuthority('ARTICLE')">
                        <label class="form-check-label left">Prodotti</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('TREATMENT')" (change)="EditAuthority('TREATMENT')">
                            <label class="form-check-label left">Servizi</label>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('STOCK')" (change)="EditAuthority('STOCK')">
                            <label class="form-check-label left">Giacenze</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('MOVEMENT')" (change)="EditAuthority('MOVEMENT')">
                        <label class="form-check-label left">Carico Magazzino</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('INVENTORY')" (change)="EditAuthority('INVENTORY')">
                            <label class="form-check-label left">Inventario</label>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('QUOTE')" (change)="EditAuthority('QUOTE')">
                            <label class="form-check-label left">Preventivi</label>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('SUBSCRIPTION')" (change)="EditAuthority('SUBSCRIPTION')">
                        <label class="form-check-label left">Abbonamenti</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('FIDELITY')" (change)="EditAuthority('FIDELITY')">
                            <label class="form-check-label left">Fidelity</label>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('DOCUMENT_MANAGEMENT')" (change)="EditAuthority('DOCUMENT_MANAGEMENT')">
                            <label class="form-check-label left">Gestione Documenti</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-bottom: 2%;">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('CASH_DESK')" (change)="EditAuthority('CASH_DESK')">
                        <label class="form-check-label left">Cassa</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('FIRST_NOTE')" (change)="EditAuthority('FIRST_NOTE')">
                            <label class="form-check-label left">Prima nota</label>
                        </div>
                    </div>
                </div>
            </div>
    
            <!--MARKETING-->
            <div class="row" style="margin-bottom: 2%;">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('PROMOTION')" (change)="EditAuthority('PROMOTION')">
                        <label class="form-check-label left">Promozioni</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('PROMO_SMS')" (change)="EditAuthority('PROMO_SMS')">
                            <label class="form-check-label left">Promo SMS</label>
                        </div>
                    </div>
                </div>
            </div>
    
            <!--STATISTICHE-->
            <div class="row" style="margin-bottom: 2%;">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('PRODUCT_REPORTS')" (change)="EditAuthority('PRODUCT_REPORTS')">
                        <label class="form-check-label left">Report dei prodotti</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('TREATMENT_REPORTS')" (change)="EditAuthority('TREATMENT_REPORTS')">
                            <label class="form-check-label left">Report dei servizi</label>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('OPERATOR_REPORTS')" (change)="EditAuthority('OPERATOR_REPORTS')">
                            <label class="form-check-label left">Report dei operatori</label>
                        </div>
                    </div>
                </div>
            </div>
    
            <!--CONFIGURATIONI-->
            <div class="row">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('ADMINISTRATION')" (change)="EditAuthority('ADMINISTRATION')">
                        <label class="form-check-label left">Amministrazione</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('SETUP_SELL_POINT')" (change)="EditAuthority('SETUP_SELL_POINT')">
                            <label class="form-check-label left">Setup centro</label>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('SETUP_CLIENTS')" (change)="EditAuthority('SETUP_CLIENTS')">
                            <label class="form-check-label left">Setup clienti</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom: 2%;">
                <div class="col-3">
                    <div class="checkbox-inline">
                        <input type="checkbox" [checked]="CheckAuth('PRODUCTS_AND_SERVICES')" (change)="EditAuthority('PRODUCTS_AND_SERVICES')">
                        <label class="form-check-label left">Prodotti e servizi</label>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('PRINTERS')" (change)="EditAuthority('PRINTERS')">
                            <label class="form-check-label left">Stampanti</label>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="col">
                        <div class="checkbox-inline">
                            <input type="checkbox" [checked]="CheckAuth('SATISPAY')" (change)="EditAuthority('SATISPAY')">
                            <label class="form-check-label left">Satispay</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-danger" (click)="this.modalService.hide()">Annulla</button>
            <button class="btn btn-primary" (click)="this.CreateOrUpdateLicense()" [disabled]="CheckDisabled()">Salva</button>
        </div>
    </div>
</ng-template>

<ng-template #EliminaLicenza>
    <div class="modal-body">
        <div class="row">
            <div class="col" style="text-align: center;">
                <fa-icon [icon]="this.faInfoCircle" size="2x"></fa-icon>
            </div>
        </div>
        <div class="text" style="text-align: center;">Sei sicuro di voler eliminare l'elemento?</div>
    </div>
    <div class="modal-footer">
        <div class="btn btn-danger" (click)="this.modalService.hide()">Annulla</div>
        <div class="btn btn-primary" (click)="this.DeleteLicense()">Elimina</div>
    </div>
</ng-template>
            