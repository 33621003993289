<div class="container">
    <div class="row">
        <div class="center">
            <img class="center" draggable="false" src="assets/img/gabservizi.png"/>
        </div>
    </div>
    <div class="row">
        <div class="center">
            <p style="font-size: 23px">GAB Servizi v. {{version}}</p>
        </div>
    </div>
    <!--
    <div class="row" *ngIf="this.auth.AuthUser.Role === 0">
        <p>1.4.1</p>
        <ul>
            <li>Possibilità di aggiungere moduli a licenza Client</li>
            <li>Inserito mouseover su stato utente</li>
            <li>Tabacchi visualizzato erroneamente</li>
            <li>Introdotto controllo unicità licenza</li>
            <li> Bug Fix: rimosse licenze canone se rimossa politica da utente</li>
            <li>Bug Fix: l'utente concessionario è erroneamente creato</li>
        </ul>
    </div>
    -->
    <div class="row center-text" style="color: gray;">
        <p>COPYRIGHT © {{this.year}} GAB Tamagnini Srl, Via Beniamino Disraeli, 17, 42124, Reggio Emilia</p>
        <p>Partita IVA, Codice Fiscale e Reg. Impr. di RE 00168780351</p>
        <p>Cap. soc. € 50.000,00 i.v. - REA n.RE-107440 presso C.C.I.A.A. di Reggio Emilia</p>
    </div>
    <div class="row">
        <div class="col center-text"><p><a role="button" href="https://www.gabtamagnini.it/cookie-policy/" target="_blank">COOKIE POLICY</a></p></div>
        <div class="col center-text"><p><a role="button" href="https://www.gabtamagnini.it/privacy-policy/" target="_blank">PRIVACY POLICY</a></p></div>
    </div>
</div>