export const IsDev = {
    active: false,
    url: 'https://localhost:44306'
};

export const VERSION: string = "2.0.1";

export const StripeString = {
    Dev: "pk_test_51GvN55IqziYJrkKS25DrF9bp1UHSVRtTHmXZYbBPJxtSQJv8KGZ5JFk1J7yxM1mPpnBLOQu3fU7wfNBCId0PNM1c00ezplO9sc",
    Prod: "pk_live_51GvN55IqziYJrkKS9fi8AMke7jMn3fgJ4uRRHcolrOhIx3KawXX9KEYXnZxZwbJBkLbKeD7YB7g8SdRlf1819QPD00me6lLCOC"
}