<div class="content fade-in">
    <div class="container-fluid">
        <div class="card strpied-tabled-with-hover">
            <div class="card-header">
                <h4 class="card-title">Crea utente</h4>
            </div>
            <div class="card-body">

                <div class="messages"></div>

                <div class="controls">
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary btn-fill" (click)="this.listChanged.emit(true)">Indietro</button>
                        </div>      
                    </div>
                    
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Nome utente *</label>
                                <input id="username" type="text" name="username" class="form-control" placeholder="Inserire nome utente *" required="required" />
                                <label style="color:red" *ngIf="this.CheckError('username')">{{this.GetError('username')}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Password *</label>
                                <input id="psw" type="text" name="psw" class="form-control" placeholder="Inserire la password *" required="required" />
                                <label style="color:red" *ngIf="this.CheckError('password')">{{this.GetError('password')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>PIVA *</label>
                                <input id="piva" type="text" name="piva" class="form-control" placeholder="Inserire la partita iva *" required="required" />
                                <label style="color:red" *ngIf="this.CheckError('piva')">{{this.GetError('piva')}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Ragione Sociale *</label>
                                <input id="rsociale" type="text" name="rsociale" class="form-control" placeholder="Inserire ragione sociale *" required="required" />
                                <label style="color:red" *ngIf="this.CheckError('rsociale')">{{this.GetError('rsociale')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Città *</label>
                                <input id="citta" type="text" name="citta" class="form-control" placeholder="Inserire città *" required="required" />
                                <label style="color:red" *ngIf="this.CheckError('citta')">{{this.GetError('citta')}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Via *</label>
                                <input id="via" type="text" name="via" class="form-control" placeholder="Inserire l'indirizzo *" required="required"/>
                                <label style="color:red" *ngIf="this.CheckError('via')">{{this.GetError('via')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>E-mail *</label>
                                <input id="email" type="email" name="email" class="form-control" placeholder="Inserire indirizzo e-mail *" required="required" />
                                <label style="color:red" *ngIf="this.CheckError('email')">{{this.GetError('email')}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Pec</label>
                                <input id="pec" type="text" name="pec" class="form-control" placeholder="Inserire indirizzo PEC *" required="required"/>
                                <label style="color:red" *ngIf="this.CheckError('pec')">{{this.GetError('pec')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>SDI *</label>
                                <input id="sdi" type="text" name="sdi" class="form-control" placeholder="Inserire SDI *" required="required"/>
                                <label style="color:red" *ngIf="this.CheckError('sdi')">{{this.GetError('sdi')}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>IBAN *</label>
                                <input id="iban" type="text" name="iban" class="form-control" placeholder="Inserire IBAN *" required="required"/>
                                <label style="color:red" *ngIf="this.CheckError('iban')">{{this.GetError('iban')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" id="tipoutente">
                        <label>SELEZIONA IL TIPO DI UTENTE</label>
                    
                        <div class="row">
                            <div class="col">
                                <div class="chiller_cb ml-1 mb-2">
                                    <input type="checkbox" name="checkRiv" value="Concessionario" [(ngModel)]="this.UserType.Concessionario" />
                                    <label for="conCheck" style="margin-left: 5%;">Concessionario</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="chiller_cb ml-1 mb-2">
                                    <input value="Utente" name="checkRiv" type="checkbox" [(ngModel)]="this.UserType.Utente"/>
                                    <label for="userCheck" style="margin-left: 5%;">Utente</label>
                                </div>
                            </div>
                        </div>

                        <label style="color:red" *ngIf="this.CheckError('roles')">{{this.GetError('roles')}}</label>

                    </div>
                </div>
                    <div class="d-flex justify-content-center" *ngIf="loading">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    <div class="row">
                        <div class="col">
                            <button type="button" class="btn btn-primary btn-block" (click)="CreateUser()" [disabled]="loading">Crea nuovo utente</button>
                        </div>
                    </div>
                    <div class="row" *ngIf="this.ErroreCreazione">
                        <div class="col">
                            <label style="color:red">Configura il concessionario/distributore per continuare</label>
                        </div>
                    </div>
                    <div class="row">
                        <alert style="width: 100%; margin-top: 2%;" type="success" [dismissible]="true" [isOpen]="alertOpenSuccess">
                            <strong style="text-align: center;">{{alertMsg}}</strong>
                        </alert>
                        <alert style="width: 100%; margin-top: 2%;" type="danger" [dismissible]="true" [isOpen]="alertOpen">
                            <strong style="text-align: center;">{{alertMsg}}</strong>
                        </alert>
                    </div>
            </div>
        </div>
    </div>
</div>