<div fxLayout="row wrap" fxLayout.lt-lg="column" id="transition" fxLayoutGap="16px grid" style="padding-top: 2rem;padding-bottom: 2rem;"   *ngIf="ShopService.ShowAcquistoPacchetti == true || ShopService.ShowAcquistoSingolo == true">
  <ng-container *ngIf="this.ShopService.prodottiMostrati.length > 0; else SENZAPRODOTTI">
    <div *ngFor="let software of this.ShopService.prodottiMostrati; let i=index;" fxFlex="25%" fxFlex.lt-lg="100%">
      <div class="owl-item active shop-item" >
        <div class="single-product">
          <div class="product-f-image" (click)="ShowDettaglioProdotto(software)">
            <img *ngIf="software.ImgName.length > 0" [src]='getProductImage(software)' class="immagine-prodotti" [id]="software.CodiceArticolo" (error)="ImageErrorHandler(software.CodiceArticolo)">
            <img *ngIf="software.ImgName.length == 0" src='assets/img/GabImageNotFound.png' class="immagine-prodotti" alt="">
          </div>
          <div>
          <h2 style="cursor: pointer;text-transform:uppercase;" (click)="ShowDettaglioProdotto(software)"><a>{{software.Descrizione}}</a></h2>
          <a>Cod.articolo:<b>{{software.CodiceArticolo}}</b></a>

          <div class="product-carousel-price">
            <ins>Prezzo:{{software.Costo}}€</ins> <!--<del>{{software.Costo + 200}}€</del>-->
          </div>
          <div>
            <div class="quantity buttons_added paddingtopandbottom" >
              <input type="button" class="btn btn-primary bottonivalue"   value="-" (click)="decrementare(i)">
              <input [id]="'quantita-articolo-' + i" type="number" size="4" class="input-text qty text bottonivalue btn btn-primary" style="margin: 0px 6px; background-color: white;color: black" title="Qty" value="1" min="1" max="999" step="1">
              <input type="button" class="btn btn-primary bottonivalue"  value="+" (click)="incrementare(i)">
            </div>
            <!--<div  *ngIf="ShopService.ShowAcquistoPacchetti" class="btn btn-primary" (click)="AggiungiPacchetto(software, i)">
              Aggiungi ai pacchetti
            </div>-->
            <div  class="btn btn-primary" (click)="ShopService.AggiuntaAlCarrelloProdottoSingolo(software, i)" style="width: 171px">
              Aggiungi al carrello
            </div>
          </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>
<ng-template #SENZAPRODOTTI>
  <div class="error_prodotti_non_trovati">
    {{this.ShopService.errorProductsNotFound}}
  </div>
</ng-template>

