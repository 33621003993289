import { AuthService } from './../../../Services/AuthService/auth.service';
import { SellpointsService } from 'src/app/Services/Cloud/sellpoint.service';
import { PlanningType, SellPoint } from 'src/app/Models/Cloud/SellPoint';
import { lastValueFrom } from 'rxjs';
import { GroupService } from './../../../Services/Cloud/group.service';
import { AlertService } from 'ngx-alerts';
import { CenterService } from 'src/app/Services/Cloud/center.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Center } from 'src/app/Models/Cloud/Center';
import { Group } from 'src/app/Models/Cloud/Group';
import { faTrash, faPlus, faChevronCircleLeft, faInfoCircle, IconDefinition, faPen } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-center-manager',
  templateUrl: './center-manager.component.html',
  styleUrls: ['./center-manager.component.scss']
})
export class CenterManagerComponent implements OnInit {

  //ICONS
  public faChevronCircleLeft: IconDefinition = faChevronCircleLeft;
  public faEdit: IconDefinition = faPen;
  public faTrash: IconDefinition = faTrash;
  public faPlus: IconDefinition = faPlus;
  public faInfoCircle: IconDefinition = faInfoCircle;

  public group: Group;
  public SelectedCenter: Center;
  public SelectedSellPoint: SellPoint;
  constructor(private modalService: BsModalService, public router: Router, public centerService: CenterService, public sellPointService: SellpointsService,
     private alertService: AlertService, public auth: AuthService) { 
    this.group = this.router.getCurrentNavigation().extras.state.group as Group;
  }

  ngOnInit(): void {
    if (this.group.centers.length > 0) {
      this.SelectedCenter = this.group.centers[0];
    }

  }

  public OpenCenterForm(center: Center, template: TemplateRef<any>) {
    if (center === null) {
      center = new Center();
      center.group = this.group;
    }

    this.SelectedCenter = JSON.parse(JSON.stringify(center));

    this.modalService.show(template, {class: "modal-xl"});
  }

  public OpenSellPointForm(sellPoint: SellPoint, template: TemplateRef<any>) {
    if (sellPoint === null) {
      sellPoint = new SellPoint();
      sellPoint.center = this.SelectedCenter;
      sellPoint.startHour = "09:00:00";
      sellPoint.finishHour = "20:00:00";
    }
    
    this.SelectedSellPoint = Object.assign({}, sellPoint);

    this.modalService.show(template, {class: "modal-xl"});
  }


  public OpenModalDeleteCenter(center: Center, template: TemplateRef<any>) {
    this.SelectedCenter = center;
    this.modalService.show(template);
  }

  public OpenModalDeleteSellPoint(sellPoint: SellPoint, template: TemplateRef<any>) {
    this.SelectedSellPoint = sellPoint;
    this.modalService.show(template);
  }

  public async UpdateGroup() {
    this.group.centers = await this.centerService.GetCentersById(this.group.id);

    this.SelectedCenter = this.group.centers.find(c => {
      return c.id === this.SelectedCenter.id
    });
  }

  public async DeleteCenter() {
    
    const success = await this.centerService.DeleteCenter(this.SelectedCenter);
    if (success) {
      this.modalService.hide();
      this.alertService.success("Centro eliminato con successo");

      const index = this.group.centers.findIndex(c => {
        return c.id === this.SelectedCenter.id
      });

      this.group.centers.splice(index, 1);

    } else {
      this.modalService.hide();
      this.alertService.danger("Impossibile eliminare il centro");
    }
  }
  public async DeleteSellPoint() {
    
    const success = await this.sellPointService.DeleteSellPoint(this.SelectedSellPoint);
    if (success) {
      this.modalService.hide();
      this.alertService.success("Sellpoint eliminato con successo");

      //CERCO IL CENTER
      const index: number = this.SelectedCenter.sellPoints.findIndex(s => {
        return s.id === this.SelectedSellPoint.id;
      })

      this.SelectedCenter.sellPoints.splice(index, 1);
      
    } else {
      this.modalService.hide();
      this.alertService.danger("Impossibile eliminare il centro");
    }
  }

}
