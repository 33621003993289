import { Contenitore } from './../../shop/tipologialicenza/tipologialicenza.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private http: HttpClient) { }

  public async Insert(contenitore: Contenitore) {
    const result: any =  await this.http.post('/api/shop/cart/insert', {Contenuto: contenitore.Contenuto, IdPacchettoCart: contenitore.IdPacchettoCart, Tipo: contenitore.Tipo}, {}).toPromise();
    
    return result.response === "ok";
  
  }


}
