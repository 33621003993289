<ng-template #modalStatus >
  <div class="modal-body table-responsive" >
    <table class="table table-hover">
      <thead>
      <tr>
        <th>PIVA</th>
        <th>NMAG</th>
        <th>NCASSA</th>
        <th>MAC</th>
        <th>SOFTWARE</th>
        <th>VERSIONE</th>
        <th>DESMAG</th>
        <th>RAGSOC</th>
        <th>STATUS</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let censimentoAggiorna of this.Status">
        <td>{{censimentoAggiorna.PIVA}}</td>
        <td>{{censimentoAggiorna.NMAG}}</td>
        <td>{{censimentoAggiorna.NCASSA}}</td>
        <td>{{censimentoAggiorna.MAC}}</td>
        <td>{{censimentoAggiorna.SOFTWARE}}</td>
        <td>{{censimentoAggiorna.VERSION}}</td>
        <td>{{censimentoAggiorna.DESMAG}}</td>
        <td>{{censimentoAggiorna.RAGSOC}}</td>
        <td>
          <fa-icon [icon]="faCircle" style="color: red;"></fa-icon>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<div class="content">
  <div fxLayout="row" >
    <!--<div fxFlex="30%" fxFlex.lt-lg="52%">
     <input class="form-control" type="text" placeholder="Search" id="search" (keyup)="Filter()"
            aria-label="Search" />
   </div>-->
    <div class="card search" style="display: block;width: 100%; box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);z-index: 1;">
      <div class="row">
        <div class="col">
          <input class="form-control" type="text" placeholder="Search" id="search" (keyup)="SearchByName()" aria-label="Cerca">
        </div>
      </div>

      <div class="row" style="    margin-top: 10px;">
        <div class="col">
          <!--
           <a class="dropdown-toggle drop-search mr-2 ml-2" role="button" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size: 17px">Ordinamento</a>
           <ul class="dropdown-menu" role="menu" aria-labelledby="dropdown">
             <li><small class="text-muted ml-2">Data</small></li>
             <li><a class="dropdown-item ord" data-order="ORDER BY LASTDATA DESC" role="button">Recente</a></li>
             <li><a class="dropdown-item ord" data-order="ORDER BY LASTDATA ASC" role="button">Obsolete</a></li>
             <li><small class="text-muted ml-2">Versione</small></li>
             <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Recente</a>
               <ul class="dropdown-menu">
                 <li><a class="dropdown-item ord" role="button" data-order="ORDER BY VP DESC">VisualPos</a></li>
                 <li><a class="dropdown-item ord" role="button" data-order="ORDER BY VB DESC">VisualB2B</a></li>
                 <li><a class="dropdown-item ord" role="button" data-order="ORDER BY DR DESC">Driver</a></li>
                 <li><a class="dropdown-item ord" role="button" data-order="ORDER BY VU DESC">VisualUpdate</a></li>
               </ul>
             </li>
             <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Obsoleta</a>
               <ul class="dropdown-menu">
                 <li><a class="dropdown-item ord" role="button" data-order="ORDER BY VP ASC">VisualPos</a></li>
                 <li><a class="dropdown-item ord" role="button" data-order="ORDER BY VB ASC">VisualB2B</a></li>
                 <li><a class="dropdown-item ord" role="button" data-order="ORDER BY DR ASC">Driver</a></li>
                 <li><a class="dropdown-item ord" role="button" data-order="ORDER BY VU ASC">VisualUpdate</a></li>
               </ul>
             </li>
           </ul>-->
          <div fxLayout="row">
            <div class="dropdown" style="padding-bottom: 1rem;">
              <a  style="font-size: 12px;
    color: #9A9A9A !important; cursor: pointer;font-size: 15px">
                Ordinamento
              </a> <span><img src="assets/img/down-arrow.png" height="5px" width="5px"/></span>
              <div class="first-dropdown-content">
                <ul class="dropdown-menu-ordinamento" role="menu" aria-labelledby="dropdownMenu" style="list-style-type: none;display: block; position: static; margin-bottom: 5px; border:0;padding:0.5rem">
                  <li ><small class="titoli-ordinamento">Data</small></li>
                  <li style="cursor: pointer" (click)="Ordinamento('Recente')"  class="menuitem"><a>Recenti</a></li>
                  <li style="cursor: pointer" class="menuitem" (click)="Ordinamento('Obsolete')"><a>Obsolete</a></li>
                  <li class="divider"></li>
                  <li ><small class="titoli-ordinamento">Versione software</small></li>
                  <li style="cursor: pointer;display: inline-flex;width: 100%;"   class="menuitem">
                    <p class="dropdown-title"  (click)="dropdownRecenti = !dropdownRecenti">Recente

                      <img src="assets/img/down-arrow.png" style="height: 10px;" />

                    </p>
                  </li>
                  <ul *ngIf="dropdownRecenti" style="list-style-type: none">
                    <li role="menuitem"  class="menuitem">
                      <a  role="button"
                          (click)="Ordinamento('RecenteVisualPos')">VisualPos</a>
                    </li>
                    <li role="menuitem" class="menuitem" (click)="Ordinamento('RecenteVisualB2B')"><a role="button"
                    >VisualB2B</a></li>
                    <li role="menuitem"  class="menuitem" (click)="Ordinamento('RecenteDriver')"><a  role="button"
                    >Driver</a></li>
                    <li role="menuitem"  class="menuitem" (click)="Ordinamento('RecenteVisualUpdate')"><a role="button"
                    >VisualUpdate</a></li>
                  </ul>
                  <li style="cursor: pointer;display: inline-flex;width: 100%;" class="menuitem">
                    <p class="dropdown-title"  (click)="dropdownObsoleti = !dropdownObsoleti">Obsolete
                      <img src="assets/img/down-arrow.png" style="height: 10px;"/>
                    </p>
                  </li>
                  <ul *ngIf="dropdownObsoleti" style="list-style-type: none">
                    <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteVisualPos')"><a  role="button"
                    >VisualPos</a></li>
                    <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteVisualB2B')"><a  role="button"
                    >VisualB2B</a></li>
                    <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteDriver')"><a  role="button">
                      Driver</a></li>
                    <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteVisualUpdate')"><a  role="button"
                    >VisualUpdate</a></li>
                  </ul>
                  <!--
                  <small tabindex="-1"  class="titoli-ordinamento">Versione Software</small>
                  <ul class="dropdown-menu" role="menu" style="list-style-type: none;padding-inline-start: 0;padding-top: 0;" >
                    <li >
                      <div style="cursor: auto">Recente:</div>
                      <ul class="dropdown-menu" style="list-style-type: none;padding-top: 0">
                        <li role="menuitem"  class="menuitem">
                          <a  role="button"
                              (click)="Ordinamento('RecenteVisualPos')">VisualPos</a>
                        </li>
                        <li role="menuitem" class="menuitem" (click)="Ordinamento('RecenteVisualB2B')"><a role="button"
                        >VisualB2B</a></li>
                        <li role="menuitem"  class="menuitem" (click)="Ordinamento('RecenteDriver')"><a  role="button"
                        >Driver</a></li>
                        <li role="menuitem"  class="menuitem" (click)="Ordinamento('RecenteVisualUpdate')"><a role="button"
                        >VisualUpdate</a></li>
                      </ul>
                    </li >
                    <li class="dropdown-submenu"style="padding-left: 10px">
                      <div style="cursor: auto">Obsoleta:</div>
                      <ul class="dropdown-menu-ordinamento" style="list-style-type: none;">
                        <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteVisualPos')"><a  role="button"
                        >VisualPos</a></li>
                        <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteVisualB2B')"><a  role="button"
                        >VisualB2B</a></li>
                        <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteDriver')"><a  role="button">
                          Driver</a></li>
                        <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteVisualUpdate')"><a  role="button"
                        >VisualUpdate</a></li>
                      </ul>
                    </li>
                  </ul>-->
                </ul>
              </div>
            </div>
            <div class="dropdown" style="padding-left:1rem;padding-bottom: 1rem;">
              <a  style="font-size: 12px;color: #9A9A9A !important; cursor: pointer;font-size: 15px">
                Filtra
              </a> <span><img src="assets/img/down-arrow.png" height="5px" width="5px"/></span>
              <div class="first-dropdown-content" >
                <ul class="dropdown-menu-ordinamento dropdown-filtro" role="menu" aria-labelledby="dropdownMenu" style="list-style-type: none;margin:0;padding:0.5rem;">
                  <li class="menuitem">
                    <input type="checkbox" id="filtro-XML7" [(ngModel)]="FiltroCensimenti.XML7" (ngModelChange)="Filtro()">
                    <label for="filtro-XML7" class="filter-name button" >XML7</label>
                  </li>
                  <li class="menuitem ">
                    <input type="checkbox" id="filtro-lotteria" [(ngModel)]="FiltroCensimenti.Lotteria" (ngModelChange)="Filtro()" >
                    <label for="filtro-lotteria" class="filter-name button" >Lotteria</label>
                  </li>
                </ul>
                  <!--<li (click)="dropdownFiltroXML = !dropdownFiltroRuoli"><small class="titoli-ordinamento">Ruolo
                    <span><img src="assets/img/down-arrow.png" height="5px" width="5px"/></span></small></li>
                  <ul *ngIf="dropdownFiltroRuoli" style="list-style-type: none;padding: 0;padding-left: 10px;font-size: 14px;">
                    <li style="cursor: pointer" class="menuitem filter-item">
                      <input type="checkbox" id="filtro-cliente" [(ngModel)]="FiltroUtenti.Ruolo.Cliente" (ngModelChange)="Filtro()" >
                      <label for="filtro-cliente" class="filter-name" >Cliente</label></li>
                    <li style="cursor: pointer" class="menuitem filter-item"  *ngIf="this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role2 === 0 || this.auth.AuthUser.Role === 4 || this.auth.AuthUser.Role2 === 4">
                      <input type="checkbox"  id="filtro-concessionario" [(ngModel)]="FiltroUtenti.Ruolo.Concessionario" (ngModelChange)="Filtro()" >
                      <label  for="filtro-concessionario"  class="filter-name">Concessionario</label></li>
                    <li style="cursor: pointer" class="menuitem filter-item" *ngIf="this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role2 === 0">
                      <input type="checkbox" id="filtro-distributore"  [(ngModel)]="FiltroUtenti.Ruolo.Distributore" (ngModelChange)="Filtro()" >
                      <label for="filtro-distributore"  class="filter-name">Distributore</label></li>
                    <li style="cursor: pointer" class="menuitem filter-item" *ngIf="this.auth.AuthUser.Username === 'gab'">
                      <input type="checkbox"  id="filtro-admin" [(ngModel)]="FiltroUtenti.Ruolo.Admin" (ngModelChange)="Filtro()">
                      <label for="filtro-admin" class="filter-name" (click)="Filtro()" >Admin</label></li>
                 <li style="cursor: pointer" class="menuitem filter-item">
                      <input type="checkbox"  id="filtro-segnalatore" [(ngModel)]="FiltroUtenti.Ruolo.Segnalatore" (ngModelChange)="Filtro()">
                      <label for="filtro-segnalatore" class="filter-name" (click)="Filtro()" >Segnalatore</label>
                    </li>
                  </ul>

                  <li class="divider"></li>
                  <li (click)="dropdownFiltroStato = !dropdownFiltroStato"><small class="titoli-ordinamento">Stato
                    <span><img src="assets/img/down-arrow.png" height="5px" width="5px" /></span></small></li>
                  <ul *ngIf="dropdownFiltroStato" style="list-style-type: none; padding-left:10px">
                    <li style="cursor: pointer"  class="menuitem filter-item">
                      <input type="checkbox" id="filtro-attivo" [(ngModel)]="FiltroUtenti.Stato.Attivo" (ngModelChange)="Filtro()">
                      <label for="filtro-attivo" class="filter-name" >Attivo</label>
                    </li>
                    <li style="cursor: pointer"  class="menuitem filter-item">
                      <input type="checkbox" id="filtro-sospeso" [(ngModel)]="FiltroUtenti.Stato.Sospeso" (ngModelChange)="Filtro()">
                      <label for="filtro-sospeso" class="filter-name">Sospeso</label>
                    </li>
                    <li style="cursor: pointer"   class="menuitem filter-item">
                      <input type="checkbox" id="filtro-disattivato" [(ngModel)]="FiltroUtenti.Stato.Disattivato" (ngModelChange)="Filtro()">
                      <label for="filtro-disattivato" class="filter-name">Disattivato</label>
                    </li>
                  </ul>

                  <small tabindex="-1"  class="titoli-ordinamento">Versione Software</small>
                  <ul class="dropdown-menu" role="menu" style="list-style-type: none;padding-inline-start: 0;padding-top: 0;" >
                    <li >
                      <div style="cursor: auto">Recente:</div>
                      <ul class="dropdown-menu" style="list-style-type: none;padding-top: 0">
                        <li role="menuitem"  class="menuitem">
                          <a  role="button"
                              (click)="Ordinamento('RecenteVisualPos')">VisualPos</a>
                        </li>
                        <li role="menuitem" class="menuitem" (click)="Ordinamento('RecenteVisualB2B')"><a role="button"
                        >VisualB2B</a></li>
                        <li role="menuitem"  class="menuitem" (click)="Ordinamento('RecenteDriver')"><a  role="button"
                        >Driver</a></li>
                        <li role="menuitem"  class="menuitem" (click)="Ordinamento('RecenteVisualUpdate')"><a role="button"
                        >VisualUpdate</a></li>
                      </ul>
                    </li >
                    <li class="dropdown-submenu"style="padding-left: 10px">
                      <div style="cursor: auto">Obsoleta:</div>
                      <ul class="dropdown-menu-ordinamento" style="list-style-type: none;">
                        <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteVisualPos')"><a  role="button"
                        >VisualPos</a></li>
                        <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteVisualB2B')"><a  role="button"
                        >VisualB2B</a></li>
                        <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteDriver')"><a  role="button">
                          Driver</a></li>
                        <li role="menuitem" class="menuitem" (click)="Ordinamento('ObsoleteVisualUpdate')"><a  role="button"
                        >VisualUpdate</a></li>
                      </ul>
                    </li>
                  </ul>
                </ul>-->
              </div>
            </div>
          </div>

        </div>

        <div class="col-auto">
          <a role="button" class="btn btn-primary" (click)="OpenModalStatus(modalStatus)">Status Aggiornamenti</a>
        </div>

      </div>
    </div>

  </div>
  <!--ORDINAMENTO-->
  <div fxLayout="row">
    <div fxFlex="50%"><!--dropdown menu-->

      <!-- Radio buttons { Ragsoc Negozio Piva Matricola } -->
      <!--
              <div fxFlex.lt-lg="100%" fxLayout.lt-lg="10px" class="radio-buttons">
                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="16px">
                      <div fxLayout="row" fxLayoutGap="10px">
                          <div class="btn btn-primary btn-fill">
                              <input type="radio" class="check-filter" value="rag" name="filterSearch" id="searchrag"
                                  checked="checked" (click)="Filter()" />
                              <label for="searchrag">&nbsp; Ragsoc</label>
                          </div>
                          <div class="btn btn-primary btn-fill">
                              <input type="radio" class="check-filter" value="neg" name="filterSearch" id="searchneg"
                                  (click)="Filter()" />
                              <label for="searchneg">&nbsp; Negozio</label>
                          </div>
                      </div>

                      <div fxLayout="row" fxLayoutGap="10px">
                          <div class="btn btn-primary btn-fill">
                              <input type="radio" class="check-filter" value="piva" name="filterSearch" id="searchpiv"
                                  (click)="Filter()" />
                              <label for="searchpiv"> &nbsp;Piva</label>
                          </div>
                          <div class="btn btn-primary btn-fill">
                              <input type="radio" class="check-filter" value="matr" name="filterSearch" id="searchmat"
                                  (click)="Filter()" />
                              <label for="searchmat">&nbsp; Matricola</label>
                          </div>


                      </div>
                  </div>

              </div>

            <div class="tabs">
              <input type="radio" class="check-filter" id="tab1" name="tab-control" (click)="Filter()" checked/>
              <input type="radio" class="check-filter" id="tab2" name="tab-control"(click)="Filter()" />
              <input type="radio" class="check-filter" id="tab3" name="tab-control" (click)="Filter()" />
              <input type="radio" class="check-filter" id="tab4" name="tab-control" (click)="Filter()" />
              <ul>
                <li title="Features"><label for="tab1" role="button"><br><span>Ragsoc</span></label></li>
                <li title="Delivery Contents"><label for="tab2" role="button"><br><span>Negozio</span></label></li>
                <li title="Shipping"><label for="tab3" role="button"><br><span>Piva</span></label></li>    <li title="Returns"><label for="tab4" role="button"><br><span>Matricola</span></label></li>
              </ul>

              <div class="slider"><div class="indicator"></div></div>

            </div>

              <div class="col-auto">
                  <small class="text-muted d-none" id="numRecord">Censimenti: </small>
                  <a role="button" class="d-none" id="expand"><small class="text-primary mr-2" id="expnd">Espandi</small></a>
              </div>

            </div>-->

      <div fxFlex="50%">

      </div>
    </div>


  </div>

  <!----LOADING-->
  <div *ngIf="Loading" style="position: absolute; left: 50%; top: 50%">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <!-- VISUALIZZAZIONE CARTE CELLULARE -->
  <div *ngIf="!Loading && lglt" class="cards">
    <div fxLayout="row wrap">
      <div fxFlex="25%" fxFlex.lt-lg="100%" class="censimento" *ngFor="let censimento of this.Censimenti; let i = index;"
           [hidden]="censimento.Hidden">
        <app-censur-card
          [nCensimento] = "i" [censimento]="censimento"
          [imageSrc]="censimento.UltimoAccessoInGiorni > 7 ? '../assets/img/cash-register-out.png' : '../assets/img/cash-register.png'"
          [RAGSOC]="censimento.RAGSOC" [DESMAG]="censimento.DESMAG" [RIVENDITORE]="censimento.RIVENDITORE"
          [NMAG]="censimento.NMAG" [NCASSA]="censimento.NCASSA" [VU]="censimento.VU"
          [LASTDATASTRING]="censimento.LASTDATASTRING" [CLIENTE]="censimento.CLIENTE" [PIVA]="censimento.PIVA"
          [LOC]="censimento.LOC" [VP]="censimento.VP" [VB]="censimento.VB" [DR]="censimento.DR"
          [MATRICOLA]="censimento.MATRICOLA" [AZZER]="censimento.AZZER" [GIART]="censimento.GIART"
          [LOTTERIA]="censimento.LOTTERIA" [XML7] = "censimento.XML7"
          [SERVB2B]="censimento.SERVB2B" [IDTRAB2B]="censimento.IDTRAB2B"
          [CPU]="censimento.CPU" [MAC]="censimento.MAC" [VIA]="censimento.VIA" [RT_FW] = "censimento.RT_FW"
          [RT_MODEL] = "censimento.RT_MODEL" [RE_MATR]="censimento.RE_MATR" [LastVP]="LastVP" [LastDriver] = "LastDriver"
        >
        </app-censur-card>
      </div>
    </div>
    <div class="card censimento" *ngFor="let censimento of this.Censimenti" [hidden]="censimento.Hidden">
      <div style="width: 100%;">
        <div class="riga-censimento">
          <div>
            <div *ngIf="censimento.UltimoAccessoInGiorni > 7" class="casella-dettaglio-censimenti">
              <img src="../assets/img/cash-register-out.png" />
            </div>
            <div *ngIf="censimento.UltimoAccessoInGiorni <= 7" class="casella-dettaglio-censimenti">
              <img src="../assets/img/cash-register.png" />
            </div>
            <div class="casella-dettaglio-censimenti">
              <div>{{censimento.RAGSOC}}</div>
            </div>
            <div class="casella-dettaglio-censimenti">
              <div>{{censimento.DESMAG}}</div>
            </div>
          </div>

          <div>
            <div class="casella-dettaglio-censimenti">
              <div>
                <div>Rivenditore:</div>
              </div>
              <div>
                <div class="info">{{censimento.RIVENDITORE}}</div>
              </div>
            </div>
            <div class="casella-dettaglio-censimenti">
              <div>
                <small class="text-muted">N/C:</small>
              </div>
              <div>
                <div>{{censimento.NMAG}}/{{censimento.NCASSA}}</div>
              </div>
            </div>
          </div>
          <div>
            <div class="casella-dettaglio-censimenti">
              <div>
                <small class="text-muted">Visual Update:</small>
              </div>
              <div>
                <div>{{censimento.VU}}</div>
              </div>
            </div>
            <div class="casella-dettaglio-censimenti">
              <div>
                <small class="text-muted">Ultimo Avvio:</small>
              </div>
              <div>
                <div>{{censimento.LASTDATASTRING}}</div>
              </div>
            </div>
          </div>



          <div>
            <div class="casella-dettaglio-censimenti"><a role="button">
              <fa-icon [icon]="faTrash" size="2x" style="color: red;"
                       (click)="OpenModalElimina(modalElimina, censimento)"></fa-icon>
            </a></div>
            <div class="casella-dettaglio-censimenti"> <a role="button">
              <fa-icon [icon]="faAngleRight" size="2x" *ngIf="!censimento.ShowView"
                       (click)="censimento.ShowView = true"></fa-icon>
            </a>
              <a role="button">
                <fa-icon [icon]="faAngleDown" size="2x" *ngIf="censimento.ShowView"
                         (click)="censimento.ShowView = false"></fa-icon>
              </a></div>


          </div>

        </div>
      </div>
      <div class="card-body" *ngIf="censimento.ShowView">
        <div class="row ml-2 mr-2">
          <div class="col">
            <div class="row">
              <span class="badge badge-primary">Cliente</span>
            </div>
            <div class="row">
              <small class="text-muted">P.Iva</small>
            </div>
            <div class="row">
              <div class="info piva">{{censimento.PIVA}}</div>
            </div>
            <div class="row">
              <small class="text-muted">Via</small>
            </div>
            <div class="row">
              <div class="info">{{censimento.PIVA}}</div>
            </div>
            <div class="row">
              <small class="text-muted">Localita</small>
            </div>
            <div class="row">
              <div class="info">{{censimento.LOC}}</div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <span class="badge badge-primary">Software</span>
            </div>
            <div class="row">
              <small class="text-muted">VisualPos</small>
            </div>
            <div class="row">
              <a role="button" (click)="OpenModalUpdatePos(modalVP, censimento)"><span
                class="badge badge-primary">{{censimento.VP}}</span></a>
            </div>
            <div class="row">
              <small class="text-muted">VisualB2B</small>
            </div>
            <div class="row">
              <span class="badge badge-primary">{{censimento.VB}}</span>
            </div>
            <div class="row">
              <small class="text-muted">Driver</small>
            </div>
            <div class="row">
              <a role="button" (click)="OpenModalUpdateDriver(modalDriver, censimento)"><span
                class="badge badge-primary">{{censimento.DR}}</span></a>
            </div>
            <div class="row">
              <small class="text-muted">VisualUpdate</small>
            </div>
            <div class="row">
              <span class="badge badge-primary">{{censimento.VU}}</span>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <span class="badge badge-primary">Fiscale</span>
            </div>
            <div class="row">
              <small class="text-muted">Matricola</small>
            </div>
            <div class="row">
              <div class="info matr " style="margin-bottom: 0;" >{{censimento.MATRICOLA}}</div>
            </div>
            <div class="row">
              <small class="text-muted">Azzeramento Fiscale</small>
            </div>
            <div class="row">
              <p class="info no-margin-bottom" style="margin-bottom: 0;">{{censimento.AZZER}}</p>
            </div>
            <div class="row">
              <small class="text-muted">RT</small>
            </div>
            <div class="row">
              <p class="info no-margin-bottom" style="margin-bottom: 0;">{{censimento.GIART}}</p>
            </div>
            <div class="row">
              <small class="text-muted">Data Avvio RT</small>
            </div>
            <div class="row">
              <p class="info " style="margin-bottom: 0;">{{censimento.AVVIORT}}</p>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <span class="badge badge-primary">Fatturazione Elettronica</span>
            </div>
            <div class="row">
              <small class="text-muted">Servizio B2B</small>
            </div>
            <div class="row">
              <p class="info" style="margin-bottom: 0;">{{censimento.SERVB2B}}</p>
            </div>
            <div class="row">
              <small class="text-muted">Trasmittente B2B</small>
            </div>
            <div class="row">
              <p class="info" style="margin-bottom: 0;">{{censimento.IDTRAB2B}}</p>
            </div>
            <div class="row">
              <a role="button" (click)="GetModules(modalModuli, censimento)"><small
                class="badge badge-danger">Moduli</small>
              </a>
            </div>
            <div class="row">
                <a role="button"><small
                  class="badge">Intestazione scontrino</small>
                </a>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <span class="badge badge-primary">Hardware</span>
            </div>
            <div class="row">
              <small class="text-muted">CPU</small>
            </div>
            <div class="row">
              <p class="info">{{censimento.CPU}}</p>
            </div>
            <div class="row">
              <small class="text-muted">MAC</small>
            </div>
            <div class="row">
              <p class="info">{{censimento.MAC}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- VISUALIZZAZIONE CARTE COMPUTER -->
  <ng-container *ngIf="!Loading && !lglt">
    <div class="card censimento" *ngFor="let censimento of this.Censimenti" [hidden]="censimento.Hidden" style="box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);">
      <div class="card-header card-cassa" style="cursor: pointer;padding-top: 10px;padding-bottom: 5px" (click)="censimento.ShowView = !censimento.ShowView">
        <div class="row" style="vertical-align: middle;">
          <div class="col-1" *ngIf="censimento.UltimoAccessoInGiorni > 7" style="height: fit-content">
            <img src="../assets/img/cash-register-out.png" />
          </div>
          <div class="col-1" *ngIf="censimento.UltimoAccessoInGiorni <= 7" style="height: fit-content">
            <img src="../assets/img/cash-register.png" />
          </div>
          <div class="col padding-linea-principale">
            <div class="row">
              <small *ngIf="censimento.RAGSOC !== ''" class="text-muted rag testoespandibiletitolo">{{censimento.RAGSOC}}</small>
              <small *ngIf="censimento.RAGSOC === ''" class="text-muted rag">Senza ragione sociale</small>
            </div>
            <div class="row ">
              <p class="testoespandibiletitolo">{{censimento.DESMAG}}</p>
            </div>
          </div>
          <div class="col padding-linea-principale">
            <div class="row">
              <small class="text-muted">Rivenditore</small>
            </div>
            <div class="row">
              <p class="info">{{censimento.RIVENDITORE}}</p>
            </div>
          </div>
          <div class="col padding-linea-principale">
            <div class="row">
              <small class="text-muted">N/C</small>
            </div>
            <div class="row">
              <p>{{censimento.NMAG}}/{{censimento.NCASSA}}</p>
            </div>
          </div>
          <div class="col padding-linea-principale">
            <div class="row">
              <small class="text-muted">Visual Update</small>
            </div>
            <div class="row">
              <p>{{censimento.VU}}</p>
            </div>
          </div>
          <div class="col padding-linea-principale">
            <div class="row">
              <small class="text-muted">Ultimo Avvio</small>
            </div>
            <div class="row">
              <p>{{censimento.LASTDATASTRING}}</p>
            </div>
          </div>

          <div class="col padding-linea-principale" style="text-align: right; ">
            <a style="float: left" role="button" [style.display]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Censimenti.Eliminazione ? 'none' : ''">
              <svg (click)="OpenModalElimina(modalElimina, censimento)" height="40px" viewBox="-64 0 512 512" width="40px" xmlns="http://www.w3.org/2000/svg"><path d="m256 80h-32v-48h-64v48h-32v-80h128zm0 0" fill="#62808c"/><path d="m304 512h-224c-26.507812 0-48-21.492188-48-48v-336h320v336c0 26.507812-21.492188 48-48 48zm0 0" fill="#e76e54"/><path d="m384 160h-384v-64c0-17.671875 14.328125-32 32-32h320c17.671875 0 32 14.328125 32 32zm0 0" fill="#77959e"/><path d="m260 260c-6.246094-6.246094-16.375-6.246094-22.625 0l-41.375 41.375-41.375-41.375c-6.25-6.246094-16.378906-6.246094-22.625 0s-6.246094 16.375 0 22.625l41.375 41.375-41.375 41.375c-6.246094 6.25-6.246094 16.378906 0 22.625s16.375 6.246094 22.625 0l41.375-41.375 41.375 41.375c6.25 6.246094 16.378906 6.246094 22.625 0s6.246094-16.375 0-22.625l-41.375-41.375 41.375-41.375c6.246094-6.25 6.246094-16.378906 0-22.625zm0 0" fill="#fff"/></svg>
            </a>
            <img *ngIf="!censimento.ShowView"  src="./assets/img/right-arrow-angle.png" style="height: 20px;margin-top: 15px" alt="">
            <img *ngIf="censimento.ShowView"  src="./assets/img/down-arrow.png" style="height: 20px;margin-top: 15px" alt="">
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="censimento.ShowView">
        <div class="row ml-2 mr-2">
          <div class="col">
            <div class="row">
              <span class="badge badge-info-font badge-primary">Cliente</span>
            </div>
            <div class="row">
              <small class="text-muted">P.Iva</small>
            </div>
            <div class="row">
              <p class="no-margin-bottom" *ngIf="!censimento.PIVA">-</p>
              <p *ngIf="censimento.PIVA" class="info piva no-margin-bottom">{{censimento.PIVA}}</p>
            </div>
            <div class="row">
              <small class="text-muted">Via</small>
            </div>
            <div class="row">
              <p class="no-margin-bottom"*ngIf="!censimento.VIA">-</p>
              <p class="info no-margin-bottom">{{censimento.VIA}}</p>
            </div>
            <div class="row">
              <small class="text-muted">Localita</small>
            </div>
            <div class="row">
              <p class="no-margin-bottom" *ngIf="!censimento.LOC">-</p>
              <p class="info no-margin-bottom">{{censimento.LOC}}</p>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <span class="badge badge-info-font badge-primary">Software</span>
            </div>
            <div class="row">
              <small class="text-muted">VisualPos</small>
            </div>
            <div class="row">
              <a role="button" (click)="OpenModal(modalVP, censimento)"><span class="badge badge-info-font badgeviola">{{censimento.VP}}</span></a>
            </div>
            <div class="row">
              <small class="text-muted">VisualB2B</small>
            </div>
            <div class="row">
              <span class="badge badge-info-font  badgeviola">{{censimento.VB}}</span>
            </div>
            <div class="row">
              <small class="text-muted">Driver</small>
            </div>
            <div class="row">
              <a role="button" (click)="OpenModal(modalDriver, censimento)"><span class="badge badge-info-font badgeviola">{{censimento.DR}}</span></a>
            </div>
            <div class="row">
              <small class="text-muted">VisualUpdate</small>
            </div>
            <div class="row">
              <span class="badge badge-info-font badgeviola">{{censimento.VU}}</span>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <span class="badge badge-info-font badge-primary">Fiscale</span>
            </div>
            <div class="row">
              <small class="text-muted">Matricola</small>
            </div>
            <div class="row">
              <p class="info matr no-margin-bottom">{{censimento.MATRICOLA}}</p>
            </div>
            <div class="row">
              <small class="text-muted">Azzeramento Fiscale</small>
            </div>
            <div class="row">
              <p class="info no-margin-bottom" >{{censimento.AZZER}}</p>
            </div>
            <div fxLayout="row" style="margin-right: -15px;margin-left: -15px;">
              <div fxLayout="column">
                <small class="text-muted">RT</small>
                <div class="info no-margin-bottom" >{{censimento.GIART}}</div>
              </div>
              <div fxLayout="column" style="margin-left: 10px;">
                <small class="text-muted">LOTTERIA</small>
                <div class="info no-margin-bottom" style="text-align: center">{{censimento.LOTTERIA}}</div>
              </div>
              <div fxLayout="column" style="margin-left: 10px;">
                <small class="text-muted">XML7</small>
                <div class="info no-margin-bottom" style="text-align: center">{{censimento.XML7}}</div>
              </div>

            </div>
            <div class="row">
              <small class="text-muted" >FW /&nbsp;</small>
              <small class="text-muted">Modello / &nbsp;</small>
              <small class="text-muted">Matricola</small>
            </div>
            <div class="row" style="font-weight: bold">
              <p *ngIf="censimento.RT_FW !== '-'; else nortfw" class="info no-margin-bottom">{{censimento.RT_FW}}&nbsp;/&nbsp;</p>
              <ng-template #nortfw>{{censimento.RT_FW}}&nbsp;/&nbsp;</ng-template>

              <p *ngIf="censimento.RT_MODEL !== '-'; else nortmodel" class="info no-margin-bottom">&nbsp;{{censimento.RT_MODEL}}&nbsp;/</p>
              <ng-template #nortmodel>{{censimento.RT_MODEL}}&nbsp;/</ng-template>


              <p *ngIf="censimento.RE_MATR !== '-'; else norematr" class="info no-margin-bottom">&nbsp;{{censimento.RE_MATR}}</p>
              <ng-template #norematr>&nbsp;{{censimento.RE_MATR}}&nbsp;</ng-template>

            </div>
            <div class="row">

            </div>
          </div>
          <div class="col">
            <div class="row">
              <span class="badge badge-info-font badge-primary">Fatturazione Elettronica</span>
            </div>
            <div class="row">
              <small class="text-muted">Servizio B2B</small>
            </div>
            <div class="row">
              <div *ngIf="censimento.SERVB2B" class="info">{{censimento.SERVB2B}}</div>
            </div>
            <div class="row">
              <small class="text-muted">Trasmittente B2B</small>
            </div>
            <div class="row">
              <div *ngIf="censimento.SERVB2B" class="info">{{censimento.IDTRAB2B}}</div>
            </div>
            <div class="row">
              <a role="button" (click)="GetModules(modalModuli, censimento)"><small class="badge badge-danger" style="background-color: #ff0018"> Moduli</small></a>
            </div>
            <div class="row" *ngIf="censimento.INT_SCO !== ''">
              <a role="button" (click)="GetIntSco(intsco, censimento)"><small class="badge badge-danger" style="background-color: #ff0018">Intestazione Scontrino</small></a>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <span class="badge badge-info-font badge-primary">Hardware</span>
            </div>
            <div class="row">
              <small class="text-muted">CPU</small>
            </div>
            <div class="row">
              <div></div>
              <p class="info testoespandibilecpu no-margin-bottom">{{censimento.CPU}}</p>
            </div>
            <div class="row">
              <small class="text-muted">MAC</small>
            </div>
            <div class="row">
              <p class="info">{{censimento.MAC}}</p>
            </div>
            <div class="row">
              <a role="button" (click)="OpenModal(modalLicenza, censimento)">
                <span class="badge badge-info-font badge-primary">Licenza</span>
              </a>
            </div>
            <div class="row" *ngIf="censimento.LICENZA.length > 0">
              <small class="text-muted" *ngFor="let licenza of censimento.LICENZA">
                <a role="button" (click)="UpdateLicenza(censimento)">{{licenza}}</a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #modalModuli>
    <!--Body-->
    <div class="modal-body body-update">

      <table class="table table-hover">
        <thead>
        <tr>
          <th>Codice GAB</th>
          <th>Moduli</th>
        </tr>
        </thead>
        <tbody *ngIf="SelectedCensimento.MODULI.length > 0">
        <tr *ngFor="let modulo of SelectedCensimento.MODULI">
          <td>{{modulo.CODICE}}</td>
          <td>{{modulo.DESCRIZIONE}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="SelectedCensimento.MODULI.length === 0">
        <tr>
          <td>Informazioni moduli non disponibili</td>
        </tr>
        </tbody>
      </table>
      <table class="table table-hover">
        <thead>
        <tr>
          <th>ID TeamViewer</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td *ngIf="SelectedCensimento.TEAMVIEWER.length > 0">{{SelectedCensimento.TEAMVIEWER}}</td>
          <td *ngIf="SelectedCensimento.TEAMVIEWER.length === 0">Non disponibile</td>
        </tr>
        </tbody>
      </table>

    </div>
    <!--Footer-->
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modalService.hide()">Chiudi</button>
    </div>
  </ng-template>

  <ng-template #intsco>
    <!--Body-->
    <div class="modal-body body-update">
      <div class="row" *ngFor="let row of SelectedIntSco; let i = index">
        <div class="col" style="text-align: center;">{{row}}</div>
      </div>
    </div>
    <!--Footer-->
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modalService.hide()">Chiudi</button>
    </div>
  </ng-template>

  <ng-template #modalElimina>
    <!--Body-->
    <div class="modal-body body-custom">
      <div class="text-center">
        <i class="fa fa-trash-o fa-4x mb-3 animated rotateIn fa-custom"></i>
        <p>Sei sicuro di voler eliminare il record?</p>
      </div>
    </div>

    <!--Footer-->
    <div class="modal-footer justify-content-center">
      <a role="button" class="btn btn-danger" (click)="DeleteCensimento()">Si</a>
      <a role="button" class="btn btn-outline-danger waves-effect" (click)="this.modalService.hide()">No</a>
    </div>
  </ng-template>

  <ng-template #modalVP>
    <div class="modal-body">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>Software</th>
          <th>Versione</th>
          <th>Azioni</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>VisualPos</td>
          <td>{{this.LastVP.version}}</td>
          <td><a role="button">
            <fa-icon [icon]="faPen" (click)="UpdateVisualPos()" [style.display]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Censimenti.Aggiornamento ? 'none' : ''"></fa-icon>
          </a></td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

  <ng-template #modalLicenza>
    <div class="modal-body">
      <table class="table table-hover" width="100%">
        <thead>
        <tr>
          <th width="70%">
            <div style="text-align: center;">Licenza</div>
          </th>
          <th width="30%">
            <div style="text-align: center;">Azioni</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><input type="text" style="width: 100%;" [(ngModel)]="this.LicenzaDaAggiungere" (ngModelChange)="CheckLicenseIntegrity()"></td>
          <td style="text-align: center;">
            <a role="button">
            <fa-icon [icon]="faPen" 
              [ngClass]="{'licenza-not-inserted': !this.LicenzaCorretta}"
              [style.display]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Censimenti.Aggiornamento ? 'none' : ''"
              (click)="this.LicenzaCorretta ? InsertLicenza() : ''"
              >
            </fa-icon>
          </a>
         </td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

  <ng-template #modalDriver>
    <div class="modal-body">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>Software</th>
          <th>Versione</th>
          <th>Azioni</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Driver</td>
          <td>{{this.LastDriver.version}}</td>
          <td><a role="button">
            <fa-icon [icon]="faPen" (click)="UpdateDriver()" [style.display]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Censimenti.Aggiornamento ? 'none' : ''"></fa-icon>
          </a></td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

</div>
