import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../Services/AuthService/auth.service';
import {Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from 'ngx-alerts';
import {UserStoreService} from '../../Services/Admin/user-store.service';
import {ShopService} from '../../Services/ShopService/shop.service';

@Component({
  selector: 'app-choosepackage',
  templateUrl: './choosepackage.component.html',
  styleUrls: ['./choosepackage.component.scss']
})
export class ChoosepackageComponent implements OnInit {

  constructor(private http: HttpClient, public auth: AuthService, private router: Router,
              public ShopService: ShopService, public modalService: BsModalService, private alertService: AlertService, public userStoreService:UserStoreService) { }

  ngOnInit(): void {
  }
  public ContinueAcquistoPacchetti(quantità: number) {
    this.router.navigate(['/shop/acquisto-licenza-multipla']);
    this.ShopService.PacchettoScelto = quantità;
    this.ShopService.ShowSelezioneQuantitaPacchetti = false;
    this.ShopService.ShowAcquistoPacchetti = true;
    this.ShopService.ShowAcquistoSingolo= false;
  }
}
