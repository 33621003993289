import { AuthService } from './../Services/AuthService/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public Loading: boolean = false;
  constructor(public auth: AuthService, private router: Router) { 
  }

  async ngOnInit() {
    await this.Init();

    //AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName('li');
    for (let i = 0; i < sidebarElements.length; ++i) {
      if (sidebarElements[i].id !== 'admin') {
        sidebarElements[i].classList.remove('active');
      } else {
        sidebarElements[i].className += " active";
      }
    }

  }

  private async Init() {

    this.auth.Ready = false;
    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }
    this.auth.Ready = true;
  }

  private async LocalValidation() {
    
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));
      
      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if(valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.IsLogin = true;
        this.auth.Ready = false;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }

}
