import { AlertService } from 'ngx-alerts';
import { LicenseCloudService } from './../../Services/Cloud/license-cloud.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from './../../Services/AuthService/auth.service';
import { lastValueFrom } from 'rxjs';
import { GroupService } from '../../Services/Cloud/group.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Group } from 'src/app/Models/Cloud/Group';
import {faLink, faInfoCircle, faHourglassStart, faPowerOff, faEdit, faEyeSlash, faUserShield, faTrash, faTrashRestore, faArrowDown, faChartLine, faSearch, faKey, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CloudLicense, CloudLicenseDate } from 'src/app/Models/Cloud/CloudLicense';
import { Admin } from 'src/app/Models/Cloud/SuperAdmin';
import { CenterService } from 'src/app/Services/Cloud/center.service';


@Component({
  selector: 'app-group-manager',
  templateUrl: './group-manager.component.html',
  styleUrls: ['./group-manager.component.scss']
})
export class GroupManagerComponent implements OnInit {

  public Groups: Group[];
  public AllGroups: Group[];
  public cloudLicenses: CloudLicense[];

  public SelectedGroup: Group;
  public SelectedSuperAdmin: Admin;

  public Loading: boolean = false;
  public List: boolean = true;
  public NewAdminBoolean: boolean = false;
  public EditAdminBoolean: boolean = false;
  //TODAY
  public today: Date = new Date();

  public filter = {
    search: null,
    active: null,
    expired: null,
    deleted: false,
    licenseConfigId: null,
  }

  //ICONS
  public faLink: IconDefinition = faLink;
  public faHourglassStart: IconDefinition = faHourglassStart;
  public faPowerOff: IconDefinition = faPowerOff;
  public faEdit: IconDefinition = faEdit;
  public faEyeSlash: IconDefinition = faEyeSlash;
  public faArrowDown: IconDefinition = faArrowDown;
  public faChartLine: IconDefinition = faChartLine;
  public faKey: IconDefinition = faKey;
  public faTrash: IconDefinition = faTrash;
  public faTrashRestore: IconDefinition = faTrashRestore;
  public faSearch: IconDefinition = faSearch;
  public faInfoCircle: IconDefinition = faInfoCircle;
  public faUserShield: IconDefinition = faUserShield;
  

  //DATEPICKER
  public bsConfig: Partial<BsDatepickerConfig>;

  constructor(private groupService: GroupService, private centerService: CenterService, public auth: AuthService, private router: Router, private activeRoute: ActivatedRoute, private modalService: BsModalService,
    private localeService: BsLocaleService, private alertService: AlertService, private licenseCloudService: LicenseCloudService
    ) { }
  private async Init() {
    this.auth.Ready = false;

    this.Loading = true;
    await this.groupService.InitToken();

    this.AllGroups = await lastValueFrom(this.groupService.getAllGroups());

    this.Groups = [...this.AllGroups];

    this.Filter(null, null);

    this.cloudLicenses = await lastValueFrom(this.licenseCloudService.GetLicenses());

    this.cloudLicenses.forEach(license => {
      delete license.updatedAt;
    })

    this.Loading = false;

    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }

    this.auth.Ready = true;

    //AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName("li");
      for (let i = 0; i < sidebarElements.length; ++i) {
        if (sidebarElements[i].id !== 'cloud') {
          sidebarElements[i].classList.remove('active');
        } else {
          sidebarElements[i].classList.add('active');
        }
      }

    this.localeService.use('it');

    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', showWeekNumbers: false});
     
  }
  private async LocalValidation() {
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));

      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if(valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.Ready = false;
        this.auth.IsLogin = true;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }

  async ngOnInit() {
    await this.Init();
  }

  public async GetCenters(group: Group) {
    group.centers = await this.centerService.GetCentersById(group.id);

    this.List = false;

    const id: string = group.centers[0] ? String(group.centers[0].id) : '';

    this.router.navigate(["/cloud", id], {state: {group}, relativeTo: this.activeRoute});
  }

  public OpenBusinessManager() {
    this.router.navigate(["/cloud/business"], {relativeTo: this.activeRoute});
  }
  
  public OpenLicensesManager() {
    this.router.navigate(["/cloud/licenses"], {relativeTo: this.activeRoute});
  }

  public OpenModal(template: TemplateRef<any>, group: Group) {
    this.SelectedGroup = group;
    this.modalService.show(template);
  }

  public OpenModalEditGroup(template: TemplateRef<any>, group: Group) {
    this.SelectedGroup = Object.assign({}, group);
    this.modalService.show(template);
  }

  public OpenModalSuperAdmin(template: TemplateRef<any>, group: Group) {
    this.SelectedGroup = group;
    this.SelectedSuperAdmin = new Admin();
    this.NewAdminBoolean = false;
    this.modalService.show(template, {class: "modal-xl"});
  }

  public OpenModalNew(newGroup: TemplateRef<any>) {
    this.SelectedGroup = new Group();
    this.SelectedSuperAdmin = new Admin();
    this.modalService.show(newGroup);
  }

  public NewSuperAdmin() {
    this.EditAdminBoolean = false;
    this.NewAdminBoolean = true;
    this.SelectedSuperAdmin = new Admin();
  }

  public EditSuperAdmin(admin: Admin) {
    this.NewAdminBoolean = false;
    this.EditAdminBoolean = true;
    this.SelectedSuperAdmin = Object.assign({}, admin);
  }

  public SelectDays(days: number) {
    this.SelectedGroup.startDate = new Date();

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    this.SelectedGroup.expirationDate = expirationDate;
  }

  public async SaveDateLicense() {
    const cloudLicenseDate: CloudLicenseDate = new CloudLicenseDate();

    cloudLicenseDate.id = this.SelectedGroup.id;
    cloudLicenseDate.startDate = this.SelectedGroup.startDate;
    cloudLicenseDate.expirationDate = this.SelectedGroup.expirationDate;

    const success = await lastValueFrom(this.groupService.EditGroupDate(cloudLicenseDate));

    if (success) {
      this.modalService.hide();
      this.alertService.success("Date aggiornate");
    } else {
      this.modalService.hide();
      this.alertService.danger("Impossibile aggiornare la data di validità del gruppo");  
    }
  }

  public async NewGroup(validLicense: TemplateRef<any>) {

    this.SelectedSuperAdmin.username = this.SelectedSuperAdmin.email;

    if (this.SelectedGroup.name === undefined || this.SelectedGroup.licenseConfigurationId === undefined) {
      this.alertService.danger("Inserire tutti i dati per continuare");
      return;
    }
    if ((this.SelectedSuperAdmin.firstName === undefined || this.SelectedSuperAdmin.firstName === "") || (this.SelectedSuperAdmin.lastName === undefined || this.SelectedSuperAdmin.lastName === "") || (this.SelectedSuperAdmin.email === undefined || this.SelectedSuperAdmin.email === "") || (this.SelectedSuperAdmin.username === undefined || this.SelectedSuperAdmin.username === "") || (this.SelectedSuperAdmin.password === undefined || this.SelectedSuperAdmin.password === "")) {
      this.alertService.danger("Inserire tutti i dati per continuare");
      return;
    }

    this.SelectedGroup.superAdmins.push(this.SelectedSuperAdmin);
    

    this.groupService.NewGroup(this.SelectedGroup).subscribe(async (success: boolean) => {
      this.SelectedGroup.startDate = new Date();
      const nextMonth: Date = new Date();
      nextMonth.setMonth(this.today.getMonth() + 1);
      this.SelectedGroup.expirationDate = nextMonth;
  
      if (success) {
        this.alertService.success("Gruppo creato con successo");
        this.modalService.hide();
      } else {
        this.modalService.hide();
        this.alertService.danger("Impossibile creare un nuovo gruppo");  
      }
  
      await this.ngOnInit();
    }, (error) => {
      this.alertService.danger("Impossibile creare un nuovo gruppo");
    });

   

  }

  public async NewAdmin() {
    this.SelectedSuperAdmin.username = this.SelectedSuperAdmin.email;
    if (!this.SelectedSuperAdmin.id) {
      this.SelectedSuperAdmin.id = null;
    }
    if ((this.SelectedSuperAdmin.firstName === undefined || this.SelectedSuperAdmin.firstName === "") || (this.SelectedSuperAdmin.lastName === undefined || this.SelectedSuperAdmin.lastName === "") || (this.SelectedSuperAdmin.email === undefined || this.SelectedSuperAdmin.email === "") || (this.SelectedSuperAdmin.username === undefined || this.SelectedSuperAdmin.username === "") || (this.SelectedSuperAdmin.password === undefined || this.SelectedSuperAdmin.password === "")) {
      this.alertService.danger("Inserire tutti i dati per continuare");
      return;
    }
    this.SelectedSuperAdmin.groupId = this.SelectedGroup.id;
    
    const success = await lastValueFrom(this.groupService.NewOrEditAdmin(this.SelectedSuperAdmin));

    if (success) {
      this.alertService.success(this.SelectedSuperAdmin.id === null ? "Admin creato con successo" : "Admin modificato");

      /*
      if (this.SelectedSuperAdmin.id === null) {
        this.SelectedGroup.superAdmins.push(this.SelectedSuperAdmin);
      } else {
        let admin: Admin = this.SelectedGroup.superAdmins.find(a => {
          return a.id === this.SelectedSuperAdmin.id
        });
        
        admin = Object.assign({}, this.SelectedSuperAdmin);
      }
      */
      this.modalService.hide();
      this.SelectedSuperAdmin = new Admin();
      this.NewAdminBoolean = false;
      this.EditAdminBoolean = false;
    } else {
      this.alertService.danger("Impossibile creare o modificare l'admin");  
    }

    await this.ngOnInit();
  }

  public async EditGroupLicense() {
    const success = await lastValueFrom(this.groupService.EditGroupLicense(this.SelectedGroup));

    if (success) {
      this.modalService.hide();
      this.alertService.success("Gruppo aggiornato");
    } else {
      this.modalService.hide();
      this.alertService.danger("Impossibile aggiornare la licenza associata al gruppo. Contatta l'assistenza");  
    }

    await this.ngOnInit();
  }

  public async ToggleGroupState(group: Group) {
    const success = await lastValueFrom(this.groupService.ToggleGroupState(group.id));
    
    if (success) {
      group.active = !group.active;
      return;
    }

    this.alertService.danger("Impossibile modificare lo stato del gruppo");

  }

  public async ToggleGroupDeleted(group: Group) {
    const success = await lastValueFrom(this.groupService.ToggleGroupDisabled(group.id));
    
    if (success) {
      group.deleted = !group.deleted;
      this.modalService.hide();
      this.alertService.success("Gruppo eliminato");
      this.Filter(null, null);
      return;
    }

    this.alertService.danger("Impossibile modificare lo stato del gruppo");
  }

  public async DeleteAdmin(admin: Admin) {
    const success = await lastValueFrom(this.groupService.DeleteAdmin(admin));

    if (success) {

      const index = this.SelectedGroup.superAdmins.findIndex(a => {
        return a.id === admin.id
      });

      this.SelectedGroup.superAdmins.splice(index, 1);
      this.modalService.hide();
      this.alertService.success("Admin eliminato");
      return;
    }

    this.alertService.danger("Impossibile modificare lo stato del gruppo");
  }

  public Filter(value: boolean | string, type: string) {
    /*
      Filter type:
        - Search
        - Active
        - Confirmed
        - Deleted
        - Expired
        - LicenseConfigId
    */

    this.Groups = this.AllGroups;

    if (value === "null" || value === "") {
      value = null;
    }

    switch(type) {
      case 'Search': {
        this.filter.search = value;
        break;
      }
      case 'Active': {
        if (value === null) {
          this.filter.active = null;
        } else {
          this.filter.active = (value === "true");
        }
        break;

      }
      case 'Expired': {
        if (value === null) {
          this.filter.expired = null;
        } else {
          this.filter.expired = (value === "true");
        }
        break;
      }
      case 'LicenseConfigId': {
        if (value === null) {
          this.filter.licenseConfigId = null;
        } else {
          this.filter.licenseConfigId = value;
        }
        break;
      }
    }

    const filteredList: Group[] = [...this.Groups];

    this.AllGroups.forEach(group => {
      
      const index: number = filteredList.indexOf(group);

      if (this.filter.licenseConfigId != null) {
        if (index !== -1 && group.licenseConfigurationId !== Number(this.filter.licenseConfigId)) {
          filteredList.splice(index, 1);
          return;
        }
      }

      if (this.filter.expired != null) {
        if (index !== -1) {

          if (this.filter.expired && this.today.getTime() > new Date(group.expirationDate).getTime()) {
            filteredList.splice(index, 1);
            return;
          }

          if (!this.filter.expired && this.today.getTime() < new Date(group.expirationDate).getTime()) {
            filteredList.splice(index, 1);
            return;
          }
        }
      }

      if (this.filter.active != null) {
        if (index !== -1 && group.active !== this.filter.active) {
          filteredList.splice(index, 1);
          return;
        }
      }
      
      if (this.filter.deleted != null) {
        if (index !== -1 && group.deleted !== this.filter.deleted) {
          filteredList.splice(index, 1);
          return;
        }
      }

      if (this.filter.search != null) {
        if (index !== -1 && !group.name.toLowerCase().includes(this.filter.search.toLowerCase())) {
          filteredList.splice(index, 1);
          return;
        }
      }
    });

    this.Groups = filteredList;
  }
}

