<div class="card-body" style="height: auto !important;">
    <table class="table table-hover table-striped">
        <thead>
            <th style="border-top: none; width: 4%;"></th>
            <th style="border-top: none; width: 24%;"><label class="h-table">Descrizione</label></th>
            <th style="border-top: none; width: 24%;" class="black-label"><label class="h-table">MAC Address</label></th>
            <th style="border-top: none; width: 24%;" class="black-label"><label class="h-table">Punto Vendita</label></th>
            <th style="border-top: none; width: 24%;" class="black-label"><label class="h-table">Cassa</label></th>
        </thead>
        <tbody>
            <tr *ngFor="let cfg of this.CFG">
                <td *ngIf="!cfg.edited">
                    <fa-icon [icon]="faDelete" style="color: red;" (click)="DeleteCFG(cfg)" class="hand"></fa-icon>
                </td>
                <td *ngIf="cfg.edited">
                    <fa-icon [icon]="faCheck" [style.color]="this.CheckValid(cfg) ? 'green' : 'grey'" class="hand" (click)="this.CheckValid(cfg) ? this.UpdateCFG(cfg) : ''" (click)="cfg.edited = false"></fa-icon>
                </td>
                <td><input class="form-control" type="string" [(ngModel)]="cfg.ds" (ngModelChange)="CheckEdited(cfg.id)" /></td>
                <td class="black-label"><input class="form-control text-center" type="string" [(ngModel)]="cfg.mc" (ngModelChange)="CheckEdited(cfg.id)" /></td>
                <td class="black-label"><input class="form-control text-center" type="number" [(ngModel)]="cfg.pv" (ngModelChange)="CheckEdited(cfg.id)" /></td>
                <td class="black-label"><input class="form-control text-center" type="number" [(ngModel)]="cfg.cs" (ngModelChange)="CheckEdited(cfg.id)" /></td>
            </tr>
            <tr>
                <td *ngIf="!this.IsAdding">
                    <fa-icon [icon]="faPlus" style="color: green;" (click)="this.IsAdding = true" style="cursor: pointer;" class="hand"></fa-icon>
                </td>
                <td *ngIf="this.IsAdding" [class.disabled]="!this.CheckValid(this.AddedCFG)" [class.enabled]="this.CheckValid(this.AddedCFG)" (click)="this.CheckValid(this.AddedCFG) ? this.AddCFG() : ''">
                    <fa-icon [icon]="faCheck" [style.color]="this.CheckValid(this.AddedCFG) ? 'green' : 'grey'" class="hand"></fa-icon>
                </td>
                <td><input class="form-control" type="string" [(ngModel)]="AddedCFG.ds" [style.display]="(this.IsAdding) ? '' : 'none'"/></td>
                <td class="black-label"><input class="form-control text-center" type="string" [(ngModel)]="AddedCFG.mc" [style.display]="(this.IsAdding) ? '' : 'none'"/></td>
                <td class="black-label"><input class="form-control text-center" type="number" [(ngModel)]="AddedCFG.pv" [style.display]="(this.IsAdding) ? '' : 'none'"/></td>
                <td class="black-label"><input class="form-control text-center" type="number" [(ngModel)]="AddedCFG.cs" [style.display]="(this.IsAdding) ? '' : 'none'"/></td>
            </tr>
        </tbody>
    </table>
</div>
