<div class="content fade-in">
    <div class="container-fluid">
        <div class="card strpied-tabled-with-hover">
            <div class="card-header">
                <h4 class="card-title">Crea utente</h4>
            </div>
            <div class="card-body">

                <div class="messages"></div>

                <div class="controls">
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary btn-fill" (click)="this.listChanged.emit(true)">Indietro</button>
                        </div>
                        
                        <div class="col" *ngIf="this.auth.AuthUser.ID === 10 && this.auth.AdminUsername === 'gab'">
                            <div class="row">
                                <div class="col">
                                    <button class="btn btn-primary btn-fill" (click)="this.modalService.show(permessiadmin, {class: 'modal-lg'})" *ngIf="this.Admin">Configura Permessi</button>
                                </div>
                                <div class="col">
                                    <div class="custom-control custom-switch" style="float: right;">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch1" (change)="this.AdminCreation()">
                                        <label class="custom-control-label" for="customSwitch1">CREA AMMINISTRATORE</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    
                    
                    
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Nome utente *</label>
                                <input id="username" type="text" name="username" class="form-control" placeholder="Inserire nome utente *" required="required" />
                                <label style="color:red" *ngIf="this.CheckError('username')">{{this.GetError('username')}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Password *</label>
                                <input id="psw" type="text" name="psw" class="form-control" placeholder="Inserire la password *" required="required" />
                                <label style="color:red" *ngIf="this.CheckError('password')">{{this.GetError('password')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>PIVA *</label>
                                <input id="piva" type="text" name="piva" class="form-control" placeholder="Inserire la partita iva *" required="required" [disabled]="Admin" />
                                <label style="color:red" *ngIf="this.CheckError('piva')">{{this.GetError('piva')}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Ragione Sociale *</label>
                                <input id="rsociale" type="text" name="rsociale" class="form-control" placeholder="Inserire ragione sociale *" required="required" [disabled]="Admin"/>
                                <label style="color:red" *ngIf="this.CheckError('rsociale')">{{this.GetError('rsociale')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Città *</label>
                                <input id="citta" type="text" name="citta" class="form-control" placeholder="Inserire città *" required="required" [disabled]="Admin" />
                                <label style="color:red" *ngIf="this.CheckError('citta')">{{this.GetError('citta')}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Via *</label>
                                <input id="via" type="text" name="via" class="form-control" placeholder="Inserire l'indirizzo *" required="required" [disabled]="Admin"/>
                                <label style="color:red" *ngIf="this.CheckError('via')">{{this.GetError('via')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>E-mail *</label>
                                <input id="email" type="email" name="email" class="form-control" placeholder="Inserire indirizzo e-mail *" required="required" />
                                <label style="color:red" *ngIf="this.CheckError('email')">{{this.GetError('email')}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Pec</label>
                                <input id="pec" type="text" name="pec" class="form-control" placeholder="Inserire indirizzo PEC *" required="required" [disabled]="Admin"/>
                                <label style="color:red" *ngIf="this.CheckError('pec')">{{this.GetError('pec')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>SDI *</label>
                                <input id="sdi" type="text" name="sdi" class="form-control" placeholder="Inserire SDI *" required="required" [disabled]="Admin"/>
                                <label style="color:red" *ngIf="this.CheckError('sdi')">{{this.GetError('sdi')}}</label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>IBAN *</label>
                                <input id="iban" type="text" name="iban" class="form-control" placeholder="Inserire IBAN *" required="required" [disabled]="Admin"/>
                                <label style="color:red" *ngIf="this.CheckError('iban')">{{this.GetError('iban')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role2 === 0 || this.auth.AuthUser.Role === 4 || this.auth.AuthUser.Role2 === 4" id="tipoutente">
                        <label>SELEZIONA IL TIPO DI UTENTE * (MAX 3)</label>

                        <a role="button" *ngIf="this.UserType.Distributore === true || this.UserType.Concessionario || this.UserType.Segnalatore === true" >
                            <span (click)="OpenModalConfig(userConfig)" style="padding-left: 2%;">
                                <fa-icon [icon]="faCog" size="2x"></fa-icon>
                            </span>
                        </a>
                        

                        <div class="row">
                            <div class="col" *ngIf="this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role2 === 0 ">
                                <div class="chiller_cb ml-1 mb-2">
                                    <input type="checkbox" name="checkRiv" value="Distributore" (click)="this.SelectTypeOfUser('Distributore', userConfig)"/>
                                    <label for="conCheck" style="margin-left: 5%;">Distributore</label>
                                </div>
                            </div>
                            <div class="col" *ngIf="this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role2 === 0 || this.auth.AuthUser.Role === 4 || this.auth.AuthUser.Role2 === 4">
                                <div class="chiller_cb ml-1 mb-2">
                                    <input type="checkbox" name="checkRiv" value="Concessionario" (click)="this.SelectTypeOfUser('Concessionario', userConfig)"/>
                                    <label for="conCheck" style="margin-left: 5%;">Concessionario</label>
                                </div>
                            </div>
                            <div class="col" *ngIf="this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role2 === 0 || this.auth.AuthUser.Role === 4 || this.auth.AuthUser.Role2 === 4">
                                <div class="chiller_cb ml-1 mb-2">
                                    <input type="checkbox" name="checkRiv" value="Segnalatore" (click)="this.SelectTypeOfUser('Segnalatore')" />
                                    <label for="segCheck" style="margin-left: 5%;">Segnalatore</label>
                                    <span></span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="chiller_cb ml-1 mb-2">
                                    <input (click)="this.SelectTypeOfUser('Utente')" value="Utente" name="checkRiv" type="checkbox"/>
                                    <label for="userCheck" style="margin-left: 5%;">Utente</label>
                                    <span></span>
                                </div>
                            </div>
                        </div>

                        <label style="color:red" *ngIf="this.CheckError('roles')">{{this.GetError('roles')}}</label>

                    </div>
                    </div>
                    <div class="form-group" *ngIf="this.auth.AuthUser.Username === 'gab'">
                        <label>SELEZIONA SERVIZI *</label>
                        <div class="row">
                            <div class="col">
                                <div class="chiller_cb ml-1 mb-2">
                                    <input id="checkTot" name="checkServizio" type="checkbox" value="vintelligence" />
                                    <label for="checkTot" style="margin-left: 5%;"> Visual Intelligence</label>
                                    <span></span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="chiller_cb ml-1 mb-2">
                                    <input id="checkRist" name="checkServizio" type="checkbox" value="vreservation"/>
                                    <label for="checkRist" style="margin-left: 5%;">Visual Reservation</label>
                                    <span></span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="chiller_cb ml-1 mb-2">
                                    <input id="checkVo" name="checkServizio" type="checkbox" value="vorder" />
                                    <label for="checkVo" style="margin-left: 5%;">Visual Order</label>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="this.Segnalatori.length > 0 && !this.Admin">
                        <div class="row">
                            <div class="col-2"><label>Associa Segnalatore</label></div>
                            <div class="col-2">
                                    <select class="form-select" [(ngModel)]="this.Segnalatore">
                                        <option [value]="this.auth.AuthUser.Username">Nessuno</option>
                                        <option *ngFor="let user of this.Segnalatori" [value]="user.Username">{{user.Username}}</option>
                                      </select>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="loading">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    <div class="row">
                        <div class="col">
                            <button type="button" class="btn btn-primary btn-block" (click)="CreateUser()" [disabled]="loading">Crea nuovo utente</button>
                        </div>
                    </div>
                    <div class="row" *ngIf="this.ErroreCreazione">
                        <div class="col">
                            <label style="color:red">Configura il concessionario/distributore per continuare</label>
                        </div>
                    </div>
                    <div class="row">
                        <alert style="width: 100%; margin-top: 2%;" type="success" [dismissible]="true" [isOpen]="alertOpenSuccess">
                            <strong style="text-align: center;">{{alertMsg}}</strong>
                        </alert>
                        <alert style="width: 100%; margin-top: 2%;" type="danger" [dismissible]="true" [isOpen]="alertOpen">
                            <strong style="text-align: center;">{{alertMsg}}</strong>
                        </alert>
                    </div>
                </div>
            </div>
        </div>
</div>

<ng-template #userConfig>
    <div class="modal-header center" style="padding: 0;">
        <h4 class="modal-title" id="modalHead" style="text-align: center;">Configura</h4>
    </div>
    <div class="modal-body modal-lg  card strpied-tabled-with-hover">
        <div class="content fade-in">
            <div class="container-fluid">
                <div class="testo strpied-tabled-with-hover">

        <div class="row">
            <div class="col-1"></div>
            <div class="col">
                <h4 class="modal-title" id="modalHead" style="font-size: 18px; margin-bottom: 2px;">Modalità di acquisto Licenze Annuali</h4>
            </div>
        </div>
        <div class="row form-group" *ngIf="this.UserType.Concessionario || this.UserType.Distributore">
            <div class="col-1"></div>
            <div class="col">
                <div class="row" *ngIf="this.UserType.Concessionario">
                    <div class="col-6">
                        <input type="checkbox" [(ngModel)]="this.Settings.Tipo.singolo"/>
                        <label style="margin-left: 5%;">Singolo Acquisto</label>
                    </div>
                    <div class="col-3" *ngIf="this.Settings.Tipo.singolo">
                        <input id="scontosd" type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.S"/>
                        <label style="margin-left: 5%;">Sconto</label>
                    </div>
                    <div class="col-3" *ngIf="this.Settings.Tipo.singolo">
                        <input id="scontosd" type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.SBonus"/>
                        <label style="margin-left: 5%;">Sconto Bonus</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <input type="checkbox" [(ngModel)]="this.Settings.Tipo.pacchetti"/>
                        <label style="margin-left: 5%;">Pacchetti</label>
                    </div>
                </div>
                <div class="row form-group" *ngIf="this.Settings.Tipo.pacchetti">
                    <div class="col-1"></div>
                    <div class="col">
                        <div class="row">
                            <div class="col-4">
                                <label style="margin-left: 5%;" selected>5 Licenze</label>
                            </div>
                            <div class="col-4">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.P.Cinque" />
                                <label for="sconto5" style="margin-left: 5%;">Sconto</label>
                            </div>
                            <div class="col-4">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.PBonus.Cinque" />
                                <label for="sconto5" style="margin-left: 5%;">Sconto Bonus</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <label style="margin-left: 5%;">10 Licenze</label>
                            </div>
                            <div class="col-4">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.P.Dieci" />
                                <label style="margin-left: 5%;">Sconto</label>
                            </div>
                            <div class="col-4">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.PBonus.Dieci" />
                                <label style="margin-left: 5%;">Sconto Bonus</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <label style="margin-left: 5%;">15 Licenze</label>
                            </div>
                            <div class="col-4">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.P.Quindici" />
                                <label style="margin-left: 5%;">Sconto</label>
                            </div>
                            <div class="col-4">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.PBonus.Quindici" />
                                <label style="margin-left: 5%;">Sconto Bonus</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <label style="margin-left: 5%;">20 Licenze</label>
                            </div>
                            <div class="col-4">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.P.Venti" />
                                <label style="margin-left: 5%;">Sconto</label>
                            </div>
                            <div class="col-4">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.PBonus.Venti" />
                                <label style="margin-left: 5%;">Sconto Bonus</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="this.auth.AuthUser.Tipo.includes('C')">
                    <div class="col-6">
                        <input type="checkbox" [(ngModel)]="this.Settings.Tipo.canone"/>
                        <label style="margin-left: 5%;">Canone Mensile</label>
                    </div>
                </div>
                <div class="row form-group" *ngIf="this.Settings.Tipo.canone">
                    <div class="col-1"></div>
                    <div class="col">
                        <div class="form-group">
                            <label>SOFTWARE ABBINATI(MAX 3)</label>
                            <div class="row">
                                <div class="col" *ngIf="this.auth.AuthUser.Username === 'gab' || this.auth.AuthUser.SoftwareAbbinatiBoolean.VisualFood">
                                    <div class="chiller_cb ml-1 mb-2">
                                        <input type="checkbox" value="vfood" [checked]="this.Settings.SoftwareAbb.VisualFood" [(ngModel)]="this.Settings.SoftwareAbb.VisualFood"/>
                                        <label for="conCheck" style="margin-left: 5%;">Visual Food</label>
                                        <span></span>
                                    </div>
                                </div>
                                <div class="col" *ngIf="this.auth.AuthUser.Username === 'gab' || this.auth.AuthUser.SoftwareAbbinatiBoolean.VisualShop">
                                    <div class="chiller_cb ml-1 mb-2">
                                        <input type="checkbox" value="vshop" [checked]="this.Settings.SoftwareAbb.VisualShop" [(ngModel)]="this.Settings.SoftwareAbb.VisualShop"/>
                                        <label for="segCheck" style="margin-left: 5%;">Visual Shop</label>
                                        <span></span>
                                    </div>
                                </div>
                                <div class="col" *ngIf="this.auth.AuthUser.Username === 'gab' || this.auth.AuthUser.SoftwareAbbinatiBoolean.VisualRetail">
                                    <div class="chiller_cb ml-1 mb-2">
                                        <input type="checkbox" value="vretail" [checked]="this.Settings.SoftwareAbb.VisualRetail" [(ngModel)]="this.Settings.SoftwareAbb.VisualRetail"/>
                                        <label for="segCheck" style="margin-left: 5%;">Visual Retail</label>
                                        <span></span>
                                    </div>
                                </div>
                                <div class="col" *ngIf="this.auth.AuthUser.Username === 'gab' || this.auth.AuthUser.SoftwareAbbinatiBoolean.VisualSelf">
                                    <div class="chiller_cb ml-1 mb-2">
                                        <input type="checkbox" value="vself" [checked]="this.Settings.SoftwareAbb.VisualSelf" [(ngModel)]="this.Settings.SoftwareAbb.VisualSelf"/>
                                        <label for="userCheck" style="margin-left: 5%;">Visual Self</label>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label *ngIf="this.ErrorSoftware" style="color:red" >Errore selezione software</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4">
                                <input name="checkRiv" type="radio" [(ngModel)]="this.Settings.NumeroPacchetti" value="15" />
                                <label style="margin-left: 5%;">15 Licenze Annuali</label>
                            </div>
                            <div class="col-2" *ngIf="this.Settings.NumeroPacchetti === '15'">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.C" />
                                <label style="margin-left: 5%;">Sconto</label>
                            </div>
                            <div class="col-2" *ngIf="this.Settings.NumeroPacchetti === '15'">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.CBonus" />
                                <label style="margin-left: 5%;">Sconto Bonus</label>
                            </div>
                            <div class="col-4" *ngIf="this.Settings.NumeroPacchetti === '15'">
                                <input type="number" min="0.00" max="10000.00" step="0.01" [(ngModel)]="this.Settings.Canone" />
                                <label for="userCheck" style="margin-left: 5%;">Canone mensile</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4">
                                <input name="checkRiv" type="radio" [(ngModel)]="this.Settings.NumeroPacchetti" value="40"/>
                                <label style="margin-left: 5%;">40 Licenze Annuali</label>
                            </div>
                            <div class="col-2" *ngIf="this.Settings.NumeroPacchetti === '40'">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.C" />
                                <label style="margin-left: 5%;">Sconto</label>
                            </div>
                            <div class="col-2" *ngIf="this.Settings.NumeroPacchetti === '40'">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.CBonus" />
                                <label style="margin-left: 5%;">Sconto Bonus</label>
                            </div>
                            <div class="col-4" *ngIf="this.Settings.NumeroPacchetti === '40'">
                                <input id="canonec15d" type="number" min="0.00" max="10000.00" step="0.01" [(ngModel)]="this.Settings.Canone" />
                                <label for="userCheck" style="margin-left: 5%;">Canone mensile</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4">
                                <input name="checkRiv" type="radio"  [(ngModel)]="this.Settings.NumeroPacchetti" value="60" />
                                <label style="margin-left: 5%;">60 Licenze Annuali</label>
                            </div>
                            <div class="col-2" *ngIf="this.Settings.NumeroPacchetti === '60'">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.C" />
                                <label style="margin-left: 5%;">Sconto</label>
                            </div>
                            <div class="col-2" *ngIf="this.Settings.NumeroPacchetti === '60'">
                                <input  type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.CBonus" />
                                <label style="margin-left: 5%;">Sconto Bonus</label>
                            </div>
                            <div class="col-4" *ngIf="this.Settings.NumeroPacchetti === '60'">
                                <input type="number" min="0.00" max="10000.00" step="0.01" [(ngModel)]="this.Settings.Canone" />
                                <label for="userCheck" style="margin-left: 5%;">Canone mensile</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4">
                                <input name="checkRiv" type="radio" [(ngModel)]="this.Settings.NumeroPacchetti" value="-1" />
                                <label style="margin-left: 5%;">Licenze Infinite</label>
                            </div>
                            <div class="col-2" *ngIf="this.Settings.NumeroPacchetti === '-1'">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.C" />
                                <label style="margin-left: 5%;">Sconto</label>
                            </div>
                            <div class="col-2" *ngIf="this.Settings.NumeroPacchetti === '-1'">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Sconto.CBonus" />
                                <label style="margin-left: 5%;">Sconto Bonus</label>
                            </div>
                            <div class="col-4" *ngIf="this.Settings.NumeroPacchetti === '-1'">
                                <input type="number" min="0" max="100" [(ngModel)]="this.Settings.Canone" />
                                <label for="userCheck" style="margin-left: 5%;">Canone mensile</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <label *ngIf="this.ErrorContatotoreCanone" style="color:red" >Il numero di licenze è incompatibile con le licenze disponibili</label>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 2%">
                        <div class="col-4">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoRinnovo" />
                            <label style="margin-left: 5%;">Sconto Rinnovo</label>
                        </div>
                        <div class="col-4">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoModuli" />
                            <label style="margin-left: 5%;">Sconto Moduli</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col">
                    <h4 class="modal-title" id="modalHead" style="font-size: 18px; margin-bottom: 2px;">Modalità di acquisto Software Cloud</h4>
                </div>
            </div>
            <div class="row form-group" *ngIf="this.UserType.Concessionario || this.UserType.Distributore">
                <div class="col-1"></div>
                <div class="col">
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-2">
                            <label>Sconto <br> 1º Anno</label>
                        </div>
                        <div class="col-2">
                            <label>Sconto <br> Bonus</label>
                        </div>
                        <div class="col-2">
                            <label>Sconto <br> 2º Anno</label>
                        </div>
                        <div class="col-2">
                            <label>Sconto <br> Bonus</label>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom: 2%;">
                        <div class="col-4">
                            <label>Da <input class="w-20" type="number" min="0" max="100" (change)="CheckCanoneCloud()" [(ngModel)]="this.Settings.ScontoCloud.PrimoScaglione.SX"> a <input class="w-20" type="number" min="1" max="100" (change)="CheckCanoneCloud()" [(ngModel)]="this.Settings.ScontoCloud.PrimoScaglione.DX"> attivazioni</label>
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.PrimoScaglione.PrimoAnno" />
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.PrimoScaglione.PrimoAnnoBonus" />
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.PrimoScaglione.SecondoAnno" />
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.PrimoScaglione.SecondoAnnoBonus" />
                        </div>
                    </div>
                    <div class="row" style="margin-bottom: 2%;">
                        <div class="col-4">
                            <label>Da <input class="w-20" type="number" min="0" max="100" (change)="CheckCanoneCloud()" [(ngModel)]="this.Settings.ScontoCloud.SecondoScaglione.SX"> a <input class="w-20" type="number" min="0" max="100" (change)="CheckCanoneCloud()" [(ngModel)]="this.Settings.ScontoCloud.SecondoScaglione.DX"> attivazioni</label>
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.SecondoScaglione.PrimoAnno" />
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.SecondoScaglione.PrimoAnnoBonus" />
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.SecondoScaglione.SecondoAnno" />
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.SecondoScaglione.SecondoAnnoBonus" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Da <input class="w-20" type="number" min="-1" max="100" (change)="CheckCanoneCloud()" [(ngModel)]="this.Settings.ScontoCloud.TerzoScaglione.SX"> a <input class="w-20" type="number" min="-1" max="100" (change)="CheckCanoneCloud()" [(ngModel)]="this.Settings.ScontoCloud.TerzoScaglione.DX"> attivazioni</label>
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.TerzoScaglione.PrimoAnno" />
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.TerzoScaglione.PrimoAnnoBonus" />
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.TerzoScaglione.SecondoAnno" />
                        </div>
                        <div class="col-2">
                            <input type="number" min="0" max="100" [(ngModel)]="this.Settings.ScontoCloud.TerzoScaglione.SecondoAnnoBonus" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col">
                    <label style="color:red" *ngIf="this.ErroreCloud">Errore Software Cloud</label>
                    <label style="color:red" *ngIf="!this.ErroreCloud"></label>
                </div>
            </div>


            <div class="row form-group" *ngIf="this.UserType.Segnalatore">
                <div class="col-1"></div>
                <div class="col">
                    <p class="card-title"><b>Provvigioni Segnalatore</b></p>
                    <div class="row">
                        <div class="col-3">
                            <input type="checkbox" [(ngModel)]="this.SettingsSegnalatore.Software">
                            <label style="margin-left: 5%;">Software</label>
                        </div>
                        <div class="col-4">
                            <input type="number" min="0" max="100" [(ngModel)]="this.SettingsSegnalatore.VenditaSoftware" [disabled]="!this.SettingsSegnalatore.Software"/>
                            <label style="margin-left: 5%;">Vendita</label>
                        </div>
                        <div class="col-4">
                            <input type="number" min="0" max="100" [(ngModel)]="this.SettingsSegnalatore.RinnovoSoftware" [disabled]="!this.SettingsSegnalatore.Software"/>
                            <label style="margin-left: 5%;">Rinnovo</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <input type="checkbox" [(ngModel)]="this.SettingsSegnalatore.Hardware">
                            <label style="margin-left: 5%;">Hardware</label>
                        </div>
                        <div class="col-4">
                            <input type="number" min="0" max="100" [(ngModel)]="this.SettingsSegnalatore.VenditaHardware" [disabled]="!this.SettingsSegnalatore.Hardware"/>
                            <label style="margin-left: 5%;">Vendita</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div class="modal-footer">
        <button (click)="this.CloseModalConfig()" class="btn btn-primary center" style="margin-left: auto; margin-right: auto;" [disabled]="this.ErroreCloud">Chiudi</button>
    </div>
</div>
</ng-template>

<ng-template #permessiadmin>
    <div class="modal-header modal-head-user">
        <h5 class="modal-title" id="modalHead">Configura Permessi</h5>
    </div>
    <div class="modal-body modal-lg">
        <div class="card">
            <p class="card-title">Licenze</p>
            <div class="row">
                <div class="col-3">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Licenze.Generazione"/>
                    <label style="margin-left: 5%;">Generazione</label>
                </div>
                <div class="col-3">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Licenze.Upgrade"/>
                    <label style="margin-left: 5%;">Upgrade</label>
                </div>
                <div class="col-3">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Licenze.Stato"/>
                    <label style="margin-left: 5%;">Stato Licenza</label>
                </div>
                <div class="col-3">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Licenze.Rinnovo"/>
                    <label style="margin-left: 5%;">Rinnovo Licenza</label>
                </div>
            </div>
        </div>
        <div class="card">
            <p class="card-title">Shop</p>
            <div class="row">
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Shop.Inserimento"/>
                    <label style="margin-left: 5%;">Inserimento</label>
                </div>
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Shop.Modifica"/>
                    <label style="margin-left: 5%;">Modifica</label>
                </div>
            </div>
        </div>
        <div class="card">
            <p class="card-title">Manuali</p>
            <div class="row">
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Manuali.Inserimento"/>
                    <label style="margin-left: 5%;">Inserimento</label>
                </div>
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Manuali.Eliminazione"/>
                    <label style="margin-left: 5%;">Eliminazione</label>
                </div>
            </div>
        </div>
        <div class="card">
            <p class="card-title">Listini</p>
            <div class="row">
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Listini.Inserimento"/>
                    <label style="margin-left: 5%;">Inserimento</label>
                </div>
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Listini.Eliminazione"/>
                    <label style="margin-left: 5%;">Eliminazione</label>
                </div>
            </div>
        </div>
        <div class="card">
            <p class="card-title">Aggiornamenti</p>
            <div class="row">
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Aggiornamenti.Inserimento"/>
                    <label style="margin-left: 5%;">Inserimento</label>
                </div>
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Aggiornamenti.Eliminazione"/>
                    <label style="margin-left: 5%;">Eliminazione</label>
                </div>
            </div>
        </div>
        <div class="card">
            <p class="card-title">Utenti</p>
            <div class="row">
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Utenti.Creazione"/>
                    <label style="margin-left: 5%;">Creazione</label>
                </div>
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Utenti.Modifica"/>
                    <label style="margin-left: 5%;">Modifica</label>
                </div>
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Utenti.Eliminazione"/>
                    <label style="margin-left: 5%;">Eliminazione</label>
                </div>
            </div>
        </div>
        <div class="card">
            <p class="card-title">Censimenti</p>
            <div class="row">
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Censimenti.Aggiornamento"/>
                    <label style="margin-left: 5%;">Aggiornamento</label>
                </div>
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Censimenti.Eliminazione"/>
                    <label style="margin-left: 5%;">Eliminazione</label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div (click)="CloseModalPermessi()" class="btn btn-primary center" style="margin-left: auto; margin-right: auto;">Chiudi</div>
    </div>
</ng-template>