import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyBusiness } from 'src/app/Models/Cloud/CompanyBusiness';
import { GroupService } from './group.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private http: HttpClient, private groupService: GroupService) { }

  public GetBusiness() {
    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    return this.http.get<CompanyBusiness[]>('/api/cloud/activity', {headers});
  }

  public DeleteBusiness(id: number) {
    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    const params: HttpParams = new HttpParams()
    .set("id", String(id));

    return this.http.delete<boolean>('/api/cloud/activity', {headers, params});
  }


  public CreateBusiness(description: string, business?: CompanyBusiness) {
    const token = this.groupService.AuthResponse.token;

    if (business) {
      business.description = description;
      const headers: HttpHeaders = new HttpHeaders()
      .set("Authorization-Cloud", token);

      return this.http.post<boolean>('/api/cloud/activity', business, {headers});
    }
    
    const companyBusiness: CompanyBusiness = new CompanyBusiness();
     companyBusiness.description = description;

     const headers: HttpHeaders = new HttpHeaders()
     .set("Authorization-Cloud", token);

     return this.http.post('/api/cloud/activity', companyBusiness, {headers});
  }
}
