export class Log {
    public Logs: LogRow[];
    public Date: Date;
}

export class LogRow {
    public Date: string;
    public Time: string;
    public IP: string;
    public OP: string;
    public User: string;
    public License: string;
}