import { IsDev } from './../global';

import { HttpClient, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {faDownload, faTrash, faFileArchive, faPlus, faMinus, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import Update from '../Models/Update';
import { AuthService } from '../Services/AuthService/auth.service';
import { FileService } from '../Services/FileService/file.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.scss']
})
export class UpdatesComponent implements OnInit {


  public Updates: Update[] = [];

  public UpdatesPrint: Update[] = [];
  public UpdatesShow: FilterUpdate[] = [];

  public SoftCodes: string[2][] = [];
  public ready: boolean = false;

  //ALERT
  public alertOpen: boolean = false;
  public alertOpenSuccess: boolean = false;
  public alertMsg: string = "";

  //LOADING
  public showSpinner: boolean = false;

  //ICONS
  public faTrash: IconDefinition = faTrash;
  public faDownload: IconDefinition = faDownload;
  public faFileArchive: IconDefinition = faFileArchive;
  public faPlus: IconDefinition = faPlus;
  public faMinus: IconDefinition = faMinus;

  //Modal Elimina
  public UpdateDaEliminare: Update;
  public BoolDaEliminare: boolean;
  public modalRef: BsModalRef;

  constructor(private http: HttpClient, public auth: AuthService, private router: Router, public FileService: FileService, public modalService: BsModalService, private fileService: FileService) { 
    
  }

  private async Init() {

    this.auth.Ready = false;
    //AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName("li");
    for (let i = 0; i < sidebarElements.length; ++i) {
      if (sidebarElements[i].id !== 'updates') {
        sidebarElements[i].classList.remove('active');
      } else {
        sidebarElements[i].classList.add('active');
      }
    }

    await this.LoadUpdates();
    this.FilterPrintedUpdates();
    await this.InitSoftCodes();

    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }

    this.auth.Ready = true;
  }

  public fileToUpload: File = null;

  async ngOnInit() {
    await this.Init();
  }

  public async LoadUpdates() {
    
    this.Updates = await this.fileService.GetUpdates();

    return;
    
  }

  private async LocalValidation() {
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));
      
      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if(valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.IsLogin = true;
        this.auth.Ready = false;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }

  private async InitSoftCodes() {
    const result: any = await this.fileService.InitSoftcodes();
    this.SoftCodes = result;
    this.ready = true;
  }

  public FileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    //this.FileService.FileUpload(this.fileToUpload);
  }

  
  public async UploadUpdate() {
    const version: string = (document.getElementById('version') as HTMLInputElement).value;
    const date: string = (document.getElementById('data') as HTMLInputElement).value;
    

    const softcodeDesc: HTMLSelectElement = (document.getElementById('softcode') as HTMLSelectElement);

    const softcodeSelected: string = softcodeDesc.options[softcodeDesc.selectedIndex].value;
    const softcode: string = this.SoftCodes.find(softcode => softcodeSelected === softcode[0])
    
    if (version === "" || date === "" || this.fileToUpload === null) {
      this.alertMsg = "Inserire tutti i campi per continuare";
      this.alertOpen = true;
      setTimeout(() => {
        this.alertOpen = false;
      }, 5000)
    } else {
      this.showSpinner = true;
      await this.FileService.UpdateUpload(this.fileToUpload, softcode[0], version, softcode[1], date);
      this.showSpinner = false;
      this.alertMsg = "Caricamento completato";
      this.alertOpenSuccess = true;
      setTimeout(() => {
        this.alertOpenSuccess = false;
      }, 5000)

      
      await this.ngOnInit();
      setTimeout(() => {
        this.showSpinner = false;
      }, 2000);
    }
    
  }


  public OpenModalDelete(template: TemplateRef<any>, update: Update, sub: boolean) {
    this.UpdateDaEliminare = update;
    this.BoolDaEliminare = sub;
    this.modalRef = this.modalService.show(template);
  }

  public async DeleteUpdate(update: Update) {

    const result: boolean = await this.FileService.DeleteUpdate(update.softwareName, update.downloadFileName, update.version, update.releaseDate);

    
    this.modalService.hide();
    await this.ngOnInit();
    
  }

  public Filter() {
    const input: string = (document.getElementById("search") as HTMLInputElement).value;
    
    const table: HTMLTableElement = document.getElementById("table") as HTMLTableElement;
    const tr: HTMLCollectionOf<HTMLTableRowElement> = table.getElementsByTagName("tr");

    for (let i = 0; i < tr.length; i++) {
      const td: HTMLTableDataCellElement = tr[i].getElementsByTagName("td")[0];
      
      if (td) {
          const txtValue: string = td.innerHTML;

          if (txtValue.toUpperCase().includes(input.toUpperCase())) {
              tr[i].style.display = "";
          } else {
              tr[i].style.display = "none";
          }
      }
    }
  }

  public fileName(i: number, ) {
    return i;
  }

  //La funzione filtra i software all'interno della lista e li divide in
  // SOFTWARE DA STAMPARE (ULTIMA VERSIONE)
  // SOFTWARE DA MOSTRARE COME VECCHIE VERSIONE (ARRAY)

  //GLI ELEMENTI SONO GIA' INSERITI PER ORDINE DI DATA, NON E' NECESSARIO NESSUN CONTROLLO DELLA DATA
  public FilterPrintedUpdates(): void {
    this.UpdatesPrint = [];
    this.UpdatesShow = [];
    for (let i = 0; i < this.Updates.length; ++i) {
      const firstToPrint: Update = this.UpdatesPrint.find((element: Update) => {
        return element.softCode === this.Updates[i].softCode && element.softwareName.toLowerCase() === this.Updates[i].softwareName.toLowerCase()
      });

      if (firstToPrint === undefined) {
        this.UpdatesPrint.push(this.Updates[i]);
        //AGGIUNGO DENTRO L'ALTRO ARRAY UN ARRAY PER INSERIRE LE VECCHIA VERSIONI
        this.UpdatesShow.push(new FilterUpdate(this.Updates[i].softCode));
      } else {
        //INSERISCO DENTRO SOFTWARE VECCHI
        const elementFolder: FilterUpdate = this.UpdatesShow.find((element: FilterUpdate) => {
          return element.softCode === this.Updates[i].softCode
        });
        elementFolder.Updates.push(this.Updates[i]);
      }
    }
  }

  
  public GetUpdatesFromSoftcode(softcode: string): Update[] {
    const UpdateSoftkey: Update[] = [];
    for(let i = 0; i < this.UpdatesShow.length; ++i) {
      if (this.UpdatesShow[i].softCode === softcode) {
        return this.UpdatesShow[i].Updates;
      }
    }
  }

}

class FilterUpdate {
  public softCode: string;
  public Updates: Update[] = [];

  constructor(key: string) {
    this.softCode = key;
  }

};
