import { UserService } from './../../../Services/UserService/user.service';
import { AuthService } from './../../../Services/AuthService/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/Models/User';

@Component({
  selector: 'app-user-create-cashmatic',
  templateUrl: './user-create-cashmatic.component.html',
  styleUrls: ['./user-create-cashmatic.component.scss']
})
export class UserCreateCashmaticComponent implements OnInit {

  @Output() listChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() LoadUsers: EventEmitter<User[]> = new EventEmitter();
  @Input() public Users: User[];
  public userInfo: UserInfo = new UserInfo(); //MAIN
  public UserType = {
    Utente: false,
    Concessionario: false,
  };

  public Segnalatore: string = this.auth.AuthUser.Username;


  public errors: any[] = [];  //LISTA ERRORI FORM
  public loading: boolean = false;
  public ErroreCreazione: boolean = false;
   //ALERT
   public alertOpenSuccess: boolean = false;
   public alertOpen: boolean = false;
   public alertMsg: string = "";
  constructor(private auth: AuthService, private UserService: UserService) { }

  ngOnInit(): void {
  }

  public async CreateUser() {
    const formData: FormData = new FormData();
    this.errors = [];
    //TIPO ANONIMO
    
    
    //USERNAME
    this.userInfo.username = (document.getElementById('username') as HTMLInputElement).value;
    formData.append('username', this.userInfo.username);
    if (this.userInfo.username.length === 0) {
      this.errors.push({
        key: "username", value: "Inserisci username"
      })
    }
    else if (this.userInfo.username.length < 3) {
      this.errors.push({
        key: "username", value: "Nome Utente lungo almeno 3"
      })
    } else if (this.userInfo.username.includes(" ")) {
      this.errors.push({
        key: "username", value: "Il Nome Utente non può contenere spazi"
      })
    }
      else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'username')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //PASSWORD
    this.userInfo.password = (document.getElementById('psw') as HTMLInputElement).value;
    formData.append('password', this.userInfo.password);

    if (this.userInfo.password.length === 0) {
      this.errors.push({
        key: "password", value: "Inserisci password"
      })
    }
    else if (this.userInfo.password.length <= 5) {
      this.errors.push({
        key: "password", value: "La password deve essere lunga 6"
      })
    }
    else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'password')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //PIVA
    this.userInfo.piva = (document.getElementById('piva') as HTMLInputElement).value;
    formData.append('piva', this.userInfo.piva);
    if (this.userInfo.piva.length === 0 ) {
      this.errors.push({
        key: "piva", value: "Inserisci partita IVA"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'piva')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //RAGIONESOCIALE
    this.userInfo.rsociale = (document.getElementById('rsociale') as HTMLInputElement).value;
    formData.append('ragionesociale', this.userInfo.rsociale);

    if (this.userInfo.rsociale.length === 0) {
      this.errors.push({
        key: "ragionesociale", value: "Inserisci ragione sociale"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'ragionesociale')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //CITTA
    this.userInfo.citta = (document.getElementById('citta') as HTMLInputElement).value;
    formData.append('citta', this.userInfo.citta);

    if (this.userInfo.citta.length === 0) {
      this.errors.push({
        key: "citta", value: "Inserisci città"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'citta')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //VIA
    this.userInfo.via = (document.getElementById('via') as HTMLInputElement).value;
    formData.append('via', this.userInfo.via);
    if (this.userInfo.via.length === 0) {
      this.errors.push({
        key: "via", value: "Inserisci via"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'via')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }


    //EMAIL
    this.userInfo.email = (document.getElementById('email') as HTMLInputElement).value;
    formData.append('email', this.userInfo.email);
    if (this.userInfo.email.length === 0) {
      this.errors.push({
        key: "email", value: "Inserisci e-mail"
      })
    }
    else if (!this.userInfo.email.includes('@') || this.userInfo.email.includes(' ')) {
      this.errors.push({
        key: "email", value: "Inserire e-mail nel formato valido"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'email')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //PEC
    this.userInfo.pec = (document.getElementById('pec') as HTMLInputElement).value;
    formData.append('pec', this.userInfo.pec);
    if ((!this.userInfo.pec.includes('@') || this.userInfo.pec.includes(' ')) && this.userInfo.pec.length > 0) {
      this.errors.push({
        key: "pec", value: "Inserire PEC nel formato valido"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'pec')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //SDI
    this.userInfo.sdi = (document.getElementById('sdi') as HTMLInputElement).value;
    formData.append('sdi', this.userInfo.sdi);
    if (this.userInfo.sdi.length === 0) {
      this.errors.push({
        key: "sdi", value: "Inserisci codice SDI"
      })
    }
    else if (this.userInfo.sdi.length != 7) {
      this.errors.push({
        key: "sdi", value: "Lunghezza SDI errata"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'sdi')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }


    //IBAN
    this.userInfo.iban = (document.getElementById('iban') as HTMLInputElement).value;
    formData.append('iban', this.userInfo.iban);
    

    //TIPO UTENTE
    let role: string = "";
    //ORDINE: DISTRIBUTORE, CONCESSIONARIO, SEGNALATORE, UTENTE
    let roles: number = 0;

    if (this.UserType.Concessionario) {
      role += "Concessionario|";
      roles++;
    }

    if (this.UserType.Utente) {
      role += "Utente|";
      roles++;
    }  

    formData.append('role', role.substring(0, role.length - 1));

    if (roles === 0) {
      this.errors.push({
        key: "roles", value: "Seleziona almeno un ruolo"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'roles')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    if (this.UserType.Concessionario) {
      const tipo: string = "S";
      formData.append('tipo', tipo);

      const sconto: string = "S:0+0-"
      formData.append('sconto', sconto);

      const scontoCloud: string = "1:20>20+0|15+0&21:50>20+0|15+2&51:100>20+0|15+5";
      formData.append('scontocloud', scontoCloud);

    }

    formData.append("segnalatore", this.Segnalatore);
    formData.append("creatore", this.auth.AuthUser.Username);

    formData.append('servizi', "");
    formData.append('scontospecial', "50|15");
    formData.append('software', "");

    if (this.errors.length === 0) {
      this.loading = true;
      
      const result = await this.UserService.CreateUser(formData);

      if (result) {
        this.alertMsg = "Account creato con successo";
        this.alertOpenSuccess = true;
        setTimeout(async () => {
          this.alertOpenSuccess = false;
          this.loading = false;
          //AGGIORNO LA LISTA ALL'INTERNO DEL COMPONENTE PADRE
          const users: User[] = await this.UserService.LoadUsers(this.auth.AuthUser.Username);
          this.LoadUsers.emit(users);
          this.listChanged.emit(true);
        }, 3000);
      } else {
        this.alertMsg = "Username o PIVA già esistenti, riprova";
        this.alertOpen = true;
        setTimeout(() => {
          this.loading = false;
          this.alertOpen = false;
        }, 5000)
      }
    }


  }

  public CheckError(key: string): boolean {
    for (let i = 0; i < this.errors.length; ++i) {
      if (this.errors[i].key === key) {
        return true;
      }
    }
    return false;
  }
  public GetError(key: string): string {
    for (let i = 0; i < this.errors.length; ++i) {
      if (this.errors[i].key === key) {
        return this.errors[i].value;
      }
    }
  }

}

class UserInfo {
  public username: string;
  public password: string;
  public piva: string;
  public rsociale: string;
  public citta: string;
  public via: string;
  public email: string;
  public pec: string;
  public sdi: string;
  public iban: string;
}

