import { AuthService } from './../Services/AuthService/auth.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {Role, User} from '../Models/User';
import { FileService } from '../Services/FileService/file.service';
import { UserService } from '../Services/UserService/user.service';

import {faInfo, faCog, faPlus, faMinus, faCircle, faWrench, faInfoCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService, ModalOptions, } from 'ngx-bootstrap/modal';
import { LicenseStat } from '../Models/LicenzeStat';
import Censimento from "../Models/Censimento";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public Users: User[] = [];
  public AllUsers: User[] = [];

  //public User: string;  //Concessionario, Segnalatore, Utente

  public UserType = {
    Utente: false,
    Segnalatore: false,
    Distributore: false,
    Concessionario: false
  };
  public dropdownFiltroRuoli = false;
  public dropdownFiltroStato = false;

  public dropdownRecenti = false;
  public dropdownObsoleti = false;
  //ALERT
  public alertOpenSuccess: boolean = false;
  public alertOpen: boolean = false;
  public alertMsg: string = "";

  //ICON
  public faInfo: IconDefinition = faInfo;
  public faInfoCircle: IconDefinition = faInfoCircle;
  public faCog: IconDefinition = faCog;
  public faPlus: IconDefinition = faPlus;
  public faMinus: IconDefinition = faMinus;
  public faCircle: IconDefinition = faCircle;
  public faWrench: IconDefinition = faWrench;

  //MODAL
  public modalRef: BsModalRef;
  public SelectedUser: User;

  public SelectedUserTipo = {
    S: false, P: false, C: false
  }
  public FiltroUtenti = {
    Ruolo: {
      Cliente: false,
      Concessionario: false,
      Distributore: false,
      Admin: false,
      Segnalatore: false,
    },
    Stato: {
      Attivo: false,
      Sospeso: false,
      Disattivato: false,
    }
  }

  public userStats: UserStats;

  //ERRORI
  public ErrorSoftware: boolean = false;
  public ErrorContatotoreCanone: boolean = false;
  public ErroreCreazione: boolean = false;


  // READY
  public readyLoad: boolean = false;

  // TOOLTIP
  public TooltipText: string = "";

  public errors: any[] = [];  //LISTA ERRORI FORM

  //CREAZIONE UTENTE, BOOL
  public list: boolean = true;

  public editUser: boolean = false;

  public loading: boolean = false;

  constructor(public auth: AuthService, private router: Router, public FileService: FileService, public UserService: UserService, public modalService: BsModalService) {
    this.userStats = new UserStats(auth);
  }

  private async Init() {

    this.auth.Ready = false;

    //SE NON E' NESSUNO DI QUESTI, VADO INDIETRO
    if (this.auth.AuthUser.Role === 2 || this.auth.AuthUser.Role2 === 2) {
      this.router.navigateByUrl('');
      return;
    }
    this.AllUsers = await this.UserService.LoadUsers(this.auth.AuthUser.Username);

     // SE SONO UN ADMIN, RIMUOVO GLI ALTRI ADMIN
     if (this.auth.AdminUsername !== 'gab' && (this.auth.AuthUser.Role === Role.ADMIN || this.auth.AuthUser.Role2 === Role.ADMIN)) {
      const adminIndex: number[] = [];
      this.AllUsers.forEach((user, index) => {
        if (user.Role === 0) {
          adminIndex.push(index);
        }
      });

      let i = 0;
      adminIndex.forEach(index => {
        this.AllUsers.splice(index - i, 1);
        ++i;
      });
    } 

    this.InitUserStats();

    this.Users = [...this.AllUsers]
    this.readyLoad = true;

    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }

    this.auth.Ready = true;

    //AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName("li");
    for (let i = 0; i < sidebarElements.length; ++i) {
      if (sidebarElements[i].id !== 'users') {
        sidebarElements[i].classList.remove('active');
      } else {
        sidebarElements[i].classList.add('active');
      }
    }
  }

  async ngOnInit() {
    await this.Init();
  }

  private async LocalValidation() {
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));

      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if (valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.IsLogin = true;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }

  public InitUserStats() {
    //COUNT E COUNT PER TIPOLOGIA --> this.AllUsers

    this.userStats = new UserStats(this.auth);

    
    this.userStats.Count = this.AllUsers.length;
    
    this.AllUsers.forEach(user => {
      if (user.Role === Role.ADMIN || user.Role2 === Role.ADMIN) {
        ++this.userStats.TypeCount.Admin;
      }
      if (user.Role === Role.SELLER || user.Role2 === Role.SELLER) {
        ++this.userStats.TypeCount.Concessionario;
      }
      if (user.Role === Role.DEALER || user.Role2 === Role.DEALER) {
        ++this.userStats.TypeCount.Distributore;
      }
      if (user.Role === Role.SIGNAL || user.Role2 === Role.SIGNAL) {
        ++this.userStats.TypeCount.Segnalatore;
      }
      if (user.Role === Role.USER || user.Role2 === Role.USER) {
        ++this.userStats.TypeCount.Utente;
      }
    });
  }



  public OpenModalInfo(user: User, template: TemplateRef<any>) {
    this.SelectedUser = user;

    if (this.SelectedUser.Tipo !== undefined) {
      this.SelectedUserTipo.S = this.SelectedUser.Tipo.includes('S');
      this.SelectedUserTipo.P = this.SelectedUser.Tipo.includes('P');
      this.SelectedUserTipo.C = this.SelectedUser.Tipo.includes('C');
    }

    this.editUser = true;

    //this.modalRef = this.modalService.show(template, {class: 'modal-lg modal-dialog-scrollable'});
  }

  public OpenModalConfig(template: TemplateRef<any>) {


    const config: ModalOptions = {
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    };

    this.modalRef = this.modalService.show(template, config);
  }

  public async SetUserState(user: User) {

    // const id = await this.auth.GetIdByUsername();

    await this.UserService.SetUserState(user);

    switch (user.Stato) {
      case 0:
        this.TooltipText = "Utente Attivo";
        break;
      case 1:
        this.TooltipText = "Utente Sospeso";
        break;
      case 2:
        this.TooltipText = "Utente Disattivato";
        break;
    }
 
  }
  public listChanged(value: boolean) {
    this.list = value;
  }
  public editChanged(value: boolean) {
    this.editUser = value;
  }

  public handlerUserLoad(list: User[]) {
    this.AllUsers = list;
    this.Users = [...this.AllUsers]
  }

  public Filtro() {
    const input: string = (document.getElementById("search") as HTMLInputElement).value;
    //SE E' TUTTO FALSO RIMETTO TUTTA LA LISTA
    
      this.Users = [];
      //AGGIUNGO UTENTI
      if (this.FiltroUtenti.Ruolo.Cliente === true) {
        for (let i = 0; i < this.AllUsers.length; i++) {
          if (this.AllUsers[i].Role === 2 && (this.AllUsers[i].Role2 == null || this.AllUsers[i].Role2 == undefined)) {
            if (this.FiltroUtenti.Stato.Attivo) {
              if (this.AllUsers[i].Stato === 0) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Disattivato) {
              if (this.AllUsers[i].Stato === 2) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Sospeso) {
              if (this.AllUsers[i].Stato === 1) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Attivo == false && this.FiltroUtenti.Stato.Disattivato == false && this.FiltroUtenti.Stato.Sospeso == false) {
              this.Users.push(this.AllUsers[i]);
            }
          }
        }
      }
      //AGGIUNGO ADMIN
      if (this.FiltroUtenti.Ruolo.Admin === true) {
        for (let i = 0; i < this.AllUsers.length; i++) {
          if (this.AllUsers[i].Role === 0 || this.AllUsers[i].Role2 === 0) {
            if (this.FiltroUtenti.Stato.Attivo) {
              if (this.AllUsers[i].Stato === 0) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Disattivato) {
              if (this.AllUsers[i].Stato === 2) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Sospeso) {
              if (this.AllUsers[i].Stato === 1) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Attivo == false && this.FiltroUtenti.Stato.Disattivato == false && this.FiltroUtenti.Stato.Sospeso == false) {
              this.Users.push(this.AllUsers[i]);
            }
          }
        }
      }
      //AGGIUNGO CONCESSIONARI
      if (this.FiltroUtenti.Ruolo.Concessionario === true) {
        for (let i = 0; i < this.AllUsers.length; i++) {
          if (this.AllUsers[i].Role === 1 || this.AllUsers[i].Role2 === 1) {
            if (this.FiltroUtenti.Stato.Attivo) {
              if (this.AllUsers[i].Stato === 0) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Disattivato) {
              if (this.AllUsers[i].Stato === 2) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Sospeso) {
              if (this.AllUsers[i].Stato === 1) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Attivo == false && this.FiltroUtenti.Stato.Disattivato == false && this.FiltroUtenti.Stato.Sospeso == false) {
              this.Users.push(this.AllUsers[i]);
            }
          }
        }
      }
      //AGGIUNGO DISTRIBUTORI
      if (this.FiltroUtenti.Ruolo.Distributore === true) {
        for (let i = 0; i < this.AllUsers.length; i++) {
          if (this.AllUsers[i].Role === 4 || this.AllUsers[i].Role2 === 4) {
            if (this.FiltroUtenti.Stato.Attivo) {
              if (this.AllUsers[i].Stato === 0) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Disattivato) {
              if (this.AllUsers[i].Stato === 2) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Sospeso) {
              if (this.AllUsers[i].Stato === 1) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Attivo == false && this.FiltroUtenti.Stato.Disattivato == false && this.FiltroUtenti.Stato.Sospeso == false) {
              this.Users.push(this.AllUsers[i]);
            }
          }
        }
      }
      //AGGIUNGO SEGNALATORE
      if (this.FiltroUtenti.Ruolo.Segnalatore === true) {
        for (let i = 0; i < this.AllUsers.length; i++) {
          if (this.AllUsers[i].Role === 3 || this.AllUsers[i].Role2 === 3) {
            if (this.FiltroUtenti.Stato.Attivo) {
              if (this.AllUsers[i].Stato === 0) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Disattivato) {
              if (this.AllUsers[i].Stato === 2) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Sospeso) {
              if (this.AllUsers[i].Stato === 1) {
                this.Users.push(this.AllUsers[i]);
              }
            }
            if (this.FiltroUtenti.Stato.Attivo == false && this.FiltroUtenti.Stato.Disattivato == false && this.FiltroUtenti.Stato.Sospeso == false) {
              this.Users.push(this.AllUsers[i]);
            }
          }
        }
      }
      if (this.FiltroUtenti.Ruolo.Distributore == false &&
        this.FiltroUtenti.Ruolo.Cliente == false &&
        this.FiltroUtenti.Ruolo.Concessionario == false &&
        this.FiltroUtenti.Ruolo.Admin == false &&
        this.FiltroUtenti.Ruolo.Segnalatore == false) {
        if (this.FiltroUtenti.Stato.Attivo) {
          for (let i = 0; i < this.AllUsers.length; i++) {
            if (this.AllUsers[i].Stato == 0) {
              this.Users.push(this.AllUsers[i])
            }
          }
        }
        if (this.FiltroUtenti.Stato.Disattivato) {
          for (let i = 0; i < this.AllUsers.length; i++) {
            if (this.AllUsers[i].Stato == 2) {
              this.Users.push(this.AllUsers[i])
            }
          }
        }
        if (this.FiltroUtenti.Stato.Sospeso) {
          for (let i = 0; i < this.AllUsers.length; i++) {
            if (this.AllUsers[i].Stato == 1) {
              this.Users.push(this.AllUsers[i])
            }
          }
        }

        if (this.FiltroUtenti.Stato.Attivo == false && this.FiltroUtenti.Stato.Disattivato == false && this.FiltroUtenti.Stato.Sospeso == false) {
          this.Users = [...this.AllUsers]
        }
      }
  
    this.FilterByName();
  }

  public OrdinaRagSocCrescente(){
    this.Users.sort((a: User, b: User) =>  {
      let ragsocA: string = a.RagioneSociale.toUpperCase();
      let ragsocB: string = b.RagioneSociale.toUpperCase();
      return (ragsocA < ragsocB) ? -1 : (ragsocA > ragsocB) ? 1 : 0;
    });
  }
  public OrdinaRagSocDescrescente(){
    this.Users.sort((a: User, b: User) => {
      let ragsocA: string = a.RagioneSociale.toUpperCase();
      let ragsocB: string = b.RagioneSociale.toUpperCase();
      return (ragsocA > ragsocB) ? -1 : (ragsocA < ragsocB) ? 1 : 0;
    });
  }
  public FilterByName() {
    const input: string = (document.getElementById("search") as HTMLInputElement).value;
    const table: HTMLTableElement = document.getElementById("table") as HTMLTableElement;
    const tr: HTMLCollectionOf<HTMLTableRowElement> = table.getElementsByTagName("tr");

    for (let i = 0; i < tr.length; i++){
      const tdRagSoc: HTMLTableDataCellElement = tr[i].getElementsByTagName("td")[2];
      if (tdRagSoc) {
        const txtValue: string = tdRagSoc.textContent;
        if (txtValue.toUpperCase().includes(input.toUpperCase())) {
          tr[i].style.display = "";
          continue;
        }
      }

      const tdUsername: HTMLTableDataCellElement = tr[i].getElementsByTagName("td")[0];
      if (tdUsername) {
        const txtValue: string = tdUsername.textContent;
        if (txtValue.toUpperCase().includes(input.toUpperCase())) {
          tr[i].style.display = "";
          continue;
        }
      }

      const tdPiva: HTMLTableDataCellElement = tr[i].getElementsByTagName("td")[1];
      if (tdPiva) {
        const txtValue: string = tdPiva.textContent;
        if (txtValue.toUpperCase().includes(input.toUpperCase())) {
          tr[i].style.display = "";
          continue;
        }
      }

      tr[i].style.display = "none";
    }
  }
}

class UserStats {
  public Count: number = 0;

  public TypeCount = {
    Admin: 0,
    Concessionario: 0,
    Distributore: 0,
    Segnalatore: 0,
    Utente: 0
  };

  private AuthService: AuthService;

  public constructor(authService: AuthService) {
    this.AuthService = authService;
  }

  public ToString(): string {
    let returnString: string = `<p class="m-0">Utenti totali: ${this.Count}</p>`;

    returnString += `<p class="m-0">Utenti: ${this.TypeCount.Utente}</p>`;
    returnString += `<p class="m-0">Segnalatori: ${this.TypeCount.Segnalatore}</p>`;

    if (this.AuthService.AuthUser.Role === 0 || this.AuthService.AuthUser.Role2 === 0) {
      returnString += `<p class="m-0">Admin: ${this.TypeCount.Admin}</p>`;
      returnString += `<p class="m-0">Distributori: ${this.TypeCount.Distributore}</p>`;
    }

    if (this.AuthService.AuthUser.Role === 0 || this.AuthService.AuthUser.Role2 === 0 || this.AuthService.AuthUser.Role === 4 || this.AuthService.AuthUser.Role2 === 4) {
      returnString += `<p class="m-0">Concessionari: ${this.TypeCount.Concessionario}</p>`;
    }


    return returnString;

  }

}
