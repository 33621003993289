import { AlertService } from 'ngx-alerts';
import { ShopService } from './../../Services/ShopService/shop.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

declare var paypal;

@Component({
  selector: 'paypal-payment',
  templateUrl: './paypal-payment.component.html',
  styleUrls: ['./paypal-payment.component.scss']
})
export class PaypalPaymentComponent implements OnInit {

  @ViewChild('paypal', {static: true}) paypalElement: ElementRef;

  public IsSuccess: boolean = false;

  constructor(private shopService: ShopService, private alert: AlertService) { }

  async ngOnInit() {
    
    await this.InitShop();

    //console.log(this.GetPaypalUnits());

    paypal
      .Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: this.GetPaypalUnits()
              }
            }]
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          //console.log(order);
        },
        onClientAuthorization: async => (data) => {
          this.IsSuccess = true;
      },
        onError: err => {
          this.alert.danger("Pagamento con paypal fallito")
        }
      })
      .render(this.paypalElement.nativeElement);
  }

  private async InitShop() {
    if (this.shopService.IsInit === false) {
      await this.shopService.Init();
      this.shopService.IsInit = true;
    }
  }

  private GetPaypalUnits(): number {

    const paypalArray = [];

    let paypalValue: number = 0;

    for (let i = 0; i < this.shopService.Carrello.length; ++i) {
      for (let j = 0; j < this.shopService.Carrello[i].Contenuto.length; ++j) {
        paypalValue += this.shopService.Carrello[i].Contenuto[j].PrezzoTotale;
        /*
        const elem: any = paypalArray.find(elem => elem.description === this.shopService.Carrello[i].Contenuto[j].CodiceArticolo);

        if (elem !== undefined) {
          elem.amount.value += this.shopService.Carrello[i].Contenuto[j].PrezzoTotale;
        } else {
          paypalArray.push({
            description: this.shopService.Carrello[i].Contenuto[j].CodiceArticolo,
            amount: {
              value: this.shopService.Carrello[i].Contenuto[j].PrezzoTotale
            } 
          })
        }
        */
      }
    }
    return paypalValue;
  }

}







