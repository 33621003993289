<form id="payment-form">
    <div id="card-element"></div>
    <button id="submit" (click)="Pay(PagamentoAvvenuto)">
      <div class="spinner hidden" id="spinner"></div>
      <span id="button-text">Pay</span>
    </button>
  </form>
<ng-template #PagamentoAvvenuto>
  <div class="row pop-up">
    <div class="box small-6 large-centered modal-pagamento-avvenuto">
      <h3 style="background-color: #0757ad">Grazie!</h3>
      <p>La ringraziamo per aver acquistato su <b>Gab Servizi</b> </p>
      <p>Riceverà a breve una mail con lo stato dell'ordine</p>
      <div style="width: 281px;margin: auto;">
        <div fxLayout="row" fxLayoutGap="10px">
          <div (click)="GoToShop()">
              <a  style="margin: 0 auto;
                  background-color: #0757ad;
                  margin-bottom: 33px;
                  color: white;
                  padding: 1rem;">Torna allo shop
              </a>
          </div>
          <div (click)="GoToProfilo()">
            <a style="margin: 0 auto;
            background-color:#0757ad;
            margin-bottom: 33px;
            color: white;
            padding: 1rem;">Vai sul profilo
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal -->
<!--4000003800000008-->
