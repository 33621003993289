import Pacchetti from '../Models/Pacchetti';

export class User {

    public ID: number;
    public Username: string;

    public Password: string;

    public Role: Role;
    public Role2: Role;

    public PIVA: string;

    public RagioneSociale: string;
    public Citta: string;
    public Via: string;
    public Email: string;
    public PEC: string;
    public SDI: string;
    public IBAN: string;
    public Key: string;

    public Creatore: string;
    public Segnalatore: string;
    public SoftwareAbbinatiCreatore: string;
    public SoftwareAbbinatiCreatoreBoolean = {
        VisualFood: false,
        VisualSelf: false,
        VisualRetail: false,
        VisualShop: false
    }

    public Tipo: string;

    public NumeroLicenzePacchetto: number = 0;

    public ScontoEncrypted: string = "";
    public ScontoCloudEncrypted: string = "";
    public Sconto = {
        S: 0,
        SBonus: 0,
        P: {
          Cinque: 0,
          Dieci: 0,
          Quindici: 0,
          Venti: 0
        },
        PBonus: {
          Cinque: 0,
          Dieci: 0,
          Quindici: 0,
          Venti: 0
        },
        C: 0,
        CBonus: 0,
    };

    public ScontoCloud = {
      PrimoScaglione: {
        SX: 0,
        DX: 0,
        PrimoAnno: 0,
        PrimoAnnoBonus: 0,
        SecondoAnno: 0,
        SecondoAnnoBonus: 0,
      },
      SecondoScaglione: {
        SX: 0,
        DX: 0,
        PrimoAnno: 0,
        PrimoAnnoBonus: 0,
        SecondoAnno: 0,
        SecondoAnnoBonus: 0,
      },
      TerzoScaglione: {
        SX: 0, 
        DX: -1,
        PrimoAnno: 0,
        PrimoAnnoBonus: 0,
        SecondoAnno: 0,
        SecondoAnnoBonus: 0,
      }
    }

    public ScontoRinnovo: string = "";
    public ScontoModuli: string = "";

    public Canone: number //SE TIPOCONESSIONARIO CANONE, VALORE MENSILE
    public SoftwareAbbinati: string //SE TIPOCONESSIONARIO CANONE, SOFTWARE ASSOCIATI AL CANONE
    public SoftwareAbbinatiBoolean = {
        VisualFood: false,
        VisualSelf: false,
        VisualRetail: false,
        VisualShop: false
    }

    //Lista dei softcodes associati all'utente
    public SoftCodes: string[];

    public VisualIntelligence: boolean;
    public VisualReservation: boolean;
    public VisualOrder: boolean;


    //Stato
    public Stato: number;

    // SE UN UTENTE E' UN CONCESSIONARIO, PUò ESSERE SINGOLO, PACCHETTI, CANONE
    public CodiceMailCanone: string = "prova";

    //SEGNALATORE
    public Provvigione = {
      Software: false,
      Hardware: false,
      VenditaSoftware: 0,
      RinnovoSoftware: 0,
      VenditaHardware: 0
    }
    

    //PERMESSI ADMIN
    public Permessi: string = '';

    public SettingsPermessi = {
        Licenze: {
          Generazione: false, //GENERAZIONE NUOVA LICENZA
          Upgrade: false, //AGGIORNAMENTO LICENZA
          Stato: false, //MODIFICA STATO LICENZA
          Rinnovo: false, //RINNOVO LICENZA
          Attivazione: false //ATTIVAZIONE LICENZA PER TERZI
        },
        Shop: {
          Inserimento: false,
          Modifica: false //VISUALIZZAZIONE GESTIORE STORE E INTERAZIONE CON QUEST'ULTIMO
        },
        Manuali: {
          Inserimento: false,
          Eliminazione: false,
        },
        Listini: {
          Inserimento: false,
          Eliminazione: false,
        },
        Aggiornamenti: {
          Inserimento: false,
          Eliminazione: false,
        },
        Utenti: {
          Creazione: false,
          Modifica: false,
          Eliminazione: false
        },
        Censimenti: {
          Aggiornamento: false,
          Eliminazione: false
        }
      }

    public constructor() {

    }


    public DecryptPermessi() {
        //LICENZE
        let index: number = this.Permessi.indexOf('L');
        this.SettingsPermessi.Licenze.Generazione = (this.Permessi[index + 1] === '1' ? true : false);
        this.SettingsPermessi.Licenze.Upgrade = (this.Permessi[index + 2] === '1' ? true : false);
        this.SettingsPermessi.Licenze.Stato = (this.Permessi[index + 3] === '1' ? true : false);
        this.SettingsPermessi.Licenze.Rinnovo = (this.Permessi[index + 4] === '1' ? true : false);
        this.SettingsPermessi.Licenze.Attivazione = (this.Permessi[index + 5] === '1' ? true : false);
        //SHOP
        index = this.Permessi.indexOf('S');
        this.SettingsPermessi.Shop.Inserimento = (this.Permessi[index + 1] === '1' ? true : false);
        this.SettingsPermessi.Shop.Modifica = (this.Permessi[index + 2] === '1' ? true : false);
        //MANUALI
        index = this.Permessi.indexOf('M');
        this.SettingsPermessi.Manuali.Inserimento = (this.Permessi[index + 1] === '1' ? true : false);
        this.SettingsPermessi.Manuali.Eliminazione = (this.Permessi[index + 2] === '1' ? true : false);
        //LISTINI
        index = this.Permessi.indexOf('N');
        this.SettingsPermessi.Listini.Inserimento = (this.Permessi[index + 1] === '1' ? true : false);
        this.SettingsPermessi.Listini.Eliminazione = (this.Permessi[index + 2] === '1' ? true : false);
        //AGGIORNAMENTI
        index = this.Permessi.indexOf('A');
        this.SettingsPermessi.Aggiornamenti.Inserimento = (this.Permessi[index + 1] === '1' ? true : false);
        this.SettingsPermessi.Aggiornamenti.Eliminazione = (this.Permessi[index + 2] === '1' ? true : false);
        //UTENTE
        index = this.Permessi.indexOf('U');
        this.SettingsPermessi.Utenti.Creazione = (this.Permessi[index + 1] === '1' ? true : false);
        this.SettingsPermessi.Utenti.Modifica = (this.Permessi[index + 2] === '1' ? true : false);
        this.SettingsPermessi.Utenti.Eliminazione = (this.Permessi[index + 3] === '1' ? true : false);
        //CENSIMENTI
        index = this.Permessi.indexOf('C');
        this.SettingsPermessi.Censimenti.Aggiornamento = (this.Permessi[index + 1] === '1' ? true : false);
        this.SettingsPermessi.Censimenti.Eliminazione = (this.Permessi[index + 2] === '1' ? true : false);
    }

    public CreateUser(username: string, password: string, role: number, role2, PIVA: string, rsociale: string, citta: string,
        via: string, email: string, pec: string, sdi: string, iban: string) {
            this.Password = password;
            this.Username = username;
            if (role === 0) {
                this.Role = Role.ADMIN;
            } else if (role === 1) {
                this.Role = Role.SELLER;
            } else if (role === 2) {
                this.Role = Role.USER;
            } else if (role === 3) {
                this.Role = Role.SIGNAL;
            } else if (role === 4) {
                this.Role = Role.DEALER;
            }
            if (role2 === 0) {
                this.Role2 = Role.ADMIN;
            } else if (role2 === 1) {
                this.Role2 = Role.SELLER;
            } else if (role2 === 2) {
                this.Role2 = Role.USER;
            } else if (role2 === 3) {
                this.Role2 = Role.SIGNAL;
            } else if (role2 === 4) {
                this.Role2 = Role.DEALER;
            }

            this.PIVA = PIVA;
            this.RagioneSociale = rsociale;
            this.Citta = citta;
            this.Via = via;
            this.Email = email;
            this.PEC = pec;
            this.SDI = sdi;
            this.IBAN = iban;
    }

     public SignUpUser(username: string, password: string, role: number, role2, PIVA: string, rsociale: string, citta: string,
                    via: string, email: string, pec: string, sdi: string, iban: string) {
    this.Password = password;
    this.Username = username;
    this.Role = Role.USER;
    this.Role2 = Role.USER;
    this.PIVA = PIVA;
    this.RagioneSociale = rsociale;
    this.Citta = citta;
    this.Via = via;
    this.Email = email;
    this.PEC = pec;
    this.SDI = sdi;
    this.IBAN = iban;
  }
}

export enum Role {
    ADMIN, SELLER, USER, SIGNAL, DEALER
}

