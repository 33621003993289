<div class="card-body table-responsive"style="padding: 0; height: 80vh; overflow-x: hidden; padding-top: 1%">
    <div class="controls">
        <div class="row">
            <div class="col-1" (click)="this.router.navigate(['/cloud'])">
                <fa-icon [icon]="faChevronCircleLeft" size="2x" style="cursor: pointer;"></fa-icon>
            </div>
            <div class="col-9">
                <h4 class="card-title" style="margin-top: 0;">Gruppo {{group.name.toUpperCase()}}</h4>
            </div>
        </div>
        <div class="row" style="padding-top: 1%;">
            <div class="col-4">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <h4 class="card-title" style="margin: 0;">Lista Centri</h4>
                        </div>
                        <div class="col" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">
                            <fa-icon [icon]="faPlus" tooltip="Nuovo centro" style="cursor: pointer; float: right;" (click)="OpenCenterForm(null, centerForm)"></fa-icon>
                        </div>
                    </div>
                </div>
                <div [style.background-color]="this.SelectedCenter === center ? '#bce1fb' : ''" class="list-group-item list-group-item-action flexbox" *ngFor="let center of group.centers" style="padding-top: .1rem; padding-bottom: .1rem">
                    <div class="row">
                        <div class="col-8 center" (click)="this.SelectedCenter = center">
                            {{center.companyName}}
                        </div>
                        <div class="col-4">
                            <div class="row" style="height: 3rem;">
                                <div class="col-4" (click)="this.SelectedCenter = center"></div>
                                <div class="col-4 center" style="text-align: center;" (click)="OpenCenterForm(center, centerForm)">
                                    <fa-icon [icon]="faEdit" tooltip="Modifica centro" style="float: right; cursor: pointer;"></fa-icon>
                                </div>
                                <div class="col-4 center" style="text-align: center;" (click)="OpenModalDeleteCenter(center, eliminaCenter)" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">
                                    <fa-icon [icon]="faTrash" tooltip="Elimina centro" style="cursor: pointer;"></fa-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <h4 class="card-title" style="margin: 0;">Lista Sell Points {{this.SelectedCenter ? 'di ' + this.SelectedCenter.companyName : ''}}</h4>
                        </div>
                        <div class="col" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">
                            <fa-icon *ngIf="SelectedCenter !== undefined" [icon]="faPlus" (click)="OpenSellPointForm(null, sellpointForm)" tooltip="Nuovo Sell Point" style="float: right; cursor: pointer;"></fa-icon>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="SelectedCenter !== undefined">
                    <div class="list-group-item list-group-item-action flexbox" *ngFor="let sellpoint of SelectedCenter.sellPoints">
                        <div class="row">
                            <div class="col-10 center">
                                {{sellpoint.name}}
                            </div>
                            <div class="col-2">
                                <div class="row" style="height: 3rem;">
                                    <div class="col-4"></div>
                                    <div class="col-4 center">
                                        <fa-icon [icon]="faEdit" (click)="OpenSellPointForm(sellpoint, sellpointForm)" tooltip="Modifica Sell Point" style="float: right; cursor: pointer;"></fa-icon>
                                    </div>
                                    <div class="col-4 center" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">
                                        <fa-icon [icon]="faTrash" tooltip="Elimina Sell Point" style="cursor: pointer;" (click)="OpenModalDeleteSellPoint(sellpoint, eliminaSellPoint)"></fa-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>              
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #centerForm>
    <center-form [center]="this.SelectedCenter" (UpdateGroup)="this.UpdateGroup()"></center-form>
</ng-template>

<ng-template #sellpointForm>
    <sellpoint-form [sellPoint]="this.SelectedSellPoint" (UpdateGroup)="this.UpdateGroup()"></sellpoint-form>
</ng-template>


<ng-template #eliminaCenter>
    <div class="modal-content">
        <div class="modal-body" style="max-height: calc(100% - 3.5rem);">
            <div class="modal-title">
                <div class="row">
                    <div class="col" style="text-align: center;">
                        <fa-icon [icon]="this.faInfoCircle" size="2x"></fa-icon>
                    </div>
                </div>
                <h4 style="text-align: center;">Vuoi eliminare <strong>{{this.SelectedCenter.companyName}}</strong>?</h4>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" (click)="this.modalService.hide()">Annulla</button>
                <button class="btn btn-primary" (click)="DeleteCenter()">Elimina</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #eliminaSellPoint>
    <div class="modal-content">
        <div class="modal-body" style="max-height: calc(100% - 3.5rem);">
            <div class="modal-title">
                <div class="row">
                    <div class="col" style="text-align: center;">
                        <fa-icon [icon]="this.faInfoCircle" size="2x"></fa-icon>
                    </div>
                </div>
                <h4 style="text-align: center;">Vuoi eliminare <strong>{{this.SelectedSellPoint.name}}</strong>?</h4>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" (click)="this.modalService.hide()">Annulla</button>
                <button class="btn btn-primary" (click)="DeleteSellPoint()">Elimina</button>
            </div>
        </div>
    </div>
</ng-template>