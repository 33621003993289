import { CartService } from './../../Services/CartService/cart.service';
import { IsDev } from 'src/app/global';
import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../Services/ShopService/shop.service';;
import { Contenitore } from 'src/app/shop/tipologialicenza/tipologialicenza.component';
import { AlertService } from 'ngx-alerts';
import {HttpClient} from '@angular/common/http';
import SoftwareListino from '../../Models/SoftwareListino';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-dettaglio-prodotto',
  templateUrl: './dettaglio-prodotto.component.html',
  styleUrls: ['./dettaglio-prodotto.component.scss']
})
export class DettaglioProdottoComponent implements OnInit {
  public software: SoftwareListino;
  public NomeProdotto: string;
  public Costo: number;
  public Categoria: number;
  public Descrizione: string;
  public CodiceArticolo: string;
  public ImmagineUrl: string;

  constructor(public ShopService: ShopService,private alertService: AlertService,private http: HttpClient,private CartService: CartService, private router: Router) {

  }

  ngOnInit(): void {
    //ImmagineURL
    try {
      this.software = this.router.lastSuccessfulNavigation.extras.state;
      //this.software = this.ShopService.lastSoftwareDetail;
      this.NomeProdotto = this.software.Descrizione;
      this.Costo = this.software.Costo;
      this.Categoria = this.software.IdSubCat;
      this.Descrizione = this.software.Testo;
      this.CodiceArticolo = this.software.CodiceArticolo;
      //this.ImmagineUrl += this.software.ImgName;
      this.ShopService.lastSoftwareDetail = this.software;
      if (this.software.ImgName === '') {
        this.ImmagineUrl = "../../assets/img/GabImageNotFound.png"
      } else {
        if (IsDev.active) {
          this.ImmagineUrl = "https://localhost/ImgListino/" + this.software.ImgName;
        } else {
          this.ImmagineUrl = "https://www.gabservizi.it/ImgListino/" + this.software.ImgName;
        }
      }
    } catch {
      this.software = this.ShopService.lastSoftwareDetail;
      this.NomeProdotto = this.software.Descrizione;
      this.Costo = this.software.Costo;
      this.Categoria = this.software.IdSubCat;
      this.Descrizione = this.software.Descrizione;
      this.CodiceArticolo = this.software.CodiceArticolo;
      this.ImmagineUrl = "../../assets/img/GabImageNotFound.png"
    }
  }
  public async AggiungiCarrello() {
    const contenitore: Contenitore = new Contenitore;
    contenitore.IdPacchettoCart = '_' + Math.random().toString(36).substr(2,9);
    const successCarrello: boolean = await this.ShopService.AggiungiCarrello(this.ShopService.SoftwareSelezionato, this.ShopService.QuantitaSelezionato, this.ShopService.PacchettoScelto, contenitore);
    if (successCarrello) {
      this.ShopService.Carrello.push(contenitore);
    } else {
      this.alertService.danger("Impossibile aggiungere al carrello. Contatta il tuo distributore per maggiori informazioni")
      return;
    }
    //INSERISCO NEL DB
    const result: any = await this.CartService.Insert(contenitore);

    if (result) {
      this.alertService.success('Elemento aggiunto al carrello');
    }

}

  /*
  public async AggiuntaAlCarrelloProdottoSingolo(software: SoftwareListino) {
    var value = parseInt((<HTMLInputElement>document.getElementById('quantita-articolo')).value, 10);
    this.ShopService.SoftwareSelezionato = software;
    this.ShopService.QuantitaSelezionato = value;
    await this.AggiungiCarrello();
  }
  public AggiungiPacchetto(software: SoftwareListino) {
    var quantita = parseInt((<HTMLInputElement>document.getElementById('quantita-articolo')).value, 10);
    this.ShopService.QuantitaSelezionato = quantita;
    this.ShopService.SoftwareSelezionato = software;
    if (this.ShopService.QuantitaSelezionato > this.ShopService.PacchettoScelto - this.ShopService.LeftPacchettoScelto || this.ShopService.QuantitaSelezionato === 0) {
      this.ShopService.ErroreCarrello = true;
    } else {
      this.ShopService.ErroreCarrello = false;
      this.ShopService.LeftPacchettoScelto += this.ShopService.QuantitaSelezionato;
      //SE GIA' ESISTE UN ELEMENTO UGUALE, SEMPLICEMENTE SOMMO
      const index: number = this.ShopService.Pacchetto.indexOf((elemento) => elemento.software === this.ShopService.SoftwareSelezionato)
      if (index !== -1) {
        this.ShopService.Pacchetto[index].quantita += this.ShopService.QuantitaSelezionato;
      } else {
        this.ShopService.Pacchetto.push({software: this.ShopService.SoftwareSelezionato, quantita: this.ShopService.QuantitaSelezionato, pacchetto: this.ShopService.PacchettoScelto})
      }
      console.log(this.ShopService.Pacchetto)
      this.ShopService.QuantitaSelezionato = 0;
    }
  }
  */

  ImageErrorHandler(event: any) {
    document.getElementById('immagine-prodotto').setAttribute('src', 'assets/img/GabImageNotFound.png');
    //event.target.currentSrc = 'assets/img/GabImageNotFound.png'
  }

  decrementare() {
    var value = parseInt((<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+1)).value, 10);
    if (isNaN(value) || value < 1){
      value = 1;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+1)).value = String(value);
    }
    if (value > 1){
      value--;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+1)).value = String(value);
    }
  }

  incrementare() {
    var value = parseInt((<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+1)).value, 10)
    if (isNaN(value) || value < 1){
      value = 1;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+1)).value = String(value);
    }
    if(value < 999){
      value++;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+1)).value = String(value);
    }
  }
}
