import { UserStoreService } from '../../Services/Admin/user-store.service';
import {Component, OnInit, TemplateRef} from '@angular/core';
import {ShopService} from '../../Services/ShopService/shop.service';
import {PaymentService} from '../../Services/Payment/payment.service';
import {AlertService} from 'ngx-alerts';
import {Payment, Tipo} from '../../Models/Payment';
import {AuthService} from '../../Services/AuthService/auth.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  public termini_condizioni = false;
  public condizioni_vendita = false;
  public countCheckBox = 0;
  public modalRef: BsModalRef;
  public loading:boolean = false;
  public IVA: number;
  public TotalIVA: number;
  public Pagamento: Payment;

  constructor(public shopService: ShopService, private alertService: AlertService, public auth: AuthService,public modalService: BsModalService,public router: Router,
    private storeService: UserStoreService
  ) {
        
        
        
  }

  async ngOnInit() {
    this.loading = true;
    const iva = await this.storeService.GetIva();
    const carrelloTotal = this.shopService.GetCarrelloTotal();
    this.IVA = carrelloTotal * (iva / 100);
    this.TotalIVA = carrelloTotal +  this.IVA;
    this.Pagamento = new Payment(this.auth.AuthUser.ID, this.auth.AuthUser.Username, this.auth.AuthUser.PIVA, this.TotalIVA, Tipo.CARTA);
    this.loading = false;
  }

  PayWithStripe() {

    if (this.termini_condizioni && this.condizioni_vendita) {
      this.oscurareCheckbox();
      this.shopService.Stripe = true;
      this.shopService.Paypal = false;
      this.shopService.Bonifico = false;
    } else {
      this.alertService.danger('Le condizioni sono obbligatorie');
    }
  }

  PayWithPaypal() {
    if (this.termini_condizioni && this.condizioni_vendita) {
      this.oscurareCheckbox();
      this.shopService.Stripe = false;
      this.shopService.Paypal = true;
      this.shopService.Bonifico = false;
    }else {
      this.alertService.danger('Le condizioni sono obbligatorie');
    }
  }

  PayWithBonifico() {
    if (this.termini_condizioni && this.condizioni_vendita) {
      this.oscurareCheckbox();
      this.shopService.Stripe = false;
      this.shopService.Paypal = false;
      this.shopService.Bonifico = true;
    }else {
      this.alertService.danger('Le condizioni sono obbligatorie');
    }
  }

  resetPayments() {
    if (this.termini_condizioni && this.condizioni_vendita) {
      this.oscurareCheckbox();
      this.shopService.Stripe = false;
      this.shopService.Paypal = false;
      this.shopService.Bonifico = false;
    }else {
      this.alertService.danger('Le condizioni sono obbligatorie');
    }
  }

  pagamentoAvvenuto($event: string) {
    document.getElementById('circle-loader').className += ' load-complete';
    //let checkmark = document.getElementById('checkmark').className.replace("checkmark","");
    document.getElementById('checkmark').setAttribute('style', 'display: block');
  }

  /*
  terminicondizioni() {
    if(this.countCheckBox < 1){
      this.termini_condizioni = !this.termini_condizioni;
      this.countCheckBox++;
    } else {
      this.countCheckBox = 0;
    }
  }

  condizionivendita() {
    if(this.countCheckBox < 1){
      this.condizioni_vendita = !this.condizioni_vendita;
      this.countCheckBox++;
    } else {
      this.countCheckBox = 0;
    }
  }
  */

  oscurareCheckbox() {
    document.getElementById('div_condizioni_vendita').className += 'noClick';
    document.getElementById('div_termini_condizioni').className += 'noClick';
  }

  AcquistaOraBonifico(template: TemplateRef<any>){
    this.Pagamento.Tipo = Tipo.BONIFICO;
    if(this.shopService.AcquistaOra(this.Pagamento)){
      this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true});
    };
    document.getElementById('circle-loader-stripe').className += ' load-complete';
    //let checkmark = document.getElementById('checkmark').className.replace("checkmark","");
    document.getElementById('checkmark-stripe').setAttribute('style', 'display: block');
  }

  AcquistaOraCarta(template: TemplateRef<any>){
    if(this.shopService.AcquistaOra(this.Pagamento)){
      this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true});
    };
    document.getElementById('circle-loader-stripe').className += ' load-complete';
    //let checkmark = document.getElementById('checkmark').className.replace("checkmark","");
    document.getElementById('checkmark-stripe').setAttribute('style', 'display: block');
  }



  async GoToShop(){
    //await this.shopService.ClearCarrello();
    this.router.navigate(['shop']);
    this.modalRef.hide();
  }

  async GoToProfilo(){
    //await this.shopService.ClearCarrello();
    this.router.navigate(['userInfo']);
    this.modalRef.hide();
  }
}
