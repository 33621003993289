export default class Item {
    public product: string;
    public version: string;
    public softCode: string;
    public downloadFileName: string;

    constructor(product: string, version: string, softCode: string, downloadFileName: string) {
        this.product = product;
        this.version = version;
        this.softCode = softCode;
        this.downloadFileName = downloadFileName;
    }
}