export default class Ordine {
    public ID: number;
    public Username: string;
    public Piva: string;
    public NumeroLicenze: number;
    public CodiceArticolo: string;
    public Descrizione: string;
    public DataOrdine: Date;
    public CodiceOrdine: string;
    public Totale: number;

    public DateToString() {
        const date: Date = new Date(this.DataOrdine);

        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    }

}