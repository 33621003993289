<div *ngIf="loading" style="position: relative; left: 50%; top: 50%">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div class="card strpied-tabled-with-hover" *ngIf="!loading">
    <!----LOADING-->
    <div class="card-header">
        <div class="row">
            <div class="col">   
                <tabset style="width: 75%;">
                    <tab (selectTab)="Back()">
                        <ng-template tabHeading>
                            <fa-icon [icon]="faChevronCircleLeft" size="2x" style="cursor: pointer;"></fa-icon>
                        </ng-template>
                    </tab>
                    <tab heading="Informazioni di Base" [active]="true">
                        <div *ngTemplateOutlet="editUser"></div>
                    </tab>
                    <tab heading="Impostazioni" *ngIf="(this.SelectedUser.Role === 1 || this.SelectedUser.Role2 === 1 || this.SelectedUser.Role === 4 || this.SelectedUser.Role2 === 4 || this.SelectedUser.Role === 3 || this.SelectedUser.Role2 === 3 || this.SelectedUser.Role === 0 || this.SelectedUser.Role2 === 0)">
                        <div *ngTemplateOutlet="settings"></div>
                    </tab>
                    <tab heading="Applicazioni" *ngIf="this.SelectedUser.Role === 1 || this.SelectedUser.Role2 === 1 || this.SelectedUser.Role === 4 || this.SelectedUser.Role2 === 4">
                        <app-user-software-config [Username]="this.SelectedUser.Username"></app-user-software-config>
                    </tab>
                    <tab heading="Permessi" *ngIf="this.auth.AuthUser.Username === 'gab' && this.SelectedUser.Role === 0">
                        <app-user-admin-permission-config [SelectedUser]="this.SelectedUser"></app-user-admin-permission-config>
                    </tab>
                    <tab heading="CFG" *ngIf="this.auth.AuthUser.Username === 'gab' && this.CFG !== null">
                        <app-user-cfg-edit [PIVA]="this.SelectedUser.PIVA"></app-user-cfg-edit>
                    </tab>
                </tabset>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <h5 *ngIf="this.auth.AuthUser.Role === 0" style="float: right;">
                            <strong>Creatore:</strong> {{this.SelectedUser.Creatore}}
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h5 *ngIf="this.SelectedUser.Segnalatore.length > 0 && this.SelectedUser.Segnalatore !== this.auth.AuthUser.Username" style="float: right;">
                            <strong>Segnalatore:</strong> {{this.SelectedUser.Segnalatore}}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <!--

            <a role="button"><fa-icon *ngIf="this.auth.AuthUser.Role === 0" [icon]="faWrench" size="2x" (click)="this.modalService.show(userSetting,{ignoreBackdropClick: true, keyboard: false})"
                 [style.display]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Utenti.Modifica ? 'none' : ''"></fa-icon></a>

                 
                 <div style="float:right">
                     
                </div>
        -->
    </div>
</div>




<!--
<ng-template #userSetting>
    <tabset>
        <tab heading="Applicazioni">
            <app-user-software-config [Username]="this.SelectedUser.Username"></app-user-software-config>
        </tab>
        <tab heading="Permessi" *ngIf="this.auth.AuthUser.Username === 'gab' && this.SelectedUser.Role === 0">
            <app-user-admin-permission-config [SelectedUser]="this.SelectedUser"></app-user-admin-permission-config>
        </tab>
     </tabset>
</ng-template>
-->


<ng-template #editUser>
    <div class="card-body nooverflow">
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="form_name">Nome Utente</label>
                    <input type="text" class="form-control" [disabled]="true" [(ngModel)]="this.SelectedUser.Username"  />
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="form_name">Password</label>
                    <input type="text" class="form-control" [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.Password" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="form_name">PIVA</label>
                    <input type="text" class="form-control" [disabled]="true" [(ngModel)]="this.SelectedUser.PIVA" />
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="form_name">Ragione Sociale</label>
                    <input type="text" class="form-control" [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.RagioneSociale" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="form_name">Città</label>
                    <input type="text" class="form-control" [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.Citta" />
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="form_name">Via</label>
                    <input type="text" class="form-control" [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.Via" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="form_name">E-Mail</label>
                    <input type="email" class="form-control" [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.Email"/>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="form_name">PEC</label>
                    <input type="email" class="form-control" [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.PEC"/>
                </div>
            </div>
        </div>
    
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="form_name">SDI</label>
                    <input type="text" class="form-control" [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.SDI"/>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="form_name">IBAN</label>
                    <input type="text" class="form-control" [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.IBAN"/>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="this.auth.AuthUser.Username === 'gab'">
            <div class="col">
                <div class="form-group" style="text-align: center;">
                    <label for="form_name" style="text-align: center;">Visual Order</label>
                    <input type="checkbox" class="form-control" [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.VisualOrder"/>
                </div>
            </div>
            <div class="col">
                <div class="form-group" style="text-align: center;">
                    <label for="form_name">Visual Intelligence</label>
                    <input type="checkbox" class="form-control" [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.VisualIntelligence"/>
                </div>
            </div>
            <div class="col">
                <div class="form-group" style="text-align: center;">
                    <label for="form_name">Visual Reservation</label>
                    <input type="checkbox" class="form-control" [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.VisualReservation"/>
                </div>
            </div>
        </div>
    </div>
    <!--CONCESSIONARIO-->
    <div class="card" style="border: 0px;">
    
        <!-- Card header -->
        <div class="card-header" role="tab" *ngIf="this.SelectedUser.Key !== ''">
            <div class="row">
                <div class="col">
                    Codice Cliente
                </div>
                <div class="col" style="text-align: right;">
                    {{this.SelectedUser.Key}}
                </div>
            </div>
        </div>
        <div class="card-body" style="border-bottom: none;">
            <div class="row">
                <div class="col">
                    <button type="button" class="btn btn-success" (click)="this.IsUpdating = !this.IsUpdating" *ngIf="!this.IsUpdating && this.SelectedUser.Username !== 'gab'" [disabled]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Utenti.Modifica">Modifica</button>
                    <button type="button" class="btn btn-success" (click)="this.UpdateUser()" *ngIf="this.IsUpdating" [disabled]="!this.CheckCanoneCloud()">Salva</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-danger" id="mod" (click)="this.OpenModalDelete(modalElimina)" *ngIf="this.SelectedUser.Username !== 'gab' && this.SelectedUser.Username !== 'cashmatic'" [disabled]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Utenti.Eliminazione" style="float: right;">Elimina</button>
                </div>
            </div>
                
                <!--
                    <button type="button" class="btn btn-primary" (click)="this.CloseModalInfo()">Chiudi</button>
                -->
        </div>
            
    </div>
</ng-template>

<ng-template #settings>
    <div class="card-body" *ngIf="this.SelectedUser.Role === 1 || this.SelectedUser.Role2 === 1 || this.SelectedUser.Role === 4 || this.SelectedUser.Role2 === 4">
        <!--Impostazioni singolo, se esiste-->
        <div class="row">
            <div class="col">
                <label class="h-table">Impostazioni Licenze Annuali</label>
            </div>
        </div>
        <div class="row ma-1">
            <div class="col-4">
                <input [disabled]="!IsUpdating" type="checkbox" [(ngModel)]="this.SelectedUserTipo.S">
                <label>Impostazioni Acquisto Singolo</label>
            </div>
            <div class="col-4" *ngIf="this.SelectedUserTipo.S">
                <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.S" />
                <label style="margin-left: 5%;">% Sconto</label>
            </div>
            <div class="col-4" *ngIf="this.SelectedUserTipo.S">
                <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.SBonus" />
                <label style="margin-left: 5%;">% Sconto Bonus</label>
            </div>
        </div>
        <!--Impostazioni pacchetti, se esiste-->
        <div class="row">
            <div class="col">
                <input [disabled]="!IsUpdating" type="checkbox" [(ngModel)]="this.SelectedUserTipo.P">
                <label>Impostazioni Acquisto Pacchetti</label>
            </div>
        </div>
        <ng-container *ngIf="this.SelectedUserTipo.P">
            <div class="row" >
                <div class="col">
                    <label>5 Licenze</label>
                </div>
                <div class="col-4">
                    <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.P.Cinque" />
                    <label style="margin-left: 5%;">% Sconto</label>
                </div>
                <div class="col-4">
                    <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.PBonus.Cinque" />
                    <label style="margin-left: 5%;">% Sconto Bonus</label>
                </div>
            </div>
            <div class="row" >
                <div class="col">
                    <label>10 Licenze</label>
                </div>
                <div class="col-4">
                    <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.P.Dieci" />
                    <label style="margin-left: 5%;">% Sconto</label>
                </div>
                <div class="col-4">
                    <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.PBonus.Dieci" />
                    <label style="margin-left: 5%;">% Sconto Bonus</label>
                </div>
            </div>
            <div class="row" >
                <div class="col">
                    <label>15 Licenze</label>
                </div>
                <div class="col-4">
                    <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.P.Quindici" />
                    <label style="margin-left: 5%;">% Sconto</label>
                </div>
                <div class="col-4">
                    <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.PBonus.Quindici" />
                    <label style="margin-left: 5%;">% Sconto Bonus</label>
                </div>
            </div>
            <div class="row ma-1">
                <div class="col">
                    <label>20 Licenze</label>
                </div>
                <div class="col-4">
                    <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.P.Venti" />
                    <label style="margin-left: 5%;">% Sconto</label>
                </div>
                <div class="col-4">
                    <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.PBonus.Venti" />
                    <label style="margin-left: 5%;">% Sconto Bonus</label>
                </div>
            </div>
        </ng-container>
        <!--Impostazioni canone, se esiste-->
        <div class="row" *ngIf="this.auth.AuthUser.ID === 10">
            <div class="col">
                <input [disabled]="!IsUpdating" type="checkbox" [(ngModel)]="this.SelectedUserTipo.C">
                <label>Impostazioni Canone</label>
            </div>
            <div class="col" hidden>
                <input [disabled]="!IsUpdating" type="string" [(ngModel)]="this.SelectedUser.CodiceMailCanone" />
                <label>Codice Identifico Canone</label>
            </div>
        </div>
        <ng-container *ngIf="this.SelectedUserTipo.C && this.auth.AuthUser.ID === 10">
            <div class="row" >
                <div class="col-3">
                    <div class="col-2" style="margin-right: 5%">
                        <select [disabled]="!IsUpdating" [(ngModel)]="this.SelectedUser.NumeroLicenzePacchetto">
                            <option [value]="'15'">15 Licenze</option>
                            <option [value]="'40'">40 Licenze</option>
                            <option [value]="'60'">60 Licenze</option>
                            <option [value]="'-1'">Infinite Licenze</option>
                        </select>
                    </div>
                </div>
                <div class="col-2">
                    <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.C" />
                    <label style="margin-left: 5%;">% Sconto</label>
                </div>
                <div class="col-2">
                    <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Sconto.CBonus" />
                    <label  style="margin-left: 5%;">% Sconto Bonus</label>
                </div>
                <div class="col-2">
                    <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Canone" />
                    <label  style="margin-left: 5%;">Canone</label>
                </div>
            </div>
            <div class="form-group">
                <label>SOFTWARE ABBINATI</label>
                <div class="row">
                    <div class="col" *ngIf="this.auth.AuthUser.Username === 'gab' || this.auth.AuthUser.SoftwareAbbinatiBoolean.VisualFood">
                        <div class="chiller_cb ml-1 mb-2">
                            <input [disabled]="!IsUpdating" type="checkbox" [checked]="this.SelectedUser.SoftwareAbbinatiBoolean.VisualFood" [(ngModel)]="this.SelectedUser.SoftwareAbbinatiBoolean.VisualFood"/>
                            <label style="margin-left: 5%;">Visual Food</label>
                            <span></span>
                        </div>
                    </div>
                    <div class="col" *ngIf="this.auth.AuthUser.Username === 'gab' || this.auth.AuthUser.SoftwareAbbinatiBoolean.VisualShop">
                        <div class="chiller_cb ml-1 mb-2">
                            <input [disabled]="!IsUpdating" type="checkbox" [checked]="this.SelectedUser.SoftwareAbbinatiBoolean.VisualShop" [(ngModel)]="this.SelectedUser.SoftwareAbbinatiBoolean.VisualShop"/>
                            <label style="margin-left: 5%;">Visual Shop</label>
                            <span></span>
                        </div>
                    </div>
                    <div class="col" *ngIf="this.auth.AuthUser.Username === 'gab' || this.auth.AuthUser.SoftwareAbbinatiBoolean.VisualRetail">
                        <div class="chiller_cb ml-1 mb-2">
                            <input [disabled]="!IsUpdating" type="checkbox" [checked]="this.SelectedUser.SoftwareAbbinatiBoolean.VisualRetail" [(ngModel)]="this.SelectedUser.SoftwareAbbinatiBoolean.VisualRetail"/>
                            <label style="margin-left: 5%;">Visual Retail</label>
                            <span></span>
                        </div>
                    </div>
                    <div class="col" *ngIf="this.auth.AuthUser.Username === 'gab' || this.auth.AuthUser.SoftwareAbbinatiBoolean.VisualSelf">
                        <div class="chiller_cb ml-1 mb-2">
                            <input [disabled]="!IsUpdating" type="checkbox" [checked]="this.SelectedUser.SoftwareAbbinatiBoolean.VisualSelf" [(ngModel)]="this.SelectedUser.SoftwareAbbinatiBoolean.VisualSelf"/>
                            <label style="margin-left: 5%;">Visual Self</label>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label *ngIf="this.ErroreSoftwareUpdate" style="color:red" >Errore selezione software <br></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label *ngIf="this.ErroreCanoneUpdate" style="color:red" >Numero di licenze incompatibili</label>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="card-body" *ngIf="(this.SelectedUser.Role === 1 || this.SelectedUser.Role2 === 1 || this.SelectedUser.Role === 4 || this.SelectedUser.Role2 === 4)">
        <div class="row">
            <div class="col">
                <label class="h-table">Impostazioni Cloud</label>
            </div>
        </div>
        <div class="row">
            <div class="col-4"></div>
            <div class="col-2">
                <label>Sconto <br> 1º Anno</label>
            </div>
            <div class="col-2">
                <label>Sconto <br> Bonus</label>
            </div>
            <div class="col-2">
                <label>Sconto <br> 2º Anno</label>
            </div>
            <div class="col-2">
                <label>Sconto <br> Bonus</label>
            </div>
        </div>
        <div class="row" style="margin-bottom: 2%;">
            <div class="col-4">
                <label>Da <input class="w-20" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.PrimoScaglione.SX" [disabled]="!IsUpdating"> a <input class="w-20" type="number" min="1" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.PrimoScaglione.DX" [disabled]="!IsUpdating"> attivazioni</label>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.PrimoScaglione.PrimoAnno" [disabled]="!IsUpdating"/>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.PrimoScaglione.PrimoAnnoBonus" [disabled]="!IsUpdating"/>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.PrimoScaglione.SecondoAnno" [disabled]="!IsUpdating"/>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.PrimoScaglione.SecondoAnnoBonus" [disabled]="!IsUpdating"/>
            </div>
        </div>
        <div class="row" style="margin-bottom: 2%;">
            <div class="col-4">
                <label>Da <input class="w-20" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.SecondoScaglione.SX" [disabled]="!IsUpdating"> a <input class="w-20" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.SecondoScaglione.DX" [disabled]="!IsUpdating"> attivazioni</label>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.SecondoScaglione.PrimoAnno" [disabled]="!IsUpdating"/>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.SecondoScaglione.PrimoAnnoBonus" [disabled]="!IsUpdating"/>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.SecondoScaglione.SecondoAnno" [disabled]="!IsUpdating"/>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.SecondoScaglione.SecondoAnnoBonus" [disabled]="!IsUpdating"/>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label>Da <input class="w-20" type="number" min="-1" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.TerzoScaglione.SX" [disabled]="!IsUpdating"> a <input class="w-20" type="number" min="-1" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.TerzoScaglione.DX" [disabled]="!IsUpdating"> attivazioni</label>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.TerzoScaglione.PrimoAnno" [disabled]="!IsUpdating"/>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.TerzoScaglione.PrimoAnnoBonus" [disabled]="!IsUpdating"/>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.TerzoScaglione.SecondoAnno" [disabled]="!IsUpdating"/>
            </div>
            <div class="col-2">
                <input type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoCloud.TerzoScaglione.SecondoAnnoBonus" [disabled]="!IsUpdating"/>
            </div>
        </div>
    </div>

    <div class="card-body" *ngIf="this.SelectedUser.Role === 1 || this.SelectedUser.Role2 === 1 || this.SelectedUser.Role === 4 || this.SelectedUser.Role2 === 4">
        <div class="row">
            <div class="col">
                <label class="h-table">Altri Sconti</label>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="row">
                    <div class="col-2">
                        <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoRinnovo" />
                    </div>
                    <div class="col-10">
                        <label>% Sconto Su Rinnovo</label>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-2">
                        <input [disabled]="!IsUpdating" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.ScontoModuli" />
                    </div>
                    <div class="col-10">
                        <label>% Sconto Su Moduli</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body" *ngIf="(this.SelectedUser.Role === 3 || this.SelectedUser.Role2 === 3)">
        <!--Impostazioni singolo, se esiste-->
        <div class="row">
            <div class="col">
                <label class="h-table">Provvigioni Segnalatore</label>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <input [disabled]="!IsUpdating" type="checkbox" [(ngModel)]="this.SelectedUser.Provvigione.Software">
                <label>Software</label>
            </div>
            <div class="col-4">
                <input [disabled]="!IsUpdating || !this.SelectedUser.Provvigione.Software" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Provvigione.VenditaSoftware"/>
                <label style="margin-left: 5%;">Vendita</label>
            </div>
            <div class="col-4">
                <input [disabled]="!IsUpdating || !this.SelectedUser.Provvigione.Software" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Provvigione.RinnovoSoftware"/>
                <label style="margin-left: 5%;">Rinnovo</label>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <input [disabled]="!IsUpdating" type="checkbox" [(ngModel)]="this.SelectedUser.Provvigione.Hardware">
                <label>Hardware</label>
            </div>
            <div class="col-4">
                <input [disabled]="!IsUpdating || !this.SelectedUser.Provvigione.Hardware" type="number" min="0" max="100" [(ngModel)]="this.SelectedUser.Provvigione.VenditaHardware"/>
                <label style="margin-left: 5%;">Vendita</label>
            </div>
            <div class="col-4"></div>
        </div>
    </div>
    <div class="card-body" style="border-bottom: none;">
        <div class="row">
            <div class="col" style="text-align: center;">
                <button type="button" class="btn btn-success" (click)="this.IsUpdating = !this.IsUpdating" *ngIf="!this.IsUpdating && this.SelectedUser.Username !== 'gab'" [disabled]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Utenti.Modifica">Modifica</button>
                <button type="button" class="btn btn-success" (click)="this.UpdateUser()" *ngIf="this.IsUpdating" [disabled]="!this.CheckCanoneCloud()">Salva</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalElimina>
    <!--Body-->
    <div class="modal-body" style="text-align: center;">
        <p style="font-size: 20px;">Vuoi eliminare l'utente?</p>
    </div>

    <!--Footer-->
    <div class="modal-footer justify-content-center">
        <a role="button" class="btn btn-outline-danger waves-effect" (click)="this.modalService.hide()">No</a>
        <a role="button" class="btn btn-danger" (click)="DeleteUser()">Si</a>
    </div>
  </ng-template>