import { IsDev } from './../../../global';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-img-viewer',
  templateUrl: './img-viewer.component.html',
  styleUrls: ['./img-viewer.component.scss']
})
export class ImgViewerComponent implements OnInit {

  @Input() public ImgName: string;

  public BaseUrl: string = '';

  constructor() {
    if (IsDev.active) {
      this.BaseUrl = "https://localhost/ImgListino/"
    } else {
      this.BaseUrl = "https://www.gabservizi.it/ImgListino/";
    }
  }

  ngOnInit(): void {

  }

}
