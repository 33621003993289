import { UserStoreService } from '../../Services/Admin/user-store.service';
import { AlertService } from 'ngx-alerts';
import { AuthService } from './../../Services/AuthService/auth.service';
import { ShopService } from './../../Services/ShopService/shop.service';
import {Component, OnInit, Output, ViewChild, EventEmitter, TemplateRef, Input} from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { loadStripe, PaymentIntent, Stripe, StripeCardElement, StripeElement, StripeElements, StripeElementStyle, StripeError } from '@stripe/stripe-js';
import { IsDev, StripeString } from 'src/app/global';
import { PaymentService } from 'src/app/Services/Payment/payment.service';
import {Payment, Tipo} from 'src/app/Models/Payment';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';

@Component({
  selector: 'stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss']
})
export class StripePaymentComponent implements OnInit {
  @Output()
  pagamentoAvvenuto = new EventEmitter<string>();

  @Input() Pagamento: Payment;


  //STRIPE
  public stripe: Stripe;
  public stripeElements: StripeElements;
  public ClientId: {clientSecret: string};
  public Card: StripeCardElement;
  public modalRef: BsModalRef;
  constructor(
    private PaymentService: PaymentService,
    private shopService: ShopService,
    private auth: AuthService,
    private alert: AlertService,
    public modalService: BsModalService,
    public router: Router,
    public storeService: UserStoreService
  ) {}
  pagamentoFatto(){
    this.pagamentoAvvenuto.emit('complete');
  }

  async ngOnInit() {

    

    this.ClientId = await this.PaymentService.StripeIntent(this.Pagamento);
    this.stripe = await loadStripe((IsDev.active ? StripeString.Dev : StripeString.Prod));
    this.stripeElements = this.stripe.elements();

    const style: StripeElementStyle = {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };
    this.Card = this.stripeElements.create("card", {style})
    this.Card.mount("#card-element")
  }
  GoToShop(){
    this.router.navigate(['shop']);
    this.modalRef.hide();
  }

  GoToProfilo(){
    this.router.navigate(['userInfo']);
    this.modalRef.hide();
  }
  async Pay( template: TemplateRef<any>) {

    try {
      await this.stripe.confirmCardPayment(this.ClientId.clientSecret, {
        payment_method: {card: this.Card}
      })
      .then(async paymentIntent => {

        if (paymentIntent.error) {
          this.alert.danger("Dati inseriti non corretti");
          return false;
        }
        //this.alert.success("Pagamento confermato!");
        
        if(this.shopService.AcquistaOra(this.Pagamento)){
          this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true});
        };
        this.pagamentoFatto();

        //this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true});

        return true;
      })
    } catch {
      this.alert.danger("Dati inseriti non corretti")
      return false;
    }
  }

}
