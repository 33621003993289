import SoftwareListino from '../../app/Models/SoftwareListino';

export default class AcquistoPacchetto {
    public software: SoftwareListino;
    public quantita: number;
    public pacchetto: number;

    public constructor(software: SoftwareListino, quantita: number, pacchetto: number) {
        this.software = software;
        this.quantita = quantita;
        this.pacchetto = pacchetto;
    }
}