import { LicenseService } from 'src/app/Services/LicenseService/license.service';
import { AlertService } from 'ngx-alerts';
import { UserService } from './../../Services/UserService/user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs';
import { AuthService } from './../../Services/AuthService/auth.service';
import { Component, Input, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { User } from 'src/app/Models/User';

import {faInfo, faCog, faPlus, faMinus, faCircle, faWrench, faChevronCircleLeft, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { LicenseStat } from 'src/app/Models/LicenzeStat';
import { HttpParams, HttpClient } from '@angular/common/http';
import { IsDev } from 'src/app/global';
import { ShopService } from 'src/app/Services/ShopService/shop.service';
import CFG from 'src/app/Models/CFG';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  //ICON
  public faInfo: IconDefinition = faInfo;
  public faCog: IconDefinition = faCog;
  public faPlus: IconDefinition = faPlus;
  public faMinus: IconDefinition = faMinus;
  public faCircle: IconDefinition = faCircle;
  public faWrench: IconDefinition = faWrench;
  public faChevronCircleLeft: IconDefinition = faChevronCircleLeft;
  //ERRORI
  public ErroreSoftwareUpdate: boolean = false;
  public ErroreCanoneUpdate: boolean = false;

  @Input()
  public SelectedUser: User;

  @Input()
  public SelectedUserTipo: any;
  
  @Output() editChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() LoadUsers: EventEmitter<User[]> = new EventEmitter();

  
  public licenzeStats: LicenseStat[] = [];
  public CFG: CFG[];
  
  //MODAL SETTINGS
  public SettingsOpen: boolean = false;
  public IsUpdating: boolean = false;

  //LOADING
  public loading: boolean = false;
  public modalRef: BsModalRef;

  constructor(public auth: AuthService, public modalService: BsModalService, 
    private http: HttpClient, private UserService: UserService, public ShopService: ShopService, 
    private alert: AlertService, private licenseService: LicenseService, public router: Router) { }

  async ngOnInit() {

    this.loading = true;
    await this.LoadStats();
    //this.CFG = await this.UserService.GetCFG(this.SelectedUser.PIVA);
    this.loading = false;
  }



  //Modifica utente
  public async UpdateUser() {

    this.ErroreSoftwareUpdate = false;
    this.ErroreCanoneUpdate = false;


    //CHECK
    let statUpdate: LicenseStat = new LicenseStat();
    if (this.SelectedUser.Role === 1 || this.SelectedUser.Role2 === 1 || this.SelectedUser.Role === 4 || this.SelectedUser.Role2 === 4) {
      //CONTROLLO 3 SOFTWARE
      //SOFTWARE ABBINATI
      let countSoftware: number = 0;
      if (this.SelectedUser.SoftwareAbbinatiBoolean.VisualFood === true) countSoftware++
      if (this.SelectedUser.SoftwareAbbinatiBoolean.VisualRetail === true) countSoftware++
      if (this.SelectedUser.SoftwareAbbinatiBoolean.VisualSelf === true) countSoftware++
      if (this.SelectedUser.SoftwareAbbinatiBoolean.VisualShop === true) countSoftware++

      this.ErroreSoftwareUpdate = false;
      if ((countSoftware > 3 || countSoftware === 0) && this.SelectedUserTipo.C) this.ErroreSoftwareUpdate = true;


      let statDistributore: LicenseStat = this.licenzeStats.find(stat => stat.CodiceArticolo === "CANONE");
      const result: any = await this.licenseService.GetLicenseCount();

      for (let i = 0; i < result.length; ++i) {
        if (result[i].codiceArticolo === "CANONE") {
          statUpdate.CodiceArticolo === "CANONE";
          statUpdate.NumeroLicenze = result[i].numeroLicenze;
          statUpdate.LicenzeGenerate = result[i].licenzeGenerate;
          break;
        }
      }

      if (this.auth.AuthUser.NumeroLicenzePacchetto !== -1 && this.auth.AuthUser.Username !== 'gab' && this.SelectedUser.Tipo.includes('C')) {

        //STATISTICHE UTENTE DA AGGIORNARE
        const diff: number = Number(this.SelectedUser.NumeroLicenzePacchetto) - Number(statUpdate.NumeroLicenze);
        if ((statDistributore.LicenzeGenerate + diff) > statDistributore.NumeroLicenze) {
          this.ErroreCanoneUpdate = true;
          return;
        }

        if (Number(this.SelectedUser.NumeroLicenzePacchetto) === -1) {
          this.ErroreCanoneUpdate = true;
        }
        if (statUpdate.LicenzeGenerate > Number(this.SelectedUser.NumeroLicenzePacchetto)) {
          this.ErroreCanoneUpdate = true;
        }

        if (statUpdate.LicenzeGenerate > 0) {
          this.ErroreCanoneUpdate = true;
          this.IsUpdating = !this.IsUpdating;
          this.SelectedUserTipo.C = true;
          return
        }
      }
    }

    if (Number(this.SelectedUser.NumeroLicenzePacchetto) === 0 && this.SelectedUserTipo.C) {
      this.ErroreCanoneUpdate = true;
    }

    //Funzione da chiamare quando aggiorno la sezione licenze
    if (!this.ErroreSoftwareUpdate && !this.ErroreCanoneUpdate) {
      this.IsUpdating = !this.IsUpdating;
      this.ErroreSoftwareUpdate = false;
      this.ErroreCanoneUpdate = false;

      const id = await this.auth.GetIdByUsername();
      await this.UserService.UpdateUser(this.SelectedUser, this.SelectedUserTipo, this.auth.AuthUser.ID, statUpdate.NumeroLicenze, id);
      return;
    }

    //Funzione da chiamare quando non aggiorno anche la sezione licenze
    else if (!this.ErroreSoftwareUpdate) {
      this.IsUpdating = !this.IsUpdating;
      this.ErroreSoftwareUpdate = false;
      this.ErroreCanoneUpdate = false;

      const id = await this.auth.GetIdByUsername();
      await this.UserService.UpdateUser(this.SelectedUser, null, this.auth.AuthUser.ID, null, id);
      return
    }

  }
  
  public async DeleteUser() {

    // const id = await this.auth.GetIdByUsername();

    const result: boolean = await this.UserService.DeleteUser(this.SelectedUser.ID, this.SelectedUser.Username, this.SelectedUser.PIVA);

    if (!result) {
      this.alert.danger("Impossibile eliminare");
    }
    
    const users: User[] = await this.UserService.LoadUsers(this.auth.AuthUser.Username);
    this.LoadUsers.emit(users);

    this.editChanged.emit(false)

    this.modalRef.hide();
    //this.modalService.hide();
  }

  public async OpenModalDelete(deleteModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(deleteModal);

  }

  public Back() {
    this.editChanged.emit(false);
  }

  public async LoadStats() {

    //GAB
    this.licenzeStats = [];

    const result: any = await this.licenseService.GetLicenseCount();

    for (let i = 0; i < result.length; ++i) {
      const licenzeStat: LicenseStat = new LicenseStat();
      licenzeStat.CodiceArticolo = result[i].codiceArticolo;
      licenzeStat.NumeroLicenze = result[i].numeroLicenze;
      licenzeStat.LicenzeGenerate = result[i].licenzeGenerate;
      licenzeStat.Data = result[i].dataAcquisto;
      licenzeStat.GetDescrizione(this.ShopService.ListinoSoftware);

      this.licenzeStats.push(licenzeStat);
    }
    return;
  }

  public CloseModalInfo() {
    this.IsUpdating = false;
    this.modalService.hide();
  }

  public CheckCanoneCloud() {

    if (this.SelectedUser.Role === 2 || this.SelectedUser.Role === 0 || this.SelectedUser.Role === 3) return true;

    if (this.SelectedUser.ScontoCloud.PrimoScaglione.SX < this.SelectedUser.ScontoCloud.PrimoScaglione.DX) {
      if (this.SelectedUser.ScontoCloud.PrimoScaglione.DX < this.SelectedUser.ScontoCloud.SecondoScaglione.SX && this.SelectedUser.ScontoCloud.PrimoScaglione.DX === this.SelectedUser.ScontoCloud.SecondoScaglione.SX - 1) {
        if (this.SelectedUser.ScontoCloud.SecondoScaglione.SX < this.SelectedUser.ScontoCloud.SecondoScaglione.DX) {
          if (this.SelectedUser.ScontoCloud.SecondoScaglione.DX < this.SelectedUser.ScontoCloud.TerzoScaglione.SX && this.SelectedUser.ScontoCloud.SecondoScaglione.DX === this.SelectedUser.ScontoCloud.TerzoScaglione.SX - 1) {
            if (this.SelectedUser.ScontoCloud.TerzoScaglione.SX < this.SelectedUser.ScontoCloud.TerzoScaglione.DX || this.SelectedUser.ScontoCloud.TerzoScaglione.DX === -1) {
              // this.ErroreCloud = false;
              return true;
            }
          }
        }
      }  
    }

    // this.ErroreCloud = true;
    return false;
  }

  

}
