<div *ngIf="!this.list && !editUser">
    <app-user-create *ngIf="!this.auth.IsCashServizi" (listChanged)="listChanged($event)" (LoadUsers)="handlerUserLoad($event)" [Users]="this.AllUsers"></app-user-create>
    <app-user-create-cashmatic *ngIf="this.auth.IsCashServizi" (listChanged)="listChanged($event)" (LoadUsers)="handlerUserLoad($event)" [Users]="this.AllUsers"></app-user-create-cashmatic>
</div>

<div class="card strpied-tabled-with-hover" id="cardTicket" *ngIf="this.list && readyLoad && !this.editUser">
        <div class="card-header">
            <ng-template #tooltip><div [innerHtml]="this.userStats.ToString()"></div></ng-template>
            <h4 class="card-title">Lista Utenti
              <fa-icon [icon]="faInfoCircle" style="padding-left: 5px;" [tooltip]="tooltip"></fa-icon>
            </h4>
            <div class="md-form active-pink active-pink-2 mb-3 mt-1">
                <div class="input-group mb-3">
                    <input class="form-control" type="text" placeholder="Inserisci Username, Partita IVA o Ragione Sociale" id="search" (keyup)="FilterByName()" aria-label="Search" />
                    <div class="input-group-prepend">
                        <button class="btn btn-primary btn-fill" (click)="this.list = false" [disabled]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Utenti.Creazione">Crea utente</button>
                    </div>
                  </div>
            </div>
          <div fxLayout="row">
            <div class="dropdown" style="padding-bottom: 1rem;width: 100px;">
              <a class="ordinamento">
                Ordinamento
              </a>
              <span>
            <img src="assets/img/down-arrow.png" height="5px" width="5px"/>
            </span>
              <div class="first-dropdown-content">
                <ul class="dropdown-menu-ordinamento" role="menu" aria-labelledby="dropdownMenu">
                  <li ><small class="titoli-ordinamento">Ragione Sociale</small></li>
                  <li (click)="OrdinaRagSocCrescente()"  class="menuitem"><p class="dropdown-title" >Crescente</p></li>
                  <li class="menuitem" (click)="OrdinaRagSocDescrescente()"><p class="dropdown-title" >Decrescente</p></li>
                  <li class="divider"></li>
                </ul>
              </div>
            </div>
            <div class="dropdown" style="padding-left:1rem;padding-bottom: 1rem;">
              <a style="font-size: 12px;color: #9A9A9A !important; cursor: pointer;font-size: 15px">Filtra </a> 
              <span><img src="assets/img/down-arrow.png" height="5px" width="5px"/></span>
              <div class="first-dropdown-content">
                <ul class="dropdown-menu-ordinamento dropdown-filtro" role="menu" aria-labelledby="dropdownMenu">
                  <li (click)="dropdownFiltroRuoli = !dropdownFiltroRuoli"><small class="titoli-ordinamento">Ruolo
                    <span><img src="assets/img/down-arrow.png" height="5px" width="5px"/></span></small></li>
                  <ul *ngIf="dropdownFiltroRuoli" style="list-style-type: none;padding: 0;padding-left: 10px;font-size: 14px;">
                    <li style="cursor: pointer" class="menuitem filter-item">
                      <input type="checkbox" id="filtro-cliente" [(ngModel)]="FiltroUtenti.Ruolo.Cliente" (ngModelChange)="Filtro()" >
                      <label for="filtro-cliente" class="filter-name" >Cliente</label></li>
                    <li style="cursor: pointer" class="menuitem filter-item">
                      <input type="checkbox" id="filtro-cliente" [(ngModel)]="FiltroUtenti.Ruolo.Segnalatore" (ngModelChange)="Filtro()" >
                      <label for="filtro-cliente" class="filter-name" >Segnalatore</label></li>
                    <li style="cursor: pointer" class="menuitem filter-item"  *ngIf="this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role2 === 0 || this.auth.AuthUser.Role === 4 || this.auth.AuthUser.Role2 === 4">
                      <input type="checkbox"  id="filtro-concessionario" [(ngModel)]="FiltroUtenti.Ruolo.Concessionario" (ngModelChange)="Filtro()" >
                      <label  for="filtro-concessionario"  class="filter-name">Concessionario</label></li>
                    <li style="cursor: pointer" class="menuitem filter-item" *ngIf="this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role2 === 0">
                      <input type="checkbox" id="filtro-distributore"  [(ngModel)]="FiltroUtenti.Ruolo.Distributore" (ngModelChange)="Filtro()" >
                      <label for="filtro-distributore"  class="filter-name">Distributore</label></li>
                    <li style="cursor: pointer" class="menuitem filter-item" *ngIf="this.auth.AuthUser.Username === 'gab'">
                      <input type="checkbox"  id="filtro-admin" [(ngModel)]="FiltroUtenti.Ruolo.Admin" (ngModelChange)="Filtro()">
                      <label for="filtro-admin" class="filter-name">Admin</label></li>
                  </ul>

                  <li class="divider"></li>
                  <li (click)="dropdownFiltroStato = !dropdownFiltroStato"><small class="titoli-ordinamento">Stato
                    <span><img src="assets/img/down-arrow.png" height="5px" width="5px" /></span></small></li>
                  <ul *ngIf="dropdownFiltroStato" style="list-style-type: none; padding-left:10px">
                    <li style="cursor: pointer"  class="menuitem filter-item">
                      <input type="checkbox" id="filtro-attivo" [(ngModel)]="FiltroUtenti.Stato.Attivo" (ngModelChange)="Filtro()">
                      <label for="filtro-attivo" class="filter-name" >Attivo</label>
                    </li>
                    <li style="cursor: pointer"  class="menuitem filter-item">
                      <input type="checkbox" id="filtro-sospeso" [(ngModel)]="FiltroUtenti.Stato.Sospeso" (ngModelChange)="Filtro()">
                      <label for="filtro-sospeso" class="filter-name">Sospeso</label>
                    </li>
                    <li style="cursor: pointer"   class="menuitem filter-item">
                      <input type="checkbox" id="filtro-disattivato" [(ngModel)]="FiltroUtenti.Stato.Disattivato" (ngModelChange)="Filtro()">
                      <label for="filtro-disattivato" class="filter-name">Disattivato</label>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>


  <div class="card-body table-responsive"style="padding: 0">

            <div class="messages"></div>

            <div class="controls">
                <table class="table table-hover table-striped " id="table">
                    <thead>
                        <tr>
                            <th>Nome Utente</th>
                            <th>Partita IVA</th>
                            <th>Ragione Sociale</th>
                            <th>Ruolo</th>
                            <th *ngIf="this.auth.AuthUser.Username === 'gab'">Stato</th>
                        </tr>
                    </thead>
                    <tbody class="sortable">
                        <tr *ngFor="let user of this.Users;">
                          <ng-container *ngIf="user.Stato !== -1">
                            <td style="height: 58px;" (click)="OpenModalInfo(user, infoTemplate)">{{user.Username}}</td>
                            <td (click)="OpenModalInfo(user, infoTemplate)">{{user.Role === 0 ? '00168780351' : user.PIVA}}</td>
                            <td (click)="OpenModalInfo(user, infoTemplate)">{{user.RagioneSociale}}<small *ngIf="user.Creatore !== 'gab' && this.auth.AuthUser.Role === 0" class="nome-creatore-non-gab" >({{user.Creatore}})</small></td>
                            <td (click)="OpenModalInfo(user, infoTemplate)">
                                <span class="badge badge-danger" *ngIf="user.Role === 0">ADMIN</span>
                                <span class="badge badge-warning" *ngIf="user.Role === 1">CONCESSIONARIO</span>
                                <span class="badge badge-success" *ngIf="user.Role === 2">CLIENTE</span>
                                <span class="badge badge-info" *ngIf="user.Role === 3">SEGNALATORE</span>
                                <span class="badge badge-light" *ngIf="user.Role === 4">DISTRIBUTORE</span>
                                <span class="badge badge-danger" *ngIf="user.Role2 === 0">ADMIN</span>
                                <span class="badge badge-warning" *ngIf="user.Role2 === 1">CONCESSIONARIO</span>
                                <span class="badge badge-success" *ngIf="user.Role2 === 2">CLIENTE</span>
                                <span class="badge badge-info" *ngIf="user.Role2 === 3">SEGNALATORE</span>
                                <span class="badge badge-light" *ngIf="user.Role2 === 4">DISTRIBUTORE</span>
                            </td>
                            <td *ngIf="this.auth.AuthUser.Username === 'gab'">
                                <ng-template #tolTemplate>{{ user.Stato === 0 ? "Utente Attivo" : (user.Stato === 1 ? "Utente Sospeso" : "Utente Disattivato") }}</ng-template>
                                <a role="button" (click)="this.SetUserState(user)" [tooltip]="tolTemplate">
                                    <fa-icon [icon]="faCircle" style="color: green" *ngIf="user.Stato === 0"></fa-icon>
                                    <fa-icon [icon]="faCircle" style="color: orange" *ngIf="user.Stato === 1"></fa-icon>
                                    <fa-icon [icon]="faCircle" style="color: red" *ngIf="user.Stato === 2"></fa-icon>
                                </a>
                            </td>
                          </ng-container>
                        </tr>
                     </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
    <!--MODAL INFO-->
<div *ngIf="this.editUser">
    <app-user-edit [SelectedUser]="this.SelectedUser" [SelectedUserTipo]="this.SelectedUserTipo" (LoadUsers)="handlerUserLoad($event)" (editChanged)="editChanged($event)" style="overflow-y: auto"></app-user-edit>
</div>

<ng-template #userSetting>
    <app-user-software-config [Username]="this.SelectedUser.Username"></app-user-software-config>
</ng-template>
