import { AuthService } from './../../Services/AuthService/auth.service';
import { UserService } from './../../Services/UserService/user.service';
import { Component, Input, OnInit } from '@angular/core';
import {faMinusCircle, faPlusCircle, faCheckCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import CFG from 'src/app/Models/CFG';

@Component({
  selector: 'app-user-cfg-edit',
  templateUrl: './user-cfg-edit.component.html',
  styleUrls: ['./user-cfg-edit.component.scss']
})
export class UserCfgEditComponent implements OnInit {

  public CFG: CFG[];
  public OriginalCFG: CFG[];
  @Input() public PIVA: string;

  public AddedCFG: CFG = new CFG();
  //ICON
  public faDelete: IconDefinition = faMinusCircle;
  public faPlus: IconDefinition = faPlusCircle;
  public faCheck: IconDefinition = faCheckCircle;


  public IsUpdating: boolean = false;
  public IsAdding: boolean = false;

  public MaxId: number = 0;
  constructor(public userService: UserService, public auth: AuthService) { }

  async ngOnInit() {

    const cfg: CFG[] = await (await this.userService.GetCFG(this.PIVA));

    if (cfg === null) {
      this.CFG = [];
    } else {
      this.CFG = await this.userService.GetCFG(this.PIVA);
    }

    this.OriginalCFG = this.CFG.map(cfg => {
      return Object.assign({}, cfg);
    })

    this.MaxId = Math.max.apply(Math, this.CFG.map((cfg => {
      return cfg.id;
    })));
  }



  /*
  public Save() {
    this.IsUpdating = !this.IsUpdating;
    //INDIVIDUO GLI ELEMENTI DA AGGIUNGERE
    this.NewCFG.forEach(async newcfg => {
      //L'ELEMENTO NON è PRESENTE NELL'ARRAY ORIGINALE
      if (this.CFG.findIndex(cfg => cfg.id === newcfg.id) === -1) {
        await this.userService.SaveCFG(this.PIVA, newcfg);
      }
    })

    //INDIVIDUO GLI ELEMENTI DA RIMUOVERE
    this.CFG.forEach(async oldcfg => {
      //L'ELEMENTO NON E' PRESENTE NELL'ARRAY NUOVO
      if (this.NewCFG.findIndex(newcfg => newcfg.id === oldcfg.id) === -1) {
        await this.userService.DeleteCFG(this.PIVA, oldcfg.id);
      }
    })
    this.IsAdding = false;
    this.IsUpdating = false;
    return;
  }
  */

  public CheckValid(cfg: CFG) {
    return cfg.ds !== '' && cfg.mc !== '';
  }

  public CheckEdited(id: number) {
    /*
    ESTRAGGO DAGLI ARRAY I DUE OGGETTI CFG CON ID PARAMETRIZZATO
    Se sono diversi, ho effettuato una modifica che posso successivamente salvare
    */

    const CFGOriginal: CFG = this.OriginalCFG.find(cfg => {
      return cfg.id === id;
    });

    const EditedCFG: CFG = this.CFG.find(cfg => {
      return cfg.id === id;
    });

    if (!this.IsEqual(EditedCFG, CFGOriginal)) {

      (EditedCFG as any).edited = true;
      return true;

    } else {

      (EditedCFG as any).edited = false;
      return false;

    }
  }

  public async AddCFG() {
    //DEFINISCO ID

    if (this.MaxId !== -Infinity) {
      this.AddedCFG.id = this.MaxId + 1;
      this.MaxId ++;
    } else {
      this.AddedCFG.id = 1;
      this.MaxId = 1;
    }

    await this.userService.SaveCFG(this.PIVA, this.AddedCFG);

    this.CFG.push(this.AddedCFG);

    this.OriginalCFG.push(Object.assign({}, this.AddedCFG));

    this.AddedCFG = Object.assign({}, new CFG());


    this.IsAdding = false;
  }

  public async DeleteCFG(cfgToDelete: CFG) {
    await this.userService.DeleteCFG(this.PIVA, cfgToDelete.id);

    const index: number = this.CFG.findIndex(cfg => cfg.id === cfgToDelete.id);

    this.CFG.splice(index, 1);
  }

  public async UpdateCFG(cfgToEdit: CFG) {
    await this.userService.UpdateCFG(this.PIVA, cfgToEdit);

    (cfgToEdit as any).edited = false;

    this.OriginalCFG = this.CFG.map(cfg => {
      return Object.assign({}, cfg);
    })


  }

  public IsEqual(cfg: CFG, cfgToCompare: CFG) {
    return cfg.id === cfgToCompare.id && cfg.pv === cfgToCompare.pv && cfg.cs === cfgToCompare.cs && cfg.mc === cfgToCompare.mc && cfg.ds === cfgToCompare.ds;
}


}
