export default class Acquisto {
    public ID: number
    public Username: string
    public PIVA: string
    public RagSoc: string;
    public NumeroLicenze: number
    public CodiceArticolo: string
    public Descrizione: string;
    public DataOrdine: Date

    public PrezzoUnitario: number;
    public PrezzoTotale: number
    public PrezzoScontato: number

    public Tipo: string;

    public ImgName: string = "";

    public DateToString() {
        const date: Date = new Date(this.DataOrdine);

        return date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
    }
    

}