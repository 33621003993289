

<form method="post" style="margin-bottom: 20px;" *ngIf="ShopService.ShowCarrello">
  <table *ngIf="!ShopService.mobileCart; else mobilecart" cellspacing="0" class="shop_table cart">
    <thead>
    <tr >
      <th class="product-remove"></th>
      <th class="product-thumbnail"></th>
      <th class="product-code">Codice articolo</th>
      <th class="product-name">Prodotto</th>
      <th class="product-price">Prezzo unitario</th>
      <th class="product-quantity">Quantità</th>
      <th class="product-subtotal">Prezzo Totale</th>
      <th class="product-subtotal" *ngIf="this.auth.AuthUser.Role !== userRole">Prezzo scontato</th>
      <th class="product-subtotal" style="border-right: 1px solid #ddd;" *ngIf="this.auth.AuthUser.Role !== userRole">Formato</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let contenitore of this.ShopService.Carrello">
      <!-- VISUALIZZAZIONE ACQUISTO PACCHETTO-->
      <ng-container *ngIf="contenitore.Tipo === 'P'">
        <tr class="cart_item">
          <td class="product-remove">
            <fa-icon [icon]="faTrash" style="cursor: pointer;color:gray"(click)="this.ShopService.RemoveCarrello(contenitore)"></fa-icon>
          </td>
          <td>
            <a><img alt="poster_1_up" class="packet-img" src="../../../assets/img/pacchetto-licenze.png"></a>
          </td>
          <td class="product-code">
            <a>Riassuntivo pacchetto</a>
          </td>
          <td class="product-name">
            <a>Riassuntivo pacchetto</a>
          </td>
          <td class="product-price">
            <span class="amount">Riassuntivo pacchetto</span>
          </td>
          <td class="product-quantity">
            <div class="quantity buttons_added">
              <a>{{contenitore.LicenseTotali}}</a>
            </div>
          </td>
          <td class="product-subtotal" style="border-right: 1px solid #ddd;">
            <span class="amount">{{contenitore.PrezzoTotale}}€</span>
          </td>
          <td class="product-subtotal" style="border-right: 1px solid #ddd;">
            <span class="amount">Riassuntivo pacchetto</span>
          </td>
          <td class="product-subtotal" style="border-right: 1px solid #ddd;">
            <span class="amount">Riassuntivo pacchetto</span>
          </td>
        </tr>

        <ng-container *ngFor="let contenuto of contenitore.Contenuto">
          <tr class="cart_item elementi_pacchetto" >
            <td class="product-remove">
            </td>
            <td class="product-thumbnail">
              <a></a>
            </td>
            <td class="product-code">
              <a>{{contenuto.CodiceArticolo}}</a>
            </td>
            <td class="product-name">
              <a>{{contenuto.Descrizione}}</a>
            </td>
            <td class="product-price">
              <span class="amount">{{contenuto.PrezzoUnitario}}€</span>
            </td>
            <td class="product-quantity">
              <div class="quantity buttons_added">
                <a>{{contenuto.NumeroLicenze}}</a>
              </div>
            </td>
            <td class="product-subtotal" style="border-right: 1px solid #ddd;">
              <span class="amount">{{contenuto.PrezzoTotale}}€</span>
            </td>
            <td class="product-subtotal" style="border-right: 1px solid #ddd;" *ngIf="this.auth.AuthUser.Role !== userRole">
              <span class="amount">{{contenuto.PrezzoScontato === contenuto.PrezzoTotale ? 'Nessun sconto' : contenuto.PrezzoScontato }}€</span>
            </td>
            <td class="product-subtotal" style="border-right: 1px solid #ddd;">
              <span class="amount">Pacchetto</span>
            </td>
          </tr>

        </ng-container>
      </ng-container>
      <!-- VISUALIZZAZIONE ACQUISTO SINGOLO-->
      <tr class="cart_item" *ngIf="contenitore.Tipo === 'S'">
        <td class="product-remove">
          <a title="Remove this item"style="cursor: pointer;color: gray" class="remove" (click)="this.ShopService.RemoveCarrello(contenitore)"> <fa-icon [icon]="faTrash" ></fa-icon></a>
        </td>
        <td>
          <a><img [src]="getProductImageSingolo(contenitore)" class="single-product-img" [id]="contenitore.Contenuto[0].CodiceArticolo + contenitore.Tipo" (error)="ImageErrorHandlerSingolo(contenitore.Contenuto[0].CodiceArticolo + contenitore.Tipo)" width="100%" />
          </a>
        </td>
        <td class="product-code">
          <a>{{contenitore.Contenuto[0].CodiceArticolo}}</a>
        </td>
        <td class="product-name">
          <a>{{contenitore.Contenuto[0].Descrizione}}</a>
        </td>
        <td class="product-price">
          <span class="amount">{{contenitore.Contenuto[0].PrezzoUnitario}}€</span>
        </td>
        <td class="product-quantity">
          <div class="quantity buttons_added">
            <a>{{contenitore.Contenuto[0].NumeroLicenze}}</a>
          </div>
        </td>
        <td class="product-subtotal" style="border-right: 1px solid #ddd;">
          <span class="amount">{{contenitore.Contenuto[0].PrezzoTotale}}€</span>
        </td>
        <td class="product-subtotal" style="border-right: 1px solid #ddd;" *ngIf="this.auth.AuthUser.Role !== userRole">
          <span class="amount">{{contenitore.Contenuto[0].PrezzoScontato}}€</span>
        </td>
        <td class="product-subtotal" style="border-right: 1px solid #ddd;" *ngIf="this.auth.AuthUser.Role !== userRole">
          <span class="amount">Singolo</span>
        </td>
      </tr>
    </ng-container>

    <tr>
      <td class="actions" colspan="12">
        <!--<div class="coupon">
          <label for="coupon_code">Coupon:</label>
          <input type="text" placeholder="Coupon code" value="" id="coupon_code" class="input-text" name="coupon_code">
          <div class="btn btn-primary"></div>
          <input type="submit" value="Apply Coupon" name="apply_coupon" class="button">
        </div>-->

        <div class="col" style="text-align: right;">
          <button class="btn btn-danger" style="margin-right: 10px;" (click)="this.ShopService.ClearCarrello()" [disabled]="this.ShopService.loading">
            Svuota Carrello
          </button>
          <button class="btn btn-success"  (click)="this.ShopService.ConfirmCarrello()" [disabled]="this.ShopService.loading">
            {{auth.AuthUser.Role === this.userRole ? "Procedi al pagamento" : "Conferma Carrello"}}
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <!--CARRELLO DA CELLULARE-->
  <ng-template #mobilecart>
    <div>
      <button class="btn button-confirm-mobile-cart" (click)="this.ShopService.ConfirmCarrello()">
        {{auth.AuthUser.Role === this.userRole ? "Procedi all'ordine" : "Conferma Carrello"}}
      </button>
      <hr>
    </div>

    <form method="post">
      <div class="sc-list-item-content" *ngFor="let contenitore of this.ShopService.Carrello">
        <!--ACQUISTO SINGOLO-->
        <div *ngIf="contenitore.Tipo === 'S'">
          <div fxLayout.lt-lg="row" fxLayoutGap.lt-lg="10px">
            <div fxFlex.lt-lg="50%">
              <img [src]="getProductImageSingolo(contenitore)" class="single-product-img" [id]="contenitore.Contenuto[0].CodiceArticolo + contenitore.Tipo" (error)="ImageErrorHandlerSingolo(contenitore.Contenuto[0].CodiceArticolo + contenitore.Tipo)" width="100%" />
              <div style="text-align: left;margin-top: 15px;">
                <span class="a-button-inner" (click)="this.ShopService.RemoveCarrello(contenitore)">
                  <input type="button" value="Rimuovi" class="button-rimuovi-mobile-cart">
                </span>
              </div>
            </div>
            <div fxFlex.lt-lg="50%">
              <div style="font-size: 22px;font-weight: bolder">{{contenitore.Contenuto[0].Descrizione}}<br></div>
              <small><span style="font-weight: bold">Codice articolo:<br></span>{{contenitore.Contenuto[0].CodiceArticolo}}<br></small>
              <small><span style="font-weight: bold">Prezzo Unitario:</span>{{contenitore.Contenuto[0].PrezzoUnitario}}€<br></small>
              <small><span style="font-weight: bold">Quantità:</span>{{contenitore.Contenuto[0].NumeroLicenze}}<br></small>
              <small><span style="font-weight: bold">Prezzo Totale:</span>{{contenitore.Contenuto[0].PrezzoTotale}}€<br></small>
              <small><span style="font-weight: bold">Formato:</span> Singolo<br></small>
            </div>
          </div>
          <hr>

        </div>
        <!--ACQUISTO PACCHETTO-->
        <div *ngIf="contenitore.Tipo === 'P'">
          <div fxLayout.lt-lg="row" fxLayoutGap.lt-lg="10px" >
            <div fxFlex.lt-lg="50%">
              <img  src="../../../assets/img/pacchetto-licenze.png" width="100%"/>
              <small><span style="font-weight: bold">Prezzo Totale:</span>{{contenitore.PrezzoTotale}}€<br></small>
              <small><span style="font-weight: bold">Prezzo Scontato:</span>{{contenitore.TotalePrezzoScontato}}€<br></small>
              <small><span style="font-weight: bold">Quantità:</span>{{contenitore.LicenseTotali}}<br></small>
              <div style="text-align: left;margin-top: 15px;">
                <span class="a-button-inner" (click)="this.ShopService.RemoveCarrello(contenitore)">
                  <input type="button" value="Rimuovi" class="button-rimuovi-mobile-cart">
                </span>
              </div>


            </div>
            <div fxFlex.lt-lg="50%">
              <div style="font-size: 22px;font-weight: bolder">Riassuntivo pacchetto<br></div>

              <table>
                <th>Descr.</th>
                <th>Quantita</th>
                <tbody>
                <tr  *ngFor="let contenuto of contenitore.Contenuto">
                  <td>{{contenuto.Descrizione}}</td>
                  <td>{{contenuto.NumeroLicenze}}</td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
          <hr>

        </div>

      </div>
      <button class="btn button-clear-mobile-cart" (click)="this.ShopService.ClearCarrello()" [disabled]="this.ShopService.loading">
        Svuota Carrello
      </button>

    </form>
  </ng-template>
</form>





