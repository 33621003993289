export default class CFG {
    public id: number; //ID;
    public pv: number; //PUNTO VENDITA
    public cs: number; //CASSA
    public mc: string; //MAC ADDRESS
    public ds: string; //DESCRIZIONE
    public piva?: string;
    
    public constructor() {
        this.id = 0;
        this.pv = 0;
        this.cs = 0;
        this.mc = "";
        this.ds = "";
    }

    public IsEqual(cfgToCompare: CFG) {
        return this.id === cfgToCompare.id && this.pv === cfgToCompare.pv && this.cs === cfgToCompare.cs && this.mc === cfgToCompare.mc && this.ds === cfgToCompare.ds;
    }

    public static Clone(cfg: CFG): CFG {
        const clonedCFG: CFG = new CFG();
        clonedCFG.id = cfg.id;
        clonedCFG.pv = cfg.pv;
        clonedCFG.cs = cfg.cs;
        clonedCFG.mc = cfg.mc;
        clonedCFG.ds = cfg.ds;

        return clonedCFG;
    }

}