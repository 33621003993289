<div class="card-body" style="height: auto !important;">
    <table class="table table-hover table-striped">
        <thead>
            <th style="border-top: none"><label class="h-table">Software</label></th>
            <th style="text-align: center; border-top: none;"><label class="h-table">Selezionato</label></th>
        </thead>
        <tbody>
            <tr *ngFor="let softcode of this.NewSoftCodes">
                <td><label>{{softcode.description}}</label></td>
                <td style="text-align: center;"><input type="checkbox" [(ngModel)]="softcode.active" (change)="CheckSame()"></td>    
            </tr>
        </tbody>
    </table>
    <div>
        <div class="row">
            <div class="col" style="text-align: center;">
                <button class="btn btn-primary" (click)="this.SetSoftwareConfig()" [disabled]="this.Edited === false || (this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Utenti.Modifica)">Salva</button>
            </div>
        </div>
    </div>
</div>
