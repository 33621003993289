import { BsModalService } from 'ngx-bootstrap/modal';
import { Role, User } from './../../Models/User';
import { IsDev } from '../../global';

import { HttpClient, HttpParams } from '@angular/common/http';
import {HostListener, Injectable, OnDestroy} from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';

import Acquisto from 'src/app/Models/Acquisto';
import Cart from 'src/app/Models/Cart';
import { License, LicenseStat } from 'src/app/Models/LicenzeStat';
import {Contenitore} from 'src/app/shop/tipologialicenza/tipologialicenza.component';
import AcquistoPacchetto from '../../Models/AcquistoPacchetto';


import SoftwareListino from '../../Models/SoftwareListino';

import { AuthService } from '../AuthService/auth.service';
import {Router} from '@angular/router';
import { Payment } from 'src/app/Models/Payment';
import {UserStoreService} from '../Admin/user-store.service';
@Injectable({
  providedIn: 'root'
})

export class ShopService{
  //CHECKOUT
  public Stripe = false;
  public Paypal = false;
  public Bonifico = false;

  //ULTIMO DETTAGLIO MOSTRATO
  public lastSoftwareDetail:any;

  //PRODOTTI MOSTRATI
  public prodottiMostrati: SoftwareListino[] = [];

  //MOSTRA CARRELLO
  public ShowCarrello:boolean;

  //MOSTRA DROPDOWN CATEGORIE
  public ShowSceltaCategorie:boolean;

  //MOSTRA ACQUISTO PACCHETTI
  public ShowSelezioneQuantitaPacchetti: boolean = false;
  public ShowAcquistoPacchetti: boolean = false;

  //MOSTRA ACQUISTO SINGOLO
  public ShowAcquistoSingolo: boolean = false;

  //FLAG PERCORSO DI ACQUISTO

  public SectionSceltaElementi: boolean = false;
  public SectionCarrello: boolean = true;

  //FLAG CARRELLO
  public ErroreCarrello: boolean = false;
  public AlertSuccess: boolean = false;

  //Quantità scelta all'inizio dell'ordine
  public PacchettoScelto: number = 0;
  public LeftPacchettoScelto: number = 0;

  //Acquisto per pacchetto
  public Pacchetto: AcquistoPacchetto[] = [];

  //Selezione di un elemento
  public SoftwareSelezionato: SoftwareListino;
  public QuantitaSelezionato: number = 0;

  //MODAL
  public DettaglioProdotto: boolean = false;

  //SCEGLI TRA LICENZA SINGOLA O PACCHETTO DI LICENZE
  public ShowTipologiaLicenza: boolean;
  public ListinoSoftware: SoftwareListino[] = [];

  public Carrello: Contenitore[] = [];
  public CarrelloNonOrdinato: Contenitore[] = []; //CARRELLO GREZZO

  //STORICO
  public StoricoAcquisti: Acquisto[] = [];

  public IsInit: boolean = false;
  public lglt: boolean;

  //EVENTO CARRELLO VUOTO
  public CarrelloAperto: boolean = false;
  public mobileCart: boolean = false;

  public errorProductsNotFound:string;

  constructor(private http: HttpClient, private auth: AuthService, private alertService: AlertService, private router: Router, public UserStoreService: UserStoreService, private modalService: BsModalService) {

  }
  @HostListener('window:resize', ['$event'])
  setLtLg(event?): void{
    if (window.innerWidth < 1279){
      this.lglt = true;
    }else{
      this.lglt = false;
    }
  }
  public async Init() {
    this.setLtLg();
    if(this.lglt){
      this.mobileCart = true;
    } else{
      this.mobileCart = false;
    }
    await this.InitListino();
    await this.InitCart();

  }

  public async Logout() {
    this.IsInit = false;

    this.Carrello = [];
    this.StoricoAcquisti = [];

    //this.ListinoSoftware = [];

    this.CarrelloAperto = false;
  }

  public Reset() {
    this.Pacchetto = [];
    this.PacchettoScelto = 0;
    this.LeftPacchettoScelto = 0;

    /*this.PacchettoScelto = 0;
    this.LeftPacchettoScelto = 0;
    this.ShowTipologiaLicenza = true;
    this.SectionSceltaElementi = false;
    this.ShowAcquistoSingolo= false;
    this.ShowSelezionePacchetti = false;
    this.ShowAcquistoPacchetti = false;
    this.DettaglioProdotto = false;
    this.CarrelloAperto = false;*/
  }
  public async AggiuntaAlCarrelloProdottoSingolo(software: SoftwareListino, i:number) {
    let value = parseInt((<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value, 10);
    if (isNaN(value) || value < 1){
      this.alertService.danger('Perfavore inserisci una quantità corretta')
    }else{
      this.SoftwareSelezionato = software;
      this.QuantitaSelezionato = value;
      await this.AggiungiCarrelloTemp();
    }

  }

  // PASSO AL SERVIZIO, OLTRE CHE IL PRODOTTO, ANCHE LA QUANTITA, SINGOLA O PACCHETTO
  // IN QUESTO MODO, POSSO ESTRARRE LO SCONTO CORRETTO, CALCOLARLO ED INSERLO NEL CARRELLO
  public async AggiungiCarrelloTemp() {
    let Ordine: boolean = false;
    if (this.auth.AuthUser.Role === Role.USER) {
      Ordine = true;
    }

    //SE ORDINE, PRIMA DI AGGIUNGERE UN ELEMENTO CONTROLLO SE ESISTE UN CONTENITORE TALE CHE ESISTA IL SOFTWARE SELEZIONATO
    const contenitore: Contenitore = new Contenitore;
    contenitore.IdPacchettoCart = '_' + Math.random().toString(36).substr(2,9);

    //AGGIUNGO IL CONTENUTORE AL CARRELLO GREZZO
    const contenitoreTemp: Contenitore = Object.assign({}, contenitore);

    contenitoreTemp.Tipo = 'S'

    this.CarrelloNonOrdinato.push(contenitoreTemp);

    const successCarrello: boolean = await this.AggiungiCarrello(this.SoftwareSelezionato, this.QuantitaSelezionato, 1, contenitore, Ordine);
    if (successCarrello) {
      this.Carrello.push(contenitore);
    } else {
      this.alertService.danger("Impossibile aggiungere al carrello. Contatta il tuo distributore per maggiori informazioni")
      return;
    }
    //INSERISCO NEL DB
    const result: any = await this.http.post('/api/shop/cart/insert', {Contenuto: contenitore.Contenuto, IdPacchettoCart: contenitore.IdPacchettoCart, Tipo: contenitore.Tipo}, {}).toPromise();

    if (result.response === 'ok') {
      this.alertService.success('Elemento aggiunto al carrello');
      this.OrderCartS();
    }
  }
  getInputMax(left, total): number {
    return Number(total-left);
  }
  private async InitListino() {

    const result: any = await this.http.get('/api/shop/listino', {}).toPromise();

    this.ListinoSoftware = [];

    for (let i = 0; i < result.length; ++i) {

      const item: SoftwareListino = new SoftwareListino();
      item.CodiceArticolo = result[i].codiceArticolo;
      item.Descrizione = result[i].descrizione;
      item.Costo = result[i].costo;

      this.ListinoSoftware.push(item)

    }
  }


  public async InitCart() {
    const params: HttpParams = new HttpParams()
      .set("id", String(this.auth.AuthUser.ID))
      .set("username", this.auth.AuthUser.Username)
      .set("piva", this.auth.AuthUser.PIVA)


    const result: any = await this.http.get('/api/shop/cart', {params}).toPromise();

    this.Carrello = [];

    for (let i = 0; i < result.length; ++i) {

      const contenuto: Contenitore = new Contenitore();
      contenuto.Tipo = result[i].tipo;
      contenuto.IdPacchettoCart = result[i].idPacchettoCart;

      for (let j = 0; j < result[i].contenuto.length; ++j) {
        const acquisto: Acquisto = new Acquisto();
        acquisto.ID = result[i].contenuto[j].id;
        acquisto.Username = result[i].contenuto[j].username;
        acquisto.PIVA = result[i].contenuto[j].piva;
        acquisto.CodiceArticolo = result[i].contenuto[j].codiceArticolo;
        acquisto.ImgName =  result[i].contenuto[j].imgName;

        acquisto.Descrizione = result[i].contenuto[j].descrizione;

        acquisto.DataOrdine = result[i].contenuto[j].dataOrdine;
        acquisto.NumeroLicenze = result[i].contenuto[j].numeroLicenze;
        acquisto.PrezzoScontato = result[i].contenuto[j].prezzoScontato;
        acquisto.PrezzoTotale = result[i].contenuto[j].prezzoTotale;
        acquisto.PrezzoUnitario = acquisto.PrezzoTotale / acquisto.NumeroLicenze;

        contenuto.Contenuto.push(acquisto);
      }

      this.Carrello.push(contenuto);

      //SE IL CODICE ARTICOLO HA IL TERZ'ULTIMO ELEMENTO DIVERSO, CREO UN ARRAY NUOVO DENTRO LISTINOSOFTWARE
      //this.Carrello.push(item.ToAcquisto(this.ListinoSoftware))

    }
    this.CarrelloNonOrdinato = [...this.Carrello]; //CLONO IL CARRELLO

    this.OrderCartS();

    return;
  }

  /*
  public async InitStorico() {
    const params: HttpParams = new HttpParams()
    .set("id", String(this.auth.AuthUser.ID))
    .set("piva", this.auth.AuthUser.PIVA)
    .set("username", this.auth.AuthUser.Username);

    const result: any = await this.http.get('/api/shop/storico', {params}).toPromise();

    //LO STORICO E' ORDINATO PER BLOCCHI DI DATE
    this.StoricoAcquisti = [];

    for (let i = 0; i < result.length; ++i) {
      const acquisto: Acquisto = new Acquisto();
      acquisto.ID = result[i].id;
      acquisto.Username = result[i].username;
      acquisto.PIVA = result[i].piva;
      acquisto.NumeroLicenze = result[i].numeroLicenze;
      acquisto.CodiceArticolo = result[i].codiceArticolo;
      acquisto.Tipo = result[i].tipo;

      //LA FUNZIONE FIND NON FUNZIONA
      for (let j = 0; j < this.ListinoSoftware.length; ++j) {
        if (this.ListinoSoftware[j].CodiceArticolo === acquisto.CodiceArticolo) {
          acquisto.Descrizione = this.ListinoSoftware[j].Descrizione;
          break;
        }
      }

      acquisto.DataOrdine = result[i].dataOrdine;
      acquisto.DataOrdine = new Date(acquisto.DataOrdine);
      acquisto.PrezzoTotale = result[i].prezzoTotale;
      acquisto.PrezzoScontato = result[i].prezzoScontato;

      this.StoricoAcquisti.push(acquisto);
    }

    return;
  }
  */

  public GetCarrelloSize(): number {
    let size: number = 0;
    for (let i = 0; i < this.Carrello.length; ++i) {
      for (let j = 0; j < this.Carrello[i].Contenuto.length; ++j)
        size += this.Carrello[i].Contenuto[j].NumeroLicenze;
    }
    return size;
  }


  public ApriCarrello() {
    if (this.Carrello.length > 0) {
      this.router.navigate(['shop/cart']);
      this.SetPacchettoPrezzoTotale();
      this.SetCarrelloLicenzeTotali();
    } else {
      this.alertService.danger('Inserisci un elemento nel carrello per accedere');
    }
  }
  public async RemoveCarrello(contenitore: Contenitore) {
    if (contenitore.Tipo === 'P'){
      const params: HttpParams = new HttpParams()
        .set("id", contenitore.IdPacchettoCart);
      
      const result: any = await this.http.delete('/api/shop/cart/delete', {params}).toPromise();
      if (result.response === "ok") {
        this.Carrello.splice(this.Carrello.indexOf(contenitore), 1);
      }
    } else {


      for (let i = 0; i < this.CarrelloNonOrdinato.length; ++i) {
        if (this.CarrelloNonOrdinato[i].Tipo === 'S' && this.CarrelloNonOrdinato[i].Contenuto[0].CodiceArticolo === contenitore.Contenuto[0].CodiceArticolo) {
          const params: HttpParams = new HttpParams()
          .set("id", this.CarrelloNonOrdinato[i].IdPacchettoCart);

          const result: any = await this.http.delete('/api/shop/cart/delete', {params}).toPromise();

          if (result.response !== "ok") {
            this.alertService.danger("Impossibile rimuovere l'elemento");
          }
        }
      }

      await this.InitCart();
    }
  }
  public async ConfirmPacchetti() {
    if (this.LeftPacchettoScelto === this.PacchettoScelto) {
      const contenitore: Contenitore = new Contenitore;
      contenitore.Tipo = "P";
      contenitore.IdPacchettoCart = '_' + Math.random().toString(36).substr(2,9);


      for (let i = 0; i < this.Pacchetto.length; ++i) {
        const successCarrello: boolean = await this.AggiungiCarrello(this.Pacchetto[i].software, this.Pacchetto[i].quantita, this.Pacchetto[i].pacchetto, contenitore);

        //SE FALSE, ALERT E SVUOTAMENTO CARRELLO DEL PACCHETTO CHE HO PROVATO AD AGGIUNGERE
        if (!successCarrello) {
          this.alertService.danger("Impossibile aggiungere il pacchetto al carrello. Contatta il tuo distributore per maggiori informazioni");
          return;
        }
      }
      this.Carrello.push(contenitore);

      const result: any = await this.http.post('/api/shop/cart/insert', {Contenuto: contenitore.Contenuto, IdPacchettoCart: contenitore.IdPacchettoCart, Tipo: contenitore.Tipo}, {}).toPromise();

      if (result.response === 'ok') {
        this.alertService.success('Elementi aggiunto al carrello');
        this.LeftPacchettoScelto = 0;
      }

    } else {
      this.alertService.danger("Seleziona un numero di pacchetti corretto")
    }
  }


  //FUNZIONE STANDARD PER PULIRE IL CARRELLO. DA CHIAMARE PER LA VECCHIA GESTIONE DELLO STORE
  public async ClearCarrello() {
    const params: HttpParams = new HttpParams()
      .set("id", String(this.auth.AuthUser.ID))
      .set('piva', this.auth.AuthUser.PIVA)
      .set("username", this.auth.AuthUser.Username);

    const result: any = await this.http.delete('/api/shop/cart/clear', {params}).toPromise();

    if (result.response === "ok") {
      this.Carrello = []; //CARRELLO SVUOTATO
      this.alertService.warning("Carrello svuotato");

      //TORNO ALLO STORICO
      this.CarrelloAperto = false;
    }
  }

  //FUNZIONE CHE ORDINA, LATO FRONT-END, GLI ACQUISTI SINGOLI
  public OrderCartS() {
    const cartOrdinato: Contenitore[] = [];
    for (let i = 0; i < this.Carrello.length; ++i) {
      if (this.Carrello[i].Tipo === 'P') {
        const contenitore: Contenitore = Object.assign({}, this.Carrello[i]);
        cartOrdinato.push(contenitore);
      } else {
        //SE ESISTE UN ELEMENTO DENTRO IL CARRELLO DI TIPO S CON LO STESSO CODICE ARTICOLO, SOMMO SEMPLICEMENTE I VALORI
        let index: number = -1;

        for (let j = 0; j < cartOrdinato.length; ++j) {
          if (cartOrdinato[j].Contenuto[0].CodiceArticolo === this.Carrello[i].Contenuto[0].CodiceArticolo && cartOrdinato[j].Tipo === 'S' ) {
            index = j;
            break;
          }
        }
        if (index === -1) {
          const contenitore: Contenitore = Object.assign({}, this.Carrello[i]);
          cartOrdinato.push(contenitore);
        } else {
          cartOrdinato[index].Contenuto[0].NumeroLicenze += this.Carrello[i].Contenuto[0].NumeroLicenze;
          cartOrdinato[index].Contenuto[0].PrezzoTotale = cartOrdinato[index].Contenuto[0].NumeroLicenze * this.Carrello[i].Contenuto[0].PrezzoUnitario;
          cartOrdinato[index].Contenuto[0].PrezzoScontato = cartOrdinato[index].Contenuto[0].PrezzoTotale - ((cartOrdinato[index].Contenuto[0].PrezzoTotale * this.auth.AuthUser.Sconto.S) / 100);
          cartOrdinato[index].Contenuto[0].PrezzoScontato = cartOrdinato[index].Contenuto[0].PrezzoScontato - ((cartOrdinato[index].Contenuto[0].PrezzoScontato * this.auth.AuthUser.Sconto.SBonus) / 100)
        }
      }
    }

    this.Carrello = cartOrdinato;
  }


  public loading: boolean = false;

  public async ConfirmCarrello() {
    if (this.Carrello.length > 0) {

      //SE IL TIPO DI UTENTE E' USER, PASSO AL CHECKOUT, ALTRIMENTI SEMPLICEMENTE CONFERMO E DEFINISCO L'ACQUISTO COME ORDINARIO
      if (this.auth.AuthUser.Role === Role.USER) {
        this.router.navigate(['shop/checkout']);
        return;
      } else {
        const result = await this.ConfirmCarrelloAcquisto();
        if (result) {
          this.router.navigate(['home']);
          return;
        }
      }
    } else {
      this.alertService.danger("Il carrello è vuoto");
    }
  }

  //FUNZIONE ASSOCIATA ALL'ACQUISTO DA PARTE DI CONCESSIONARIO/DISTRIBUTORE
  public async ConfirmCarrelloAcquisto() {

    const result: any = await this.http.post('/api/shop/cart/confirm', 
    {id: this.auth.AuthUser.ID, piva: this.auth.AuthUser.PIVA, username: this.auth.AuthUser.Username, creatore: this.auth.AuthUser.Creatore}, {}).toPromise();

    if (result.response === "ok") {
      this.loading = false;
      this.Carrello = []; //CARRELLO SVUOTATO
      this.alertService.success("Carrello confermato");

      return true;
    } else {
      return false;
    }
  }


  public async AggiungiCarrello(softwareSelezionato: SoftwareListino, numeroLicenze: number, pacchettoScelto: number, contenitore: Contenitore, isOrdine: boolean = false) {
    const acquisto: Acquisto = new Acquisto();
    acquisto.ID = this.auth.AuthUser.ID;
    acquisto.Username = this.auth.AuthUser.Username;
    acquisto.PIVA = this.auth.AuthUser.PIVA;
    acquisto.RagSoc = this.auth.AuthUser.RagioneSociale;

    acquisto.CodiceArticolo = softwareSelezionato.CodiceArticolo;
    acquisto.Descrizione = softwareSelezionato.Descrizione;
    acquisto.ImgName = softwareSelezionato.ImgName;
    acquisto.DataOrdine = new Date();
    acquisto.NumeroLicenze = numeroLicenze;

    acquisto.PrezzoUnitario = softwareSelezionato.Costo;
    acquisto.PrezzoTotale = numeroLicenze * softwareSelezionato.Costo;

    if (pacchettoScelto === 1) {
      const prezzoScontato: number = acquisto.PrezzoTotale - ((acquisto.PrezzoTotale * this.auth.AuthUser.Sconto.S) / 100);
      const prezzoScontatoBonus: number = prezzoScontato - ((prezzoScontato * this.auth.AuthUser.Sconto.SBonus) / 100);

      acquisto.PrezzoScontato = prezzoScontatoBonus;
    }
    if (pacchettoScelto === 5) {
      const prezzoScontato: number = acquisto.PrezzoTotale - ((acquisto.PrezzoTotale * this.auth.AuthUser.Sconto.P.Cinque) / 100);
      const prezzoScontatoBonus: number = prezzoScontato - ((prezzoScontato * this.auth.AuthUser.Sconto.PBonus.Cinque) / 100);

      acquisto.PrezzoScontato = prezzoScontatoBonus;
    }
    if (pacchettoScelto === 10) {
      const prezzoScontato: number = acquisto.PrezzoTotale - ((acquisto.PrezzoTotale * this.auth.AuthUser.Sconto.P.Dieci) / 100);
      const prezzoScontatoBonus: number = prezzoScontato - ((prezzoScontato * this.auth.AuthUser.Sconto.PBonus.Dieci) / 100);

      acquisto.PrezzoScontato = prezzoScontatoBonus;
    }
    if (pacchettoScelto === 15) {
      const prezzoScontato: number = acquisto.PrezzoTotale - ((acquisto.PrezzoTotale * this.auth.AuthUser.Sconto.P.Quindici) / 100);
      const prezzoScontatoBonus: number = prezzoScontato - ((prezzoScontato * this.auth.AuthUser.Sconto.PBonus.Quindici) / 100);

      acquisto.PrezzoScontato = prezzoScontatoBonus;
    }
    if (pacchettoScelto === 20) {
      const prezzoScontato: number = acquisto.PrezzoTotale - ((acquisto.PrezzoTotale * this.auth.AuthUser.Sconto.P.Venti) / 100);
      const prezzoScontatoBonus: number = prezzoScontato - ((prezzoScontato * this.auth.AuthUser.Sconto.PBonus.Venti) / 100);

      acquisto.PrezzoScontato = prezzoScontatoBonus;
    }

    //PRIMA DI PUSHARE, CONTROLLO SE IL PROPRIO DISTRIBUTORE, A PARTE LA GAB, HA UN NUMERO DI LICENZE ALL'INTERNO DEL CANONE O ALL'INTERNO DELLE LICENZE ACQUISTATE
    //NEL CASO IN CUI NON CI SIANO LE LICENZE, NON PERMETTO DI AGGIUNGERE L'ELEMENTO AL CARRELLO

    //ACQUISTO CONCESSIONARIO/DISTRIBUTORE
    if (!isOrdine) {
      if (this.auth.AuthUser.Creatore !== 'gab') {
        const params: HttpParams = new HttpParams()
          .set("username", this.auth.AuthUser.Creatore)
          .set("id", String(this.auth.AuthUser.ID));

        const result: any = await this.http.get('/api/license/count2', {params}).toPromise();
        let statCanone: LicenseStat = new LicenseStat();
        let statAcquisto: LicenseStat = new LicenseStat();
        for (let i = 0; i < result.length; ++i) {
          if (result[i].codiceArticolo === "CANONE") {
            statCanone.CodiceArticolo === "CANONE";
            statCanone.NumeroLicenze = result[i].numeroLicenze;
            statCanone.LicenzeGenerate = result[i].licenzeGenerate;
          }

          if (result[i].codiceArticolo === acquisto.CodiceArticolo) {
            statAcquisto.CodiceArticolo === "CANONE";
            statAcquisto.NumeroLicenze = result[i].numeroLicenze;
            statAcquisto.LicenzeGenerate = result[i].licenzeGenerate;
          }
        }

        //SE IL MIO DISTRIBUTORE E' A CANONE ED HA UN NUMERO DI LICENZE INFINITO, POSSO AGGIUNGERE
        if (statCanone.NumeroLicenze !== 0 && statCanone.NumeroLicenze === -1) {

          for (let i = 0; i < contenitore.Contenuto.length; ++i) {
            if (contenitore.Contenuto[i].CodiceArticolo === acquisto.CodiceArticolo) {
              contenitore.Contenuto[i].NumeroLicenze += acquisto.NumeroLicenze;
              return true;
            }
          }

          contenitore.Contenuto.push(acquisto);
          return true;
        }
        //SE IL MIO DISTRIBUTORE E' A CANONE ED HA UN NUMERO DI LICENZE SUFFICENTE, POSSO AGGIUNGERE
        else if (statCanone.NumeroLicenze !== 0 && (statCanone.LicenzeGenerate + acquisto.NumeroLicenze + this.GetCarrelloSize() <= statCanone.NumeroLicenze)) {
          for (let i = 0; i < contenitore.Contenuto.length; ++i) {
            if (contenitore.Contenuto[i].CodiceArticolo === acquisto.CodiceArticolo) {
              contenitore.Contenuto[i].NumeroLicenze += acquisto.NumeroLicenze;
              return true;
            }
          }

          contenitore.Contenuto.push(acquisto);
          return true;
        }
        //LICENZE ACQUISTATE
        else if (statAcquisto.NumeroLicenze !== 0) {

          let count: number = 0;  //SOMMA DELLE LICENZE ALL'INTERNO DELL'ACQUISTO DA VALUTARE
          for (let i = 0; i < this.Carrello.length; ++i) {
            for (let j = 0; j < this.Carrello[i].Contenuto.length; ++j) {
              if (this.Carrello[i].Contenuto[j].CodiceArticolo === acquisto.CodiceArticolo) {
                count ++;
              }
            }
          }
          statAcquisto.LicenzeGenerate += count + acquisto.NumeroLicenze;

          //SE LE LICENZE SONO SUFFICIENTI, POSSO AGGIUNGERLE AL CARRELLO
          if (statAcquisto.LicenzeGenerate <= statAcquisto.NumeroLicenze) {
            for (let i = 0; i < contenitore.Contenuto.length; ++i) {
              if (contenitore.Contenuto[i].CodiceArticolo === acquisto.CodiceArticolo) {
                contenitore.Contenuto[i].NumeroLicenze += acquisto.NumeroLicenze;
                return true;
              }
            }

            contenitore.Contenuto.push(acquisto);
            return true;
          } else {
            return false;
          }

        } else {
          return false;
        }

      } else {
        for (let i = 0; i < contenitore.Contenuto.length; ++i) {
          if (contenitore.Contenuto[i].CodiceArticolo === acquisto.CodiceArticolo) {
            contenitore.Contenuto[i].NumeroLicenze += acquisto.NumeroLicenze;
            return true;
          }
        }

        contenitore.Contenuto.push(acquisto);
        return true;
      }
      //ACQUISTO UTENTE ORDINARIO
    } else {
      for (let i = 0; i < contenitore.Contenuto.length; ++i) {
        if (contenitore.Contenuto[i].CodiceArticolo === acquisto.CodiceArticolo) {
          contenitore.Contenuto[i].NumeroLicenze += acquisto.NumeroLicenze;
          return true;
        }
      }

      contenitore.Contenuto.push(acquisto);
      return true;
    }
  }

  public GetCarrelloTotal(): number{
    let prezzoTotale: number = 0;
    for (let i = 0; i < this.Carrello.length; ++i) {
      for (let j = 0; j < this.Carrello[i].Contenuto.length; ++j)
        prezzoTotale += this.Carrello[i].Contenuto[j].PrezzoTotale;
    }
    return prezzoTotale;
  }

  public SetPacchettoPrezzoTotale(){
    for (let i = 0; i < this.Carrello.length; ++i) {
      let prezzoTotale: number = 0;
      for (let j = 0; j < this.Carrello[i].Contenuto.length; ++j){
        prezzoTotale += this.Carrello[i].Contenuto[j].PrezzoTotale;
      }
      this.Carrello[i].PrezzoTotale = prezzoTotale;
    }
  }

  public SetCarrelloLicenzeTotali(): void{
    for (let i = 0; i < this.Carrello.length; ++i) {
      let licenzeTotali: number = 0;
      for (let j = 0; j < this.Carrello[i].Contenuto.length; ++j){
        licenzeTotali += this.Carrello[i].Contenuto[j].NumeroLicenze;
      }
      this.Carrello[i].LicenseTotali =  licenzeTotali;
    }

  }

  //FUNZIONE DA CHIAMARE PER IL PAGAMENTO CON BONIFICO!
  public async AcquistaOra(payment: Payment): Promise<boolean> {
    this.loading = true;
    const body: string = JSON.stringify(payment);
    const result: any = await this.http.post('/api/shop/confirm', body, {headers: {
        'Content-Type': 'application/json'
      }}).toPromise();

    if (result.response === "ok") {
      this.Carrello = [];
      return true;
    } else {
      return false;
    }
  }

  public RemovePacchetto(elemento: AcquistoPacchetto) {
    const quantita = elemento.quantita;
    this.Pacchetto.splice(this.Pacchetto.indexOf(elemento), 1);
    this.LeftPacchettoScelto -= quantita;

    if (this.Pacchetto.length === 0) {
      this.modalService.hide();
    }

  }
}
