import { GroupService } from './group.service';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Center } from 'src/app/Models/Cloud/Center';
import { SellPoint } from 'src/app/Models/Cloud/SellPoint';
import { SellpointsService } from './sellpoint.service';

@Injectable({
  providedIn: 'root'
})
export class CenterService {
  
  constructor(private http: HttpClient, private groupService: GroupService, private sellpointsService: SellpointsService) { }

  public async GetCentersById(groupId: number) {

    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    const params: HttpParams = new HttpParams()
    .set("id", String(groupId));

    const centers: Center[] = await lastValueFrom(this.http.get<Center[]>('/api/cloud/centers', {headers, params}))
    
    centers.forEach(async center => {
      center.sellPoints = await lastValueFrom(this.sellpointsService.GetSellPoint(center.id));
    })
    return centers;
  }

  public async EditCenter(center: Center) {

    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    const returnCenter: Center = await lastValueFrom(this.http.put<Center>('/api/cloud/centers', center, {headers}))

    return returnCenter;
  }

  public async CreateCenter(center: Center) {

    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    const returnCenter: Center = await lastValueFrom(this.http.post<Center>('/api/cloud/centers', center, {headers}))

    return returnCenter;
  }

  public async DeleteCenter(center: Center) {

    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    const params: HttpParams = new HttpParams()
    .set("id", String(center.id))
    .set("groupId", String(center.group.id));

    return await lastValueFrom(this.http.delete<boolean>('/api/cloud/centers', {headers, params}))
  }

}
