import { TickOptions } from "chart.js";

export class Payment {
    public ID_ut: number;
    public Username_ut: string;
    public Piva_ut: string;
    public Data: Date;
    public Totale: number;
    public Tipo: Tipo;
    public CodiceOrdine: string = "";

    public constructor(id: number, username: string, piva: string, quantita: number, tipo: Tipo) {
        this.ID_ut = id;
        this.Username_ut = username;
        this.Piva_ut = piva;
        this.Totale = quantita;
        this.Data = new Date();
        this.Tipo = tipo;

        const chars: string = "0123456789";
        for (let i = 0; i < 8; ++i) {
            this.CodiceOrdine += chars.charAt(Math.floor(Math.random() * chars.length));
        }
    }

    public DateToString() {
        const date: Date = new Date(this.Data);

        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    }

    public TipoToString() {
        switch(this.Tipo) {
            case Tipo.CARTA: {
                return "CARTA";
            }
            case Tipo.BONIFICO: {
                return "BONIFICO";
            }
            case Tipo.PAYPAL: {
                return "PAYPAL";
            }
        }
    }


}

export enum Tipo {
    CARTA, BONIFICO, PAYPAL
}