<div class="card">
    <div class="card-header">
      <p class="card-title"><b>Censimento</b></p>
    </div>
    <div class="card-body" *ngIf="censimentoInfo.esiste">
      <p>Partita IVA: <b>{{censimentoInfo.PIVA}}</b></p>
      <p>Ragione Sociale: <b>{{censimentoInfo.RAGSOC}}</b></p>
      <p>NMAG: <b>{{censimentoInfo.NMAG}}</b></p>
      <p>NCASSA: <b>{{censimentoInfo.NCASSA}}</b></p>
      <p>
        MAC Address: <b><input type="text" [disabled]="!editMacAddress" [(ngModel)]="censimentoInfo.MAC" (ngModelChange)="CheckMacAddress()"></b>&nbsp;&nbsp;
        <a role="button">
          <fa-icon [icon]="faPen" (click)="editMacAddress = true" *ngIf="!editMacAddress"></fa-icon>

          <fa-icon [icon]="faSave" (click)="SaveModalMACAddress()" *ngIf="editMacAddress" [class.fa-disabled]="!canSave"></fa-icon>
        </a>
      </p>
    </div>
    <div class="card-body" *ngIf="!censimentoInfo.esiste">
      <p>La seguente licenza non è associata a nessun MAC Address</p>
    </div>
    <div class="card-footer" style="text-align: center;">
      <button class="btn btn-primary" (click)="this.modalService.hide()">Chiudi</button>
    </div>
  </div>