<!-- HEADER-->
<nav class="navbar navbar-default"  role="navigation" style="padding: 0" >
  <!-- Brand and toggle get grouped for better mobile display -->
  <div fxLayout="row" class="headerrowone" style="width: 100%" *ngIf="!this.ShopService.mobileCart">
    <div>
      <a role="button" class="btn btn-primary" style="color: white;margin:6px; width: 150px; " (click)="Indietro()" *ngIf="!(auth.AuthUser.Role === 2 && ShopService.ShowAcquistoSingolo)">Indietro</a>
    </div>
    <div style="float: right;width: 100%" *ngIf="!this.ShopService.mobileCart">
      <a>
        <div class="shopping-item " style="cursor: pointer" (click)="this.ShopService.ApriCarrello()">
          <a>Totale carrello - <span class="cart-amunt">{{this.ShopService.GetCarrelloTotal()}}€&nbsp;&nbsp;</span> <i class="fa fa-shopping-cart"></i> <span class="product-count">&nbsp;{{this.ShopService.GetCarrelloSize()}}</span></a>
        </div>
      </a>
    </div>
  </div>
  <div fxLayout="row" class="headerrowtwo"  *ngIf="(ShopService.ShowAcquistoPacchetti || ShopService.ShowAcquistoSingolo) && !this.ShopService.DettaglioProdotto && !this.ShopService.mobileCart">
    <div fxFlex="20%" style="margin-left: 6px;">
      <div *ngIf="ShopService.ShowAcquistoPacchetti && !ShopService.mobileCart ">
        <a role="button" class="btn btn-primary btn-block shop-buttons" (click)="(ShopService.Pacchetto.length > 0) ? this.modalService.show(pacchetto) : ''">
          Ordine: {{this.ShopService.LeftPacchettoScelto}}/{{this.ShopService.PacchettoScelto}}
        </a>
      </div>
    </div>
    <div fxFlex="30%">
      <a role="button" class="btn btn-success btn-block shop-buttons" *ngIf="ShopService.ShowAcquistoPacchetti && !ShopService.mobileCart " style="color:white;" (click)="this.ShopService.ConfirmPacchetti()">Aggiungi al Carrello</a>
    </div>
    <div fxFlex="50%" >
      <div class="container" *ngIf="ShopService.ShowSceltaCategorie && !ShopService.DettaglioProdotto && (ShopService.ShowAcquistoSingolo || ShopService.ShowAcquistoPacchetti) && !ShopService.mobileCart">
        <div class="tutorial" style="margin-right: 6px">
          <ul>
            <li *ngFor="let categoria of this.categorie" >
              <ng-container *ngIf="((categoria.Visibile !== 0 && auth.AuthUser.Role === 2) || (categoria.Visibile !== 1 && auth.AuthUser.Role !== 2)) && categoria.Visibile !== 3">
                <a (click)="goToCategoria(categoria.ID)"> {{categoria.Nome}}</a> <i class="fa fa-angle-down"></i>
                <ul>
                  <ng-container *ngFor="let sottocategoria of categoria.SottoCategorie">
                    <li *ngIf="((sottocategoria.Visibile !== 0 && auth.AuthUser.Role === 2) || (sottocategoria.Visibile !== 1 && auth.AuthUser.Role !== 2)) && sottocategoria.Visibile !== 3" [id] ="sottocategoria.ID" (click)="goToSottocategoria(sottocategoria.ID)">{{sottocategoria.Nome}}</li>
                  </ng-container>
                </ul>
              </ng-container>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div style="width: 100%" fxLayout="row" *ngIf="this.ShopService.mobileCart && ShopService.ShowSceltaCategorie && !ShopService.DettaglioProdotto && (ShopService.ShowAcquistoSingolo || ShopService.ShowAcquistoPacchetti)">
    <button class="btn btn-primary" style="width: 100%;margin: 0 !important;" (click)="openCategorie()">CATEGORIE</button>
  </div>
  <div style="width: 100%;" *ngIf="this.categoriaMobile && ShopService.ShowSceltaCategorie && !ShopService.DettaglioProdotto && (ShopService.ShowAcquistoSingolo || ShopService.ShowAcquistoPacchetti)">
      <ul style="list-style-type: none">
        <li class="nav__menu" *ngFor="let categoria of this.categorie">

            <h5 *ngIf="((categoria.Visibile !== 0 && auth.AuthUser.Role === 2) || (categoria.Visibile !== 1 && auth.AuthUser.Role !== 2)) && categoria.Visibile !== 3" class="titoli-categorie" (click)="goToCategoria(categoria.ID)"><b>{{categoria.Nome}} </b><span *ngIf="categoria.SottoCategorie.length > 0"><img src="assets/img/down-arrow.png" height="10px" width="10px" (click)="openSottoCategorie(categoria.ID)"/></span></h5>

          <ul class="categorieMobile" style="list-style-type: none;display: none" [id]="categoria.ID">
            <ng-container *ngFor="let sottocategoria of categoria.SottoCategorie">
              <li *ngIf="((sottocategoria.Visibile !== 0 && auth.AuthUser.Role === 2) || (sottocategoria.Visibile !== 1 && auth.AuthUser.Role !== 2)) && sottocategoria.Visibile !== 3" class="sottoCategorieMobile" [id] = "sottocategoria.ID" (click)="goToSottocategoria(sottocategoria.ID)">{{sottocategoria.Nome}}</li>
            </ng-container>
          </ul>
        </li>
      </ul>
    </div>
  <!-- Collect the nav links, forms, and other content for toggling -->
</nav>

<!--<div fxLayout="row" fxLayoutGap="16px" style="width: 100%">


  <div class="categorie">
    <div class="nav" *ngIf="ShopService.ShowSceltaCategorie" >
      <ul class="nav__list">
        <li class="nav__menu" *ngFor="let categoria of this.categorie">
          <ul class="nav__menu-lists nav__menu--1-lists"> <b (click)="goToCategoria(categoria.ID)" >{{categoria.Nome}}</b>
            <li class="nav__menu-items" *ngFor="let sottocategoria of categoria.SottoCategorie" [id] = "sottocategoria.ID" (click)="goToSottocategoria(sottocategoria.ID)">{{sottocategoria.Nome}}</li>

          </ul>
        </li>
      </ul>
    </div>
    <div class="nav" *ngIf="ShopService.ShowSceltaCategorie">
      <ul class="nav__list">
        <li class="nav__menu" *ngFor="let categoria of this.userStoreService.listino">
          <ul class="nav__menu-lists nav__menu--1-lists"> <b (click)="goToCategoria(categoria.ID)" >{{categoria.Nome}}</b>
            <li class="nav__menu-items" *ngFor="let sottocategoria of categoria.SottoCategorie" [id] = "sottocategoria.ID" (click)="goToSottocategoria(sottocategoria.ID)">{{sottocategoria.Nome}}</li>
          </ul>
        </li>

      </ul>
    </div>
  </div>

  <div fxFlex="25%">

  </div>
</div>-->


<ng-template #pacchetto>
  <div class="modal-body">
      <div class="container">
          <div class="controls">
              <table class="table table-hover table-striped">
                  <thead>
                      <tr>
                          <th>Codice Articolo</th>
                          <th>Descrizione</th>
                          <th>Costo</th>
                          <th>Quantità</th>
                          <th>Elimina</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let elemento of this.ShopService.Pacchetto">
                          <td>{{elemento.software.CodiceArticolo}}</td>
                          <td>{{elemento.software.Descrizione}}</td>
                          <td>{{elemento.software.Costo}}€</td>
                          <td>{{elemento.quantita}}</td>
                          <td>
                            <a role="button"><fa-icon [icon]="faTrash" size="2x" (click)="this.ShopService.RemovePacchetto(elemento)"></fa-icon></a>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
  <div class="modal-footer">
      <div class="btn btn-danger center" style="margin-left: auto; margin-right: auto;" (click)="this.modalService.hide()">Chiudi</div>
  </div>
</ng-template>
