import { License } from './../../Models/LicenzeStat';
import { AuthService } from './../../Services/AuthService/auth.service';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions, } from 'ngx-bootstrap/modal';

import {faInfo, faCog, faPlus, faMinus, faCircle, faWrench, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { UserService } from '../../Services/UserService/user.service';
import { LicenseStat } from 'src/app/Models/LicenzeStat';
import { User } from 'src/app/Models/User';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  constructor(public auth: AuthService, public modalService: BsModalService, public UserService: UserService) { }

  @Output() listChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() LoadUsers: EventEmitter<User[]> = new EventEmitter();

  @Input()
  public Users: User[];
  public Segnalatori: User[] = [];

  public UserType = {
    Utente: false,
    Segnalatore: false,
    Distributore: false,
    Concessionario: false,
    Admin: false
  };

  public errors: any[] = [];  //LISTA ERRORI FORM

  public modalRef: BsModalRef;

  //LICENZE
  public licenzeStats: LicenseStat[] = [];

  //ICONS
  public faCog: IconDefinition = faCog;

  public loading: boolean = false;

  //VARIABILI FORM
  public userInfo: UserInfo = new UserInfo(); //MAIN
  public Admin: boolean = false;
  public Settings = {
    Tipo: {
      singolo: false,
      pacchetti: false,
      canone: false
    },
    Sconto: {
      S: 40,
      SBonus: 0,
      P: {
        Cinque: 50,
        Dieci: 50,
        Quindici: 50,
        Venti: 50
      },
      PBonus: {
        Cinque: 0,
        Dieci: 2,
        Quindici: 5,
        Venti: 8
      },
      C: 55,
      CBonus: 0,
    },
    ScontoCloud: {
      PrimoScaglione: {
        SX: 1,
        DX: 20,
        PrimoAnno: 20,
        PrimoAnnoBonus: 0,
        SecondoAnno: 15,
        SecondoAnnoBonus: 0,
      },
      SecondoScaglione: {
        SX: 21,
        DX: 50,
        PrimoAnno: 20,
        PrimoAnnoBonus: 0,
        SecondoAnno: 15,
        SecondoAnnoBonus: 2,
      },
      TerzoScaglione: {
        SX: 51, 
        DX: -1,
        PrimoAnno: 20,
        PrimoAnnoBonus: 0,
        SecondoAnno: 15,
        SecondoAnnoBonus: 5,
      }
    },
    ScontoRinnovo: 50,
    ScontoModuli: 50,
    NumeroPacchetti: "15", //NUMERO PACCHETTI CANONE
    Canone: 0,  //VALORE CANONE
    SoftwareAbb: {
      VisualFood: false,
      VisualShop: false,
      VisualRetail: false,
      VisualSelf: false,
    }
  }

  public SettingsSegnalatore = {
    Software: true,
    Hardware: false,
    VenditaSoftware: 5,
    RinnovoSoftware: 5,
    VenditaHardware: 5
  }

  public Segnalatore: string = this.auth.AuthUser.Username;

  public SettingsPermessi = {
    Licenze: {
      Generazione: true, //GENERAZIONE NUOVA LICENZA
      Upgrade: true, //AGGIORNAMENTO LICENZA
      Stato: true, //MODIFICA STATO LICENZA
      Rinnovo: true //RINNOVO LICENZA
    },
    Shop: {
      Inserimento: true,
      Modifica: true //VISUALIZZAZIONE GESTIORE STORE E INTERAZIONE CON QUEST'ULTIMO
    },
    Manuali: {
      Inserimento: true,
      Eliminazione: true,
    },
    Listini: {
      Inserimento: true,
      Eliminazione: true,
    },
    Aggiornamenti: {
      Inserimento: true,
      Eliminazione: true,
    },
    Utenti: {
      Creazione: true,
      Modifica: true,
      Eliminazione: true
    },
    Censimenti: {
      Aggiornamento: true,
      Eliminazione: true
    }
  }

  private PermessiString: string = "";

  //ERRORI
  public ErrorSoftware: boolean = false;
  public ErrorContatotoreCanone: boolean = false;
  public ErroreCreazione: boolean = false;
  public ErroreCloud: boolean = false;

  //ALERT
  public alertOpenSuccess: boolean = false;
  public alertOpen: boolean = false;
  public alertMsg: string = "";

  async ngOnInit() {
    await this.FilterSegnalatori();

    if ((this.auth.AuthUser.Role === 1 || this.auth.AuthUser.Role2 === 1) && this.auth.AuthUser.Role !== 0 && this.auth.AuthUser.Role !== 4 && this.auth.AuthUser.Role2 !== 0 && this.auth.AuthUser.Role2 !== 4) {
      this.UserType.Utente = true;
    }

  }


  public async FilterSegnalatori() {
    for (let i = 0; i < this.Users.length; ++i) {
      if (this.Users[i].Role === 3 || this.Users[i].Role2 === 3) {
        this.Segnalatori.push(this.Users[i]);
      }
    }
  }

  public CheckError(key: string): boolean {
    for (let i = 0; i < this.errors.length; ++i) {
      if (this.errors[i].key === key) {
        return true;
      }
    }
    return false;
  }

  public GetError(key: string): string {
    for (let i = 0; i < this.errors.length; ++i) {
      if (this.errors[i].key === key) {
        return this.errors[i].value;
      }
    }
  }

  public OpenModalConfig(template: TemplateRef<any>) {
    const config: ModalOptions = {
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-lg'
    };

    this.modalRef = this.modalService.show(template, config);
  }

  public SelectTypeOfUser(tipo: string, template: TemplateRef<any>) {

    if (tipo !== 'Utente') {
      if (this.UserType.Utente === true) {
        this.UserType.Utente = false;
        const input = document.getElementsByName("checkRiv");

        for(let i = 0; i < input.length; ++i) {
          const inputValue: HTMLInputElement = (input[i] as HTMLInputElement);

          if (inputValue.value === 'Utente') {
            inputValue.checked = false;
          }
        }
      }
    }

    if (tipo === 'Segnalatore') {
      this.UserType.Segnalatore = !this.UserType.Segnalatore
    }
    if (tipo === 'Distributore') {
      this.UserType.Distributore = !this.UserType.Distributore

      /*
      if (this.UserType.Distributore) {
        this.OpenModalConfig(template, 'Distributore');
      }
      */

    }
    if (tipo === 'Concessionario') {
      this.UserType.Concessionario = !this.UserType.Concessionario

      /*
      if (this.UserType.Concessionario) {
        this.OpenModalConfig(template, 'Concessionario');
      }
      */
    }
    if (tipo === 'Utente') {
      if (this.UserType.Utente === false) {
        this.UserType.Utente = true;

        //DISABILITO LE ALTRE CHECKBOX E RESETTO I VALORI DI USERTYPE
        this.UserType.Concessionario = false;
        this.UserType.Distributore = false;
        this.UserType.Segnalatore = false;
        this.UserType.Admin = false;

        const input = document.getElementsByName("checkRiv");

        for(let i = 0; i < input.length; ++i) {
          const inputValue: HTMLInputElement = (input[i] as HTMLInputElement);

          if (inputValue.value !== 'Utente') {
            inputValue.checked = false;
          }
        }
      } else {
        this.UserType.Utente = false;
      }
    }
  }

  public async CreateUser() {
    const formData: FormData = new FormData();
    this.errors = [];
    //TIPO ANONIMO

    //USERNAME
    this.userInfo.username = (document.getElementById('username') as HTMLInputElement).value;
    formData.append('username', this.userInfo.username);
    if (this.userInfo.username.length === 0) {
      this.errors.push({
        key: "username", value: "Inserisci username"
      })
    }
    else if (this.userInfo.username.length < 3) {
      this.errors.push({
        key: "username", value: "Nome Utente lungo almeno 3"
      })
    } else if (this.userInfo.username.includes(" ")) {
      this.errors.push({
        key: "username", value: "Il Nome Utente non può contenere spazi"
      })
    }
      else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'username')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //PASSWORD
    this.userInfo.password = (document.getElementById('psw') as HTMLInputElement).value;
    formData.append('password', this.userInfo.password);

    if (this.userInfo.password.length === 0) {
      this.errors.push({
        key: "password", value: "Inserisci password"
      })
    }
    else if (this.userInfo.password.length <= 5) {
      this.errors.push({
        key: "password", value: "La password deve essere lunga 6"
      })
    }
    else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'password')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //PIVA
    this.userInfo.piva = (document.getElementById('piva') as HTMLInputElement).value;
    formData.append('piva', this.userInfo.piva);
    if (this.userInfo.piva.length === 0 ) {
      this.errors.push({
        key: "piva", value: "Inserisci partita IVA"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'piva')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //RAGIONESOCIALE
    this.userInfo.rsociale = (document.getElementById('rsociale') as HTMLInputElement).value;
    formData.append('ragionesociale', this.userInfo.rsociale);

    if (this.userInfo.rsociale.length === 0) {
      this.errors.push({
        key: "ragionesociale", value: "Inserisci ragione sociale"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'ragionesociale')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //CITTA
    this.userInfo.citta = (document.getElementById('citta') as HTMLInputElement).value;
    formData.append('citta', this.userInfo.citta);

    if (this.userInfo.citta.length === 0) {
      this.errors.push({
        key: "citta", value: "Inserisci città"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'citta')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //VIA
    this.userInfo.via = (document.getElementById('via') as HTMLInputElement).value;
    formData.append('via', this.userInfo.via);
    if (this.userInfo.via.length === 0) {
      this.errors.push({
        key: "via", value: "Inserisci via"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'via')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }


    //EMAIL
    this.userInfo.email = (document.getElementById('email') as HTMLInputElement).value;
    formData.append('email', this.userInfo.email);
    if (this.userInfo.email.length === 0) {
      this.errors.push({
        key: "email", value: "Inserisci e-mail"
      })
    }
    else if (!this.userInfo.email.includes('@') || this.userInfo.email.includes(' ')) {
      this.errors.push({
        key: "email", value: "Inserire e-mail nel formato valido"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'email')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //PEC
    this.userInfo.pec = (document.getElementById('pec') as HTMLInputElement).value;
    formData.append('pec', this.userInfo.pec);
    if ((!this.userInfo.pec.includes('@') || this.userInfo.pec.includes(' ')) && this.userInfo.pec.length > 0) {
      this.errors.push({
        key: "pec", value: "Inserire PEC nel formato valido"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'pec')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    //SDI
    this.userInfo.sdi = (document.getElementById('sdi') as HTMLInputElement).value;
    formData.append('sdi', this.userInfo.sdi);
    if (this.userInfo.sdi.length === 0) {
      this.errors.push({
        key: "sdi", value: "Inserisci codice SDI"
      })
    }
    else if (this.userInfo.sdi.length != 7) {
      this.errors.push({
        key: "sdi", value: "Lunghezza SDI errata"
      })
    } else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'sdi')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }


    //IBAN
    this.userInfo.iban = (document.getElementById('iban') as HTMLInputElement).value;
    formData.append('iban', this.userInfo.iban);


    //TIPO UTENTE
    let role: string = "";
    //ORDINE: DISTRIBUTORE, CONCESSIONARIO, SEGNALATORE, UTENTE
    let roles: number = 0;

    
    if (this.UserType.Distributore) {
      role += "Distributore|";
      roles++;
    }

    if (this.UserType.Concessionario) {
      role += "Concessionario|";
      roles++;
    }

    if (this.UserType.Segnalatore) {
      role += "Segnalatore|";
      roles++;
    }

    if (this.UserType.Utente) {
      role += "Utente|";
      roles++;
    }

    if (this.UserType.Admin) {
      role += "Admin|";
      roles++;
    }
    

    formData.append('role', role.substring(0, role.length - 1));

    if (roles > 2) {
      this.errors.push({
        key: "roles", value: "Seleziona al massimo due ruoli"
      })
    } else if (roles === 0) {
      this.errors.push({
        key: "roles", value: "Seleziona almeno un ruolo"
      })
    }
    else {
      //SE ESISTE, LO ELIMINO DALLA LISTA
      const index: number = this.errors.findIndex((error: any) => error.key === 'roles')
      if (index != -1) {
        this.errors.splice(index, 1);
      }
    }

    if (this.UserType.Concessionario || this.UserType.Distributore) {

      let tipo: string = "";

      if (this.Settings.Tipo.singolo) {
        tipo += 'S';
      }

      if (this.Settings.Tipo.pacchetti) {
        tipo += 'P';
      }

      //PACCHETTI

      if (this.Settings.Tipo.canone ) {
        tipo += 'C';

        formData.append('numeroPacchetti', this.Settings.NumeroPacchetti)


        formData.append('canone', String(this.Settings.Canone));

        //Software abbinati
        let softwareAbb: string = "";
        let totChecked: number = 0;
        if (this.Settings.SoftwareAbb.VisualFood) {
          totChecked++;
          softwareAbb += 'vfood|'
        }
        if (this.Settings.SoftwareAbb.VisualShop) {
          totChecked++;
          softwareAbb += 'vshop|'
        }
        if (this.Settings.SoftwareAbb.VisualSelf) {
          totChecked++;
          softwareAbb += 'vself|'
        }
        if (this.Settings.SoftwareAbb.VisualRetail) {
          totChecked++;
          softwareAbb += 'vretail|'
        }
        formData.append('software', softwareAbb.substring(0, softwareAbb.length - 1));
      }
      formData.append('tipo', tipo)

      if (tipo === '') this.ErroreCreazione = true;
      else this.ErroreCreazione = false;

      //SCONTO
      let sconto: string = "";
      //SINGOLO, SE SELEZIONATO
      if(this.Settings.Tipo.singolo) {
        sconto += "S:" + this.Settings.Sconto.S + "+" + this.Settings.Sconto.SBonus;

        sconto += '-';
      }
      //PACCHETTI, SE SELEZIONATO
      if(this.Settings.Tipo.pacchetti) {
        sconto += "P:";
        //5
        sconto += "5&" + this.Settings.Sconto.P.Cinque + "+" + this.Settings.Sconto.PBonus.Cinque + "|";
        //10
        sconto += "10&" + this.Settings.Sconto.P.Dieci + "+" + this.Settings.Sconto.PBonus.Dieci + "|";
        //15
        sconto += "15&" + this.Settings.Sconto.P.Quindici + "+" + this.Settings.Sconto.PBonus.Quindici + "|";
        //20
        sconto += "20&" + this.Settings.Sconto.P.Venti + "+" + this.Settings.Sconto.PBonus.Venti;

        sconto += '-';
      }
      //CANONE, SE SELEZIONATO
      if(this.Settings.Tipo.canone) {
        sconto += "C:" + this.Settings.Sconto.C + "+" + this.Settings.Sconto.CBonus;
      }
      formData.append('sconto', sconto)

      //SCONTO RINNOVO
      formData.append('scontospecial', String(this.Settings.ScontoRinnovo) + '|' + String(this.Settings.ScontoModuli));

      let scontoCloud: string = `${this.Settings.ScontoCloud.PrimoScaglione.SX}:${this.Settings.ScontoCloud.PrimoScaglione.DX}>${this.Settings.ScontoCloud.PrimoScaglione.PrimoAnno}+${this.Settings.ScontoCloud.PrimoScaglione.PrimoAnnoBonus}|${this.Settings.ScontoCloud.PrimoScaglione.SecondoAnno}+${this.Settings.ScontoCloud.PrimoScaglione.SecondoAnnoBonus}&`;
      scontoCloud += `${this.Settings.ScontoCloud.SecondoScaglione.SX}:${this.Settings.ScontoCloud.SecondoScaglione.DX}>${this.Settings.ScontoCloud.SecondoScaglione.PrimoAnno}+${this.Settings.ScontoCloud.SecondoScaglione.PrimoAnnoBonus}|${this.Settings.ScontoCloud.SecondoScaglione.SecondoAnno}+${this.Settings.ScontoCloud.SecondoScaglione.SecondoAnnoBonus}&`;
      scontoCloud += `${this.Settings.ScontoCloud.TerzoScaglione.SX}:${this.Settings.ScontoCloud.TerzoScaglione.DX}>${this.Settings.ScontoCloud.TerzoScaglione.PrimoAnno}+${this.Settings.ScontoCloud.TerzoScaglione.PrimoAnnoBonus}|${this.Settings.ScontoCloud.TerzoScaglione.SecondoAnno}+${this.Settings.ScontoCloud.TerzoScaglione.SecondoAnnoBonus}`;
      
      formData.append('scontocloud', scontoCloud);

    }


    // PERMESSI ADMIN
    if (this.UserType.Admin) {
      formData.append("permessi", this.PermessiString);
    }

    // PROVVIGIONI SEGNALATORE
    if (this.UserType.Segnalatore) {
      let provvigioni = "";
      provvigioni += (this.SettingsSegnalatore.Software ? (String(this.SettingsSegnalatore.VenditaSoftware) + "|" + String(this.SettingsSegnalatore.RinnovoSoftware)) : "-1|-1");
      provvigioni += "|";
      provvigioni += (this.SettingsSegnalatore.Hardware ? (String(this.SettingsSegnalatore.VenditaHardware)) : "-1");

      //let provvigioni: string = String(this.SettingsSegnalatore.VenditaSoftware) + "|" + String(this.SettingsSegnalatore.RinnovoSoftware) + "|" + String(this.SettingsSegnalatore.VenditaHardware);
      formData.append("provvigioni", provvigioni);
    }

    // SEGNALATORE ASSOCIATO
    formData.append("segnalatore", this.Segnalatore);

    let checkboxs = document.getElementsByName('checkServizio');
    let softwareAbb = "";
    for (let k = 0; k < checkboxs.length; ++k) {
      if ((checkboxs[k] as HTMLInputElement).checked) {
        softwareAbb += (checkboxs[k] as HTMLInputElement).value + "|";
      }
    }
    formData.append('servizi', softwareAbb.substring(0, softwareAbb.length - 1));

    formData.append("creatore", this.auth.AuthUser.Username);

    const id = await this.auth.GetIdByUsername();
    formData.append("idCreatore", String(id));

    if (this.ErrorSoftware) {

    }

    //OGNI CAMPO E' STATO INSERITO CORRETTAMENTE
    else if (this.errors.length === 0 && !this.ErroreCreazione) {

      this.loading = true;
      
      const result = await this.UserService.CreateUser(formData);

      if (result) {
        this.alertMsg = "Account creato con successo";
        this.alertOpenSuccess = true;
        setTimeout(async () => {
          this.alertOpenSuccess = false;
          this.loading = false;
          //AGGIORNO LA LISTA ALL'INTERNO DEL COMPONENTE PADRE
          const users: User[] = await this.UserService.LoadUsers(this.auth.AuthUser.Username);
          this.LoadUsers.emit(users);
          this.listChanged.emit(true);
        }, 3000);
      } else {
        this.alertMsg = "Username o PIVA già esistenti, riprova";
        this.alertOpen = true;
        setTimeout(() => {
          this.loading = false;
          this.alertOpen = false;
        }, 5000)
      }
    }

  }

  public CloseModalConfig() {
    //CONTROLLO 3 SOFTWARE
    //CONTROLLO PRESENZA CAMPI
    //SE CI STA UN ERRORE, NON CHIUDO, ALTRIMENTI CHIUDO

    if (this.UserType.Concessionario || this.UserType.Distributore) {
      let error: number = 0;
      this.ErrorSoftware = false;
      this.ErrorContatotoreCanone = false;

      //SOFTWARE ABBINATI
      let countSoftware: number = 0;
      if (this.Settings.SoftwareAbb.VisualFood === true) countSoftware++
      if (this.Settings.SoftwareAbb.VisualRetail === true) countSoftware++
      if (this.Settings.SoftwareAbb.VisualSelf === true) countSoftware++
      if (this.Settings.SoftwareAbb.VisualShop === true) countSoftware++

      if ((countSoftware > 3 || countSoftware === 0) && this.Settings.Tipo.canone && !this.UserType.Distributore && this.UserType.Concessionario) error = 1;

      if (countSoftware === 0 && this.Settings.Tipo.canone && this.UserType.Distributore) error = 1;

      //CONTROLLO
      //SE NON E' LA GAB A CREARE L'UTENTE
      //SE IL DISTRIBUTORE CHE CREA L'UTENTE HA UN NUMERO DI LICENZE LIMITATO, COME A CANONE
      //POSSO CREARE UN UTENTE A CANONE SOLO SE SONO UN DISTRIBUTORE A CANONE

      if (this.auth.AuthUser.Username !== 'gab' && this.auth.AuthUser.NumeroLicenzePacchetto !== -1 && this.Settings.Tipo.canone) {
        //STATISTICHE LICENZE A CANONE DELL'UTENTE LOGGATO
        const stat: LicenseStat = this.licenzeStats.find(stat => stat.CodiceArticolo === "CANONE");

        //SE LE LICENZE SCELTE SONO MAGGIORI DELLE LICENZE DISPONIBILI, ERRORE
        if (Number(this.Settings.NumeroPacchetti) === -1 && stat.NumeroLicenze !== -1) {
          error = 2;
        }
        else if (Number(this.Settings.NumeroPacchetti) > (stat.NumeroLicenze - stat.LicenzeGenerate)) {
          error = 2;
        }
      }

      if (error === 1) {
        this.ErrorSoftware = true;
      } else if (error === 2) {
        this.ErrorContatotoreCanone = true;
      } else {
        this.ErrorSoftware = false;
        this.modalRef.hide();
        return;
      }
    }
    this.modalRef.hide();
    return;
  }

  public AdminCreation() {
    this.Admin = !this.Admin;
    
    //NEL MOMENTO IN CUI IMPOSTO LA CREAZIONE DI UN UTENTE ORDINARIO, RESETTO LE SUE IMPOSTAZIONI
    if (this.Admin) {
      (document.getElementById('username') as HTMLInputElement).value = '';
      (document.getElementById('psw') as HTMLInputElement).value = '';
      (document.getElementById('piva') as HTMLInputElement).value = '00168780351';
      (document.getElementById('rsociale') as HTMLInputElement).value = 'GAB Tamagnini';
      (document.getElementById('citta') as HTMLInputElement).value = "Reggio Emilia";
      (document.getElementById('via') as HTMLInputElement).value = "Via Disraeli, 17";
      (document.getElementById('email') as HTMLInputElement).value;
      (document.getElementById('sdi') as HTMLInputElement).value = '0000000';
      (document.getElementById('iban') as HTMLInputElement).value;

      // NASCONDO LA SELEZIONE DELL'UTENTE
      (document.getElementById('tipoutente') as HTMLDivElement).style.display = 'none';
      
      // IMPOSTO L'UTENTE COME ADMIN
      this.UserType.Utente = false;
      this.UserType.Concessionario = false;
      this.UserType.Distributore = false;
      this.UserType.Segnalatore = false;
      this.UserType.Admin = true;

    } else {
      (document.getElementById('username') as HTMLInputElement).value = '';
      (document.getElementById('psw') as HTMLInputElement).value = '';
      (document.getElementById('piva') as HTMLInputElement).value = '';
      (document.getElementById('rsociale') as HTMLInputElement).value = '';
      (document.getElementById('citta') as HTMLInputElement).value = '';
      (document.getElementById('via') as HTMLInputElement).value = '';
      (document.getElementById('email') as HTMLInputElement).value = '';
      (document.getElementById('pec') as HTMLInputElement).value = '';
      (document.getElementById('sdi') as HTMLInputElement).value = '';
      (document.getElementById('iban') as HTMLInputElement).value = '';

      (document.getElementById('tipoutente') as HTMLDivElement).removeAttribute("style");

      // RESET
      this.UserType.Utente = false;
      this.UserType.Concessionario = false;
      this.UserType.Distributore = false;
      this.UserType.Segnalatore = false;
      this.UserType.Admin = false;

    }
  }

  public CheckCanoneCloud() {

    if (this.Settings.ScontoCloud.PrimoScaglione.SX < this.Settings.ScontoCloud.PrimoScaglione.DX) {
      if (this.Settings.ScontoCloud.PrimoScaglione.DX < this.Settings.ScontoCloud.SecondoScaglione.SX && this.Settings.ScontoCloud.PrimoScaglione.DX === this.Settings.ScontoCloud.SecondoScaglione.SX - 1) {
        if (this.Settings.ScontoCloud.SecondoScaglione.SX < this.Settings.ScontoCloud.SecondoScaglione.DX) {
          if (this.Settings.ScontoCloud.SecondoScaglione.DX < this.Settings.ScontoCloud.TerzoScaglione.SX && this.Settings.ScontoCloud.SecondoScaglione.DX === this.Settings.ScontoCloud.TerzoScaglione.SX - 1) {
            if (this.Settings.ScontoCloud.TerzoScaglione.SX < this.Settings.ScontoCloud.TerzoScaglione.DX || this.Settings.ScontoCloud.TerzoScaglione.DX === -1) {
              this.ErroreCloud = false;
              return true;
            }
          }
        }
      }  
    }

    this.ErroreCloud = true;
    return false;
  }

  /*
  public Reset() {
    this.userInfo = new UserInfo(); //MAIN

  //public User: string;  //Concessionario, Segnalatore, Utente

    this.UserType = {
      Utente: false,
      Segnalatore: false,
      Distributore: false,
      Concessionario: false
    };

    this.Settings = {
    Tipo: {
      singolo: false,
      pacchetti: false,
      canone: false
    },
    Sconto: {
      S: 0,
      SBonus: 0,
      P: {
        Cinque: 0,
        Dieci: 0,
        Quindici: 0,
        Venti: 0
      },
      PBonus: {
        Cinque: 0,
        Dieci: 0,
        Quindici: 0,
        Venti: 0
      },
      C: 0,
      CBonus: 0,
    },
    NumeroPacchetti: "15", //NUMERO PACCHETTI CANONE,
    ScontoRinnovo: 50,
    ScontoArticoli: 15,
    Canone: 0,  //VALORE CANONE
    SoftwareAbb: {
      VisualFood: false,
      VisualShop: false,
      VisualRetail: false,
      VisualSelf: false,
    }
  }

  this.errors = [];  //LISTA ERRORI FORM
  }
  */

  public CloseModalPermessi() {
    //PermessiString
    this.PermessiString = this.GenerateStringPermessiAdmin();
    this.modalService.hide();
  }

  private GenerateStringPermessiAdmin() {
    let returnValue: string = "";
    //LICENZE
    returnValue += "L" +  (this.SettingsPermessi.Licenze.Generazione ? '1' : 0) + (this.SettingsPermessi.Licenze.Upgrade ? '1' : 0) + (this.SettingsPermessi.Licenze.Stato ? '1' : 0) + (this.SettingsPermessi.Licenze.Rinnovo ? '1' : 0);
    //SHOP
    returnValue += "S" +  (this.SettingsPermessi.Shop.Inserimento ? '1' : '0') + (this.SettingsPermessi.Shop.Modifica ? '1' : '0');
    //MANUALI
    returnValue += "M" +  (this.SettingsPermessi.Manuali.Inserimento ? '1' : '0') + (this.SettingsPermessi.Manuali.Eliminazione ? '1' : '0');
    //LISTINI
    returnValue += "N" +  (this.SettingsPermessi.Listini.Inserimento ? '1' : '0') + (this.SettingsPermessi.Listini.Eliminazione ? '1' : '0');
    //AGGIORNAMENTI
    returnValue += "A" +  (this.SettingsPermessi.Aggiornamenti.Inserimento ? '1' : '0') + (this.SettingsPermessi.Aggiornamenti.Eliminazione ? '1' : '0');
    //UTENTI
    returnValue += "U" +  (this.SettingsPermessi.Utenti.Creazione ? '1' : '0') + (this.SettingsPermessi.Utenti.Modifica ? '1' : '0') + (this.SettingsPermessi.Utenti.Eliminazione ? '1' : '0');
    //CENSIMENTI
    returnValue += "C" +  (this.SettingsPermessi.Censimenti.Aggiornamento ? '1' : '0') + (this.SettingsPermessi.Censimenti.Eliminazione ? '1' : '0');

    return returnValue;
  }

}

class UserInfo {
  public username: string;
  public password: string;
  public piva: string;
  public rsociale: string;
  public citta: string;
  public via: string;
  public email: string;
  public pec: string;
  public sdi: string;
  public iban: string;
}
