import { AuthService } from './../../../../Services/AuthService/auth.service';
import { AlertService } from 'ngx-alerts';
import { SellPoint } from './../../../../Models/Cloud/SellPoint';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SellpointsService } from 'src/app/Services/Cloud/sellpoint.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'sellpoint-form',
  templateUrl: './sellpointform.component.html',
  styleUrls: ['./sellpointform.component.scss']
})
export class SellpointformComponent implements OnInit {

  constructor(private sellPointService: SellpointsService, public modalService: BsModalService, private alertService: AlertService, public auth: AuthService) { }
  @Input() public sellPoint: SellPoint;
  @Output() public UpdateGroup: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
    
  }

  public async EditSellPoint() {
    
    if (this.sellPoint.name === "" || this.sellPoint.name === undefined) {
      this.alertService.danger("Inserisci il nome per continuare");
      return;
    }


    const success = this.sellPoint.id === undefined ? await this.sellPointService.CreateSellPoint(this.sellPoint) : await this.sellPointService.EditSellPoint(this.sellPoint);

    if (success !== null) {
      this.modalService.hide();
      this.alertService.success("Centro modificato con successo");
    } else {
      this.modalService.hide();
      this.alertService.danger("Impossibile modificare il centro");
    }

    this.UpdateGroup.emit();
  }
}
