import SoftwareListino from './SoftwareListino';

export default class Categoria {
  public ID: number;
  public IDPadre: number;
  public Nome: string;
  public Elementi: SoftwareListino[] = [];
  public SottoCategorie: Categoria[] = [];
  public Visibile: number;

  //BOOL GRAFICO COMPONENTE
  public IsSubMenu: boolean = false;


  constructor(id: number, nome: string) {
    this.ID = id;
    this.Nome = nome;
    this.IDPadre = -1;
    this.Visibile = 1;
  }

}
