<div class="card-body table-full-width table-responsive">
    <table class="table table-hover table-striped" id="table" width="100%">
        <thead>
            <tr>
                <th width="30%">Licenze</th>
                <th><a role="button">Data Creazione</a></th>
                <th><a role="button">Data Attivazione</a></th>
                <th><a role="button">Data Scadenza</a></th>
                <th>Descrizione</th>
                <th>Info</th>
                <th *ngIf="this.auth.AuthUser.Role === 0">Creatore</th>
                <th>Aggiorna</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let licenseInfo of this.Licenses; let i = index">
                <td><a>{{licenseInfo.License.License}}</a></td>
                <td>{{licenseInfo.CreationDate}}</td>
                <td>{{licenseInfo.ActivationDate}}</td>
                <td>{{licenseInfo.ExpirationDate}}</td>
                <td>{{licenseInfo.Descrizione}}</td>
                <!--INFO-->
                <td>
                    <button class="btn" style="border: none" type="button" [tooltip]="GetTooltip(licenseInfo)" [style.display]="licenseInfo.License.Modules !== 'DZZZZ' || licenseInfo.License.Modules.substr(5) !== '00000000' || licenseInfo.Descrizione !== 'CLIENT' ? '' : 'none'">
                        <fa-icon class="center" [icon]="faInfo" size="2x"></fa-icon>
                    </button>
                </td>
                <td *ngIf="this.auth.AuthUser.Role === 0">{{licenseInfo.Creatore.toUpperCase()}}</td>
                <td>
                    <fa-icon class="center" style="cursor: pointer;" [icon]="faSync" size="2x" (click)="this.renewLicense(licenseInfo, i)"></fa-icon>
                </td>
            </tr>
        </tbody>
   </table>
</div>