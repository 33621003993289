import { lastValueFrom } from 'rxjs';
import { AuthService } from './../AuthService/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Censimento from 'src/app/Models/Censimento';
import CensimentoAggiorna from 'src/app/Models/CensimentoAggiorna';
import Modulo from 'src/app/Models/Modulo';
import { StringDecoder } from 'string_decoder';

@Injectable({
  providedIn: 'root'
})
export class CensisService {

  constructor(private http: HttpClient, private auth: AuthService) { }

  public async LoadStatus(): Promise<CensimentoAggiorna[]> {
    const params: HttpParams = new HttpParams().set("rivenditore",this.auth.AuthUser.Username).set("id", String(this.auth.AuthUser.ID));
    const result: any = await this.http.get('/api/censis/status', {params}).toPromise();

    const status: CensimentoAggiorna[]  = [];

    for (let i = 0; i < result.length; ++i) {
      const censimento: CensimentoAggiorna = new CensimentoAggiorna();
      censimento.PIVA = result[i].piva;
      censimento.NMAG = result[i].nmag;
      censimento.NCASSA = result[i].ncassa;
      censimento.MAC = result[i].mac;
      censimento.SOFTWARE = result[i].software;
      censimento.VERSION = result[i].version;
      censimento.DESMAG = result[i].desmag;
      censimento.RAGSOC = result[i].ragsoc;
      status.push(censimento);
    }

    return status;

  }

  public async LoadCensimenti(): Promise<Censimento[]> {
    const params: HttpParams = new HttpParams()
      .set('id', String(this.auth.AuthUser.ID))
      .set('rivenditore', this.auth.AuthUser.Username);

    const result: any = await this.http.get('/api/censis', { params }).toPromise();

    const Censimenti: Censimento[] = [];
    for (let i = 0; i < result.length; ++i) {
      const censimento: Censimento = this.GenerateCensimento(result[i]);
      const oggi: Date = new Date();
      const lastData: Date = new Date(censimento.LASTDATA);
      const millisecondi = oggi.getTime() - lastData.getTime();
      censimento.UltimoAccessoInGiorni = millisecondi / (1000 * 60 * 60 * 24);
      Censimenti.push(censimento);
    }

    return Censimenti;
  }

  public async SingleCensimento(licenza: string) {
    const params: HttpParams = new HttpParams()
    .set("licenza", licenza);
    let result: any = await this.http.get('/api/censis/single', {params}).toPromise();
    
    return result;
  }

  private GenerateCensimento(result: any) {
    const censimento: Censimento = new Censimento();
    censimento.PIVA = result.piva;
    censimento.NMAG = result.nmag;
    censimento.NCASSA = result.ncassa;
    censimento.DESMAG = result.desmag;
    censimento.RAGSOC = result.ragsoc;
    censimento.LOC = result.loc;
    censimento.VIA = result.via;
    censimento.CAP = result.cap;
    censimento.PROV = result.prov;
    censimento.SERVB2B = result.servB2B;
    censimento.IDTRAB2B = result.idtraB2B;
    censimento.MATRICOLA = result.matricola;
    censimento.AZZER = result.azzer;
    censimento.LOTTERIA = result.lotteria;
    censimento.XML7 = result.xmL7;
    censimento.AVVIORT = result.avviort;
    censimento.GIART = result.giart;
    censimento.MAC = result.mac;
    censimento.RIVENDITORE = result.rivenditore;
    censimento.INT_SCO = result.inT_SCO;
    /*
    if (this.auth.AuthUser.ID !== 10) {
      censimento.RIVENDITORE = this.auth.AuthUser.Username;
    } else {
    }
    */

    censimento.RT_FW = result.rT_FW;
    censimento.RT_MODEL = result.rT_MODEL;
    censimento.RE_MATR = result.rE_MATR;
    // LAST DATA --> CONVERTO IN FORMATO DD/MM/YYYY
    censimento.LASTDATA = result.lastdata;
    const lastData: Date = new Date(censimento.LASTDATA);
    const data: Date = lastData;
    const giorno: number = data.getDate();
    const mese: number = data.getMonth() + 1;
    const anno: number = data.getFullYear();
    // tslint:disable-next-line:max-line-length
    censimento.LASTDATASTRING = giorno + '/' + ((mese >= 10) ? (mese) : ('0' + mese)) + '/' + anno + ' ' + ((data.getHours() >= 10) ? (data.getHours()) : ('0' + data.getHours())) + ':' + ((data.getMinutes() >= 10) ? (data.getMinutes()) : ('0' + data.getMinutes()));
    censimento.VP = result.vp;
    censimento.VB = result.vb;
    censimento.DR = result.dr;
    censimento.VU = result.vu;
    censimento.CPU = result.cpu;

    censimento.LICENZA = result.licenza;

    return censimento;
  }

  public async UpdateVisualPos(censimento: Censimento) {
    const response: any = await this.http.post('/api/censis/update/visualpos',
      // tslint:disable-next-line:max-line-length
      { PIVA: censimento.PIVA, NMAG: censimento.NMAG, NCASSA: censimento.NCASSA, MAC: censimento.MAC }, {}).toPromise();

    return response.response;
  }

  public async UpdateDriver(censimento: Censimento) {
    const result: any = await this.http.post('/api/censis/update/driver',
      // tslint:disable-next-line:max-line-length
      { PIVA: censimento.PIVA, NMAG: censimento.NMAG, NCASSA: censimento.NCASSA, MAC: censimento.MAC }, {}).toPromise();

    return result.response;
  }

  public async GetStatus(): Promise<CensimentoAggiorna[]> {
    const params: HttpParams = new HttpParams().set("rivenditore",this.auth.AuthUser.Username)
    const result: any = await this.http.get('/api/getstatus', {params}).toPromise();

    const status: CensimentoAggiorna[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < result.length; ++i) {
      const censimento: CensimentoAggiorna = new CensimentoAggiorna();
      censimento.PIVA = result[i].piva;
      censimento.NMAG = result[i].nmag;
      censimento.NCASSA = result[i].ncassa;
      censimento.MAC = result[i].mac;
      censimento.SOFTWARE = result[i].software;
      censimento.VERSION = result[i].version;
      censimento.DESMAG = result[i].desmag;
      censimento.RAGSOC = result[i].ragsoc;
      status.push(censimento);
    }

    return status;
  }

  public async UpdateLicense(censimento: Censimento) {
    const returnValue: any = await this.http.put('/api/censis/update/license',
      // tslint:disable-next-line:max-line-length
      { PIVA: censimento.PIVA, NMAG: censimento.NMAG, NCASSA: censimento.NCASSA, MAC: censimento.MAC }, {}).toPromise();

    return returnValue.response;

    }

  public async InsertLicense(censimento: Censimento, licenza: string[]) {
    const result: any = await this.http.post('/api/censis/update/license',
      // tslint:disable-next-line:max-line-length
      { PIVA: censimento.PIVA, NMAG: censimento.NMAG, NCASSA: censimento.NCASSA, MAC: censimento.MAC, LICENZA: licenza }, {}).toPromise();
  
    return result.response === "ok";
  
 }

  public async DeleteCensimento(censimento: Censimento) {
    const params: HttpParams = new HttpParams()
    .set('piva', censimento.PIVA)
    .set('nmag', String(censimento.NMAG))
    .set('ncassa', String(censimento.NCASSA))
    .set('mac', censimento.MAC);

    const result: any = await this.http.delete('/api/censis', { params }).toPromise();
        
    return result.response === "ok";
  
  }

  public async GetModuli(censimento: Censimento) {
    const params: HttpParams = new HttpParams()
      .set('piva', censimento.PIVA)
      .set('nmag', String(censimento.NMAG))
      .set('ncassa', String(censimento.NCASSA))
      .set('mac', censimento.MAC);

    const resultModuli: any = await this.http.get('/api/censis/moduli', { params }).toPromise();
    censimento.MODULI = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < resultModuli.length; ++i) {

      if ((resultModuli[i].codice as string).startsWith('IDTEAM')) {
        const idTeamViewer: string = (resultModuli[i].codice as string).substr(6);
        censimento.TEAMVIEWER = idTeamViewer;
      } else {
        const modulo: Modulo = new Modulo(resultModuli[i].codice, resultModuli[i].descrizione);
        censimento.MODULI.push(modulo);
      }
    }
  }

  public async GetFormattedIntSco(int_sco: string) {
    return await lastValueFrom(this.http.post<string[]>('/api/censis/intsco', {int_sco}, {}));
  }

}
