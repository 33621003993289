import { AlertService } from 'ngx-alerts';
import { AuthService } from './../../Services/AuthService/auth.service';
import { License, LicenseInfo, LicenseClient } from './../../Models/LicenzeStat';
import { LicenseService } from 'src/app/Services/LicenseService/license.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faInfo, faSync, IconDefinition } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-licenses-expiring',
  templateUrl: './licenses-expiring.component.html',
  styleUrls: ['./licenses-expiring.component.scss']
})
export class LicensesExpiringComponent implements OnInit {

  @Input() expiringLicense: string;
  @Output() refreshStats: EventEmitter<string> = new EventEmitter();
  //ICON
  public faInfo: IconDefinition = faInfo;
  public faSync: IconDefinition = faSync;

  public Licenses: (LicenseInfo | LicenseClient)[] = [];
  constructor(private licenseService: LicenseService, public auth: AuthService, private alertService: AlertService) { }

  async ngOnInit() {
    this.Licenses = [];
    const Licenses: LicenseInfo[] = await this.licenseService.GetExpiringLicenses();
    Licenses.forEach(license => {
      if (license.Segnalatore === "CLIENT") {
          license.Descrizione = "CLIENT";
      }
      this.Licenses.push(license);

      /*
      license.LicenseClient.forEach(client => {
        console.log(client)
        if (client.Stato === 1) {
          client.Creatore = license.Creatore;
          client.Descrizione = "CLIENT";
          client.Master = license.License.License;

          this.Licenses.push(client);
        }
      })
      */
    });

  }

  public GetTooltip(license: LicenseInfo | LicenseClient) {

    if (license.Descrizione === "CLIENT") {
      return "Master: " + (license as LicenseClient).Master;
    }

    const modules: string = license.License.Modules;

    let string: string = "";
    const submod: string = modules.substr(5);

    if (submod.length === 0) {
      return "Versione Demo"
    } else {
      for(let i = 0; i < submod.length; ++i) {
        switch(i) {
          case 0:
            {
              if(submod[i] === "1") {
                string = string + "Collegamento Cassetto Automatico, "
              }
              break;
            }
          case 1:
            {
              if(submod[i] === "1") {
                string = string +  "Promo Cassa, "
              }
              break;
            }
          case 2:
            {
                if(submod[i] === "1") {
                  string = string +  "Celiachia, "
                }
                break;
            }
          case 3:
            {
                if(submod[i] === "1") {
                  string = string +  "Modulo 730, "
                }
                break;
            }
          case 4:
            {
                if(submod[i] === "1") {
                  string = string +  "Tabacchi, "
                }
                break;
            }
          case 5:
            {
              if(submod[i] === "1") {
                string = string +  "Cooki, "
              }
              break;
            }
          case 6:
            {
              if(submod[i] === "1") {
                string = string + "Keenon, "
              }
              break;
            }
          case 7:
            {
              if(submod[i] === "1") {
                string = string +  "Visual B2B, "
              }
              break;
            }
        }

      }

      return string.length > 0 ? string.substring(0, string.length - 2) : '';
    }
  }

  public async renewLicense(license: LicenseInfo | LicenseClient, i: number) {
    const result = await this.licenseService.RenewLicense(license.Creatore, license.License.License);

    if (result) {
      this.alertService.success('Licenza rinnovata con successo');

      //AGGIORNO LA LISTA E AGGIORNO LE STATISTICHE RELATIVE ALLE LICENZE IN SCADENZA
      this.Licenses.splice(i, 1);

      const expiringValue: number = Number(this.expiringLicense) - 1;

      this.expiringLicense = String(expiringValue);

      if (expiringValue >= 0) {
        this.refreshStats.emit(this.expiringLicense);
      }


      return true;
    }

    this.alertService.danger('Impossibile rinnovare la licenza');
    return false;
  }

}
