import { AuthService } from './../AuthService/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  constructor(private http: HttpClient, private auth: AuthService) { }

  public async GetVpCount() {
    let params = new HttpParams()
    .set("id", String(this.auth.AuthUser.ID))
    .set("rivenditore", this.auth.AuthUser.Username.toUpperCase());

    const result: any = await this.http.get('/api/stats/vpcount', {params}).toPromise();
    return result.response
  }

  public async GetVpUsed() {
    let params = new HttpParams()
    .set("id", String(this.auth.AuthUser.ID))
    .set("rivenditore", this.auth.AuthUser.Username.toUpperCase());

    const result: any = await this.http.get('/api/stats/vpused', {params}).toPromise();
    return result.response
  }

  public async GetB2BCount() {
    let params = new HttpParams()
    .set("id", String(this.auth.AuthUser.ID))
    .set("rivenditore", this.auth.AuthUser.Username.toUpperCase());

    const result: any = await this.http.get('/api/stats/b2bcount', {params}).toPromise();
    return result.response;
  }

  public async GetExpiringLicenses() {
    let params = new HttpParams()
    .set("rivenditore", this.auth.AuthUser.Username.toUpperCase());
    
    const result: any = await this.http.get('/api/stats/licenses', {params}).toPromise();
    
    return result.response
  }

  public async GetVisualPosUsage() {
    let params = new HttpParams()
    .set("rivenditore", this.auth.AuthUser.Username.toUpperCase())
    return await this.http.get('/api/stats/vp', {params}).toPromise();
  }

  public async GetModuleUsage() {
    let params = new HttpParams()
    .set("rivenditore", this.auth.AuthUser.Username.toUpperCase());
    return await this.http.get('/api/stats/modules', {params}).toPromise();
  }

}
