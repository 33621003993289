<section  class="buy-package-license-section">
  <div fxLayout="row wrap" fxLayout.lt-lg="column wrap" fxLayoutGap="16px grid" style="padding-top: 2rem" >
    <ng-container *ngIf="this.ShopService.prodottiMostrati.length > 0; else SENZAPRODOTTI">
    <div *ngFor="let software of this.ShopService.prodottiMostrati; let i=index;" fxFlex="25%">
      <div class="owl-item active ">
        <div class="single-product">
          <div class="product-f-image" (click)="ShowDettaglioProdotto(software)">
            <img *ngIf="software.ImgName.length > 0" [src]='getProductImage(software)' class="immagine-prodotti" [id]="software.CodiceArticolo" (error)="ImageErrorHandler(software.CodiceArticolo)">
            <img *ngIf="software.ImgName.length == 0" src='assets/img/GabImageNotFound.png' class="immagine-prodotti" >
          </div>
          <div>
            <h2 style="cursor: pointer;text-transform:uppercase;" (click)="ShowDettaglioProdotto(software)"><a>{{software.Descrizione}}</a></h2>
            <a>Cod.articolo:<b>{{software.CodiceArticolo}}</b></a>

            <div class="product-carousel-price">
              <ins>Prezzo:{{software.Costo}}€ </ins>
            </div>
            <div>
              <div class="quantity buttons_added paddingtopandbottom" >
                <input type="button" class="btn btn-primary bottonivalue"   value="-" (click)="decrementare(i)">
                <input [id]="'quantita-articolo-' + i" type="number" size="4" class="input-text qty text bottonivalue btn btn-primary" style="margin: 0px 6px; background-color: white;color: black" title="Qty" value="1" min="1" max="999" step="1">
                <input type="button" class="btn btn-primary bottonivalue"  value="+" (click)="incrementare(i)">
              </div>
              <div class="btn btn-primary" (click)="AggiungiPacchetto(software, i)" style="width: 171px">
                Aggiungi prodotto
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    </ng-container>
  </div>
  <div class="floating-buttons" fxLayout.lt-lg="row" fxLayoutGap.lt-lg="5px" *ngIf="ShopService.mobileCart">
    <div class="floating-button" >
      <a role="button" style="opacity: 1" class="btn btn-primary btn-block" (click)="(ShopService.Pacchetto.length > 0) ? this.modalService.show(pacchetto) : ''">
        Ordine: {{this.ShopService.LeftPacchettoScelto}}/{{this.ShopService.PacchettoScelto}}
      </a>
    </div>
    <div class="floating-button" >
      <a style="opacity: 1" *ngIf="this.ShopService.LeftPacchettoScelto == this.ShopService.PacchettoScelto" role="button" class="btn btn-success btn-block" (click)="this.ShopService.ConfirmPacchetti()">
          Aggiungi al carrello
      </a>
    </div>
  </div>
</section>

<ng-template #pacchetto>
  <div class="modal-body">
    <div class="container">
      <div class="controls">
        <table class="table table-hover table-striped">
          <thead>
          <tr>
            <th>Codice Articolo</th>
            <th>Descrizione</th>
            <th>Costo</th>
            <th>Quantità</th>
            <th>Elimina</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let elemento of this.ShopService.Pacchetto">
            <td>{{elemento.software.CodiceArticolo}}</td>
            <td>{{elemento.software.Descrizione}}</td>
            <td>{{elemento.software.Costo}}€</td>
            <td>{{elemento.quantita}}</td>
            <td>
              <a role="button"><fa-icon [icon]="faTrash" size="2x" (click)="this.ShopService.RemovePacchetto(elemento)"></fa-icon></a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-danger center" style="margin-left: auto; margin-right: auto;" (click)="this.modalService.hide()">Chiudi</div>
  </div>
</ng-template>
<ng-template #SENZAPRODOTTI>
  <div class="error_prodotti_non_trovati">
    {{this.ShopService.errorProductsNotFound}}
  </div>
</ng-template>
