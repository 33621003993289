<!--<div class="modal-header">
  <h4 class="modal-title pull-left">Crea elemento</h4>
  <div class="modal-body">
    <div class="controls">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Codice Listino *</label>
            <input type="text" class="form-control" placeholder="Inserisci" required="required" [(ngModel)]="this.NuovoListino.CodiceArticolo"  maxlength="12" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Nome *</label>
            <input type="text"class="form-control" placeholder="Inserisci" required="required" [(ngModel)]="this.NuovoListino.Descrizione" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Costo *</label>
            <input type="number" class="form-control" required="required" [(ngModel)]="this.NuovoListino.Costo" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="input-group">
              <div class="form-group">
                <label>Immagine</label>
                <input type="file" (change)="FileInput($event)" accept="image/png, image/jpeg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group" style="width: 100%;">
          <div class="col-md-12">
            <label>Descrizione</label>
            <input type="text" class="form-control" required="required" [(ngModel)]="this.NuovoListino.Testo" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col" style="float: right">
          <span class="btn btn-primary btn-fill" style="float: right;" (click)="this.Upload()">Inserisci</span>
        </div>
      </div>
    </div>
  </div>
</div>-->
<div class="wrapper">
  <header>
    <h1>Inserimento nuovo prodotto</h1>
  </header>
  <div class="sections">
    <section class="active">
      <input type="text" placeholder="Codice listino" id="codice-listino" [(ngModel)]="CodiceListino" (keyup)="InputCodiceListino()"/>
      <small *ngIf="ErroreCodiceListino" class="error-danger">Codice listino è obbligatorio</small>
      <small *ngIf="ErroreCodiceListinoSpazi" class="error-danger">Codice listino non può contenere spazi</small>
      <textarea placeholder="Nome prodotto" id="nome" [(ngModel)]="NomeProdotto" (keyup)="InputNomeProdotto()"></textarea>
      <small *ngIf="ErroreNomeProdotto" class="error-danger">Nome prodotto è obbligatorio</small>

      <input type="number" placeholder="Costo" id="costo" min="1" [(ngModel)]="CostoProdotto" (keyup)="InputCostoProdotto()"/>
      <small *ngIf="ErroreCostoProdottoNull" class="error-danger">Il costo è obbligatorio</small>
      <small *ngIf="ErroreCostoProdottoZero" class="error-danger">Il costo deve essere maggiore di 0</small>
      <small *ngIf="ErroreCostoProdottoLettere" class="error-danger">Il costo deve avere solo numeri</small>

      <textarea placeholder="Descrizione prodotto (max 5000 caratteri)" id="descrizione" [(ngModel)]="DescrizioneProdotto" (keyup)="InputDescrizioneProdotto()"></textarea>
      <small *ngIf="ErroreDescrizioneProdotto" class="error-danger">La descrizione è obbligatoria</small>
      <small *ngIf="ErroreDescrizioneProdottoLength" class="error-danger">Descrizione troppo lunga</small>
      <div class="images">
        Carica l'immagine del prodotto<br>

        <form id="uploadImageForm">
          <input type="file" (change)="onSelectFile($event)" accept=".png, .jpg, .jpeg" id="uploadImage">
          <small *ngIf="ErroreFormatoImmagine" class="error-danger">L'immagine non rispetta il formato 1:1</small>
          <small *ngIf="ErroreRisoluzioneImmagine" class="error-danger">La risoluzione dell'immagine deve essere almeno 200 x 200</small>
        </form>
        <ul>
          <li>È preferibile che la risoluzione sia 500 x 500</li>
          <li>Deve avere risoluzione superiore a 200 x 200</li>
          <li>Deve essere in <b>formato 1:1</b></li>
        </ul>
      </div>
    </section>

    <section>
      <input type="text" placeholder="Topic" id="topic"/>
      <textarea placeholder="something..." id="msg"></textarea>
    </section>

  </div>

  <footer>
    <ul>
      <li style="font-size: 23px"><span id="reset" (click)="this.modalService.hide()">Annulla</span></li>
      <li><span id="send" (click)="this.checkFormValidity()">Conferma</span></li>
    </ul>
  </footer>
</div>
