import { UserStoreService } from '../Admin/user-store.service';
import { AuthService } from './../AuthService/auth.service';
import { ShopService } from './../ShopService/shop.service';
import {Injectable, TemplateRef} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IsDev } from 'src/app/global';
import { Payment, Tipo } from 'src/app/Models/Payment';
import Contenitore from 'src/app/Models/Contenitore';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  constructor(private http: HttpClient, private shopService: ShopService, private auth: AuthService,public modalService: BsModalService, private userStoreService: UserStoreService
  ) {
  }
  public modalRef: BsModalRef;
  public async StripeIntent(pagamento: Payment) {

    await this.InitShop();

    const iva = await this.userStoreService.GetIva();
    const carrelloTotal = this.shopService.GetCarrelloTotal();

    const ivaParziale: number = carrelloTotal * (iva / 100);
    const totalePagamento: number = carrelloTotal +  ivaParziale;
    //const Carrello: Contenitore[] = this.shopService.Carrello;

    //QUESTO OGGETTO CONTIENE AL SUO INTERNO LE INFORMAZIONI SUL PAGAMENTO ED IL CARRELLO
    /*
      IL CARRELLO è

    */
    const body: string = JSON.stringify(pagamento);

    const result: any = await this.http.post('/api/payment/stripe', body, {headers: {
      'Content-Type': 'application/json'
    }}).toPromise();
    return result; //PAYMENT INTENT ID
  }

  private async InitShop() {
    if (this.shopService.IsInit === false) {
      await this.shopService.Init();
      this.shopService.IsInit = true;
    }
  }

}
