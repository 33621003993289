<div class="content fade-in">
  <div class="row" *ngIf="!lglt; else nondisponibile">
    <div class="col-md-12">
      <div  id="cardTicket">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <a role="button" class="btn btn-primary" (click)="IsSub = false" (click)="this.modalService.show(categoriaModal)" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Inserimento">Crea categoria</a>
            </div>

            <div class="col">
              <a role="button" class="btn btn-primary" style="float: right;" (click)="this.OpenModalIva(ivaSetting)" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Shop.Modifica">Impostazioni IVA</a>
            </div>

          </div>
        <div class="card-body table-full-width table-responsive">
          <table class="table table-striped" id="table">
            <tbody *ngFor="let categoria of this.Categorie">
              <tr>
                <th scope="col" class="categoria">Categoria</th>
                <th scope="col"></th>
              </tr>
              <tr>
                <td>{{categoria.Nome}}</td>
                <td style="float: right;">
                  <div class="row">
                    <div class="col-4" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Modifica">
                      <select class="custom-select" [(ngModel)]="categoria.Visibile" (ngModelChange)="ChangeVisibileCategoria(categoria)">
                        <option [value]="0">Concessionari</option>
                        <option [value]="1">Utenti</option>
                        <option [value]="2">Entrambi</option>
                        <option [value]="3">Nascosta</option>
                      </select>
                    </div>
                    <div class="col" >
                      <a role="button" *ngIf="!categoria.IsSubMenu" data-placement="top" data-toogle="tooltip" tooltip="Mostra elementi">
                        <fa-icon style="color:black" [icon]="arrowDown" size="2x" (click)="categoria.IsSubMenu = !categoria.IsSubMenu" > </fa-icon>
                      </a>
                      <a role="button" *ngIf="categoria.IsSubMenu" data-placement="top" data-toogle="tooltip" tooltip="Nascondi elementi">
                        <fa-icon style="color:black" [icon]="arrowUp" size="2x" (click)="categoria.IsSubMenu = !categoria.IsSubMenu" ></fa-icon>
                      </a>
                    </div>
                    <div class="col" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Modifica">
                      <a role="button" data-placement="top" data-toogle="tooltip" tooltip="Modifica categoria">
                        <fa-icon [icon]="faEdit" size="2x" (click)="this.modalService.show(editCategoria); this.CategoriaModifica = categoria"></fa-icon>
                      </a>
                    </div>
                    <div class="col" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Inserimento">
                      <a role="button" data-placement="top" data-toogle="tooltip" tooltip="Aggiungi elemento"><fa-icon [icon]="faCartPlus" size="2x"
                                                (click)="this.modalService.show(listinoModal, {class: 'modal-lg', ignoreBackdropClick: true})"
                                                (click)="this.NuovoListino.IdCat = categoria.ID"
                                                (click)="this.NuovoListino.CodiceArticolo = 'ND00000000' + (categoria.Elementi.length + 1)"
                                                >
                                              </fa-icon></a>
                    </div>
                    <div class="col" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Modifica">
                      <a role="button" data-placement="top" data-toogle="tooltip" tooltip="Elimina categoria">
                        <fa-icon [icon]="faTrash" size="2x" (click)="this.CategoriaElimina = categoria" (click)="this.modalService.show(eliminaCategoria)">

                        </fa-icon>
                      </a>
                    </div>
                    <div class="col" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Inserimento">
                      <a role="button"  data-placement="top" data-toogle="tooltip" tooltip="Aggiungi sottocategoria">
                        <fa-icon [icon]="faPlus" size="2x" (click)="this.IdCat = categoria.ID; IsSub = true" (click)="this.modalService.show(categoriaModal)"></fa-icon>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="categoria.IsSubMenu && categoria.Elementi.length > 0">
                <td colspan="2" style="padding: 0;">
                    <table class="table">
                      <thead>
                        <tr>
                          <th colspan="6" style="font-size: 15px;" class="text-center"><b>Lista prodotti categoria</b></th>
                        </tr>
                        <tr>
                          <th>Codice Articolo</th>
                          <th>Descrizione</th>
                          <th>Costo</th>
                          <th>Immagine</th>
                          <th>Modifica</th>
                          <th>Elimina</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let elem of categoria.Elementi">
                          <td>{{elem.CodiceArticolo}}</td>
                          <td>{{elem.Descrizione}}</td>
                          <td>{{elem.Costo}}</td>
                          <td>
                            <a *ngIf="elem.ImgName === ''" data-placement="top" data-toogle="tooltip" tooltip="Immagine non disponibile" style="color: black">
                              <fa-icon  [icon]="faEyeSlash" size="2x"></fa-icon>
                            </a>

                            <a role="button" data-toogle="tooltip" tooltip="Visualizza immagine">
                              <fa-icon *ngIf="elem.ImgName !== ''" 
                                        [icon]="faEye" size="2x"
                                        (click)="this.imgStr = elem.ImgName;
                                        this.modalService.show(modalImage)">
                              </fa-icon>
                            </a>
                          </td>
                          <td>
                            <a role="button">
                              <fa-icon 
                                [icon]="faEdit" size="2x"
                                (click)="this.ListinoModifica = elem"
                                (click)="this.modalService.show(updateListinoModal, {class: 'modal-lg', ignoreBackdropClick: true})"
                                [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Shop.Modifica"
                                ></fa-icon>
                            </a>
                          </td>
                          <td>
                            <a role="button">
                              <fa-icon 
                                [icon]="faTrash"
                                size="2x"
                                (click)="this.ListinoElimina = elem"
                                (click)="this.modalService.show(eliminaListino)"
                                [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Shop.Modifica"
                                ></fa-icon>
                            </a>
                          </td>
                          <td>
                            <div class="row">
                              <button [disabled]="categoria.Elementi.indexOf(elem) === 0" (click)="OrdineUp(elem, categoria)">
                                <fa-icon [icon]="faArrowUp"></fa-icon>
                              </button>
                            </div>
                            <div class="row">
                              <button [disabled]="categoria.Elementi.indexOf(elem) === categoria.Elementi.length - 1" (click)="OrdineDown(elem, categoria)">
                                <fa-icon [icon]="faArrowDown"></fa-icon>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </td>
              </tr>
              <tr *ngIf="categoria.IsSubMenu && categoria.SottoCategorie.length > 0">
                <th style="font-size: 15px; text-transform: uppercase; color: #9A9A9A;" colspan="2"><b>Lista Sottocategorie</b></th>
              </tr>
              <ng-container *ngIf="categoria.IsSubMenu && categoria.SottoCategorie.length > 0">
                <ng-container *ngFor="let subcategoria of categoria.SottoCategorie">
                  <tr>
                    <td>{{subcategoria.Nome}}</td>
                    <td style="float: right;">
                      <div class="row">
                        <div class="col-4" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Modifica">
                          <select class="custom-select" [(ngModel)]="subcategoria.Visibile" (ngModelChange)="ChangeVisibileCategoria(subcategoria)">
                            <option [value]="0">Concessionari</option>
                            <option [value]="1">Utenti</option>
                            <option [value]="2">Entrambi</option>
                            <option [value]="3">Nascosta</option>
                          </select>
                        </div>
                        <div class="col">
                          <a role="button" *ngIf="!subcategoria.IsSubMenu">
                            <fa-icon style="color:black" [icon]="arrowDown" size="2x" (click)="subcategoria.IsSubMenu = !subcategoria.IsSubMenu"   >
                            </fa-icon>
                          </a>
                          <a role="button" *ngIf="subcategoria.IsSubMenu">
                            <fa-icon style="color:black" [icon]="arrowUp" size="2x" (click)="subcategoria.IsSubMenu = !subcategoria.IsSubMenu"></fa-icon>
                          </a>
                        </div>
                        <div class="col" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Modifica">
                          <a role="button"><fa-icon [icon]="faEdit" size="2x" (click)="this.modalService.show(editCategoria); this.CategoriaModifica = subcategoria"></fa-icon></a>
                        </div>
                        <div class="col" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Inserimento" >
                          <a role="button">
                            <fa-icon [icon]="faCartPlus" size="2x" data-toogle="tooltip" tooltip="Aggiungi elemento"
                                    (click)="this.NuovoListino.IdCat = categoria.ID; this.NuovoListino.IdSubCat = subcategoria.ID"
                                    (click)="this.NuovoListino.CodiceArticolo = 'ND00000000' + (subcategoria.Elementi.length + 1)"
                                    (click)="this.modalService.show(listinoModal, {class: 'modal-lg'})"    
                            >
                            </fa-icon></a>
                        </div>
                        <div class="col" *ngIf="this.auth.AuthUser.SettingsPermessi.Shop.Modifica">
                          <a role="button"><fa-icon [icon]="faTrash" size="2x" (click)="this.CategoriaElimina = subcategoria" (click)="this.modalService.show(eliminaCategoria)"></fa-icon></a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="subcategoria.IsSubMenu && subcategoria.Elementi.length > 0">
                    <td colspan="2" style="padding:0">
                      <table class="table">
                        <thead>
                          <tr>
                            <th colspan="6" style="font-size: 15px;" class="text-center"><b>Lista prodotti categoria</b></th>
                          </tr>
                          <tr>
                            <th>Codice Articolo</th>
                            <th>Descrizione</th>
                            <th>Costo</th>
                            <th>Immagine</th>
                            <th>Modifica</th>
                            <th>Elimina</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let elem of subcategoria.Elementi">
                            <td>{{elem.CodiceArticolo}}</td>
                            <td>{{elem.Descrizione}}</td>
                            <td>{{elem.Costo}}</td>
                            <td>
                              <a role="button" data-toogle="tooltip" tooltip="Immagine non disponibile">
                                <fa-icon *ngIf="elem.ImgName === ''" [icon]="faEyeSlash" size="2x">
                                </fa-icon>
                              </a>
                              <a role="button" data-toogle="tooltip" tooltip="Visualizza immagine">
                                <fa-icon *ngIf="elem.ImgName !== ''" [icon]="faEye" size="2x">
                                </fa-icon>
                              </a>
                            </td>
                            <td>
                              <a role="button"><fa-icon 
                                [icon]="faEdit" size="2x"
                                (click)="this.ListinoModifica = elem"
                                (click)="this.modalService.show(updateListinoModal, {ignoreBackdropClick: true})"
                                [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Shop.Modifica"
                                ></fa-icon></a>
                            </td>
                            <td>
                              <a role="button"><fa-icon 
                                [icon]="faTrash" size="2x"
                                (click)="this.ListinoElimina = elem"
                                (click)="this.modalService.show(eliminaListino)"
                                [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Shop.Modifica"
                                ></fa-icon></a>
                            </td>
                            <td>
                              <div class="row">
                                <button [disabled]="categoria.Elementi.indexOf(elem) === 0" (click)="OrdineUp(elem, categoria)">
                                  <fa-icon [icon]="faArrowUp"></fa-icon>
                                </button>
                              </div>
                              <div class="row">
                                <button [disabled]="categoria.Elementi.indexOf(elem) === categoria.Elementi.length - 1" (click)="OrdineDown(elem, categoria)">
                                  <fa-icon [icon]="faArrowDown"></fa-icon>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</div>
  </div>
  <ng-template #nondisponibile>
    <h4 style="text-align: center">La gestione dello store non è momentaneamente disponibile per versione mobile</h4>
  </ng-template>
</div>
<ng-template #categoriaModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left" *ngIf="!this.IsSub">Crea categoria</h4>
    <h4 class="modal-title pull-left" *ngIf="this.IsSub">Crea sottocategoria</h4>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <input type="text" class="form-control" placeholder="Inserisci" [(ngModel)]="NuovaCategoria">
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
        <button *ngIf="!IsSub" class="btn-primary btn categoriadisabled" (click)="this.CreateCategoria()" [disabled]="this.NuovaCategoria === ''">
          Crea categoria
        </button>
        <button *ngIf="IsSub" class="btn-primary btn categoriadisabled" (click)="this.CreateSottoCategoria()" [disabled]="this.NuovaCategoria === ''">
          Crea sottocategoria
        </button>
    </div>
  </div>
</ng-template>
<ng-template #editCategoria>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Modifica nome</h4>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <input type="text" class="form-control" placeholder="Inserisci" [(ngModel)]="this.NuovaCategoria">
    </div>
  </div>
  <div class="modal-footer">
    <div class="row center">
        <button class="btn-primary btn" [disabled]="this.NuovaCategoria === ''" (click)="this.EditCategoria()">
          Modifica Nome
        </button>
    </div>
  </div>
</ng-template>
    <!--CREA ELEMENTO-->
    <ng-template #listinoModal>

      <app-crea-elemento [Categorie]="Categorie" [NuovoListino] ="NuovoListino"></app-crea-elemento>
    </ng-template>

<!--AGGIORNA LISTINO-->
    <ng-template #updateListinoModal>
      <app-modifica-elemento [ListinoModifica]="ListinoModifica" ></app-modifica-elemento>

    </ng-template>

<!--ELIMINA LISTINO-->
    <ng-template #eliminaListino>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <h4 style="text-align: center;">Vuoi eliminare l'elemento?</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer center">
        <a role=button class="btn btn-primary" style="margin-left: 25%;" (click)="RemoveListino()">Elimina</a>
      </div>
    </ng-template>

<!--ELIMINA CATEGORIA-->
    <ng-template #eliminaCategoria>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <h4 style="text-align: center;">Vuoi eliminare la categoria?</h4>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h5 style="text-align: center;">L'eliminazione della categoria comporta l'eliminazione di tutti gli elementi al suo interno</h5>
          </div>
        </div>
      </div>
      <div class="modal-footer center">
        <a role=button class="btn btn-primary" style="margin-left: 25%;" (click)="RemoveCategoria()">Elimina</a>
      </div>
    </ng-template>


    <ng-template #modalImage>
      <app-img-viewer [ImgName]="this.imgStr"></app-img-viewer>
    </ng-template>

    <ng-template #ivaSetting>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <h4 style="text-align: center;">Impostazioni IVA</h4>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>Percentuale</p>
          </div>
          <div class="col">
            <input type="number" step="1" [(ngModel)]="this.IVA">
          </div>
        </div>
      </div>
      <div class="modal-footer center">
        <a role=button class="btn btn-primary" style="margin-left: 25%;" (click)="UpdateIva()">Salva</a>
      </div>
    </ng-template>


