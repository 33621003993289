import { AuthService } from './../../Services/AuthService/auth.service';
import { AlertService } from 'ngx-alerts';
import { BsModalService } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { LicenseCloudService } from './../../Services/Cloud/license-cloud.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Authority, CloudLicense } from 'src/app/Models/Cloud/CloudLicense';
import { faTrash, faInfoCircle, faChevronCircleLeft, IconDefinition, faPen } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-license-manager',
  templateUrl: './license-manager.component.html',
  styleUrls: ['./license-manager.component.scss']
})
export class LicenseManagerComponent implements OnInit {

  public cloudLicenses: CloudLicense[];
  public selectedLicense: CloudLicense;
  //ICON
  public faTrash: IconDefinition = faTrash;
  public faInfoCircle: IconDefinition = faInfoCircle;
  public faChevronCircleLeft: IconDefinition = faChevronCircleLeft;
  public faPen: IconDefinition = faPen;
  constructor(public licenseCloudService: LicenseCloudService, public modalService: BsModalService, private alertService: AlertService, public router: Router, public auth: AuthService) { }

  async ngOnInit() {
    this.cloudLicenses = await lastValueFrom(this.licenseCloudService.GetLicenses());

    this.cloudLicenses.forEach(license => {
      delete license.updatedAt;
    })
  }

  public CheckAuth(auth: Authority) {
    return this.selectedLicense.authorities.includes(auth);
  }

  public EditAuthority(auth: Authority) {
    if (this.selectedLicense.authorities.includes(auth)) {
      this.selectedLicense.authorities.splice(this.selectedLicense.authorities.indexOf(auth), 1);
    } else {
      this.selectedLicense.authorities.push(auth);
    }
  }

  public OpenEditLicense(LicenseForm: TemplateRef<any>, license: CloudLicense) {
    this.selectedLicense = license
    this.modalService.show(LicenseForm, {class: 'modal-xl modal-dialog-scrollable'})
  }

  public OpenNewLicense(LicenseForm: TemplateRef<any>) {
    this.selectedLicense = new CloudLicense();
    this.modalService.show(LicenseForm, {class: 'modal-xl modal-dialog-scrollable'})
  }

  public CheckDisabled() {
    return (this.selectedLicense.name === undefined || this.selectedLicense.name === "")  && (this.selectedLicense.maxCabins === undefined || this.selectedLicense.maxCabins === null) && (this.selectedLicense.maxOperators === undefined || this.selectedLicense.maxOperators === null);
  }
  
  public async CreateOrUpdateLicense() {
    if (!this.selectedLicense.id) {
      const success = await lastValueFrom(this.licenseCloudService.CreateLicense(this.selectedLicense));

      if (success) {
        this.modalService.hide();
        this.alertService.success("Licenza generata con successo");

        this.cloudLicenses.push(Object.assign({}, this.selectedLicense));

        this.selectedLicense = new CloudLicense();

        return;
      }
  
      this.alertService.danger("Impossibile generare la licenza");
    } else {
      const success = await lastValueFrom(this.licenseCloudService.EditLicense(this.selectedLicense));

      if (success) {
        this.modalService.hide();
        this.alertService.success("Licenza modificata con successo");
        return;
      }
  
      this.alertService.danger("Impossibile modificare la licenza");
    }

    await this.ngOnInit();
  }

  public async DeleteLicense() {

    const result = await lastValueFrom(this.licenseCloudService.DeleteLicense(this.selectedLicense.id));

    if (result) {
      
      const index: number = this.cloudLicenses.findIndex(l => {
        return l.id === this.selectedLicense.id;
      })
      
      this.cloudLicenses.splice(index, 1);
      this.modalService.hide();
      this.alertService.success("Licenza eliminata");
    } else {
      this.modalService.hide();
      this.alertService.danger("Impossibile eliminare la licenza");
    }
  }
}
