import { UserService } from './../../Services/UserService/user.service';
import { User } from 'src/app/Models/User';
import { AuthService } from './../../Services/AuthService/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AlertService } from 'ngx-alerts';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, Input, OnInit } from '@angular/core';
import { IsDev } from 'src/app/global';

@Component({
  selector: 'app-user-admin-permission-config',
  templateUrl: './user-admin-permission-config.component.html',
  styleUrls: ['./user-admin-permission-config.component.scss']
})
export class UserAdminPermissionConfigComponent implements OnInit {

  
  @Input() public SelectedUser: User;
  

  public SettingsPermessi = {
    Licenze: {
      Generazione: false, //GENERAZIONE NUOVA LICENZA
      Upgrade: false, //AGGIORNAMENTO LICENZA
      Stato: false, //MODIFICA STATO LICENZA
      Rinnovo: false, //RINNOVO LICENZA
      Attivazione: false //ATTIVAZIONE TERZI
    },
    Shop: {
      Inserimento: false,
      Modifica: false //VISUALIZZAZIONE GESTIORE STORE E INTERAZIONE CON QUEST'ULTIMO
    },
    Manuali: {
      Inserimento: false,
      Eliminazione: false,
    },
    Listini: {
      Inserimento: false,
      Eliminazione: false,
    },
    Aggiornamenti: {
      Inserimento: false,
      Eliminazione: false,
    },
    Utenti: {
      Creazione: false,
      Modifica: false,
      Eliminazione: false
    },
    Censimenti: {
      Aggiornamento: false,
      Eliminazione: false
    }
  }

  public Edited: boolean = false;

  public NewSettingPermessi: any;

  constructor(private modalService: BsModalService, private alert: AlertService, private http: HttpClient, private auth: AuthService, private userService: UserService) { }

  ngOnInit(): void {
    this.SettingsPermessi = this.SelectedUser.SettingsPermessi;

    this.NewSettingPermessi = JSON.parse(JSON.stringify(this.SettingsPermessi));
  }

  private GenerateStringPermessiAdmin() {
    let returnValue: string = "";
    //LICENZE
    returnValue += "L" +  (this.SettingsPermessi.Licenze.Generazione ? '1' : '0') + (this.SettingsPermessi.Licenze.Upgrade ? '1' : '0') + (this.SettingsPermessi.Licenze.Stato ? '1' : '0') + (this.SettingsPermessi.Licenze.Rinnovo ? '1' : '0') + (this.SettingsPermessi.Licenze.Attivazione ? '1' : '0');
    //SHOP
    returnValue += "S" +  (this.SettingsPermessi.Shop.Inserimento ? '1' : '0') + (this.SettingsPermessi.Shop.Modifica ? '1' : '0');
    //MANUALI
    returnValue += "M" +  (this.SettingsPermessi.Manuali.Inserimento ? '1' : '0') + (this.SettingsPermessi.Manuali.Eliminazione ? '1' : '0');
    //LISTINI
    returnValue += "N" +  (this.SettingsPermessi.Listini.Inserimento ? '1' : '0') + (this.SettingsPermessi.Listini.Eliminazione ? '1' : '0');
    //AGGIORNAMENTI
    returnValue += "A" +  (this.SettingsPermessi.Aggiornamenti.Inserimento ? '1' : '0') + (this.SettingsPermessi.Aggiornamenti.Eliminazione ? '1' : '0');
    //UTENTI
    returnValue += "U" +  (this.SettingsPermessi.Utenti.Creazione ? '1' : '0') + (this.SettingsPermessi.Utenti.Modifica ? '1' : '0') + (this.SettingsPermessi.Utenti.Eliminazione ? '1' : '0');
    //CENSIMENTI
    returnValue += "C" +  (this.SettingsPermessi.Censimenti.Aggiornamento ? '1' : '0') + (this.SettingsPermessi.Censimenti.Eliminazione ? '1' : '0');

    return returnValue;
  }

  public async SetPermessi() {
    //PERMESSI
    this.SelectedUser.Permessi = this.GenerateStringPermessiAdmin();

    // const AdminId: number = await this.auth.GetIdByUsername();

    await this.userService.SetPermessi(this.SelectedUser);
    
    this.Edited = false;

    this.alert.success("Modifiche salvate");
  }
  

  public CheckSame() {

    if (JSON.stringify(this.SettingsPermessi) !== JSON.stringify(this.NewSettingPermessi)) {
      this.Edited = true;
      return;
    }

    this.Edited = false;
    return;
  }

}
