import Modulo from '../Models/Modulo';

export default class Censimento {
    public PIVA: string;
    public NMAG: number;
    public NCASSA: number;
    public DESMAG: string;
    public RAGSOC: string;
    public LOC: string;
    public VIA: string;
    public CAP: string;
    public PROV: string;
    public SERVB2B: string;
    public IDTRAB2B: string;
    public MATRICOLA: string;
    public AZZER: string;
    public LOTTERIA: string;
    public XML7: string;
    public GIART: string;
    public MAC: string;
    public RIVENDITORE: string;
    public LASTDATA: Date;
    public LASTDATASTRING: string;
    public VP: string;
    public VB: string;
    public DR: string;
    public VU: string;
    public CPU: string;
    public RT_MODEL:string;
    public RT_FW:string;
    public RE_MATR:string;
    public UltimoAccessoInGiorni: number;
    public MODULI: Modulo[] = [];
    public TEAMVIEWER: string = "";
    public CLIENTE:string;
    public AVVIORT: string;
    public LICENZA: string[] = [];
    public INT_SCO: string;
    public ShowView: boolean = false;
    public Hidden: boolean = false;
}
