<ng-template #popTemplate><div [innerHtml]="popupInfo"></div></ng-template>

<!----LOADING-->
<div *ngIf="Loading" style="position: absolute; left: 50%; top: 50%">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!---LICENZE-->
<div class="row" *ngIf="list && auth.Ready && !Loading && !auth.IsCashServizi">
  <div style="height: 100%; width: 100%">
    <div>
      <div class="card" style="padding-bottom: 1rem;font-size: 36px;">
        <div style="text-align: center;margin: 1rem;">Licenze</div>
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.lt-lg="16px">
          <div fxFlex="50%" fxFlex.lt-lg="">
            <div class="licenza-generica">
              <div class="primary-color classic-admin-card">
                <div class="licenze-acquistate card-body" style=" border-radius: 10px;"
                     (click)="ApriDettagli(infoLicenze, 'Acquistate')">
                  <div fxLayout="column">
                    <div fxFlex="50%">
                      <h4 style="color: white;margin: 0;text-align: center;"><strong
                        id="total">Licenze
                        Acquistate</strong></h4>
                    </div>
                    <div fxFlex="50%">
                      <div
                        style="text-align: center; color: white;font-weight: 600;font-size: 30px;">
                        <a role="button">
                          <div style="text-align: center;">{{this.LicenzeAcquistate}}
                          </div>
                        </a>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex="50%">
            <div class="licenza-generica">
              <div class="primary-color classic-admin-card">
                <div class="card-body licenze-a-canone"
                     style=" height: 100%;border-radius: 10px;"
                     (click)="ApriDettagli(infoLicenze, 'Canone')">
                  <h4 style="color: white;margin: 0; text-align: center;"><strong>Licenze A Canone</strong>
                  </h4>
                  <div style="text-align: center;">
                    <a role="button"
                       style="color: white; font-weight: 600;font-size: 30px;">
                      <div style="text-align: center;" *ngIf="this.LicenzeCanone !== -1">
                        {{LicenzeCanone}}
                      </div>
                      <div style="text-align: center;" *ngIf="this.LicenzeCanone === -1">
                        Infinite
                      </div>
                    </a>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        <div class="content" *ngIf="list && this.auth.Ready === true && !Loading" style="padding-top: 1%;">
            <div class="row">
                <div class="col-md-12">
                    <div class="card strpied-tabled-with-hover" id="cardTicket">
                        <div class="card-header ">
                            <h4 class="card-title" style="text-align: center;">Storico Licenze</h4>
                            <div class="md-form active-pink active-pink-2 mb-3 mt-1">
                                <div class="input-group mb-3">
                                    <input class="form-control" type="text" placeholder="Inserisci licenza" id="search" (keyup)="Cerca()" aria-label="Search" />
                                    <div class="input-group-prepend">
                                        <button class="btn btn-primary btn-fill" style="z-index: 0" (click)="auth.IsCashServizi ? PanelCreation(cashservizi) : PanelCreation()" [disabled]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Licenze.Generazione">Crea licenza</button>
                                    </div>
                                  </div>
                            </div>
                            <div class="row" *ngIf="this.auth.AuthUser.Role === 0" style="text-align: center;" >
                              <div class="col-1" >
                                <div class="row center"><input type="checkbox" [(ngModel)]="Filtri.Canone" (ngModelChange)="Cerca()"></div>
                                <div class="row center"><label>CANONE</label></div>
                              </div>
                              <div class="col-1">
                                <div class="row center"><input type="checkbox" [(ngModel)]="Filtri.Spot" (ngModelChange)="Cerca()"></div>
                                <div class="row center"><label>SPOT</label></div>
                              </div>
                              <div class="col-1">
                                <div class="row center"><input type="checkbox" [(ngModel)]="Filtri.Gab" (ngModelChange)="Cerca()"></div>
                                <div class="row center"><label>LICENZE GAB</label></div>
                              </div>
                              <div class="col-1">
                                <div class="row center"><input type="checkbox" [(ngModel)]="Filtri.Concessionari" (ngModelChange)="Cerca()"></div>
                                <div class="row center"><label>ALTRE LICENZE</label></div>
                              </div>  
                            </div>
                            <div class="row" *ngIf="this.auth.AuthUser.Role === 4" style="text-align: center;" >
                              <div class="col-2">
                                <div class="row center"><input type="checkbox" [(ngModel)]="Filtri.Gab" (ngModelChange)="Cerca()"></div>
                                <div class="row center"><label>LICENZE</label></div>
                              </div>
                              <div class="col-2">
                                <div class="row center"><input type="checkbox" [(ngModel)]="Filtri.Concessionari" (ngModelChange)="Cerca()"></div>
                                <div class="row center"><label>CONCESSIONARI</label></div>
                              </div>  
                            </div>
                        </div>
                        <div class="card-body table-full-width table-responsive">
                            <table class="table table-hover table-striped" id="table" width="100%">
                              <ng-container *ngIf="!this.auth.IsCashServizi">
                                <thead>
                                  <tr>
                                      <th width="30%">Licenze</th>
                                      <th (click)="SortLicenses('Creazione')"><a role="button">Data Creazione<fa-icon [icon]="faUp" size="0.5x" *ngIf="this.Sort.DataCreazione === 2"></fa-icon><fa-icon [icon]="faDown" size="0.5x" *ngIf="this.Sort.DataCreazione === 1"></fa-icon></a></th>
                                      <th (click)="SortLicenses('Attivazione')"><a role="button">Data Attivazione<fa-icon [icon]="faUp" size="0.5x" *ngIf="this.Sort.DataAttivazione === 2"></fa-icon><fa-icon [icon]="faDown" size="0.5x" *ngIf="this.Sort.DataAttivazione === 1"></fa-icon></a></th>
                                      <th (click)="SortLicenses('Scadenza')"><a role="button">Data Scadenza<fa-icon [icon]="faUp" size="0.5x" *ngIf="this.Sort.DataScadenza === 2"></fa-icon><fa-icon [icon]="faDown" size="0.5x" *ngIf="this.Sort.DataScadenza === 1"></fa-icon></a></th>
                                      <th (click)="SortLicenses('Limite')"><a role="button">Data Limite Attivazione<fa-icon [icon]="faUp" size="0.5x" *ngIf="this.Sort.DataLimite === 2"></fa-icon><fa-icon [icon]="faDown" size="0.5x" *ngIf="this.Sort.DataLimite === 1"></fa-icon></a></th>
                                      <th>Descrizione</th>
                                      <th *ngIf="this.auth.AuthUser.Role === 4 || this.auth.AuthUser.Role2 === 4" width="10%">Creatore</th>
                                      <th width="10%">Stato Licenza</th>
                                      <th>Info</th>
                                      <th>Upgrade</th>
                                      <th></th>
                                  </tr>
                                </thead>
                              </ng-container>
                              <ng-container *ngIf="this.auth.IsCashServizi">
                                <thead>
                                  <tr>
                                      <th width="30%">Licenze</th>
                                      <th (click)="SortLicenses('Creazione')"><a role="button">Data Creazione<fa-icon [icon]="faUp" size="0.5x" *ngIf="this.Sort.DataCreazione === 2"></fa-icon><fa-icon [icon]="faDown" size="0.5x" *ngIf="this.Sort.DataCreazione === 1"></fa-icon></a></th>
                                      <th (click)="SortLicenses('Attivazione')"><a role="button">Data Attivazione<fa-icon [icon]="faUp" size="0.5x" *ngIf="this.Sort.DataAttivazione === 2"></fa-icon><fa-icon [icon]="faDown" size="0.5x" *ngIf="this.Sort.DataAttivazione === 1"></fa-icon></a></th>
                                      <th (click)="SortLicenses('Scadenza')"><a role="button">Data Scadenza<fa-icon [icon]="faUp" size="0.5x" *ngIf="this.Sort.DataScadenza === 2"></fa-icon><fa-icon [icon]="faDown" size="0.5x" *ngIf="this.Sort.DataScadenza === 1"></fa-icon></a></th>
                                      <th (click)="SortLicenses('Limite')"><a role="button">Data Limite Attivazione<fa-icon [icon]="faUp" size="0.5x" *ngIf="this.Sort.DataLimite === 2"></fa-icon><fa-icon [icon]="faDown" size="0.5x" *ngIf="this.Sort.DataLimite === 1"></fa-icon></a></th>
                                      <th *ngIf="this.auth.AuthUser.ID === 44" width="10%">Creatore</th>
                                      <th width="10%">Stato Licenza</th>
                                      <th></th>
                                  </tr>
                                </thead>
                              </ng-container>

                                
                                <ng-container *ngIf="this.auth.IsCashServizi"> 
                                  <tbody *ngFor="let licenseInfo of this.Licenses; trackBy: licenseInfo">
                                    <tr>
                                        <td><a role="button" [tooltip]="licenseInfo.Creatore !== licenseInfo.Segnalatore ? 'Segnalatore: ' + licenseInfo.Segnalatore : ''" (click)="OpenModalCensimento(censimentoLicenza, licenseInfo.License.License)">{{licenseInfo.License.License}}</a></td>
                                        <td>{{licenseInfo.CreationDate}}</td>
                                        <td>{{licenseInfo.ActivationDate}}</td>
                                        <td>{{licenseInfo.ExpirationDate}}</td>
                                        <td>{{licenseInfo.ActivationDateLimit}}</td>
                                        <td *ngIf="this.auth.AuthUser.ID === 44">{{licenseInfo.Creatore}}</td>
                                        <td>
                                            <select [(ngModel)]="licenseInfo.Stato" class="custom-select" (ngModelChange)="this.licenseService.ChangeLicenzeState(licenseInfo)" [disabled]="(this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Licenze.Stato) || CheckExpired(licenseInfo)">
                                                <option [value]="0" [disabled]="this.auth.AuthUser.Role !== 0">Generata</option>
                                                <option [value]="1">Attivata</option>
                                                <option [value]="2">Sospesa</option>
                                            </select>
                                        </td>
                                        <td></td>
                                    </tbody>
                                </ng-container>
                                <ng-container *ngIf="!this.auth.IsCashServizi">
                                  <tbody *ngFor="let licenseInfo of this.Licenses; trackBy: licenseInfo">
                                      <tr>
                                          <td><a role="button" [tooltip]="licenseInfo.Creatore !== licenseInfo.Segnalatore ? 'Segnalatore: ' + licenseInfo.Segnalatore : ''" (click)="OpenModalCensimento(censimentoLicenza, licenseInfo.License.License)">{{licenseInfo.License.License}}</a></td>
                                          <td>{{licenseInfo.CreationDate}}</td>
                                          <td>{{licenseInfo.ActivationDate}}</td>
                                          <td>{{licenseInfo.ExpirationDate}}</td>
                                          <td>{{licenseInfo.ActivationDateLimit}}</td>
                                          <td>{{licenseInfo.Descrizione}}</td>
                                          <td *ngIf="this.auth.AuthUser.Role === 4 || this.auth.AuthUser.Role2 === 4">{{licenseInfo.Creatore.toUpperCase()}}</td>
                                          <td>
                                              <select [(ngModel)]="licenseInfo.Stato" class="custom-select" (ngModelChange)="this.licenseService.ChangeLicenzeState(licenseInfo)" [disabled]="(this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Licenze.Stato) || CheckExpired(licenseInfo)">
                                                  <option [value]="0" [disabled]="this.auth.AuthUser.Role !== 0">Generata</option>
                                                  <option [value]="1">Attivata</option>
                                                  <option [value]="2">Sospesa</option>
                                              </select>
                                          </td>
  
                                          <!--INFO-->
                                          <td>
                                            <button class="btn" style="border: none" type="button" [tooltip]="GetTooltip(licenseInfo.License.Modules)" [style.display]="licenseInfo.License.Modules !== 'DZZZZ' && licenseInfo.License.Modules.substr(5) !== '00000000' ? '' : 'none'">
                                              <fa-icon class="center" [icon]="faInfo" size="2x"></fa-icon>
                                            </button>
                                          </td>
  
                                          <!--UPGRADE-->
                                          <td>
                                            <button class="btn" style="border: none" type="button" (click)="OpenModalUpgrade(licenseInfo, upgradeLicenza, false)" [style.display]="licenseInfo.License.Modules !== 'DZZZZ' && licenseInfo.Stato !== 2 ? '' : 'none'" [disabled]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Licenze.Upgrade">
                                              <fa-icon class="center" [icon]="faPen" size="2x"></fa-icon>
                                            </button>
                                          </td>
  
                                          <!--LICENZE CLIENT-->
                                          <td>
                                            <button class="btn" style="border: none" type="button" [style.display]="licenseInfo.LicenseClient.length > 0 ? '' : 'none'" (click)="licenseInfo.ShowClient = !licenseInfo.ShowClient">
                                              <fa-icon class="center" [icon]="faDown" size="2x" *ngIf="!licenseInfo.ShowClient"></fa-icon>
                                              <fa-icon class="center" [icon]="faUp" size="2x" *ngIf="licenseInfo.ShowClient"></fa-icon> 
                                            </button>
                                          </td>
                                      </tr>
                                      <ng-container *ngIf="licenseInfo.ShowClient">
                                        <tr *ngFor="let client of licenseInfo.LicenseClient" style="background-color: white;">
                                          <td style="padding-left: 5%"><a role="button" (click)="OpenModalCensimento(censimentoLicenza, client.License.License)">{{client.License.License}}</a></td>
                                          <td>{{client.CreationDate}}</td>
                                          <td>{{licenseInfo.ActivationDate}}</td>
                                          <td>{{licenseInfo.ExpirationDate}}</td>
                                          <td>{{licenseInfo.ActivationDateLimit}}</td>
                                          <td>Licenza Client</td>
                                          <td *ngIf="this.auth.AuthUser.Role === 0"></td>
                                          <td>
                                              <select [(ngModel)]="client.Stato" class="custom-select" [disabled]="licenseInfo.Stato != 1 || CheckExpired(client)" (ngModelChange)="this.licenseService.ChangeLicenseStateClient(client)">
                                                  <option [value]="0" [disabled]="this.auth.AuthUser.Role !== 0">Generata</option>
                                                  <option [value]="1">Attivata</option>
                                                  <option [value]="2">Sospesa</option>
                                              </select>
                                          </td>
  
                                          <!--INFO-->
                                          <td>
                                            <button class="btn" style="border: none" type="button" [tooltip]="GetTooltip(client.License.Modules)" [style.display]="client.License.Modules !== 'DZZZZ' && client.License.Modules.substr(5) !== '00000000' ? '' : 'none'">
                                              <fa-icon class="center" [icon]="faInfo" size="2x"></fa-icon>
                                            </button>
                                          </td>
  
                                          <!--UPGRADE-->
                                          <td>
                                            <button class="btn" style="border: none" type="button" (click)="OpenModalUpgrade(client, upgradeLicenza, true)" [style.display]="client.License.Modules !== 'DZZZZ' && client.Stato !== 2 ? '' : 'none'" [disabled]="this.auth.AuthUser.Role === 0 && !this.auth.AuthUser.SettingsPermessi.Licenze.Upgrade">
                                              <fa-icon class="center" [icon]="faPen" size="2x"></fa-icon>
                                            </button>
                                          </td>
                                          <td></td>
                                      </tr>
                                      </ng-container>
                                  </tbody>
                                </ng-container>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <button class="btn btn-primary btn-fill" (click)="this.Back()" *ngIf="!list" style="margin-top: 1%;">Indietro</button>
        <div class="content fade-in" *ngIf="!list">
            <app-license-genera [licenzeStats]="this.licenzeStats" [Licenses]="this.Licenses" (LoadLicenses)="handleLicense($event)"></app-license-genera>
        </div>

<ng-template #censimentoLicenza>
  <!--
  <div class="card">
    <div class="card-header">
      <p class="card-title"><b>Censimento</b></p>
    </div>
    <div class="card-body" *ngIf="censimentoInfo.esiste">
      <p>Partita IVA: <b>{{censimentoInfo.PIVA}}</b></p>
      <p>Ragione Sociale: <b>{{censimentoInfo.RAGSOC}}</b></p>
      <p>NMAG: <b>{{censimentoInfo.NMAG}}</b></p>
      <p>NCASSA: <b>{{censimentoInfo.NCASSA}}</b></p>
      <p>
        MAC Address: <b><input type="text" [disabled]="!editMacAddress" [(ngModel)]="censimentoInfo.MAC" (change)="CheckMacAddress()"></b>&nbsp;&nbsp;
        <a role="button">
          <fa-icon [icon]="faPen" (click)="editMacAddress = true" *ngIf="!editMacAddress"></fa-icon>

          <fa-icon [icon]="faSave" (click)="SaveModalMACAddress()" *ngIf="editMacAddress" [class.fa-disabled]="CheckMacAddress()"></fa-icon>
        </a>
      </p>
    </div>
    <div class="card-body" *ngIf="!censimentoInfo.esiste">
      <p>La seguente licenza non è associata a nessun MAC Address</p>
    </div>
    <div class="card-footer" style="text-align: center;">
      <button class="btn btn-primary" (click)="this.modalService.hide()">Chiudi</button>
    </div>
  </div>
  -->
  <app-censimento-info [censimentoInfo]="this.censimentoInfo"></app-censimento-info>
</ng-template>


<ng-template #infoLicenze>
    <div class="card strpied-tabled-with-hover" style="border: 0px;">
        <div class="card-body">
            <table class="table table-hover table-striped" id="table">
                <thead>
                    <ng-container *ngIf="StatsModal === 'Canone'">
                        <tr>
                            <th>Codice Articolo</th>
                            <th>Licenze Acquistate</th>
                            <th>Licenze Generate</th>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="StatsModal === 'Acquistate'">
                        <tr>
                            <th>Codice Articolo</th>
                            <th>Descrizione</th>
                            <th>Licenze Acquistate</th>
                            <th>Licenze Generate</th>
                        </tr>
                    </ng-container>
                </thead>
                <tbody>
                    <tr *ngFor="let licenzeStat of this.licenzeStats">
                        <ng-container *ngIf="StatsModal === 'Canone' && licenzeStat.CodiceArticolo === 'CANONE'">
                            <td>Canone</td>
                            <td *ngIf="licenzeStat.NumeroLicenze !== -1">{{licenzeStat.NumeroLicenze}}</td>
                            <td *ngIf="licenzeStat.NumeroLicenze === -1">Infinite</td>
                            <td>{{licenzeStat.LicenzeGenerate}}</td>
                        </ng-container>
                        <ng-container *ngIf="StatsModal === 'Acquistate' && licenzeStat.CodiceArticolo !== 'CANONE'">
                            <td>{{licenzeStat.CodiceArticolo}}</td>
                            <td>{{licenzeStat.Descrizione}}</td>
                            <td>{{licenzeStat.NumeroLicenze}}</td>
                            <td>{{licenzeStat.LicenzeGenerate}}</td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer" style="text-align: right;">
            <div class="row">
                <div class="col">
                    <a role="button" class="btn btn-primary" (click)="this.modalRef.hide()">
                        Chiudi
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #upgradeLicenza>
    <app-license-upgrade [License]="this.UpgradeLicenze" [CanRetail]="this.CanRetail" [Client]="this.IsClient"
        (refreshStats)="this.LoadStats()"
    ></app-license-upgrade>
</ng-template>


<ng-template #cashservizi>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <img style="height: auto; margin: auto; width: 50%; padding-bottom: 1rem;" src="../../assets/img/loghi/appagato_icon.png">
          </div>
          <div class="row" style="padding-bottom: 1rem;">
            <div class="col" style="text-align: center;">
              <select class="custom-select" [(ngModel)]="this.cashmaticSelected">
                <option selected [value]="false">Seleziona</option>
                <option [value]="true">APPagato</option>
              </select>
            </div>
          </div>
          <div class="row" *ngIf="this.UserConcessionari.length > 0">
            <div class="col">
              <div style="display: table; height: 100%;">
                <div style="display: table-cell; vertical-align: middle;">
                  Seleziona concessionario
                </div>
              </div>
            </div>
            <div class="col">
              <select class="custom-select" [(ngModel)]="this.SelectedConcessionarioID">
                <option [ngValue] = 0>Nessuno</option>
                <option *ngFor="let conc of this.UserConcessionari" [ngValue]="conc.ID">{{conc.Username}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" *ngIf="this.cashmaticGeneratedLicense !== ''" style="width: 100%;">
          <div class="col" style="text-align: center;">
            <p>La licenza generata è:</p>
            <p><b>{{this.cashmaticGeneratedLicense}}</b><a role="button"><fa-icon style="padding-left: 5px;" (click)="copyClickboard()" [icon]="faClipboard"></fa-icon></a></p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row" style="width: 100%;">
          <div class="col">
            <button type="button" class="btn btn-secondary" (click)="this.modalRef.hide()" style="float: left;">Chiudi</button>
          </div>
          <div class="col">
            <button style="float: right;" type="button" class="btn btn-primary" (click)="GenerateLicenseCashmatic()" [disabled]="!this.cashmaticSelected || this.cashmaticGeneratedLicense !== ''">Genera</button>    
          </div>
        </div>
      </div>
</ng-template>


