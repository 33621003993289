import { IsDev } from './../global';


import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../Services/AuthService/auth.service';
import {ChangeDetectorRef} from '@angular/core';
import {faDownload, faTrash, IconDefinition } from '@fortawesome/free-solid-svg-icons';


import Item from '../Models/Item';
import {FileService} from '../Services/FileService/file.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manuals',
  templateUrl: './manuals.component.html',
  styleUrls: ['./manuals.component.scss']
})
export class ManualsComponent implements OnInit {

  
  public Manuals: Item[] = [];
  
  public SoftCodes: string[2][] = [];
  public ready: boolean = false;

  //ALERT
  public alertOpen: boolean = false;
  public alertOpenSuccess: boolean = false;
  public alertMsg: string = "";

  //ICONS
  public faTrash: IconDefinition = faTrash;
  public faDownload: IconDefinition = faDownload;


  //ELIMINA
  public modalRef: BsModalRef;
  public ManualDaEliminare: Item;


  constructor(private http: HttpClient, public auth: AuthService, private router: Router, public FileService: FileService, private changeDetectorRef: ChangeDetectorRef, public modalService: BsModalService) { 
   
  }

  private async Init() {

    this.auth.Ready = false;
    //AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName("li");
    for (let i = 0; i < sidebarElements.length; ++i) {
      if (sidebarElements[i].id !== 'manuals') {
        sidebarElements[i].classList.remove('active');
      } else {
        sidebarElements[i].classList.add('active');
      }
    }


    await this.LoadManuals();
    await this.InitSoftCodes();

    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }
    this.auth.Ready = true;

    

  }
  
  public fileToUpload: File = null;

  private async InitSoftCodes() {
    const result: any = await this.FileService.InitSoftcodes();
    this.SoftCodes = result;
    this.ready = true;
  }


  private async LocalValidation() {
    
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));
      
      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if(valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.IsLogin = true;
        this.auth.Ready = false;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }

  async ngOnInit() {
    await this.Init();
  }

  public async LoadManuals() {
    this.Manuals = await this.FileService.GetManuals();
    
    return;
    
  }

  private GenerateManual(result: any): Item {
    return new Item(result.product, result.version, result.softCode, result.downloadFileName);
  }

  public FileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    //this.FileService.FileUpload(this.fileToUpload);
  }

  public async UploadManual() {
    const prodotto: string = (document.getElementById('prodotto') as HTMLInputElement).value;
    const version: string = (document.getElementById('versione') as HTMLInputElement).value;
    
    const softcodeDesc: HTMLSelectElement = (document.getElementById('softcode') as HTMLSelectElement);

    const softcodeSelected: string = softcodeDesc.options[softcodeDesc.selectedIndex].value;
    const softcode: string = this.SoftCodes.find(softcode => softcodeSelected === softcode[0])

    if (prodotto === "" || version === "" || this.fileToUpload === null) {
      this.alertMsg = "Inserire tutti i campi per continuare";
      this.alertOpenSuccess = true;
      setTimeout(() => {
        this.alertOpenSuccess = false;
      }, 5000)
    } else {
      await this.FileService.ManualUpload(this.fileToUpload, prodotto, version, softcode[1]);
      this.alertMsg = "Caricamento completato";
      this.alertOpenSuccess = true;
      setTimeout(() => {
        this.alertOpenSuccess = false;
      }, 5000)
      this.Manuals.push(this.GenerateManual({product: prodotto, version: version, softCode: softcode[1], downloadFileName: this.fileToUpload.name}));
    }
  }

  public OpenModalDelete(template: TemplateRef<any>, manual: Item) {
    this.ManualDaEliminare = manual;
    this.modalRef = this.modalService.show(template);
  }


  public async DeleteManual(product: string, softCode: string, fileName: string) {

    const result: boolean = await this.FileService.DeleteManual(product, softCode, fileName);
    
    //SE SUCCESS, ELIMINO LA RIGA
    if (result) {
      for (let i = 0; i < this.Manuals.length; ++i) {
        if (this.Manuals[i].downloadFileName === fileName && this.Manuals[i].product === product && this.Manuals[i].softCode === softCode) {
          this.Manuals.splice(i, 1);  //Elimino la riga
          this.changeDetectorRef.detectChanges();
          this.modalService.hide();
          return;
        }
      }
    }
    
  }

  public Filter() {
    const input: string = (document.getElementById("search") as HTMLInputElement).value;
    
    const table: HTMLTableElement = document.getElementById("table") as HTMLTableElement;
    const tr: HTMLCollectionOf<HTMLTableRowElement> = table.getElementsByTagName("tr");

    for (let i = 0; i < tr.length; i++) {
      const td: HTMLTableDataCellElement = tr[i].getElementsByTagName("td")[0];
      
      if (td) {
          const txtValue: string = td.innerHTML;

          if (txtValue.toUpperCase().includes(input.toUpperCase())) {
              tr[i].style.display = "";
          } else {
              tr[i].style.display = "none";
          }
      }
    }
  }

  public fileName(i: number, ) {
    return i;
  }

}
