<div class="content fade-in">
    <div class="container-fluid">

        <div class="card"  *ngIf="this.auth.AuthUser.Role === 0 && this.auth.AuthUser.SettingsPermessi.Aggiornamenti.Inserimento">
            <div class="card-header">
                <h4 class="card-title">Inserisci update</h4>
            </div>
            <div class="card-body">

                <div class="messages"></div>

                <div class="controls">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="form_name">Software *</label>
                                <select id="softcode" type="text" name="softcode" class="form-control" placeholder="Inserire il codice software *" required="required" *ngIf="ready">
                                    <option *ngFor="let softcode of this.SoftCodes" (value)="softcode">{{softcode[0]}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="form_lastname">Versione *</label>
                                <input id="version" type="text" name="versione" class="form-control" placeholder="Inserire la versione *" required="required" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="form_email">Data di rilascio</label>
                                <input id="data" type="date" name="data" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="input-group">
                                    <div class="form-group">
                                        <input id="file-upload" type="file" (change)="FileInput($event.target.files)">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <span class="btn btn-primary btn-fill" style="float: right;" (click)="UploadUpdate()">UPLOAD</span>
                        </div>
                    </div>
                </div>
                <div class="spinner-border" role="status" *ngIf="showSpinner">
                    <span class="sr-only">Loading...</span>
                  </div>
                <alert style="width: 100%; margin-top: 10%;" type="danger" [dismissible]="true" [isOpen]="alertOpen" >
                    <strong style="text-align: center;">{{alertMsg}}</strong>
                </alert>
                <alert style="width: 100%; margin-top: 10%;" type="success" [dismissible]="true" [isOpen]="alertOpenSuccess">
                    <strong style="text-align: center;">{{alertMsg}}</strong>
                </alert>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12" >
                <div class="card strpied-tabled-with-hover" id="cardTicket">
                    <div class="card-header">
                        <h4 class="card-title">Lista Software</h4>
                    </div>
                    <div class="card-body table-full-width table-responsive">
                        <table class="table table-hover table-striped" id="table">
                            <thead>
                                <tr>
                                    <th>Software</th>
                                    <th>Versione</th>
                                    <th>Data Rilascio</th>
                                    <th>Download</th>
                                    <th  *ngIf="this.auth.AuthUser.Role === 0 && this.auth.AuthUser.SettingsPermessi.Aggiornamenti.Eliminazione">Elimina</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let update of this.UpdatesPrint; trackBy: fileName" >
                                <ng-container *ngIf="this.auth.AuthUser.SoftCodes.includes(update.softCode)">
                                    <tr>
                                        <td (click)="this.FileService.DownloadUpdate(update)">{{update.softwareName}}</td>
                                        <td (click)="this.FileService.DownloadUpdate(update)">{{update.version}}</td>
                                        <td (click)="this.FileService.DownloadUpdate(update)">{{update.releaseDatePrint}}</td>

                                        <td *ngIf="!update.downloading"><a (click)="this.FileService.DownloadUpdate(update)" role="button"><fa-icon [icon]="faDownload" size="2x"></fa-icon></a></td>
                                        <td *ngIf="update.downloading">
                                            {{this.FileService.Progress}}%
                                        </td>

                                        <td  *ngIf="this.auth.AuthUser.Role === 0 && this.auth.AuthUser.SettingsPermessi.Aggiornamenti.Eliminazione"><a role="button" (click)="this.OpenModalDelete(modalElimina, update)"><fa-icon [icon]="faTrash" size="2x"></fa-icon></a></td>
                                        <td (click)="update.IsSubmenu = !update.IsSubmenu;" *ngIf="this.GetUpdatesFromSoftcode(update.softCode).length > 0">
                                            <fa-icon [icon]="faPlus" size="2x" *ngIf="!update.IsSubmenu"></fa-icon>
                                            <fa-icon [icon]="faMinus" size="2x" *ngIf="update.IsSubmenu"></fa-icon>
                                        </td>
                                        <td *ngIf="this.GetUpdatesFromSoftcode(update.softCode).length === 0"></td>
                                    </tr>
                                    <ng-container *ngIf="this.GetUpdatesFromSoftcode(update.softCode).length > 0 && update.IsSubmenu">
                                        <tr *ngFor="let updateShow of this.GetUpdatesFromSoftcode(update.softCode)" style="background-color: white;">
                                            <td></td>
                                            <td>{{updateShow.version}}</td>
                                            <td >{{updateShow.releaseDatePrint}}</td>
                                            <td *ngIf="!updateShow.downloading"><a (click)="this.FileService.DownloadUpdate(updateShow)" role="button"><fa-icon [icon]="faDownload" size="2x"></fa-icon></a></td>
                                            <td *ngIf="updateShow.downloading">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                  </div>
                                            </td>
                                            <td *ngIf="this.auth.AuthUser.Role === 0 && this.auth.AuthUser.SettingsPermessi.Aggiornamenti.Eliminazione"><a role="button" (click)="this.OpenModalDelete(modalElimina, updateShow)"><fa-icon [icon]="faTrash" size="2x"></fa-icon></a></td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #modalElimina>
    <!--Body-->
    <div class="modal-body body-custom">
        <div class="text-center">
            <i class="fa fa-trash-o fa-4x mb-3 animated rotateIn fa-custom"></i>
            <p>Sei sicuro di voler eliminare il file?</p>
        </div>
    </div>

    <!--Footer-->
    <div class="modal-footer justify-content-center">
        <a role="button" class="btn btn-outline-danger waves-effect" (click)="this.modalService.hide()">No</a>
        <a role="button" class="btn btn-danger" (click)="this.DeleteUpdate(this.UpdateDaEliminare)" style="margin-left: 5%;">Si</a>
    </div>
</ng-template>
