<div class="fade-in" >
  <app-shopheader></app-shopheader>
  <router-outlet></router-outlet>

  <!--
        <div class="owl-item active" style="width: 350px; margin-right: 20px;">
              <div class="single-product">
                <div class="product-f-image">
                  <img src="./../../../assets/img/singola-licenza.png" style="height: 300px;width: 350px;" alt="">
                  <div class="product-hover">
                    <a href="#" class="add-to-cart-link"><i class="fa fa-shopping-cart"></i> Aggiungi al carrello</a>
                    <a href="single-product.html" class="view-details-link"><i class="fa fa-link"></i> See details</a>
                  </div>
              </div>

              <h2><a>Singola licenza</a></h2>

              <div class="product-carousel-price">
                <ins>€700.00</ins> <del>$100.00</del>
              </div>
            </div>
            </div>
        -->

</div>
