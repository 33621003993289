<div fxLayout="row" fxLayout.lt-lg="column">
  <div class="cart_totals" style="border-right:hidden" fxFlex.lt-lg="100%">
    <h4 class="titoli-checkout">Il tuo ordine:</h4>
    <table cellspacing="0">
      <tbody>
      <tr>
        <th>Carrello Subtotale</th>
        <td><span class="amount" *ngIf="!loading">{{this.TotalIVA.toFixed(2)}}€</span></td>
      </tr>
      <tr class="order-total">
        <th>IVA 22%</th>
        <td><strong><span class="amount" *ngIf="!loading">{{IVA.toFixed(2)}}€</span></strong></td>
      </tr>
      <tr class="order-total">
        <th>Totale (Escl. Iva)</th>
        <td><strong><span class="amount" *ngIf="!loading">{{this.shopService.GetCarrelloTotal().toFixed(2)}}€</span></strong></td>
      </tr>
      <tr class="shipping">
        <th>Spedizione</th>
        <td>Gratis</td>
      </tr>
      <tr class="order-total">
        <th>Totale ordine</th>
        <td><strong><span class="amount" *ngIf="!loading">{{this.TotalIVA.toFixed(2)}}€</span></strong></td>
      </tr>
      </tbody>
    </table>
    <div class="termscondition">
      <div id="div_termini_condizioni">
        <input type="checkbox" id="termini_condizioni" [(ngModel)]="this.termini_condizioni" [disabled]="this.shopService.Stripe || this.shopService.Paypal || this.shopService.Bonifico"/>
        <label for="termini_condizioni" >
          &nbsp;Accetto termini e condizioni
        </label>
      </div>
      <div  id="div_condizioni_vendita">
        <input type="checkbox" id="condizioni_vendita"  [(ngModel)]="this.condizioni_vendita" [disabled]="this.shopService.Stripe || this.shopService.Paypal || this.shopService.Bonifico"/>
        <label for="condizioni_vendita">
          &nbsp;Accetto le condizioni di vendita
        </label>
      </div>
    </div>

    <!--
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row">
        <div style="padding: 25px 20px;">
          <input type="radio" name="rdo" id="opt1" class="hidden"/>
        </div>
        <div style="padding: 20px 0;font-size: 20px" >Carta di Credito</div>
      </div>
      <div fxLayout="row">
        <div style="padding: 25px 20px;">
          <input type="radio" name="rdo" id="opt2" class="hidden"/>
        </div>
        <div style="padding: 20px 0;font-size: 20px">Paypal</div>
      </div>
      <div fxLayout="row">
        <div style="padding: 25px 20px;">
          <input type="radio" id="Bonifico_Bancario" name="scelta_pagamento" required/>
        </div>
        <div style="padding: 20px 0;font-size: 20px">Bonifico bancario</div>
      </div>
    </div>
    <div class="cntr">

      <label for="opt1" class="radio">

        <span class="label"></span>Adobe
      </label>

      <label for="opt2" class="radio">

        <span class="label"></span>Figma
      </label>

      <label for="opt3" class="radio">
        <input type="radio" name="rdo" id="opt3" class="hidden"/>
        <span class="label"></span>InVision
      </label>

      <label for="opt4" class="radio">
        <input type="radio" name="rdo" id="opt4" class="hidden"/>
        <span class="label"></span>Sketch
      </label>

    </div>
-->
  </div>

  <!--SCEGLI PAGAMENTO-->
  <div class="cart_totals" style="border-left:hidden"
       *ngIf="!shopService.Stripe && !shopService.Paypal && !shopService.Bonifico">
    <h4 class="titoli-checkout">Scegli metodo di pagamento:</h4>
    <div fxLayout="column" fxLayoutGap="10px" style="padding: 73px 0;">
      <button type="button" class="btn btn-primary choose-payment-buttons" (click)="PayWithStripe()">CARTA DI CREDITO
      </button>
      <!--
      <button type="button" class="btn btn-warning choose-payment-buttons" style="color: white"
              (click)="PayWithPaypal()">PAYPAL
      </button>
      -->
      <button type="button" class="btn btn-dark choose-payment-buttons" (click)="PayWithBonifico()">BONIFICO BANCARIO
      </button>
    </div>
  </div>

  <!--SEGNALO PAGAMENTO STRIPE-->
  <div class="cart_totals stripe" *ngIf="shopService.Stripe && !shopService.Paypal && !shopService.Bonifico">
    <div class="titoli-checkout">
      <h4>Stai pagando con <b>carta di credito</b></h4>
      <button type="button" class="btn btn-primary" style="width: 270px" (click)="resetPayments()">CAMBIA METODO
        PAGAMENTO
      </button>
    </div>
    <div style="padding: 73px 0;text-align: center;">
      <div id="circle-loader" class="circle-loader">
        <div id="checkmark" class="checkmark draw"></div>
      </div>
    </div>
  </div>
  <!--SEGNALO PAGAMENTO PAYPAL-->
  <div class="cart_totals" *ngIf="!shopService.Stripe && shopService.Paypal && !shopService.Bonifico">
    <div class="titoli-checkout">
      <h4>Stai pagando con <b>PAYPAL</b></h4>
      <button type="button" class="btn btn-primary" style="width: 270px" (click)="resetPayments()">CAMBIA METODO
        PAGAMENTO
      </button>
    </div>
    <div style="padding: 73px 0;text-align: center;">
      <div class="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
  <!--SEGNALO PAGAMENTO BONIFICO-->
  <div class="cart_totals" *ngIf="!shopService.Stripe && !shopService.Paypal && shopService.Bonifico">
    <div class="titoli-checkout">
      <h4>Stai pagando con <b>BONIFICO BANCARIO</b></h4>
      <button type="button" class="btn btn-primary" style="width: 270px" (click)="resetPayments()">CAMBIA METODO
        PAGAMENTO
      </button>
    </div>
    <div style="padding: 73px 0;text-align: center;">
      <div id="circle-loader-stripe" class="circle-loader">
        <div id="checkmark-stripe" class="checkmark draw"></div>
      </div>
    </div>
  </div>

</div>
<!--PAGAMENTO STRIPE-->
<div class="payment-section" *ngIf="shopService.Stripe && !shopService.Paypal && !shopService.Bonifico">
  <stripe-payment (pagamentoAvvenuto)="pagamentoAvvenuto($event)" [Pagamento]="this.Pagamento"></stripe-payment>
</div>
<!--PAGAMENTO PAYPAL-->
<div class="payment-section" *ngIf="!shopService.Stripe && shopService.Paypal && !shopService.Bonifico">
  <p> ciao</p>
</div>
<!--PAGAMENTO BONIFICO-->
<div class="payment-section" *ngIf="!shopService.Stripe && !shopService.Paypal && shopService.Bonifico">
  <div class="payment-bank-transfer">
    Gab Tamagnini <br>
    MONTE DEI PASCHI DI SIENA<br>
    IBAN - IT 19 P 01030 12807 000000499837  <br>
    Swift code: PASCITM1307
  </div>
  <button class="btn btn-success" style="height: 60px;
    margin-bottom: 20px;" (click)="AcquistaOraBonifico(PagamentoAvvenuto)">ACQUISTA ORA
  </button>
</div>

<ng-template #PagamentoAvvenuto>
  <div class="row pop-up">
    <div class="box small-6 large-centered modal-pagamento-avvenuto" >
      <h3 style="background-color: #0757ad">Grazie!</h3>
      <p>La ringraziamo per aver acquistato su <b>Gab Servizi</b> </p>
      <p>Riceverà a breve una mail con lo stato dell'ordine</p>
      <div style="width: 281px;margin: auto;">
        <div fxLayout="row" fxLayoutGap="10px" >
          <div (click)="GoToShop()" >
            <a  style="margin: 0 auto;
                  background-color: #0757ad;
                  margin-bottom: 33px;
                  color: white;
                  padding: 1rem;cursor: pointer">Torna allo shop
            </a>
          </div>
          <div (click)="GoToProfilo()" >
            <a style="margin: 0 auto;
            background-color: #0757ad;
            margin-bottom: 33px;
            color: white;
            padding: 1rem;cursor: pointer">Vai sul profilo
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
