export default class SoftwareListino {
    public CodiceArticolo: string
    public Descrizione: string
    public Testo: string;
    public Costo: number
    public Img: File;
    public ImgName: string = '';
    public IdCat: number;
    public IdSubCat: number;
    public Ordine: number;

    public constructor() {
        this.CodiceArticolo = "ND000000";
        this.Descrizione = "";
        this.Costo = 0;
        this.IdSubCat = -1;
        this.Img = null;
        this.Testo = '';
    }
}
