import { User } from 'src/app/Models/User';
import { AlertService } from 'ngx-alerts';
import { UserService } from './../Services/UserService/user.service';
import { waitForAsync } from '@angular/core/testing';
import { IsDev } from './../global';

import {Component, HostListener, OnInit, TemplateRef} from '@angular/core';

import { AuthService } from '../Services/AuthService/auth.service';
import { Router } from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public year: number = new Date().getFullYear();
  public alertMsg: string = "";
  public alertOpen = false;

  public modalRef: BsModalRef;

  //ANIMAZIONI
  public IsRimpicciolire:boolean = false;
  public newUserLoading: boolean = false;
  //REGISTRAZIONE
  public ErroreRegistrazione: boolean = false;
  public loading: boolean = false;
  public userInfo: UserInfo = new UserInfo(); //MAIN
  //VALIDAZIONE SIGN-UP
  public usernameEmpty: boolean = false;
  public usernameTooShort: boolean = false;
  public usernameNotCorrect:boolean = false;
  public passwordEmpty: boolean = false;
  public passwordTooShort: boolean = false;
  public pivaEmpty: boolean = false;
  public pivaNotCorrect: boolean = false;
  public ragsocEmpty: boolean = false;
  public cittaEmpty: boolean = false;
  public viaEmpty: boolean = false;
  public emailEmpty: boolean = false;
  public emailNotCorrect: boolean = false;
  public pecEmpty: boolean = false;
  public pecNotCorrect: boolean = false;
  public sdiEmpty: boolean = false;
  public sdiNotCorrect: boolean = false;
  public ibanEmpty: boolean = false;
  public ibanNotCorrect: boolean = false;

  //RECUPERA PASSWORD
  public IsPassword: boolean = false;
  public Username: string = "";

  //REGISTRAZIONE
  public IsSignUp:boolean = false;
  private errors: any[];

  //ADAPTIVE
  public lglt: boolean;

  //LOADING SPINNER
  public loadingLogin: boolean = false;

  constructor(private auth: AuthService, private router: Router, private user: UserService,private alert: AlertService,public UserService: UserService,public modalService: BsModalService) {

  }
  @HostListener('window:resize', ['$event'])
  setLtLg(event?): void{
    if (window.innerWidth < 1279){
      this.lglt = true;
    }else{
      this.lglt = false;
    }
  }

  

  private async InitLogin() {
    this.setLtLg();
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));

      if (new Date().getTime() > expirationDate.getTime()) {
        this.auth.Logout();
      } else {
        //HO IL TOKEN SALVATO, LO CONVALIDO ED ACCEDO
        if (await this.auth.ValidateLogin()) {
          this.auth.IsLogin = false;
          this.auth.IsValidationDone = true;
          this.GoHomePage();
        } else {
          this.auth.IsLogin = true;
          this.auth.Logout();
        }
      }

    }
  }

  //Login quando premo enter

  private async GoHomePage() {
    await this.router.navigate(['']);
  }

  public async RecoverPass() {
    (document.getElementById('recoverPass') as HTMLButtonElement).disabled = true;
    (document.getElementById('recoverPassTitle') as HTMLParagraphElement).textContent = "E-mail inviata";

    const result: boolean = await this.user.RecoverPass(this.Username);
  }

  public async Login() {
    this.VerificaCM();
    this.loadingLogin = true;

    const form: HTMLFormElement = document.querySelector('form');
    const username: string = form.elements['login-username'].value
    const password: string = form.elements['login-password'].value;

    //CHECK INSERIMENTO USER PASSWORD
    if (username === "" || password === "") {
      this.alertMsg = "Inserisci nome utente e password per continuare";
      this.alertOpen = true;
      setTimeout(() => {
        this.alertOpen = false;
      }, 5000)
      return;
    }

    //EFFETTUO IL LOGIN
    const success: boolean = await this.auth.Login(username, password);
    
    if (success === null) {
      this.alertMsg = "Server in manutenzione";
      this.alertOpen = true;
      this.loadingLogin = false;
      return;
    }

    if (!success) {
      this.alertMsg = "Nome utente e/o password errati";
      this.alertOpen = true;
      this.loadingLogin = false;
      setTimeout(() => {
        this.alertOpen = false;
      }, 5000)
      this.auth.Logout();
      return;
    } else {
      //VADO ALLA PAGINA SUCCESSIVA

      // SE L'UTENTE E' DI CASHMATIC ED E' SOLO UN UTENTE, NON GLI PERMETTO IL LOGIN
      if (this.auth.AuthUser.Stato !== 0 || (this.auth.IsCashServizi && this.auth.AuthUser.Role === 2)) {
        if (this.auth.IsCashServizi == true){
          this.alertMsg = "Impossibile accedere a CashmaticServizi";
        } else this.alertMsg = "Impossibile accedere a GAB Servizi";
        this.loadingLogin = false;
        this.alertOpen = true;
        this.loadingLogin = false;
        setTimeout(() => {
          this.alertOpen = false;
          this.auth.Logout();
        }, 5000)
        return;
      } else if (this.auth.IsCashServizi == true && (this.auth.AuthUser.Creatore != 'cashmatic' && this.auth.AuthUser.Username != 'cashmatic')) {
        this.alertMsg = "Impossibile accedere a CashmaticServizi";
        this.loadingLogin = false;
        this.alertOpen = true;
        this.loadingLogin = false;
        setTimeout(() => {
          this.alertOpen = false;
          this.auth.Logout();
        }, 5000)
        return;
      }



      this.auth.IsLogin = false;
      this.loadingLogin = false;

      await this.GoHomePage();
    }
  }

  VerificaCM(){
    let str: string = window.location.origin;
    if (str.toUpperCase().indexOf("CASHMATIC") > -1) {
      this.auth.IsCashServizi = true;
    }
  }

  async ngOnInit() {
    this.VerificaCM();
    await this.InitLogin();
  }

  async IndietroDaRegistrazione() {
    this.ibanEmpty = false;
    this.usernameEmpty = false;
    this.usernameTooShort = false;
    this.usernameNotCorrect = false;
    this.passwordEmpty = false;
    this.pivaEmpty = false;
    this.ragsocEmpty = false;
    this.cittaEmpty = false;
    this.passwordTooShort = false;
    this.pivaEmpty= false;
    this.pivaNotCorrect = false;
    this.ragsocEmpty= false;
    this.cittaEmpty= false;
    this.viaEmpty = false;
    this.emailEmpty = false;
    this.emailNotCorrect = false;
    this.pecEmpty = false;
    this.pecNotCorrect = false;
    this.sdiEmpty = false;
    this.ibanEmpty = false;
    this.sdiNotCorrect = false;
    this.ibanEmpty= false;
    this.ibanNotCorrect = false;
    //console.log(document.getElementById("form-login").className);
    this.IsSignUp = false;
    this.rimpicciolire();
    await this.delay(1000);
    this.rimpicciolire();
    //document.getElementById("form-signin").className += ' rimpicciolire';
  }
   delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  rimpicciolire(){
    this.IsRimpicciolire = !this.IsRimpicciolire;

  }

  public async SignUpUser(template: TemplateRef<any>) {
    this.loading = true;
    const formData: FormData = new FormData();
    //TIPO ANONIMO
    //USERNAME
    if(this.CampiValidi()){
      this.newUserLoading = true;
      formData.append('username', this.userInfo.username);
      //PASSWORD
      formData.append('password', this.userInfo.password);
      //PIVA
      formData.append('PIVA', this.userInfo.piva);
      //RAGIONESOCIALE
      formData.append('ragionesociale', this.userInfo.rsociale);
      //CITTA
      formData.append('citta', this.userInfo.citta);
      //VIA
      formData.append('via', this.userInfo.via);
      //EMAIL
      formData.append('email', this.userInfo.email);
      //PEC
      formData.append('pec', this.userInfo.pec);
      //SDI
      formData.append('sdi', this.userInfo.sdi);
      //IBAN
      formData.append('iban', this.userInfo.iban);


      const result = await this.UserService.RegisterUser(formData);

      if(result){
        this.loading = false;
        this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true});
      }else{
        return
      }
    } else{
      return
    }


  }



  public UsernameValidation(value){
    this.userInfo.username = value;
    this.usernameEmpty = false;
    this.usernameTooShort = false;
    this.usernameNotCorrect = false;
    if (this.userInfo.username.length == 0) {
      this.usernameEmpty = true;
    } else if (this.userInfo.username.length < 3) {
      this.usernameTooShort = true;
    } else if(this.userInfo.username.includes(' ')){
      this.usernameNotCorrect = true;
    } else{
      this.usernameEmpty = false;
      this.usernameTooShort = false;
      this.usernameNotCorrect = false;
    }
  }
  public PasswordValidation(value){
    this.userInfo.password = value;
    this.passwordEmpty = false;
    this.passwordTooShort = false;
    if (this.userInfo.password.length == 0) {
      this.passwordEmpty = true;
    }
    else if (this.userInfo.password.length <= 5) {
      this.passwordTooShort = true;
    }else{
      this.passwordEmpty = false;
      this.passwordTooShort = false;
    }


  }
  public PivaValidation(value){
    this.userInfo.piva = value;
    this.pivaEmpty = false;
    this.pivaNotCorrect = false;
    if (this.userInfo.piva.length == 0 ) {
      this.pivaEmpty = true;
    }else{
      this.pivaEmpty = false;
      this.pivaNotCorrect = false;
    }
  }
  public RagSocValidation(value) {
    this.userInfo.rsociale = value;
    this.ragsocEmpty = false;

    if (this.userInfo.rsociale.length == 0) {
      this.ragsocEmpty = true;
    }else{
      this.ragsocEmpty = false;

    }
  }
  public CittaValidation(value) {
    this.userInfo.citta = value;
    this.cittaEmpty = false
    if (this.userInfo.citta.length == 0) {
      this.cittaEmpty = true;
    }else{
      this.cittaEmpty = false

    }
  }
  public ViaValidation(value) {
    this.userInfo.via = value;
    this.viaEmpty = false;
    if (this.userInfo.via.length == 0) {
      this.viaEmpty = true;
    }else{
      this.viaEmpty = false;

    }
  }
  public EmailValidation(value) {
    this.userInfo.email = value;
    this.emailEmpty = false;
    this.emailNotCorrect = false;
    this.emailNotCorrect = false;
    if (this.userInfo.email.length == 0) {
      this.emailEmpty = true;
    } else if (!this.userInfo.email.includes('@') || this.userInfo.email.includes(' ')) {
      this.emailNotCorrect = true;
    }else{
      this.emailEmpty = false;
      this.emailNotCorrect = false;
      this.emailNotCorrect = false;
    }
  }
  public PecValidation(value) {
    this.userInfo.pec = value;
    this.pecEmpty = false;
    this.pecNotCorrect = false;
    if (this.userInfo.pec.length === 0) {
      this.pecEmpty = true;
    } else if ((!this.userInfo.pec.includes('@') || this.userInfo.pec.includes(' '))) {
      this.pecNotCorrect = true;
    }else{
      this.pecEmpty = false;
      this.pecNotCorrect = false;
    }
  }
  public SdiValidation(value) {
    var reg = /^\d+$/;
    this.userInfo.sdi = value;
    this.sdiEmpty = false;
    this.sdiNotCorrect = false;
    if (this.userInfo.sdi.length == 0) {
      this.sdiEmpty = true;
    } else if (this.userInfo.sdi.length != 7 || !reg.test(value)) {
      this.sdiNotCorrect = true;
    }else{
      this.sdiEmpty = false;
      this.sdiNotCorrect = false;
    }
  }
  public IbanValidation(value) {
    this.userInfo.iban = value;
    this.ibanEmpty = false;
    this.ibanNotCorrect = false;
    if (this.userInfo.iban.length == 0) {
      this.ibanEmpty = true;
    }else{
      this.ibanEmpty = false;
      this.ibanNotCorrect = false;
    }
  }

  public CampiValidi() {

    if (this.usernameTooShort ||
      this.usernameNotCorrect ||
      this.passwordTooShort ||
      this.pivaNotCorrect ||
      this.emailNotCorrect ||
      this.pecNotCorrect ||
      this.sdiNotCorrect ||
      this.ibanNotCorrect
    ){
      return false;
    }else{
      let campiNonValidi = 0;
      this.usernameEmpty = false;
      this.passwordEmpty = false;
      this.pivaEmpty = false;
      this.ragsocEmpty = false;
      this.cittaEmpty = false;
      this.viaEmpty = false;
      this.emailEmpty = false;
      this.pecEmpty = false;
      this.sdiEmpty = false;
      this.ibanEmpty = false;


    if((document.getElementById('sign-up-username') as HTMLInputElement).value.length == 0){
      this.usernameEmpty = true;
      campiNonValidi++;
    }
    if((document.getElementById('sign-up-password') as HTMLInputElement).value.length == 0 ){
      this.passwordEmpty = true;
      campiNonValidi++;
    }
    if((document.getElementById('sign-up-piva') as HTMLInputElement).value.length == 0 ){
      this.pivaEmpty = true;
      campiNonValidi++;
    }
    if((document.getElementById('sign-up-ragsoc') as HTMLInputElement).value.length == 0 ){
      this.ragsocEmpty = true;
      campiNonValidi++;
    }
    if((document.getElementById('sign-up-citta') as HTMLInputElement).value.length == 0){
      this.cittaEmpty = true;
      campiNonValidi++;
    }
    if((document.getElementById('sign-up-via') as HTMLInputElement).value.length == 0){
      this.viaEmpty = true;
      campiNonValidi++;
    }
    if((document.getElementById('sign-up-email') as HTMLInputElement).value.length == 0){
      this.emailEmpty = true;
      campiNonValidi++;
    }
    if((document.getElementById('sign-up-pec') as HTMLInputElement).value.length == 0 && (document.getElementById('sign-up-sdi') as HTMLInputElement).value.length == 0){
      this.pecEmpty = true;
      this.sdiEmpty = true;
      this.userInfo.sdi = "";
      this.userInfo.pec = "";
      campiNonValidi++;
    }
    if((document.getElementById('sign-up-iban') as HTMLInputElement).value.length == 0){
      this.ibanEmpty = true;
        this.userInfo.iban = "";

      }

    if(campiNonValidi == 0){
      return true;
    } else{
      return false;
    }
  }
  }

  GoToLogin() {
    this.IsSignUp = false;
    this.modalRef.hide();
  }
}


class UserInfo {
  public username: string;
  public password: string;
  public piva: string;
  public rsociale: string;
  public citta: string;
  public via: string;
  public email: string;
  public pec: string;
  public sdi: string;
  public iban: string;
}

