export class CloudLicense {
    public authorities: string[];
    public createdAt: Date;
    public updatedAt: Date;
    public name: string;
    public description: string;
    public maxCabins: number;
    public maxOperators: number;
    public uuid: string;
    public id: number;
    public licenseCloudValue: CloudLicenseValue;
    public constructor() {
        this.authorities = [];
        this.licenseCloudValue = new CloudLicenseValue();
    }
}

export class CloudLicenseDate {
    public id: number;
    public startDate: Date;
    public expirationDate: Date;
}

export class CloudLicenseValue {
    public month: number;
    public quarter: number;
    public year: number;
    
    public constructor() {
        this.month = 0;
        this.quarter = 0;
        this.year = 0;
    }
}

export enum Authority
{
    /*MANAGE_CONTACT, MANAGE_CONFIGURATION, MANAGE_PRODUCT,*/
    DASHBOARD = "DASHBOARD",
    PLANNING = "PLANNING",
    PAYMENT = "PAYMENT",

    /*CONTACTS*/
    CLIENT = "CLIENT",
    COMPANY = "COMPANY",
    PROVIDER = "PROVIDER",

    /*Back office*/
    ARTICLE = "ARTICLE",
    TREATMENT = "TREATMENT",
    STOCK = "STOCK",
    MOVEMENT = "MOVEMENT",
    INVENTORY = "INVENTORY",
    QUOTE = "QUOTE",
    SUBSCRIPTION = "SUBSCRIPTION",
    FIDELITY = "FIDELITY",
    DOCUMENT_MANAGEMENT = "DOCUMENT_MANAGEMENT",
    FIRST_NOTE = "FIRST_NOTE",
    CASH_DESK = "CASH_DESK",

    /*MARKETING*/
    PROMOTION = "PROMOTION",
    PROMO_SMS = "PROMO_SMS",

    /*STATISTICS*/
    PRODUCT_REPORTS = "PRODUCT_REPORTS",
    TREATMENT_REPORTS = "TREATMENT_REPORTS",
    OPERATOR_REPORTS = "OPERATOR_REPORTS",

    /*CONFIGURATIONS*/
    ADMINISTRATION = "ADMINISTRATION",
    SETUP_SELL_POINT = "SETUP_SELL_POINT",
    SETUP_CLIENTS = "SETUP_CLIENTS",
    PRODUCTS_AND_SERVICES = "PRODUCTS_AND_SERVICES",
    PRINTERS = "PRINTERS",
    SATISPAY = "SATISPAY",
    /*PRODUCT_AND_SERVICES,
    MOVEMENT_AND_STOCK,
    SMS,
    PROMO,
    REPORT,
    CONFIGURATION_TABLE,
    PAYMENT,
    SALES,*/
    // authorities for hierarchy authentication
    /*GROUP, OWNER, SELL_POINT, GROUPP*/
}