<div class="card-header" style="border-bottom: 0; background-color: #f5f5f5;">
    <div class="row">
        <div class="col-1" (click)="this.router.navigate(['/cloud'])">
            <fa-icon [icon]="faChevronCircleLeft" size="2x" style="cursor: pointer;"></fa-icon>
        </div>
        <div class="col-9">
            <h4 class="card-title" style="margin-top: 0;">Tipologie Attività</h4>
        </div>
        <div class="col-2">
            <button class="btn btn-primary" style="float: right;" (click)=" OpenModalNewBusiness(CreaAttivita)" [disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione">Crea Attività</button>
        </div>
    </div>
</div>
<div class="card-body" style="padding-top: 0;">
    <table class="table table-hover table-striped " id="table">
        <thead>
            <th></th>
            <th></th>
        </thead>
        <tbody>
            <tr *ngFor="let business of this.companyBusinesses">
                <td><label>{{business.description}}</label></td>
                <td>
                    <div class="row" style="float: right;" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione">
                        <div class="col">
                            <fa-icon [icon]="faTrash" tooltip="Elimina" style="cursor: pointer;" (click)="this.selectedBusiness = business; this.modalService.show(EliminaAttivita);"></fa-icon>
                        </div>
                        <div class="col">
                            <fa-icon [icon]="faPen" tooltip="Modifica" style="cursor: pointer;" (click)="this.modalService.show(EditAttivita); this.descriptionNew = business.description; this.selectedBusiness = business"></fa-icon>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>


<ng-template #CreaAttivita>
    <div class="modal-header">
        <div class="modal-title">
            Aggiungi una nuova attività
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Inserire descrizione *" required="required" [(ngModel)]="this.descriptionNew"/>
        </div>
    </div>
    <div class="modal-footer">
        <div class="btn btn-danger" (click)="this.modalService.hide()">Annulla</div>
        <div class="btn btn-primary" (click)="CreateBusiness(this.descriptionNew)">Salva</div>
    </div>
</ng-template>

<ng-template #EditAttivita>
    <div class="modal-header">
        <div class="modal-title">
            Modifica attività
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Inserire descrizione *" required="required" [(ngModel)]="this.descriptionNew"/>
        </div>
    </div>
    <div class="modal-footer">
        <div class="btn btn-danger" (click)="this.modalService.hide()">Annulla</div>
        <div class="btn btn-primary" (click)="UpdateBusiness(this.descriptionNew, this.selectedBusiness)">Salva</div>
    </div>
</ng-template>

<ng-template #EliminaAttivita>
    <div class="modal-body">
        <div class="row">
            <div class="col" style="text-align: center;">
                <fa-icon [icon]="this.faInfoCircle" size="2x"></fa-icon>
            </div>
        </div>
        <div class="text" style="text-align: center;">Sei sicuro di voler eliminare l'elemento?</div>
    </div>
    <div class="modal-footer">
        <div class="btn btn-danger" (click)="this.modalService.hide()">Annulla</div>
        <div class="btn btn-primary" (click)="this.DeleteBusiness()">Elimina</div>
    </div>
</ng-template>