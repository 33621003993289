import {Component, OnInit, TemplateRef} from '@angular/core';

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {AlertService} from 'ngx-alerts';
import {UserStoreService} from '../../Services/Admin/user-store.service';
import {ShopService} from '../../Services/ShopService/shop.service';
import {AuthService} from '../../Services/AuthService/auth.service';
import {IconDefinition, faShoppingCart, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Acquisto from '../../Models/Acquisto';

@Component({
  selector: 'app-tipologialicenza',
  templateUrl: './tipologialicenza.component.html',
  styleUrls: ['./tipologialicenza.component.scss']
})
export class TipologialicenzaComponent implements OnInit {

// ICONS
  public faPen: IconDefinition = faPen;
  public faTrash: IconDefinition = faTrash;
  //CATEGORIE
  public categorie: any;

  // FLAG
  public NuovoOrdine = false;


  //FLAG DI SELEZIONE LICENZA

  // MODAL
  public modalRef: BsModalRef;
  public modalRef2: BsModalRef;
  constructor(private http: HttpClient, public auth: AuthService, private router: Router,
              public ShopService: ShopService, public modalService: BsModalService, private alertService: AlertService, public userStoreService:UserStoreService) {

  }
  public async ngOnInit(){
    /*
    if (!(this.auth.AuthUser.Tipo.includes('S') || this.auth.AuthUser.Tipo.includes('P'))) {
      this.router.navigateByUrl('');
      return;
    }
    */

    await this.Init();
    this.ShopService.InitCart();
  }
  private async Init() {
    this.auth.Ready = false;
    // AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName('li');
    for (let i = 0; i < sidebarElements.length; ++i) {
      if (sidebarElements[i].id !== 'shop') {
        sidebarElements[i].classList.remove('active');
      } else {
        sidebarElements[i].classList.add('active');
      }
    }
    if (this.ShopService.IsInit === false) {
      await this.ShopService.Init();
      this.ShopService.IsInit = true;
    }
    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }

    this.auth.Ready = true;
  }

  private async LocalValidation() {

    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));

      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if (valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.Ready = false;
        this.auth.IsLogin = true;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }





  public CreaNuovoOrdine(template: TemplateRef<any>) {
    this.NuovoOrdine = true;
    this.ShopService.CarrelloAperto = false;
  }


  /********************SEZIONE LISTINO*********************/

  public OpenModalListino(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, {id: 1, class: 'modal-lg', ignoreBackdropClick: true});
  }
  public ContinueAcquistoSingolo() {
    this.router.navigate(['/shop/acquisto-licenza-singola']);
    this.ShopService.ShowSceltaCategorie = true;
    this.ShopService.ShowAcquistoSingolo = true;
  }


  public ContinueToSelezionePacchetto() {
    if(this.auth.AuthUser.Role === 2 || (this.auth.AuthUser.Role === 1 && !this.auth.AuthUser.Tipo.includes('P')) || (this.auth.AuthUser.Role === 4 && !this.auth.AuthUser.Tipo.includes('P'))){
      this.alertService.danger("Non hai l'accesso")
    } else{
      this.ShopService.ShowTipologiaLicenza = false;
      this.ShopService.ShowSelezioneQuantitaPacchetti = true;
      this.router.navigate(['/shop/selezione-quantita-pacchetti']);
    }

    //this.userStoreService.listino = await this.userStoreService.GetListino();
  }






  public RemovePacchetto(elemento) {
    const quantita = elemento.quantita;
    this.ShopService.Pacchetto.splice(this.ShopService.Pacchetto.indexOf(elemento), 1);
    this.ShopService.LeftPacchettoScelto -= quantita;

    if (this.ShopService.LeftPacchettoScelto === 0) {
      this.modalService.hide();
    }

  }




  public Reset() {
    this.ShopService.Pacchetto = [];
    this.ShopService.PacchettoScelto = 0;
    this.ShopService.LeftPacchettoScelto = 0;
    this.ShopService.ShowTipologiaLicenza = true;
    this.ShopService.SectionSceltaElementi = false;
    this.ShopService.ShowAcquistoSingolo= false;
    this.ShopService.ShowSelezioneQuantitaPacchetti = false;
    this.ShopService.ShowAcquistoPacchetti = false;
  }







}

export class Contenitore {
  IdPacchettoCart: string
  Contenuto: Acquisto[]
  PrezzoTotale: number;
  LicenseTotali: number;
  TotalePrezzoScontato: number;
  public constructor() {
    this.Contenuto = [];
  }

  public Tipo: string = "S";

  public GetDimensione(): number {
    let value: number = 0;
    for (let i = 0; i < this.Contenuto.length; ++i) {
      value += this.Contenuto[i].NumeroLicenze;
    }
    return value;
  }
  public GetTotal(){}

}
