<div class="card strpied-tabled-with-hover" style="height: 80vh;">
    <!----LOADING-->
    <div class="card-header">

        <div class="row" *ngIf="this.auth.AdminID === 10">
            <div class="col">
                <tabset style="width: 75%;">
                    <tab heading="Log">
                        <app-log></app-log>
                    </tab>
                    <tab heading="Gestione Shop">
                        <app-store-manager></app-store-manager>
                    </tab>
                </tabset>
            </div>
        </div>

        <div class="row" *ngIf="this.auth.AdminID !== 10">
            <div class="col">
                <tabset style="width: 75%;">
                    <tab heading="Gestione Shop">
                        <app-store-manager></app-store-manager>
                    </tab>
                </tabset>
            </div>
        </div>

    </div>
</div>

