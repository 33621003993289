import { FileService } from './../Services/FileService/file.service';
import { CensisService } from './../Services/CensisService/censis.service';
import { IsDev } from './../global';

import { HttpClient, HttpParams } from '@angular/common/http';
import {Component, HostListener, OnInit, TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../Services/AuthService/auth.service';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import Censimento from '../Models/Censimento';
import CensimentoAggiorna from '../Models/CensimentoAggiorna';
import Modulo from '../Models/Modulo';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { faTrash, faAngleRight, faAngleDown, faPen, faCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';

import { AlertService } from 'ngx-alerts';
import {promises} from 'fs';
import {filter} from 'rxjs/operators';
import Update from '../Models/Update';


@Component({
  selector: 'app-census',
  templateUrl: './census.component.html',
  styleUrls: ['./census.component.scss']
})
export class CensusComponent implements OnInit {

  public Censimenti: Censimento[] = [];
  public AllCensimenti: Censimento[] = [];
  public Status: CensimentoAggiorna[] = [];
  public dropdownRecenti = false;
  public dropdownObsoleti = false;
  //MODAL
  public modalRef: BsModalRef;
  public SelectedCensimento: Censimento;
  public SelectedIntSco: string[];
  public CensimentoDaEliminare: Censimento;
  public LicenzaDaAggiungere: string;
  public LicenzaCorretta: boolean = false;

  // RICERCA
  public OpenOrdinamento = false;

  // ICONS
  public faTrash: IconDefinition = faTrash;
  public faAngleDown: IconDefinition = faAngleDown;
  public faAngleRight: IconDefinition = faAngleRight;
  public faPen: IconDefinition = faTelegramPlane;
  public faCircle: IconDefinition = faCircle;

  // LAST DRIVER
  public LastDriver = {
    version: '',
    data: null
  };
  // LAST VP
  public LastVP = {
    version: '',
    data: null
  };
  public FiltroCensimenti = {
    XML7:false,
    Lotteria: false,
  }
  public Loading = false;
  public lglt: boolean;

  // tslint:disable-next-line:max-line-length
  constructor(public auth: AuthService, private router: Router, private http: HttpClient, public modalService: BsModalService, public alert: AlertService, private censisService: CensisService, private fileService: FileService) {

  }
  @HostListener('window:resize', ['$event'])
  setLtLg(event?): void{
    if (window.innerWidth < 1279){
      this.lglt = true;
    }else{
      this.lglt = false;
    }
  }

  private async Init(): Promise<void>{
    this.setLtLg();
    this.auth.Ready = false;
    // SE NON E' NESSUNO DI QUESTI, VADO INDIETRO
    // tslint:disable-next-line:max-line-length
    if (!(this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role === 4 || this.auth.AuthUser.Role === 1 || this.auth.AuthUser.Role2 === 0 || this.auth.AuthUser.Role2 === 1 || this.auth.AuthUser.Role2 === 4)) {
      this.router.navigateByUrl('');
      return;
    }

    // BLOCCO ULTIMO DRIVER E VP DA INSTALLARE

    const result: Update[] = await this.fileService.GetUpdates();
    for (let i = 0; i < result.length; ++i) {
      if (result[i].softCode === 'DRV') {
        const temp: string = result[i].version;
        const data: string = result[i].releaseDate;

        if (this.LastDriver.version === '') {
          this.LastDriver.data = data;
          this.LastDriver.version = temp;
          continue;
        }

        if (data > this.LastDriver.data) {
          this.LastDriver.version = temp;
        }

      }

      if (result[i].softCode === 'VP') {
        const temp: string = result[i].version;
        const data: string = result[i].releaseDate;

        if (this.LastVP.version === '') {
          this.LastVP.data = data;
          this.LastVP.version = temp;
          continue;
        }

        if (data > this.LastVP.data) {
          this.LastVP.version = temp;
        }

      }

    }
    //AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName('li');
    for (let i = 0; i < sidebarElements.length; ++i) {
      if (sidebarElements[i].id !== 'census') {
        sidebarElements[i].classList.remove('active');
      } else {
        sidebarElements[i].classList.add('active');
      }
    }

    this.Loading = true;
    //  await this.LoadStatus();
    await this.LoadCensimento();
    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }
    this.auth.Ready = true;
  }


  async ngOnInit(): Promise<void>{
    await this.Init();
  }

  public async LoadStatus(): Promise<void> {
    this.Status = await this.censisService.LoadStatus();
    return;
  }

  public async LoadCensimento(): Promise<void> {

    this.Censimenti = await this.censisService.LoadCensimenti();

    this.AllCensimenti = [...this.Censimenti]
    this.Loading = false;

    return;

  }

  public async GetModules(template: TemplateRef<any>, censimento: Censimento): Promise<void>{
    // GET MODULI
    await this.censisService.GetModuli(censimento);

    this.OpenModal(template, censimento);
  }

  public async GetIntSco(template: TemplateRef<any>, censimento: Censimento) {
        
    this.SelectedIntSco = await this.censisService.GetFormattedIntSco(censimento.INT_SCO);
    
    this.modalService.show(template);
  }


  public Filtro() {
    let arrayCensimentiFiltrati: Censimento[] = [];
    if (this.FiltroCensimenti.XML7 && !this.FiltroCensimenti.Lotteria) {
      for (let i = 0; i < this.AllCensimenti.length; i++) {
        if (this.AllCensimenti[i].XML7 === 'SI') {
          arrayCensimentiFiltrati.push(this.AllCensimenti[i])
        }
      }
      this.Censimenti = arrayCensimentiFiltrati;
      return;
    }

    if (!this.FiltroCensimenti.XML7 && this.FiltroCensimenti.Lotteria) {
      for (let i = 0; i < this.AllCensimenti.length; i++) {
        if (this.AllCensimenti[i].LOTTERIA === 'SI') {
          arrayCensimentiFiltrati.push(this.AllCensimenti[i])
        }
      }
      this.Censimenti = arrayCensimentiFiltrati;
      return;
    }

    if (this.FiltroCensimenti.XML7 && this.FiltroCensimenti.Lotteria) {
      for (let i = 0; i < this.AllCensimenti.length; i++) {
        if (this.AllCensimenti[i].LOTTERIA === 'SI' && this.AllCensimenti[i].XML7 === 'SI') {
          arrayCensimentiFiltrati.push(this.AllCensimenti[i])
        }
      }
      this.Censimenti = arrayCensimentiFiltrati;
      return;
    }

    
    if (!this.FiltroCensimenti.XML7 && !this.FiltroCensimenti.Lotteria) {
      arrayCensimentiFiltrati = [...this.AllCensimenti]
      this.Censimenti = arrayCensimentiFiltrati;
    }
  }
  private async LocalValidation(): Promise<void>{
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));

      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if (valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.IsLogin = true;
        this.auth.Ready = false;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }

  public OpenModal(template: TemplateRef<any>, censimento: Censimento): void {
    this.SelectedCensimento = censimento;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  public OpenModalUpdateLicenza(template: TemplateRef<any>, censimento: Censimento): void {
    this.SelectedCensimento = censimento;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  public OpenModalElimina(template: TemplateRef<any>, censimento: Censimento): void {
    this.CensimentoDaEliminare = censimento;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  public async OpenModalStatus(template: TemplateRef<any>): Promise<void> {
    await this.LoadStatus();
    this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
  }


  public async DeleteCensimento(): Promise<void> {

    const result: any = await this.censisService.DeleteCensimento(this.CensimentoDaEliminare);

    if (result) {
      // RIMUOVO DALLA LISTA, RICHIAMARLI TUTTI DA ZERO E' TROPPO FATICOSO
      const index: number = this.Censimenti.indexOf(this.CensimentoDaEliminare);
      if (index > -1) {
        this.Censimenti.splice(index, 1);
      }
      this.modalService.hide();
    }

  }


  public async UpdateVisualPos() {

    const result: any = await this.censisService.UpdateVisualPos(this.SelectedCensimento);
    
    if (result !== 'ok') {
      this.alert.danger('Software già in coda!');
    } else {
      this.alert.success('Software in coda per l\'aggiornamento');
      await this.LoadStatus();
    }
  }



  public async UpdateDriver() {
  
    const result: any = await this.censisService.UpdateDriver(this.SelectedCensimento);

    if (result !== 'ok') {
      this.alert.danger('Software già in coda!');
    } else {
      this.alert.success('Software in coda per l\'aggiornamento');
      await this.LoadStatus();
    }
  }

  
  public async InsertLicenza() {
    const licenza: string[] = [];
    licenza.push(this.LicenzaDaAggiungere) 
    
    const result = await this.censisService.InsertLicense(this.SelectedCensimento, licenza);
    
    if (!result) {
      this.alert.danger('Licenza non inserita');
    } else {
      this.alert.success('Licenza in coda per la convalidazione');
      this.LicenzaDaAggiungere = "";
      this.modalService.hide();
      await this.LoadStatus();
    }
  }
  
  public async UpdateLicenza(censimento: Censimento) {

    this.SelectedCensimento = censimento;
    
    const result = await this.censisService.UpdateLicense(this.SelectedCensimento);
    
    if (result !== 'ok') {
      this.alert.danger('Licenza non aggiornabile');
    } else {
      this.alert.success('Licenza in coda per l\'aggiornamento');
      await this.LoadStatus();
    }
  }

  public CheckLicenseIntegrity() {
    // DLPVTS-TT026K-YSC2HD-K4LI4E-0EH0T1

    if (this.LicenzaDaAggiungere.length !== 34) {
      this.LicenzaCorretta = false;
      return;
    }
    
    if (this.LicenzaDaAggiungere[6] !== '-' || this.LicenzaDaAggiungere[13] !== '-' || this.LicenzaDaAggiungere[20] !== '-' || this.LicenzaDaAggiungere[27] !== '-') {
      this.LicenzaCorretta = false;
      return;
    }

    const subLicense: string[] = this.LicenzaDaAggiungere.split('-');

    for (let string of subLicense) {
      if (!string.match('^[0-9]*[A-Z]*[A-Z0-9]*$')) {
        this.LicenzaCorretta = false;
        return;
      }
    }
    
    this.LicenzaCorretta = true;
    return;
  }
  public SearchByName() {
    const input: string = (document.getElementById('search') as HTMLInputElement).value.toUpperCase().trim();

    const filters = document.getElementsByClassName('check-filter');
    // 0 --> Ragione sociale
    // 1 --> Negozio
    // 2 --> Partita IVA
    // 3 --> Matricola
    for (let j = 0; j < this.Censimenti.length; ++j) {
      this.Censimenti[j].Hidden = true;

      if (this.Censimenti[j].RAGSOC.toUpperCase().includes(input)) {
        this.Censimenti[j].Hidden = false;
      }

      if (this.Censimenti[j].DESMAG.toUpperCase().includes(input)) {
        this.Censimenti[j].Hidden = false;
      }


      if (this.Censimenti[j].RIVENDITORE.toUpperCase().includes(input)) {
        this.Censimenti[j].Hidden = false;
      }

      if (this.Censimenti[j].PIVA.toUpperCase().includes(input)) {
        this.Censimenti[j].Hidden = false;

      }

      if (this.Censimenti[j].MATRICOLA.toUpperCase().includes(input)) {
        this.Censimenti[j].Hidden = false;
      }

      if (this.Censimenti[j].MAC.toUpperCase().includes(input.toUpperCase())) {
        this.Censimenti[j].Hidden = false;
      }

      for (let k = 0; k < this.Censimenti[j].LICENZA.length; ++k) {
        if (this.Censimenti[j].LICENZA[k].includes(input.toUpperCase())) {
          this.Censimenti[j].Hidden = false;
          break;
        }
      }

    }
  }

  public Ordinamento(tipo: string) {
    if (tipo === 'Recente') {
      this.Censimenti.sort((a: Censimento, b: Censimento) => {

        const dataA: Date = new Date(a.LASTDATA);
        const dataB: Date = new Date(b.LASTDATA);
        const aTime: number = dataA.getTime();
        const bTime: number = dataB.getTime();

        if (aTime > bTime) {
          return -1;
        }
        if (aTime < bTime) {
          return 1;
        }
        if (aTime === bTime) {
          return 0;
        }
      });
    }
    if (tipo === 'Obsolete') {
      this.Censimenti.sort((a: Censimento, b: Censimento) => {

        const dataA: Date = new Date(a.LASTDATA);
        const dataB: Date = new Date(b.LASTDATA);

        const aTime: number = dataA.getTime();
        const bTime: number = dataB.getTime();

        if (aTime < bTime) {
          return -1;
        }
        if (aTime > bTime) {
          return 1;
        }
        if (aTime === bTime) {
          return 0;
        }
      });
    }
    if (tipo === 'RecenteVisualPos') {
      this.Censimenti.sort((a: Censimento, b: Censimento) => {
        return this.SortRecenti(a, b, 'VP');
      });
    }
    if (tipo === 'RecenteVisualB2B') {
      this.Censimenti.sort((a: Censimento, b: Censimento) => {
        return this.SortRecenti(a, b, 'VB');
      });
    }
    if (tipo === 'RecenteDriver') {
      this.Censimenti.sort((a: Censimento, b: Censimento) => {
        return this.SortRecenti(a, b, 'DR');
      });
    }
    if (tipo === 'RecenteVisualUpdate') {
      this.Censimenti.sort((a: Censimento, b: Censimento) => {
        return this.SortRecenti(a, b, 'VU');
      });
    }
    if (tipo === 'ObsoleteVisualPos') {
      this.Censimenti.sort((a: Censimento, b: Censimento) => {
        return this.SortObsoleti(a, b, 'VP');
      });
    }
    if (tipo === 'ObsoleteVisualB2B') {
      this.Censimenti.sort((a: Censimento, b: Censimento) => {
        return this.SortObsoleti(a, b, 'VB');
      });
    }
    if (tipo === 'ObsoleteDriver') {
      this.Censimenti.sort((a: Censimento, b: Censimento) => {
        return this.SortObsoleti(a, b, 'DR');
      });
    }
    if (tipo === 'ObsoleteVisualUpdate') {
      this.Censimenti.sort((a: Censimento, b: Censimento) => {
        return this.SortObsoleti(a, b, 'VU');
      });
    }
  }

  private SortRecenti(a: Censimento, b: Censimento, software: string): number {
    const aVPSplitted: string[] = a[software].split('.');
    const bVPSplitted: string[] = b[software].split('.');

    // PRIMO CHAR
    if (Number(aVPSplitted[0]) > Number(bVPSplitted[0])) {
      return -1;
    } else if (Number(aVPSplitted[0]) < Number(bVPSplitted[0])) {
      return 1;
    } else if (Number(aVPSplitted[0]) === Number(bVPSplitted[0])) {
      // SECONDO CHAR
      if (Number(aVPSplitted[1]) > Number(bVPSplitted[1])) {
        return -1;
      } else if (Number(aVPSplitted[1]) < Number(bVPSplitted[1])) {
        return 1;
      } else if (Number(aVPSplitted[1]) === Number(bVPSplitted[1])) {
        if (Number(aVPSplitted[2]) > Number(bVPSplitted[2])) {
          return -1;
        } else if (Number(aVPSplitted[2]) < Number(bVPSplitted[2])) {
          return 1;
        } else if (Number(aVPSplitted[2]) === Number(bVPSplitted[2])) {
          if (Number(aVPSplitted[3]) > Number(bVPSplitted[3])) {
            return -1;
          } else if (Number(aVPSplitted[3]) < Number(bVPSplitted[3])) {
            return 1;
          } else if (Number(aVPSplitted[3]) === Number(bVPSplitted[3])) {
            return 0;
          }
        }
      }
    }
  }
  private SortObsoleti(a: Censimento, b: Censimento, software: string): number {
    const aVPSplitted: string[] = a[software].split('.');
    const bVPSplitted: string[] = b[software].split('.');

    // PRIMO CHAR
    if (Number(aVPSplitted[0]) < Number(bVPSplitted[0])) {
      return -1;
    } else if (Number(aVPSplitted[0]) > Number(bVPSplitted[0])) {
      return 1;
    } else if (Number(aVPSplitted[0]) === Number(bVPSplitted[0])) {
      // SECONDO CHAR
      if (Number(aVPSplitted[1]) < Number(bVPSplitted[1])) {
        return -1;
      } else if (Number(aVPSplitted[1]) > Number(bVPSplitted[1])) {
        return 1;
      } else if (Number(aVPSplitted[1]) === Number(bVPSplitted[1])) {
        if (Number(aVPSplitted[2]) < Number(bVPSplitted[2])) {
          return -1;
        } else if (Number(aVPSplitted[2]) > Number(bVPSplitted[2])) {
          return 1;
        } else if (Number(aVPSplitted[2]) === Number(bVPSplitted[2])) {
          if (Number(aVPSplitted[3]) < Number(bVPSplitted[3])) {
            return -1;
          } else if (Number(aVPSplitted[3]) > Number(bVPSplitted[3])) {
            return 1;
          } else if (Number(aVPSplitted[3]) === Number(bVPSplitted[3])) {
            return 0;
          }
        }
      }
    }
  }
  clickCensimento(censimento:any, tipo, modalElimina:TemplateRef<any>){
    if(tipo === "apri"){
      censimento.ShowView = !censimento.ShowView;
    } else if(tipo === 'elimina'){
      this.OpenModalElimina(modalElimina, censimento)
    }

  }



}
