<div class="container-fluid">
    <!-- Visual POS List -->
      <div class="card row mt-3">
          <div class="card-body">
              <div class="row">
                  <div class="col-4">
                      <h4 class="card-title">Attivazione Licenza</h4>
                      <div class="custom-control custom-switch">
                        <input (change)="toggleDemo()" type="checkbox" class="custom-control-input" id="switch">
                        <label class="custom-control-label" for="switch">Demo</label>
                      </div>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-4" *ngIf="this.auth.AuthUser.Tipo.includes('C') && (this.auth.AuthUser.Tipo.includes('S') || this.auth.AuthUser.Tipo.includes('P')) && !demo && this.auth.AuthUser.Role !== 0">
                      <div class="row">
                          <div class="col">
                              <input type="radio" name="tipogenerazione" value="Canone" [(ngModel)]="TipoGenerazione">
                              <label>Licenza Canone</label>
                          </div>
                          <div class="col">
                              <input type="radio" name="tipogenerazione" value="Acquistate" [(ngModel)]="TipoGenerazione">
                              <label>Licenza Acquistate</label>
                          </div>
                      </div>
                  </div>
                  <div class="col-6" *ngIf="this.auth.AuthUser.Role === 0 && !demo">
                    <div class="row">
                        <div class="col-4">
                            <label><b>Tipo Licenza</b></label>
                        </div>
                        <div class="col-4" *ngIf="this.auth.AuthUser.SettingsPermessi.Licenze.Attivazione">
                            <label><b>Cliente</b></label>
                        </div>
                        <div class="col-4">
                            <label><b>Segnalatore</b></label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <input type="radio" name="tipogenerazione" value="Annuale" [(ngModel)]="TipoGenerazioneAdmin"  (ngModelChange)="UserIDLicenza = 10">
                            <label>CANONE</label>
                        </div>
                        <div class="col-2">
                            <input type="radio" name="tipogenerazione" value="Spot" [(ngModel)]="TipoGenerazioneAdmin" (change)="TipoGenerazioneAdmin === 'Spot' ? this.DataScadenzaBoolean = false : ''">
                            <label>SPOT</label>
                        </div>
                        <div class="col-4" *ngIf="this.auth.AuthUser.SettingsPermessi.Licenze.Attivazione">
                            <select class="form-select form-select-sm" aria-label=".form-select-sm example"  [(ngModel)]="UserIDLicenza">
                                <option [ngValue]="10">GAB</option>
                                <option *ngFor="let user of UserConcessionari" [ngValue]="user.ID">{{user.Username.toUpperCase()}}</option>
                              </select>
                        </div>
                        <div class="col-4">
                            <select class="form-select form-select-sm" aria-label=".form-select-sm example"  [(ngModel)]="UserIDSegnalatore">
                                <option [ngValue]="10">GAB</option>
                                <option *ngFor="let user of UserSegnalatori" [ngValue]="user.ID">{{user.Username.toUpperCase()}}</option>
                              </select>
                        </div>
                    </div>
                </div>
              </div>

          </div>
          <div class="card-body" *ngIf="!demo">
              <div class="controls">
                  <div class="row">
                      <div class="col visualchoise">
                          <div class="row">
                              <h4  style="padding-left: 2rem;">Visual Food</h4>
                          </div>
                          <div class="form-check form-group">
                              <input name="visual" type="radio" id="vfoodstart" (change)="SelectedToObj()">
                              <label style="margin-left: 2%;">Visual Food Start</label>
                          </div>
                          <div class="form-check form-group">
                              <input name="visual" type="radio" id="vfoodpro" (change)="SelectedToObj()">
                              <label style="margin-left: 2%;">Visual Food Pro</label>
                          </div>
                          <div class="form-check form-group">
                              <input name="visual" type="radio" id="vfoodgold" (change)="SelectedToObj()">
                              <label style="margin-left: 2%;">Visual Food Gold</label>
                          </div>

                      </div>
                      <div class="col visualchoise">
                          <div class="row" style="text-align: center;">
                              <h4 style="padding-left: 2rem;">Visual Shop</h4>
                          </div>
                          <div class="form-check form-group">
                              <input name="visual" type="radio" id="vshopstart" (change)="SelectedToObj()">
                              <label style="margin-left: 2%;">Visual Shop Start</label>
                          </div>
                          <div class="form-check form-group">
                              <input name="visual" type="radio" id="vshoppro" (change)="SelectedToObj()">
                              <label style="margin-left: 2%;">Visual Shop Pro</label>
                          </div>
                      </div>
                      <div class="col visualchoise">
                          <div class="row" style="text-align: center;">
                              <h4 style="padding-left: 2rem;">Visual Kiosk</h4>
                          </div>
                          <div class="form-check form-group">
                              <input name="visual" type="radio" id="vkioskstart" (change)="SelectedToObj()">
                              <label style="margin-left: 2%;">Visual Kiosk Start</label>
                          </div>
                          <div class="form-check form-group">
                              <input name="visual" type="radio" id="vkioskpro" (change)="SelectedToObj()">
                              <label style="margin-left: 2%;">Visual Kiosk Pro</label>
                          </div>
                          <div class="form-check form-group">
                            <input name="visual" type="radio" id="vkioskgold" (change)="SelectedToObj()">
                            <label style="margin-left: 2%;">Visual Kiosk Gold</label>
                          </div>
                          <div class="row" style="text-align: center;">
                            <h4 style="padding-left: 2rem;">Visual Self</h4>
                        </div>
                        <div class="form-check form-group">
                            <input name="visual" type="radio" id="vselfstart" (change)="SelectedToObj()">
                            <label style="margin-left: 2%;">Visual Self Start</label>
                        </div>
                        <div class="form-check form-group">
                            <input name="visual" type="radio" id="vselfpro" (change)="SelectedToObj()">
                            <label style="margin-left: 2%;">Visual Self Pro</label>
                        </div>
                        <!--
                        <div class="form-check form-group">
                            <input name="visual" type="radio" id="vselfgold" (change)="SelectedToObj()">
                            <label style="margin-left: 2%;">Visual Self Gold</label>
                        </div>
                        -->
                        <div class="row" style="text-align: center;">
                            <h4 style="padding-left: 2rem;">Visual Beach</h4>
                        </div>
                        <div class="form-check form-group">
                            <input name="visual" type="radio" id="vbeachstart" (change)="SelectedToObj()">
                            <label style="margin-left: 2%;">Visual Beach Start</label>
                        </div>
                        <div class="form-check form-group">
                            <input name="visual" type="radio" id="vbeachpro" (change)="SelectedToObj()">
                            <label style="margin-left: 2%;">Visual Beach Pro</label>
                        </div>
                        <!--
                        <div class="form-check form-group">
                            <input name="visual" type="radio" id="vbeachgold" (change)="SelectedToObj()">
                            <label style="margin-left: 2%;">Visual Beach Gold</label>
                        </div>
                        -->
                      </div>
                      <div class="col visualchoise">
                          <div class="row" style="text-align: center;">
                              <h4 style="padding-left: 2rem;">Visual Retail</h4>
                          </div>
                          <div class="form-check form-group">
                              <input name="visualr" type="radio" id="vretailstart" (change)="SelectedToObj()">
                              <label style="margin-left: 2%;">Visual Retail Start</label>
                          </div>
                          <div class="form-check form-group">
                              <input name="visualr" type="radio" id="vretailpro" (change)="SelectedToObj()">
                              <label style="margin-left: 2%;">Visual Retail Pro</label>
                          </div>
                      </div>
                  </div>
                  <!-- <div class="row" *ngIf="this.auth.AuthUser.ID === 10">
                      <div class="col-2"></div>
                      <div class="col-4">
                          <label style="display: inline; margin-right: 3%;">Data di Scadenza</label>
                          <fa-icon [icon]="faInfo" tooltip="Predefinita: 1 anno dopo l'attivazione"></fa-icon>
                      </div>
                      <div class="col"></div>
                  </div> -->
                  <div class="row">
                      <div class="col-2">
                        <button class="btn btn-primary" (click)="Reset()">Reset</button>
                      </div>
                      <!-- <div class="col-4" *ngIf="this.auth.AuthUser.ID === 10">
                        <input type="checkbox" [(ngModel)]="this.DataScadenzaBoolean" style="display: inline; margin-right: 3%;" [disabled]="TipoGenerazioneAdmin === 'Spot'">
                        <input type="text" style="width: 30%; display: inline;"
                            placeholder="Scadenza Licenza"
                            class="form-control"
                            [disabled]="!this.DataScadenzaBoolean || TipoGenerazioneAdmin === 'Spot'"
                            [(ngModel)]="this.DataScadenza"
                            [bsConfig]="bsConfig"
                            bsDatepicker>
                      </div> -->
                      <div class="col" *ngIf="this.auth.AuthUser.ID"></div>
                  </div>
              </div>
          </div>
      </div>

      <!-- Moduli Aggiuntivi -->
      <div class="card row mt-1" *ngIf="!demo">
          <div class="card-header">
              <h4 class="card-title">Moduli Aggiuntivi</h4>
          </div>
          <div class="card-body">
              <div class="messages"></div>
              <div class="controls">
                  <div class="row">
                      <div class="col">
                          <div>
                              <input type="checkbox" id="cca" name="additional" [(ngModel)]="this.sceltaModuli.cassetto">
                              <label style="margin-left: 2%;" for="cca">Collegamento Cassetto</label>
                          </div>
                      </div>
                      <div class="col">
                          <div>
                              <input type="checkbox" id="vp" name="additional" [(ngModel)]="this.sceltaModuli.promotion">
                              <label style="margin-left: 2%;" for="vp">Promo Cassa</label>
                          </div>
                      </div>
                      <div class="col">
                          <div>
                              <input type="checkbox" id="celiachia" name="additional" [(ngModel)]="this.sceltaModuli.celiachia">
                              <label style="margin-left: 2%;" for="celiachia">Celiachia</label>
                          </div>
                      </div>
                      <div class="col">
                          <div>
                              <input type="checkbox" id="730" name="additional" [(ngModel)]="this.sceltaModuli.modulo730">
                              <label style="margin-left: 2%;" for="730">Modulo 730</label>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col">
                          <div>
                              <input type="checkbox" id="tb" name="additional" [(ngModel)]="this.sceltaModuli.tabacchi">
                              <label style="margin-left: 2%;" for="tb">Tabacchi</label>
                          </div>
                      </div>
                      <div class="col">
                          <div>
                              <input type="checkbox" id="vf" name="additional" [(ngModel)]="this.sceltaModuli.verify">
                              <label style="margin-left: 2%;" for="vf">Cooki</label>
                          </div>
                      </div>
                      <div class="col">
                          <div>
                              <input type="checkbox" id="vt" name="additional" [(ngModel)]="this.sceltaModuli.turn">
                              <label style="margin-left: 2%;" for="vt">Keenon</label>
                          </div>
                      </div>
                      <div class="col">
                          <div>
                              <input type="checkbox" id="vb" name="additional" [(ngModel)]="this.sceltaModuli.b2b">
                              <label style="margin-left: 2%;" for="vb">Visual B2B</label>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <!-- Resume of choisees-->
      <div class="card row mt-1" *ngIf="!demo">
        <div class="card-body">
            <div class="messages"></div>
              <div class="row">
                <div class="col">
                  <p><b>Hai selezionato:</b></p>
                  <p *ngFor="let string of this.SelectedOBJToStringHTML()">{{string}}</p>
                </div>
                <div class="col">
                  <p><b>Moduli Aggiuntivi:</b></p>
                  <p *ngFor="let string of ModuleToString()">{{string}}</p>
                </div>
              </div>
            </div>
        </div>

        <div class="d-flex justify-content-center" *ngIf="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div class="text-center" *ngIf="this.auth.AuthUser.ID === 10">
                <label style="display: inline; margin-right: 3%;">Data di Scadenza</label>
                <fa-icon [icon]="faInfo" tooltip="Predefinita: 1 anno dopo l'attivazione"></fa-icon>
        </div>
        <div class="text-center" *ngIf="this.auth.AuthUser.ID === 10">
              <input type="checkbox" [(ngModel)]="this.DataScadenzaBoolean" style="display: inline; margin-right: 3%;" [disabled]="TipoGenerazioneAdmin === 'Spot'">
              <input type="text" style="width: 120px; display: inline;"
                  placeholder="Scadenza Licenza"
                  class="form-control"
                  [disabled]="!this.DataScadenzaBoolean || TipoGenerazioneAdmin === 'Spot'"
                  [(ngModel)]="this.DataScadenza"
                  [bsConfig]="bsConfig"
                  bsDatepicker>
        </div>

        <div>
          <div style="padding-top:1%; padding-bottom: 1%;" class="text-center">
            <button type="button" class="btn btn-primary" (click)="GenerateLicense()" [disabled]="this.LicenseGenerationCompleted">GENERA LICENZA</button>
          </div>
        </div>
      </div>



<ng-template #licenseModal>
    <div class="modal-body modal-lg" style="height: auto !important;" (close)="modalService.hide()">

      <p><b>La licenza generata è:</b></p>
      <p [hidden]="true" id="license">{{licenseGenerated}}</p>
      <div class="row">
        <div class="col-1"></div>
        <input style="text-align: center; " type="text" class="col-10" placeholder={{licenseGenerated}} readonly>
        <div class="col-1"></div>
      </div>
      <div class="row">
        <div class="col-4"></div>
        <button type="button" class="btn btn-primary col-4" style="margin-top: 1%;" (click)="copyClickboard()">
          Copia
        </button>
        <div class="col-4"></div>
      </div>
      <div class="form-group">
      </div>
    </div>
    <div class="modal-footer">
        <div class="container">
            <div class="row">
                <alert style="width: 100%; margin-top: 10%;" type="success" [dismissible]="true" [isOpen]="alertOpen" *ngIf="generated">
                    <strong style="text-align: center;">{{alertMsg}}</strong>
                </alert>
            </div>
        </div>
    </div>
</ng-template>
