import { AuthService } from './../../Services/AuthService/auth.service';
import { UserService } from './../../Services/UserService/user.service';
import { LogRow } from './../../Models/Log';
import { LogService } from './../../Services/Admin/log.service';
import { Component, OnInit } from '@angular/core';
import { Log } from '../../Models/Log'
import { User } from 'src/app/Models/User';
import { faInfoCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

  public AllLogs: LogRow[];
  public Logs: LogRow[]; //PAGED LOGS
  public StartDate: Date;
  public EndDate: Date;
  public TodayString: string = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().split("T")[0];

  public LoadingLocal: boolean = false;
  public TotalPages: number;
  public ActivePage: number = 0;

  //FILTRI
  public AllUsers: User[] = [];
  public SelectedUser: User = null;

  public IsLogin: boolean = false;

  // ICONS
  public faInfo: IconDefinition = faInfoCircle;


  constructor(public LogService: LogService, public UserService: UserService, public auth: AuthService) { 
    this.EndDate = new Date();
    this.EndDate.setHours(23, 59);

    this.StartDate = new Date();
    this.StartDate.setHours(0, 1);

    this.Logs = [];
  }

  async ngOnInit() {
    
    await this.Filter();
    
    this.AllUsers = await this.UserService.LoadUsers(this.auth.AuthUser.Username);
  }

  public async Filter() {
    this.LoadingLocal = true;

    this.Logs = [];
    this.AllLogs = [];

    const Logs: Log[] = await this.LogService.GetLogs(this.StartDate, this.EndDate);

    Logs.forEach(log => {
      log.Logs.forEach(logrow => {
        
        //SELECTED USER PART
        if (this.SelectedUser === null) {
          this.AllLogs.push(logrow);
          return;
        }

        if (logrow.User.toLowerCase() === this.SelectedUser.Username.toLowerCase()) {
          this.AllLogs.push(logrow);
          return;
        }

      });
    })
    this.FilterPage(0);

    this.LoadingLocal = false;
  }

  public FilterPage(index: number) {
    //FILTRO I LOGS
    //TotalPages è il numero delle pagine disponibili

    this.Logs = [];
 
    this.AllLogs.forEach(logRow => {
      if (!logRow.OP.toLowerCase().includes('login')) {
        this.Logs.push(logRow);
        return;
      }

      if (this.IsLogin) this.Logs.push(logRow);
    });

    const startIndex: number = index * 15;
    const endIndex: number = ((index + 1) * 15 < this.Logs.length) ? (index + 1) * 15 : this.Logs.length;
    

    this.TotalPages = Math.ceil(this.Logs.length / 15);

    this.Logs = this.Logs.slice(startIndex, endIndex);
    
  }

}
