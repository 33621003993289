<div class="modal-header">
    <div class="modal-title">
        <p>Upgrade Licenza</p>
    </div>
</div>
<div class="modal-body modal-lg" style="height: auto !important;">
    <ng-container *ngIf="this.LicenseUpgrade.Upgradable && !this.Client">
        <!--Visual Food-->
        <ng-container *ngIf="this.LicenseUpgrade.VisualFood.Installed">
            <table class="table table-hover table-striped">
                <thead>
                    <th>Software</th>
                    <th>Seleziona aggiornamento</th>
                </thead>
                <tbody>
                    <tr>
                        <td>Visual Food</td>
                        <td>
                            <select class="custom-select" [(ngModel)]="this.Upgradable.VisualFood">
                                <option selected="selected" value="">
                                    Mantieni la stessa versione
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualFood.Start === false" value="Start">
                                    Start
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualFood.Pro === false" value="Pro">
                                    Pro
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualFood.Gold === false" value="Gold">
                                    Gold
                                </option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <!--Visual Shop-->
        <ng-container *ngIf="this.LicenseUpgrade.VisualShop.Installed">
            <table class="table table-hover table-striped">
                <thead>
                    <th>Software</th>
                    <th>Seleziona aggiornamento</th>
                </thead>
                <tbody>
                    <tr>
                        <td>Visual Shop</td>
                        <td>
                            <select class="custom-select" [(ngModel)]="this.Upgradable.VisualShop">
                                <option selected="selected" value="">
                                    Mantieni la stessa versione
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualShop.Start === false" value="Start">
                                    Start
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualShop.Pro === false" value="Pro">
                                    Pro
                                </option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <!--Visual Self-->
        <ng-container *ngIf="this.LicenseUpgrade.VisualSelf.Installed || this.LicenseUpgrade.VisualBeach.Installed || this.LicenseUpgrade.VisualKiosk.Installed">
            <table class="table table-hover table-striped">
                <thead>
                    <th>Software</th>
                    <th>Seleziona aggiornamento</th>
                </thead>
                <tbody>
                    <tr *ngIf="this.LicenseUpgrade.VisualKiosk.Installed">
                        <td>Visual Kiosk</td>
                        <td>
                            <select class="custom-select" [(ngModel)]="this.Upgradable.VisualKiosk">
                                <option selected="selected" value="">
                                    Mantieni la stessa versione
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualKiosk.Start === false" value="Start">
                                    Start
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualKiosk.Pro === false" value="Pro">
                                    Pro
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualKiosk.Gold === false" value="Gold">
                                    Gold
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr *ngIf="this.LicenseUpgrade.VisualSelf.Installed">
                        <td>Visual Self</td>
                        <td>
                            <select class="custom-select" [(ngModel)]="this.Upgradable.VisualSelf">
                                <option selected="selected" value="">
                                    Mantieni la stessa versione
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualSelf.Start === false" value="Start">
                                    Start
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualSelf.Pro === false" value="Pro">
                                    Pro
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr *ngIf="this.LicenseUpgrade.VisualBeach.Installed">
                        <td>Visual Beach</td>
                        <td>
                            <select class="custom-select" [(ngModel)]="this.Upgradable.VisualBeach">
                                <option selected="selected" value="">
                                    Mantieni la stessa versione
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualBeach.Start === false" value="Start">
                                    Start
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualBeach.Pro === false" value="Pro">
                                    Pro
                                </option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <!--Visual Retail-->
        <ng-container *ngIf="this.LicenseUpgrade.VisualRetail.Installed">
            <table class="table table-hover table-striped">
                <thead>
                    <th>Software</th>
                    <th>Seleziona aggiornamento</th>
                </thead>
                <tbody>
                    <tr>
                        <td>Visual Retail</td>
                        <td>
                            <select class="custom-select" [(ngModel)]="this.Upgradable.VisualRetail">
                                <option selected="selected" value="">
                                    Mantieni la stessa versione
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualRetail.Start === false" value="Start">
                                    Start
                                </option>
                                <option *ngIf="this.LicenseUpgrade.VisualRetail.Pro === false" value="Pro">
                                    Pro
                                </option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="this.Moduli.length > 0">
        <div class="row">
            <div class="col">
                <h5 style="text-align: center;"><b>Aggiungi moduli</b></h5>
            </div>
        </div>
        <div class="row" *ngFor="let module of this.Moduli;">
            <ng-container *ngIf="module.value === 0">
                <div class="col-2">
                    <input type="checkbox" [(ngModel)]="module.toUpgrade" />
                </div>
                <div class="col">
                    <label for="checkTot">{{module.description === 'Regiondo' ? 'Keenon' : module.description}}</label>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <!---Licenza Client-->
    <ng-container *ngIf="!this.Client">
        <div class="row">
            <div class="col">
                <h5 style="text-align: center;"><b>Aggiungi Licenza Client</b></h5>
            </div>
        </div>
            <table class="table table-hover table-striped">
                <tbody>
                    <tr>
                        <td>Quantità</td>
                        <td>
                            <input type="number" min="0" max="10" step="1" [(ngModel)]="this.LicenseClient" style="width: 100%;">
                        </td>
                    </tr>
                </tbody>
            </table>
    </ng-container>


    <ng-container *ngIf="this.CanRetail.CanRetail && !this.LicenseUpgrade.VisualRetail.Installed && !this.Client">
        <div class="row">
            <div class="col">
                <h5 style="text-align: center;"><b>Aggiungi Visual Retail</b></h5>
            </div>
        </div>
        <table class="table table-hover table-striped">
            <tbody>
                <tr>
                    <td>Visual Retail</td>
                    <td>
                        <select class="custom-select" [(ngModel)]="this.Upgradable.VisualRetail">
                            <option selected="selected" value="">
                                Non Selezionato
                            </option>
                            <option *ngIf="this.CanRetail.Tipo.Canone || this.CanRetail.Tipo.Start" value="StartNew">
                                Start
                            </option>
                            <option *ngIf="this.CanRetail.Tipo.Canone || this.CanRetail.Tipo.Pro" value="ProNew">
                                Pro
                            </option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
    <ng-container *ngIf="!this.LicenseUpgrade.Upgradable && this.Moduli.length === 0 && !this.CanRetail.CanRetail">
        <div class="row">
            <div class="col">
                <h5 style="text-align: center;">Licenza non modificabile</h5>
            </div>
        </div>
    </ng-container>
</div>
<div class="row">
    <div class="col" style="text-align: center;">
        <button class="btn btn-primary" (click)="this.modalService.show(sicuro, {id: 1, class: 'modal-lg'})" style="cursor: pointer;" (click)="CheckModuliUpgrade()" [disabled]="!IsNotDisabled()">Aggiorna</button>
    </div>
</div>
<div class="modal-footer">

</div>



<ng-template #sicuro>
        <!--Body-->
        <div class="modal-body body-custom">
            <div class="text-center">
                <fa-icon [icon]="faCheck" size="4x" style="color: green;"></fa-icon>
                <p style="font-size: 20px;">Sei sicuro di voler aggiornare la licenza?</p>
            </div>
            <ul>
            <li *ngIf="this.LicenseUpgrade.VisualFood.Installed && this.Upgradable.VisualFood !== ''"><p>
                Visual Food {{this.LicenseUpgrade.VisualFood.Start ? "Start" : (this.LicenseUpgrade.VisualFood.Pro ? "Pro" : (this.LicenseUpgrade.VisualFood.Gold ? "Gold" : ""))}} --> <b>{{this.Upgradable.VisualFood}}</b>
            </p></li>
            <li *ngIf="this.LicenseUpgrade.VisualShop.Installed && this.Upgradable.VisualShop !== ''"><p>
                Visual Shop {{this.LicenseUpgrade.VisualShop.Start ? "Start" : (this.LicenseUpgrade.VisualShop.Pro ? "Pro" : "")}} --> <b>{{this.Upgradable.VisualShop}}</b>
            </p></li>
            <li *ngIf="this.LicenseUpgrade.VisualKiosk.Installed && this.Upgradable.VisualKiosk !== ''"><p>
                Visual Kiosk {{this.LicenseUpgrade.VisualKiosk.Start ? "Start" : (this.LicenseUpgrade.VisualKiosk.Pro ? "Pro" : (this.LicenseUpgrade.VisualKiosk.Gold ? "Gold" : ""))}} --> <b>{{this.Upgradable.VisualKiosk}}</b>
            </p></li>
            <li *ngIf="this.LicenseUpgrade.VisualSelf.Installed && this.Upgradable.VisualSelf !== ''"><p>
                Visual Self {{this.LicenseUpgrade.VisualSelf.Start ? "Start" : (this.LicenseUpgrade.VisualSelf.Pro ? "Pro" : "")}} --> <b>{{this.Upgradable.VisualSelf}}</b>
            </p></li>
            <li *ngIf="this.LicenseUpgrade.VisualBeach.Installed && this.Upgradable.VisualBeach !== ''"><p>
                Visual Beach {{this.LicenseUpgrade.VisualBeach.Start ? "Start" : (this.LicenseUpgrade.VisualBeach.Pro ? "Pro" : "")}} --> <b>{{this.Upgradable.VisualBeach}}</b>
            </p></li>
            <li *ngIf="this.LicenseUpgrade.VisualRetail.Installed && this.Upgradable.VisualRetail !== ''"><p>
                Visual Retail {{this.LicenseUpgrade.VisualRetail.Start ? "Start" : (this.LicenseUpgrade.VisualRetail.Pro ? "Pro" : "")}} --> <b>{{this.Upgradable.VisualRetail}}</b>
            </p></li>

            <li *ngIf="this.CanRetail.CanRetail && this.Upgradable.VisualRetail !== ''"><p>
                Visual Retail <b>{{this.Upgradable.VisualRetail.substr(0, this.Upgradable.VisualRetail.length - 3)}}</b>
                <span class="badge badge-danger" style="margin-left: 2%">NEW</span>
            </p></li>

            <li *ngIf="this.LicenseClient > 0"><p>
                Licenze Client: <b>{{this.LicenseClient}}</b>
                <span class="badge badge-danger" style="margin-left: 2%">NEW</span>
            </p></li>

            <li *ngIf="this.upgradeModuli"><b>Moduli Aggiuntivi</b></li>
            <ul>
                <ng-container *ngFor="let module of this.Moduli">
                    <li *ngIf="module.toUpgrade && module.value === 0">
                      {{module.description === 'Regiondo' ? 'Keenon' : module.description}}
                        <span class="badge badge-danger" style="margin-left: 2%">NEW</span>
                    </li>
                </ng-container>
            </ul>
            </ul>

        </div>
        <!--Footer-->
        <div class="modal-footer justify-content-center">
            <button role="button" class="btn btn-outline-danger waves-effect" (click)="this.modalService.hide(1)" style="cursor: pointer;">No</button>
            <button role="button" class="btn btn-danger" (click)="this.Upgrade()" style="margin-left: 5%;" [disabled]="IsUpgrading" style="cursor: pointer;">Si</button>
        </div>
</ng-template>
