import { AuthService } from './../../Services/AuthService/auth.service';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { BsModalService } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { CompanyBusiness } from 'src/app/Models/Cloud/CompanyBusiness';
import { BusinessService } from './../../Services/Cloud/business.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { faTrash, faInfoCircle, faChevronCircleLeft, IconDefinition, faPen } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-business-manager',
  templateUrl: './business-manager.component.html',
  styleUrls: ['./business-manager.component.scss']
})
export class BusinessManagerComponent implements OnInit {

  public companyBusinesses: CompanyBusiness[];
  public selectedBusiness: CompanyBusiness;
  public descriptionNew: string = "";

  //ICON
  public faTrash: IconDefinition = faTrash;
  public faPen: IconDefinition = faPen;
  public faInfoCircle: IconDefinition = faInfoCircle;
  public faChevronCircleLeft: IconDefinition = faChevronCircleLeft;

  constructor(private businessService: BusinessService, public modalService: BsModalService, public alertService: AlertService, public router: Router, public auth: AuthService) { }

  async ngOnInit(): Promise<void> {
    this.companyBusinesses = await lastValueFrom(this.businessService.GetBusiness());
  }

  public async DeleteBusiness() {
    
    const result = await lastValueFrom(this.businessService.DeleteBusiness(this.selectedBusiness.id));

    if (result) {
      
      const index: number = this.companyBusinesses.findIndex(cb => {
        return cb.id === this.selectedBusiness.id;
      })
      
      this.companyBusinesses.splice(index, 1);
      this.modalService.hide();
      this.alertService.success("Attività eliminata");
    } else {
      this.modalService.hide();
      this.alertService.danger("Impossibile eliminare l'attività");
    }
  }

  public OpenModalNewBusiness(template: TemplateRef<any>) {
    this.descriptionNew = "";
    this.modalService.show(template);
  }

  public async CreateBusiness(description: string) {
    
    if (description === undefined || description === "") {
      this.alertService.danger("Inserire il nome per continuare");
      return;
    }


    const success = await lastValueFrom(this.businessService.CreateBusiness(description));
    
    if (success) {
      this.modalService.hide();
      this.descriptionNew = "";
      this.alertService.success("Attività creata");
    } else { 
      this.modalService.hide();
      this.descriptionNew = "";
      this.alertService.danger("Impossibile creare una nuova attività");
    }

    this.companyBusinesses = await lastValueFrom(this.businessService.GetBusiness());
  }

  public async UpdateBusiness(description: string, business: CompanyBusiness) {
    await lastValueFrom(this.businessService.CreateBusiness(description, business));
    this.modalService.hide();
    this.descriptionNew = "";
    business.description = description;
    this.alertService.success("Attività modificata");
  }

}
