<app-login *ngIf="auth.IsLogin"></app-login>

<div class="bounds" *ngIf="!auth.IsLogin">
    <div class="content" fxLayout="row" fxLayout.lt-lg="column" fxFlexFill>
        <div fxFlex="15%" id="sidebar"  style="overflow-y: hidden; box-sizing: inherit !important;">
            <app-sidebar [headerName]="this.headerName" style="box-sizing: inherit !important;"></app-sidebar>
        </div>
        <div fxFlex="85%" height="100vh" >
          <nav class="navbar navbar-expand-lg fixed-header" >
            <div class=" container-fluid  ">
              <a class="navbar-brand" >{{headerName}}</a>
              <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
              </button>
            </div>
          </nav>
          <ngx-alerts></ngx-alerts>
          <div  id="view">
            <router-outlet></router-outlet>
          </div>
        </div>
    </div>
</div>
