<div style="background-color: #f8f8f8" *ngIf="ShopService.ShowTipologiaLicenza">
  <div class="scelta-tipologia-image-row">
    <section>
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="scelta-tipologia-row">
        <div fxFlex="50%" fxFlex.lt-lg="100%" class="owl-item active scelta-tipologia"  style="margin-right: 20px;">
          <div class="single-product" *ngIf="(this.auth.AuthUser.Role === 1 && !this.auth.AuthUser.Tipo.includes('S')) || (this.auth.AuthUser.Role === 4 && !this.auth.AuthUser.Tipo.includes('S'));else accessoconsentitos">
            <div  class="product-f-image" >
              <img src="./../../../assets/img/singola-licenza.png" class="scelta-tipologia-img" alt="">
              <div class="product-hover" >
                <a (click)="this.alertService.danger('Accesso non consentito')" class="view-details-link"><img src="assets/img/padlock.png" style="filter: brightness(0) invert(1);height: 40px;width: 40px"></a>
              </div>
            </div>
          </div>
          <ng-template #accessoconsentitos>
            <div  class="single-product">
              <div  class="product-f-image">
                <img src="./../../../assets/img/singola-licenza.png" class="scelta-tipologia-img" alt="">
                <div class="product-hover" >
                  <a (click)="ContinueAcquistoSingolo()" class="view-details-link">Continua</a>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <div fxFlex="50%" fxFlex.lt-lg="100%" class="owl-item active scelta-tipologia" style="margin-right: 20px;">
          <div class="single-product" *ngIf="(this.auth.AuthUser.Role === 1 && !this.auth.AuthUser.Tipo.includes('P')) || (this.auth.AuthUser.Role === 4 && !this.auth.AuthUser.Tipo.includes('P'));else accessoconsentitop">
            <div  class="product-f-image" >
              <img src="./../../../assets/img/pacchetto-licenze.png" class="scelta-tipologia-img" alt="">
              <div class="product-hover" >
                <a (click)="this.alertService.danger('Accesso non consentito')" class="view-details-link"><img src="assets/img/padlock.png" style="filter: brightness(0) invert(1);height: 40px;width: 40px"></a>
              </div>
            </div>
          </div>
          <ng-template #accessoconsentitop>
            <div  class="single-product">
              <div  class="product-f-image">
                <img src="./../../../assets/img/pacchetto-licenze.png" class="scelta-tipologia-img" alt="">
                <div class="product-hover" >
                  <a (click)="ContinueToSelezionePacchetto()" class="view-details-link">Continua</a>
                </div>
              </div>
            </div>
          </ng-template>

        </div>
      </div>
    </section>

    <!--


    <div *ngIf="ShopService.DettaglioProdotto">
      <app-dettaglio-prodotto></app-dettaglio-prodotto>
    </div>

    <ng-template #softwareScelto>
      <section>
        <div class="modal-header modal-head-user">
          <h5 class="modal-title" id="modalHead">Software</h5>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="controls">
              <table class="table table-hover table-striped" id="table">
                <thead>
                <tr>
                  <th>Codice Articolo</th>
                  <th>Descrizione</th>
                  <th>Costo</th>
                  <th>Quantità</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ShopService.SoftwareSelezionato.CodiceArticolo}}</td>
                  <td>{{ShopService.SoftwareSelezionato.Descrizione}}</td>
                  <td>{{ShopService.SoftwareSelezionato.Costo}}€</td>
                  <td><input type="number" min="1" [(ngModel)]="ShopService.QuantitaSelezionato"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-6">
              <label [hidden]="!ShopService.ErroreCarrello" style="color: red;">Quantita di licenze non compatibile col pacchetto scelto</label>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col">
                  <div class="btn btn-danger center" style="margin-left: auto; margin-right: auto;" (click)="this.modalService.hide()">Annulla</div>
                </div>
                <div class="col">
                  <div class="btn btn-success center" style="margin-left: auto; margin-right: auto;" >Conferma</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-template>

    <ng-template #pacchetto>
      <div class="modal-body">
        <div class="container">
          <div class="controls">
            <table class="table table-hover table-striped">
              <thead>
              <tr>
                <th>Codice Articolo</th>
                <th>Descrizione</th>
                <th>Costo</th>
                <th>Quantità</th>
                <th>Elimina</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let elemento of ShopService.Pacchetto">
                <td>{{elemento.software.CodiceArticolo}}</td>
                <td>{{elemento.software.Descrizione}}</td>
                <td>{{elemento.software.Costo}}€</td>
                <td>{{elemento.quantita}}</td>
                <td><fa-icon [icon]="faTrash" size="2x" (click)="RemovePacchetto(elemento)"></fa-icon></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn btn-danger center" style="margin-left: auto; margin-right: auto;color: white" (click)="this.modalService.hide()">Chiudi</div>
      </div>
    </ng-template>
  -->
  </div>
</div>
