import { CartService } from './../../Services/CartService/cart.service';
import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../Services/AuthService/auth.service';
import {Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from 'ngx-alerts';
import {UserStoreService} from '../../Services/Admin/user-store.service';
import {ShopService} from '../../Services/ShopService/shop.service';
import SoftwareListino from '../../Models/SoftwareListino';
import AcquistoPacchetto from '../../Models/AcquistoPacchetto';
import {Contenitore} from '../tipologialicenza/tipologialicenza.component';
import {IsDev} from '../../global';

@Component({
  selector: 'app-buypackagelicense',
  templateUrl: './buypackagelicense.component.html',
  styleUrls: ['./buypackagelicense.component.scss']
})
export class BuypackagelicenseComponent implements OnInit {

  listino: SoftwareListino[] = [];
  IsDev = IsDev;
  constructor(private http: HttpClient, public auth: AuthService, private router: Router,
              public ShopService: ShopService, public modalService: BsModalService, private alertService: AlertService, public userStoreService:UserStoreService,
              
              private cartService: CartService
              ) { }

  async ngOnInit(): Promise<void> {
    let categorie = await this.userStoreService.GetListino();

    for (let j = 0; j < categorie[0].Elementi.length; j++)
    {
      this.listino.push(categorie[0].Elementi[j]);
    }


    this.ShopService.prodottiMostrati = this.listino;

  }
  ShowDettaglioProdotto(software: SoftwareListino) {
    this.router.navigate(["shop/acquisto-licenza-multipla", software.CodiceArticolo], {state:software});
    this.ShopService.ShowAcquistoSingolo = false;
    this.ShopService.ShowAcquistoPacchetti = true;
    //this.router.navigate(['/shop',software.CodiceArticolo]);
  }

  incrementare(i: number, showAcquistoPacchetti?, showAcquistoSingolo?) {
    var value = parseInt((<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value, 10);
    if (isNaN(value) || value < 1){
      value = 1;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value = String(value);
    }
    if(value < Number(this.ShopService.PacchettoScelto - this.ShopService.LeftPacchettoScelto)){
      value++;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value = String(value);
    }
  }
  decrementare(i: number) {
    var value = parseInt((<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value, 10);
    if (isNaN(value) || value < 1){
      value = 1;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value = String(value);
    }
    if (value > 1){
      value--;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value = String(value);
    }
  }
  /**
   * AGGIUNTA AL CARRELLO PRODOTTO SINGOLO
   * */
  public async AggiuntaAlCarrelloProdottoSingolo(software: SoftwareListino, i:number) {
    var value = parseInt((<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value, 10);
    this.ShopService.SoftwareSelezionato = software;
    this.ShopService.QuantitaSelezionato = value;
    await this.AggiungiCarrello();
  }
  // PASSO AL SERVIZIO, OLTRE CHE IL PRODOTTO, ANCHE LA QUANTITA, SINGOLA O PACCHETTO
  // IN QUESTO MODO, POSSO ESTRARRE LO SCONTO CORRETTO, CALCOLARLO ED INSERLO NEL CARRELLO
  public async AggiungiCarrello() {
    const contenitore: Contenitore = new Contenitore;
    contenitore.Tipo = "P";

    contenitore.IdPacchettoCart = '_' + Math.random().toString(36).substr(2,9);

    const successCarrello: boolean = await this.ShopService.AggiungiCarrello(this.ShopService.SoftwareSelezionato, this.ShopService.QuantitaSelezionato, this.ShopService.PacchettoScelto, contenitore);
    if (successCarrello) {
      this.ShopService.Carrello.push(contenitore);
    } else {
      this.alertService.danger("Impossibile aggiungere al carrello. Contatta il tuo distributore per maggiori informazioni")
      return;
    }
    //INSERISCO NEL DB
    const result: any = await this.cartService.Insert(contenitore);

    if (result.response === 'ok') {
      this.alertService.success('Elemento aggiunto al carrello');
    }

  }
  getInputMax(left, total): number {
    return Number(total-left);
  }

  public AggiungiPacchetto(software: SoftwareListino, i: number) {
    let quantita = parseInt((<HTMLInputElement>document.getElementById('quantita' + '-' + 'articolo' + '-' + i)).value, 10);
    if (isNaN(quantita) || quantita < 1){
      this.alertService.danger('Perfavore inserisci una quantità corretta')
    }else{
      this.ShopService.QuantitaSelezionato = quantita;
      this.ShopService.SoftwareSelezionato = software;

      if (this.ShopService.QuantitaSelezionato > this.ShopService.PacchettoScelto - this.ShopService.LeftPacchettoScelto || this.ShopService.QuantitaSelezionato === 0) {
        this.ShopService.ErroreCarrello = true;
      } else {
        this.ShopService.ErroreCarrello = false;
        this.ShopService.LeftPacchettoScelto += this.ShopService.QuantitaSelezionato;
        this.modalService.hide();

        //SE GIA' ESISTE UN ELEMENTO UGUALE, SEMPLICEMENTE SOMMO
        let index: number = -1;

        for (let i = 0; i < this.ShopService.Pacchetto.length; ++i) {
          if (this.ShopService.Pacchetto[i].software.CodiceArticolo === software.CodiceArticolo) {
            index = i;
            break;
          }
        }

        if (index !== -1) {
          this.ShopService.Pacchetto[index].quantita += this.ShopService.QuantitaSelezionato;
        } else {

          const acquistoPacchetto: AcquistoPacchetto = new AcquistoPacchetto(this.ShopService.SoftwareSelezionato, this.ShopService.QuantitaSelezionato, this.ShopService.PacchettoScelto);

          this.ShopService.Pacchetto.push(acquistoPacchetto);
        }
        this.ShopService.QuantitaSelezionato = 0;
      }
    }

  }
  getProductImage(software: SoftwareListino) {
    if(this.IsDev){
      return 'https://localhost/ImgListino/' + software.ImgName;
    }else{
      return 'https://www.gabservizi.it/ImgListino/' + software.ImgName;
    }
  }

  ImageErrorHandler(codice:any) {
    document.getElementById(codice).setAttribute('src', 'assets/img/GabImageNotFound.png');
    //'assets/img/GabImageNotFound.png';
  }
}
