import { CompanyBusiness } from 'src/app/Models/Cloud/CompanyBusiness';
import { Group } from './Group';
import { SellPoint } from './SellPoint';
import { ZipCode } from './ZipCode';

export class Center {
    id: number;
    companyName: string;
    uuid: string;
    vatNumber: string;
    fiscalCode: string;
    sdiCode: string;
    rea: string;
    address: string;
    street: string;
    zipCodeId: string;
    zipCode: ZipCode;
    phone: string;
    phonePrefix: string;
    mobile: string;
    mobilePrefix: string;
    fax: string;
    faxPrefix: string;
    email: string;
    contact: string;
    website: string;
    bank: string;
    branchBank: string;
    iban: string;
    cin: string;
    abi: string;
    cab: string;
    swift: string;
    note: string;
    business: CompanyBusiness;
    group: Group;
    sellPoints: SellPoint[];
  }
  
  