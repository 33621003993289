export default class CensimentoAggiorna {
  public PIVA: string
  public NMAG: string
  public NCASSA : string
  public MAC : string
  public SOFTWARE : string
  public VERSION : string
  public DESMAG : string
  public RAGSOC : string
}
