<div class="fade-in " style="background-color: #f5f5f5; height: 100vh;">
  <header style="height: 50px;"></header>
  <div  id="form-login" *ngIf="!this.IsPassword && !this.IsSignUp">
    <form  id="form-signin" class="form-signin " [class.rimpicciolire]="IsRimpicciolire" (keydown.enter)="Login()" >
      <div style="text-align: center">
        <img class="mb-2 logo-gab" draggable="false" [src]="!this.auth.IsCashServizi ? 'assets/img/gabservizi.png' : 'assets/img/logo_cash.png'"/>
      </div>
      <h1 class="h3 mb-3 font-weight-normal" translate="yes" style="text-align: center;">{{!this.auth.IsCashServizi ? 'Accedi a Gab Servizi' : 'Accesso Cashmatic Servizi'}}</h1>
      <input type="text" id="login-username" class="form-control" placeholder="Username..." autofocus="autofocus"
             required />
      <label for="login-username" class="sr-only">Username</label>
      <input type="password" id="login-password" class="form-control" placeholder="Password..." required />
      <label for="login-password" class="sr-only">Password</label>
      <input type="button" id="loginbtn" class="btn btn-primary btn-fill btn-block mb-3" value="Login"
             (click)="Login()" />
      <div class="row">
        <div class="col-6">
          <a role="button" (click)="this.IsPassword = true" (click)="this.Username = ''"><small>Recupera Password</small></a>
        </div>
        <div class="col-6">
          <a role="button" style="float: right;color: black;" (click)="this.IsSignUp = true;"><small style="font-weight: bold;">{{!this.auth.IsCashServizi ? 'Registrazione' : ''}}</small></a>
        </div>
      </div>
      <footer>
        <div class="row">
          <div class="col">
            <p class="mt-4 mb-3 text-muted" style="text-align: center;">{{!this.auth.IsCashServizi ? 'GAB Tamagnini ©' + this.year : ''}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <alert type="danger" [dismissible]="true" [isOpen]="alertOpen">
              <strong style="text-align: center;">{{alertMsg}}</strong>
            </alert>
          </div>
        </div>
      </footer>
    </form>
  </div>
  <!--LOADING-->
  <div style="position: absolute; left: 50%; padding-top: 2%" *ngIf="this.loadingLogin">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <form class="form-signin" *ngIf="this.IsPassword">
    <div style="text-align: center">
      <img class="mb-2 logo-gab" draggable="false" [src]="!this.auth.IsCashServizi ? 'assets/img/gabservizi.png' : 'assets/img/logo_cash.png'"/>
    </div>
    <h1 class="h3 mb-3 font-weight-normal" translate="yes" style="text-align: center;">Recupera Password</h1>
    <h3 class="h3 mb-3 font-weight-normal" translate="yes" style="text-align: center; font-size: 22px !important;" id="recoverPassTitle">Inserisci Nome Utente</h3>
    <label for="recoverPassTitle" class="sr-only">Username</label>
    <input type="text" id="sign-in-username" class="form-control" placeholder="Username..." autofocus="autofocus" [(ngModel)]="this.Username" [ngModelOptions]="{standalone: true}"
           required />
    <input type="button" id="recoverPass" class="btn btn-primary btn-fill btn-block mb-3" value="Recupera Password" style="margin-top:3%"
           (click)="RecoverPass()" />
    <div class="row">
      <div class="col-6">
        <a role="button" (click)="this.IsPassword=false"><small>Indietro</small></a>
      </div>
    </div>
    <footer>
      <p class="mt-4 mb-3 text-muted" style="text-align: center;">{{!this.auth.IsCashServizi ? 'GAB Tamagnini ©' + this.year : ''}}</p>
      <div class="row">
      </div>
      <div class="row">
        <div class="col"></div>
        <div class="col">
          <alert style="width: 50%;" type="danger" [dismissible]="true" [isOpen]="alertOpen">
            <strong style="text-align: center;">{{alertMsg}}</strong>
          </alert>
        </div>
        <div class="col"></div>
      </div>
    </footer>
  </form>
  <!--REGISTRAZIONE DA COMPUTER-->
  <div *ngIf="this.IsSignUp">
    <form  id="form-signup" class="form-signup allargare" (keydown.enter)="SignUpUser(RegistrazioneAvvenuta)" >
      <div style="text-align: center">
        <img class="mb-2 logo-gab" draggable="false" src="assets/img/gabservizi.png"/>
      </div>
      <div class="card-header" style="border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 1px solid rgba(0, 0, 0, 0.125);">
        <div class=" sign-up-title">Iscriviti!</div><small>È veloce e semplice</small>
      </div>
      <div style="padding: 15px">
        <div class="messages"></div>
        <div class="controls">
          <div class="row" >
            <div class="col">
              <div >
                <label for="sign-up-username">NOME UTENTE *</label>
                <input #username id="sign-up-username" type="text" name="username" class="form-control" placeholder="Inserire nome utente *" required="required" (keyup)="UsernameValidation(username.value)" >
                <small *ngIf="usernameEmpty" class="empty-input">Il nome utente è obbligatorio<br></small>
                <small *ngIf="usernameTooShort" class="empty-input">Il nome utente deve avere almeno 3 caratteri<br></small>
                <small *ngIf="usernameNotCorrect" class="empty-input">Il nome utente non è valido</small>
              </div>
            </div>
            <div class="col">
              <div >
                <label for="sign-up-password">PASSWORD *</label>
                <input #password id="sign-up-password" type="password" name="psw" class="form-control" placeholder="Inserire la password *" required="required" (keyup)="PasswordValidation(password.value)" >
                <small *ngIf="passwordEmpty" class="empty-input">La password è obbligatoria</small>
                <small *ngIf="passwordTooShort" class="empty-input">La password deve avere almeno 6 caratteri</small>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col">
              <div  style="margin-bottom: 0">
                <label for="sign-up-piva">PIVA *</label>
                <input #piva id="sign-up-piva" type="text" name="piva" class="form-control" placeholder="Inserire la partita iva *" required="required" (keyup)="PivaValidation(piva.value)" >
                <small *ngIf="pivaEmpty" class="empty-input">La partita IVA è obbligatoria</small>
                <small *ngIf="pivaNotCorrect" class="empty-input">La partita IVA non è valida</small>
              </div>
            </div>
            <div class="col">
              <div >
                <label for="sign-up-ragsoc">RAGIONE SOCIALE *</label>
                <input #ragsoc id="sign-up-ragsoc" type="text" name="negozi" class="form-control" placeholder="Inserire ragione sociale *" required="required" (keyup)="RagSocValidation(ragsoc.value)">
                <small *ngIf="ragsocEmpty" class="empty-input">La ragione sociale è obbligatoria</small>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col">
              <div >
                <label for="sign-up-citta">CITTÀ *</label>
                <input #citta id="sign-up-citta" type="text" name="piva" class="form-control" placeholder="Inserire la città *" required="required" (keyup)="CittaValidation(citta.value)">
                <small *ngIf="cittaEmpty" class="empty-input">La città è obbligatoria</small>
              </div>
            </div>
            <div class="col">
              <div >
                <label for="sign-up-via">VIA *</label>
                <input #via id="sign-up-via" type="text" name="negozi" class="form-control" placeholder="Inserire l'indirizzo *" required="required" (keyup)="ViaValidation(via.value)" >
                <small *ngIf="viaEmpty" class="empty-input">La via è obbligatoria</small>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col">
              <div >
                <label for="sign-up-email">EMAIL *</label>
                <input #email id="sign-up-email" type="text" name="piva" class="form-control" placeholder="Inserire indirizzo e-mail iva *" required="required" (keyup)="EmailValidation(email.value)">
                <small *ngIf="emailEmpty" class="empty-input">La e-mail è obbligatoria</small>
                <small *ngIf="emailNotCorrect" class="empty-input">La e-mail non è valida</small>
              </div>
            </div>
            <div class="col">
              <div >
                <label for="sign-up-pec">PEC</label>
                <input #pec id="sign-up-pec" type="text" name="negozi" class="form-control" placeholder="Inserire indirizzo PEC" required="required" (keyup)="PecValidation(pec.value)">
                <small *ngIf="pecEmpty" class="empty-input">Una tra SDI e PEC è obbligatoria</small>
                <small *ngIf="pecNotCorrect" class="empty-input">La PEC non è valida</small>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col">
              <div >
                <label for="sign-up-sdi">SDI</label>
                <input #sdi id="sign-up-sdi" type="text" name="piva" class="form-control" placeholder="Inserire SDI " required="required" (keyup)="SdiValidation(sdi.value)">
                <small *ngIf="sdiEmpty" class=" empty-input">Una tra SDI e PEC è obbligatoria</small>
                <small *ngIf="sdiNotCorrect" class="empty-input">Lo SDI non è valido</small>
              </div>
            </div>
            <div class="col">
              <div >
                <label for="sign-up-iban">IBAN </label>
                <input #iban id="sign-up-iban" type="text" name="negozi" class="form-control" placeholder="Inserire IBAN " required="required" (keyup)="IbanValidation(iban.value)">
                <small *ngIf="ibanEmpty" class="empty-input-non-obbligatorio">Il campo è vuoto, non è obbligatorio</small>
                <small *ngIf="ibanNotCorrect" class="empty-input">L'IBAN non è valido</small>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col-md-12">
              <div style="margin-top: 15px">
                <input type="submit" name="creaUtente" value="Crea utente" id="creaUtente" (click)="SignUpUser(RegistrazioneAvvenuta)" class="btn btn-primary btn-fill btn-block" [disabled]="loading == true">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div _ngcontent-lop-c1="" class="row" >
        <div _ngcontent-lop-c1="" class="col-6">
          <a _ngcontent-lop-c1="" role="button" role="button" (click)="IndietroDaRegistrazione()">
            <small style="padding-left: 15px;" _ngcontent-lop-c1="">Indietro</small>
          </a>
        </div>
      </div>
    </form>
    <header style="height: 50px;"></header>
  </div>
  <!--REGISTRAZIONE DA MOBILE-->
</div>
<ng-template #RegistrazioneAvvenuta>
  <div class="row pop-up">
    <div class="box small-6 large-centered" style="height: 290px;">
      <h3 class="h3modal">Grazie!</h3>
      <p style="padding: 1rem">La ringraziamo per essersi registrato su <b>Gab Servizi</b> </p>
      <div>
        <div >
          <div (click)="GoToLogin()" style="margin-top: 80px;">
            <a  style="
                  background-color: #007bff;
                  margin-bottom: 33px;
                  color: white;
                  padding: 1rem;cursor: pointer;margin-top: 50px">Torna allo login
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
