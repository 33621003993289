<div class="card-body table-responsive" style="padding: 0; overflow-x:hidden" >
    <div class="card-header">
        <div class="row">
            <div class="col">
                <h4 class="card-title" style="margin-top: 0;">Licenze Cloud</h4>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col">
                        <h5 *ngIf="this.auth.AuthUser.ID === 10" class="card-title pointer" (click)="this.OpenBusinessManager()"><strong><fa-icon [icon]="faChartLine"></fa-icon>&nbsp;&nbsp;Tipologie attività</strong></h5>
                    </div>
                    <div class="col">
                        <h5 *ngIf="this.auth.AuthUser.ID === 10" class="card-title pointer" (click)="OpenLicensesManager()"><strong><fa-icon [icon]="faKey"></fa-icon>&nbsp;&nbsp;Configurazioni Licenze</strong></h5>
                    </div>
                    <div class="col" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">
                        <h5 class="card-title pointer" (click)="OpenModalNew(newGroup)"><strong><fa-icon [icon]="faChartLine"></fa-icon>&nbsp;&nbsp;Nuovo Gruppo</strong></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="controls">
        <div class="row" style="margin-top: 1%;">
            <div class="col-2">
                <div class="form-group">
                    <label>Ricerca</label>
                    <input type="text" class="form-control form-control-sm" placeholder="Cerca" (keyup)="Filter($event.target.value, 'Search')" id="search">
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label>Valido/Scaduto</label>
                    <select class="form-control form-control-sm" (change)="Filter($event.target.value, 'Expired')">
                        <option selected [value]="null">Tutti</option>
                        <option [value]="true">Valido</option>
                        <option [value]="false">Scaduto</option>
                    </select>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label>Attivo/Disattivato</label>
                    <select class="form-control form-control-sm" (change)="Filter($event.target.value, 'Active')">
                        <option selected [value]="null">Tutti</option>
                        <option [value]="true">Attivo</option>
                        <option [value]="false">Disattivato</option>
                    </select>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label>Licenza</label>
                    <select class="form-control form-control-sm"  (change)="Filter($event.target.value, 'LicenseConfigId')">
                        <option selected [value]="null">Tutti</option>
                        <option *ngFor="let license of cloudLicenses" [value]="license.id">{{license.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col" *ngIf="this.auth.AuthUser.ID === 10">
                <div class="form-group">
                    <input type="checkbox" [(ngModel)]="this.filter.deleted" (ngModelChange)="Filter(this.filter.deleted, 'Deleted')">
                    <label class="form-check-label" style="padding-left: 1%;">Cestino</label>
                </div>
            </div>
        </div>
        <table class="table table-hover table-striped " id="table" style="margin-top: 0;">
            <thead>
                <th style="width: 5%;"></th>
                <th style="width: 5%;"></th>
                <th style="width: 35%;"></th>
                <th style="width: 35%;"></th>
                <th style="width: 20%;"></th>
            </thead>
            <tbody>
                <ng-container *ngFor="let group of this.Groups">
                    <tr>
                        <td (click)="GetCenters(group)"><label class="label-c">{{group.createdAt | date: 'dd/MM/yyyy'}}</label></td>
                        <td (click)="GetCenters(group)"><label class="label-c">{{group.qrCode}}</label></td>
                        <td (click)="GetCenters(group)">{{group.name}}</td>
                        <td (click)="GetCenters(group)"><label class="label-c">{{group.description}}</label></td>
                        <td>
                            <div class="row">
                                <div class="col-2">
                                    <fa-icon [icon]="faLink" tooltip="Visualizza Centri" (click)="GetCenters(group)"></fa-icon>
                                </div>
                                <div class="col-2">
                                    <fa-icon [icon]="faHourglassStart" [style.color]="group.expirationDate < today ? 'red' : 'green'" [tooltip]="group.expirationDate < today ? 'Scaduto' : 'Valido'" (click)="OpenModal(validLicense, group)" ></fa-icon>
                                </div>
                                <div class="col-2" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Stato && this.auth.AuthUser.Role === 0">
                                    <fa-icon [icon]="faPowerOff" [tooltip]="group.active ? 'Attivo' : 'Inattivo'" [style.color]="group.active ? 'green' : 'red'" (click)="this.ToggleGroupState(group)"></fa-icon>
                                </div>
                                <div class="col-2">
                                    <fa-icon [icon]="faEdit" (click)="this.OpenModalEditGroup(chooseLicense, group)" tooltip="Modifica Gruppo"></fa-icon>
                                </div>
                                <div class="col-2" *ngIf="!group.deleted && this.auth.AuthUser.ID === 10" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">
                                    <fa-icon [icon]="faTrash" style="color:red" (click)="this.OpenModal(elimina, group)" tooltip="Elimina"></fa-icon>
                                </div>
                                <div class="col-2" *ngIf="group.deleted && this.auth.AuthUser.ID === 10">
                                    <fa-icon [icon]="faTrashRestore" style="color:green" (click)="this.OpenModal(elimina, group)" tooltip="Ripristina"></fa-icon>
                                </div>
                                <div class="col-2">
                                    <fa-icon [icon]="faUserShield" (click)="this.OpenModalSuperAdmin(superadmin, group)" tooltip="Admins"></fa-icon>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<ng-template #validLicense>
    <div class="modal-content">
        <div class="modal-body" style="max-height: calc(100% - 3.5rem);" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Upgrade && this.auth.AuthUser.Role === 0">
            <div class="modal-title">
                <h4>Validità licenza</h4>
            </div>
            <div class="form-group">
                <label>Durata della licenza</label>
                <div class="row">
                    <div class="col">
                        <button class="btn btn-light" style="height: 70px;" (click)="this.SelectDays(30)">
                            <label>30 giorni</label>
                        </button>
                    </div>
                    <div class="col">
                        <button class="btn btn-light" style="height: 70px;" (click)="this.SelectDays(90)">
                            <label>90 giorni</label>
                        </button>
                    </div>
                    <div class="col">
                        <button class="btn btn-light" style="height: 70px;" (click)="this.SelectDays(180)">
                            <label>180 giorni</label>
                        </button>
                    </div>
                    <div class="col">
                        <button class="btn btn-light" style="height: 70px;" (click)="this.SelectDays(360)">
                            <label>360 giorni</label>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col">
                        Inizio
                    </div>
                    <div class="col">
                        <input type="text" style="display: inline;"
                                    placeholder="Inizio Licenza"
                                    class="form-control"
                                    [(ngModel)]="this.SelectedGroup.startDate"
                                    [bsConfig]="bsConfig"
                                    bsDatepicker>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col">
                        Scadenza
                    </div>
                    <div class="col">
                        <input type="text" style="display: inline;"
                                    placeholder="Scadenza Licenza"
                                    class="form-control"
                                    [(ngModel)]="this.SelectedGroup.expirationDate"
                                    [bsConfig]="bsConfig"
                                    bsDatepicker>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Durata licenza: <strong>{{(this.SelectedGroup.expirationDate.getTime() - this.SelectedGroup.startDate.getTime()) / (1000 * 3600 * 24) | number: '1.0-0' }}</strong> giorni
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-danger" (click)="this.modalService.hide()">Annulla</button>
            <button class="btn btn-primary" (click)="this.SaveDateLicense()" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Upgrade && this.auth.AuthUser.Role === 0">Salva</button>
        </div>
    </div>
</ng-template>

<ng-template #chooseLicense>
    <div class="modal-content">
        <div class="modal-body" style="max-height: calc(100% - 3.5rem);" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Upgrade && this.auth.AuthUser.Role === 0">
            <div class="modal-title">
                <h4>Modifica cliente</h4>
            </div>
            <div class="form-group">
                <label>Nome gruppo</label>
                <input type="text" class="form-control" [(ngModel)]="this.SelectedGroup.name">
            </div>
            <div class="form-group">
                <label>Licenza gruppo</label>
                <select class="form-control" [(ngModel)]="this.SelectedGroup.licenseConfigurationId">
                    <option *ngFor="let license of cloudLicenses" [selected]="this.SelectedGroup.licenseConfigurationId === license.id" [ngValue]="license.id">{{license.name}}</option>
                </select>
            </div>
            <label>Modalità di fatturazione</label>
            <div class="form-check">
                <input class="form-check-input" type="radio" [(ngModel)]="this.SelectedGroup.billingType" [value]="0">
                <label class="form-check-label" for="exampleRadios1">Mensile</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" [(ngModel)]="this.SelectedGroup.billingType" [value]="1">
                <label class="form-check-label" for="exampleRadios1">Trimestre</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" [(ngModel)]="this.SelectedGroup.billingType" [value]="2">
                <label class="form-check-label" for="exampleRadios1">Annuale</label>
            </div>
            <div class="form-group">
                <label>Descrizione gruppo</label>
                <textarea class="form-control" [(ngModel)]="this.SelectedGroup.description" row="2"></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-danger" (click)="this.modalService.hide()">Annulla</button>
            <button class="btn btn-primary" (click)="EditGroupLicense()" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Upgrade && this.auth.AuthUser.Role === 0">Salva</button>
        </div>
    </div>
</ng-template>

<ng-template #newGroup>
    <div class="modal-content">
        <div class="modal-body">
            <div class="modal-title">
                <h4>Nuovo cliente</h4>
            </div>
            <div class="form-group">
                <label>Nome gruppo</label>
                <input type="text" class="form-control" placeholder="Inserire nome gruppo" required [(ngModel)]="this.SelectedGroup.name">
            </div>
            <div class="form-group">
                <label>Licenza gruppo</label> 
                <select class="form-control" [(ngModel)]="this.SelectedGroup.licenseConfigurationId">
                    <option *ngFor="let license of cloudLicenses" [selected]="this.SelectedGroup.licenseConfigurationId === license.id" [ngValue]="license.id">{{license.name}}</option>
                </select>
            </div>
            <label>Modalità di fatturazione</label>
            <div class="form-check">
                <input class="form-check-input" type="radio" [(ngModel)]="this.SelectedGroup.billingType" [value]="0">
                <label class="form-check-label" for="exampleRadios1">Mensile</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" [(ngModel)]="this.SelectedGroup.billingType" [value]="1">
                <label class="form-check-label" for="exampleRadios1">Trimestre</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" [(ngModel)]="this.SelectedGroup.billingType" [value]="2">
                <label class="form-check-label" for="exampleRadios1">Annuale</label>
            </div>
            <div class="form-group">
                <label>Descrizione gruppo</label>
                <textarea class="form-control" [(ngModel)]="this.SelectedGroup.description" row="2" placeholder="Inserire descrizione gruppo"></textarea>
            </div>
            <hr>
            <div class="modal-title">
                <h5>Super Admin</h5>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Nome</label>
                        <input type="text" class="form-control" placeholder="Inserire nome" required [(ngModel)]="this.SelectedSuperAdmin.firstName">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Cognome</label>
                        <input type="text" class="form-control" placeholder="Inserire cognome" required [(ngModel)]="this.SelectedSuperAdmin.lastName">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Indirizzo e-mail</label>
                <input type="email" class="form-control" placeholder="Inserire indirizzo e-mail" required [(ngModel)]="this.SelectedSuperAdmin.email">
            </div>
            <div class="form-group">
                <label>Password</label>
                <input type="password" class="form-control" placeholder="Inserire password" required [(ngModel)]="this.SelectedSuperAdmin.password">
            </div>
            <div class="form-group">
                <label>Cellulare</label>
                <input type="tel" class="form-control" placeholder="Inserire cellulare" [(ngModel)]="this.SelectedSuperAdmin.mobile">
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-danger" (click)="this.modalService.hide()">Annulla</button>
            <button class="btn btn-primary" (click)="NewGroup(validLicense)">Salva</button>
        </div>
    </div>
</ng-template>

<ng-template #elimina>
    <div class="modal-content">
        <div class="modal-body" style="max-height: calc(100% - 3.5rem);">
            <div class="modal-title">
                <div class="row">
                    <div class="col" style="text-align: center;">
                        <fa-icon [icon]="this.faInfoCircle" size="2x"></fa-icon>
                    </div>
                </div>
                <h4 style="text-align: center;" *ngIf="!this.SelectedGroup.deleted">Vuoi eliminare <strong>{{this.SelectedGroup.name}}</strong>?</h4>
                <h4 style="text-align: center;" *ngIf="this.SelectedGroup.deleted">Vuoi ripristinare <strong>{{this.SelectedGroup.name}}</strong>?</h4>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" (click)="this.modalService.hide()">Annulla</button>
                <button class="btn btn-primary" (click)="ToggleGroupDeleted(this.SelectedGroup)">Elimina</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #eliminaAdmin>
    <div class="modal-content">
        <div class="modal-body" style="max-height: calc(100% - 3.5rem);">
            <div class="modal-title">
                <div class="row">
                    <div class="col" style="text-align: center;">
                        <fa-icon [icon]="this.faInfoCircle" size="2x"></fa-icon>
                    </div>
                </div>
                <h4 style="text-align: center;">Vuoi eliminare l'Admin?</h4>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" (click)="this.modalService.hide()">Annulla</button>
                <button class="btn btn-primary" (click)="DeleteAdmin(this.SelectedSuperAdmin)">Elimina</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #superadmin>
    <div class="modal-content">
        <div class="modal-body" style="overflow-x: hidden;">
                <div class="modal-title">
                    <div class="row">
                        <div class="col">
                            <h4 style="margin-top: 0;">Lista SuperAdmin</h4>
                        </div>
                        <!--
                        <div class="col" style="display: grid; align-items: center;">
                            <button class="btn btn-primary">Crea Admin</button>
                        </div>
                        -->
                    </div>
                </div>
                <table class="table table-hover table-striped " id="table" style="margin-top: 0;">
                    <thead>
                        <th style="width: 45%;">Username</th>
                        <th style="width: 45%;">Dati</th>
                        <th style="width: 10%;"></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let admin of this.SelectedGroup.superAdmins">
                            <td><label>{{admin.username}}</label></td>
                            <td><label>{{admin.firstName}} {{admin.lastName}}</label></td>
                            <td>
                                <div class="row" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">
                                    <div class="col">
                                        <fa-icon [icon]="faLink" tooltip="Modifica" (click)="EditSuperAdmin(admin)"></fa-icon>
                                    </div>
                                    <div class="col">
                                        <fa-icon [icon]="faTrash" style="color:red" tooltip="Elimina" (click)="this.SelectedSuperAdmin = admin" (click)="OpenModal(eliminaAdmin, this.SelectedGroup)" ></fa-icon>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row" *ngIf="!this.EditAdminBoolean" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">
                    <div class="col">
                        <label style="cursor: pointer;" (click)="NewSuperAdmin()"><strong>Clicca qui per creare un nuovo admin</strong></label>
                    </div>
                </div>
                <ng-container *ngIf="this.NewAdminBoolean || this.EditAdminBoolean">
                    <div class="modal-title">
                        <h5>Super Admin</h5>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label>Nome</label>
                                <input type="text" class="form-control" placeholder="Inserire nome" required [(ngModel)]="this.SelectedSuperAdmin.firstName">
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Cognome</label>
                                <input type="text" class="form-control" placeholder="Inserire cognome" required [(ngModel)]="this.SelectedSuperAdmin.lastName">
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Indirizzo e-mail</label>
                        <input type="email" class="form-control" placeholder="Inserire indirizzo e-mail" required [(ngModel)]="this.SelectedSuperAdmin.email">
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" class="form-control" placeholder="Inserire password" required [(ngModel)]="this.SelectedSuperAdmin.password">
                    </div>
                    <div class="form-group">
                        <label>Cellulare</label>
                        <input type="tel" class="form-control" placeholder="Inserire cellulare" [(ngModel)]="this.SelectedSuperAdmin.mobile">
                    </div>
                </ng-container>
            </div>
            <!--
                
            -->
        </div>
        <div class="modal-footer">
            <button class="btn btn-danger" (click)="this.modalService.hide();">Annulla</button>
            <button class="btn btn-primary" (click)="NewAdmin()" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">Salva</button>
        </div>
</ng-template>

<!--
<ng-container>
    <app-center-manager [group]="this.SelectedGroup"></app-center-manager>
    <app-business-manager></app-business-manager>
</ng-container>
-->