<div class="card-body" style="height: auto !important;">
    <table class="table table-hover table-striped">
        <tbody>
            <tr>
                <td>Licenze</td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Licenze.Generazione" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Generazione</label>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Licenze.Upgrade" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Upgrade</label>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Licenze.Stato" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Stato Licenza</label>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Licenze.Rinnovo" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Rinnovo Licenza</label>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Licenze.Attivazione" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Attivazione Terzi Licenza</label>
                </td>
            </tr>
            <tr>
                <td>Shop</td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Shop.Inserimento" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Inserimento</label>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Shop.Modifica" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Modifica</label>
                </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Manuali</td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Manuali.Inserimento" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Inserimento</label>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Manuali.Eliminazione" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Eliminazione</label>
                </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Listini</td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Listini.Inserimento" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Inserimento</label>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Listini.Eliminazione" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Eliminazione</label>
                </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Aggiornamenti</td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Aggiornamenti.Inserimento" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Inserimento</label>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Aggiornamenti.Eliminazione" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Eliminazione</label>
                </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Utenti</td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Utenti.Creazione" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Creazione</label>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Utenti.Modifica" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Modifica</label>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Utenti.Eliminazione" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Eliminazione</label>
                </td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Censimenti</td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Censimenti.Aggiornamento" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Aggiornamento</label>
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="this.SettingsPermessi.Censimenti.Eliminazione" (change)="CheckSame()"/>
                    <label style="margin-left: 5%;">Eliminazione</label>
                </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
    <div>
        <div class="row">
            <div class="col" style="text-align: center;">
                <button class="btn btn-primary" (click)="this.SetPermessi()" [disabled]="this.Edited === false">Salva</button>
            </div>
        </div>
    </div>

</div>
