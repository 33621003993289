import {Component, HostListener, OnInit, TemplateRef} from '@angular/core';
import {AuthService} from '../../Services/AuthService/auth.service';
import {Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UserStoreService} from '../../Services/Admin/user-store.service';
import {AlertService} from 'ngx-alerts';
import Categoria from '../../Models/Categoria';

import {faChevronDown, faChevronUp, faPlus, faCartPlus, faEye, faEyeSlash, faEdit, faTrash, faArrowUp, faArrowDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import SoftwareListino from '../../Models/SoftwareListino';
import { PaymentService } from '../../Services/Payment/payment.service';
import {ShopService} from "../../Services/ShopService/shop.service";

@Component({
  selector: 'app-store-manager',
  templateUrl: './store-manager.component.html',
  styleUrls: ['./store-manager.component.scss']
})
export class StoreManagerComponent implements OnInit {

  //DATI PER LA CREAZIONE DI NUOVI ELEMENTI
  public NuovaCategoria: string = '';
  public NuovoListino: SoftwareListino = new SoftwareListino();
  public IdCat: number;
  public IsSub: boolean = false;

  //UPDATE
  public CategoriaModifica: Categoria;
  public ListinoModifica: SoftwareListino;

  //DELETE
  public ListinoElimina: SoftwareListino;
  public CategoriaElimina: Categoria;

  //LISTA CATEGORIE
  public Categorie: Categoria[] = [];

  //FRONT-END CATEGORIE-APERTE
  public CategorieAperte = [];

  //ICONE
  public faPlus: IconDefinition = faPlus;
  public arrowDown: IconDefinition = faChevronDown;
  public arrowUp: IconDefinition = faChevronUp;
  public faCartPlus: IconDefinition = faCartPlus;
  public faEye: IconDefinition = faEye;
  public faEyeSlash: IconDefinition = faEyeSlash;
  public faEdit: IconDefinition = faEdit;
  public faTrash: IconDefinition = faTrash;
  public faArrowUp: IconDefinition = faArrowUp;
  public faArrowDown: IconDefinition = faArrowDown;


  //IMG
  public imgStr: string;

  //IVA
  public IVA: number;

  public lglt:boolean;
  constructor(private auth: AuthService, private router: Router, public modalService: BsModalService, public userStore: UserStoreService,
              private alert: AlertService, public payment: PaymentService, public shopService: ShopService ) { }

  async ngOnInit(){
    this.setLtLg();
    await this.Init();
    this.setLtLg();
  }
  @HostListener('window:resize', ['$event'])
  setLtLg(event?): void{
    if (window.innerWidth < 1279){
      this.lglt = true;
    }else{
      this.lglt = false;
    }
  }

  private async Init() {

    this.auth.Ready = false;

    //SE NON E' NESSUNO DI QUESTI, VADO INDIETRO
    if (this.auth.AuthUser.Role === 2 || this.auth.AuthUser.Role2 === 2) {
      this.router.navigateByUrl('');
      return;
    }

    this.Categorie = await this.userStore.GetListino();
    
    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }

    this.auth.Ready = true;

  }

  private async LocalValidation() {
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));

      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if(valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.IsLogin = true;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }

  //CREA CATEGORIA
  public async CreateCategoria() {

    const id = await this.auth.GetIdByUsername();

    const result = await this.userStore.CreateCategoria(this.NuovaCategoria.trim(), id);
    if (result) {
      this.alert.success("Categoria creata");
      this.Categorie = await this.userStore.GetListino();
      this.NuovaCategoria = "";
      this.modalService.hide();
      return true;
    } else {
      this.alert.danger("Categoria non creata. Riprova con un nuovo nome")
      return false;
    }
  }

  //CREA SOTTOCATEGORIA
  public async CreateSottoCategoria() {

    const id = await this.auth.GetIdByUsername();

    const result = await this.userStore.CreateSottoCategoria(this.NuovaCategoria.trim(), this.IdCat, id);

    if (result) {
      this.alert.success("Sottocategoria creata");
      this.Categorie = await this.userStore.GetListino();
      this.NuovaCategoria = "";
      this.modalService.hide();
      return true;
    } else {
      this.alert.danger("Categoria non creata. Riprova con un nuovo nome")
      return false;
    }
  }






  public FileInput(event) {
    this.NuovoListino.Img = event.target.files[0];
  }

  public FileInputEdit(event) {
    this.ListinoModifica.Img = event.target.files[0];
  }



  public async RemoveListino() {

    const id = await this.auth.GetIdByUsername();

    const result: boolean = await this.userStore.DeleteListino(this.ListinoElimina.CodiceArticolo, this.ListinoElimina.IdCat, this.ListinoElimina.IdSubCat, this.ListinoElimina.Ordine, id);

    if (result) {
      this.modalService.hide();
      this.alert.success("Elemento rimosso con successo");
      this.Categorie = await this.userStore.GetListino();
    } else {
      this.alert.danger("Impossibile rimuovere l'elemento");
    }

  }


  public async EditCategoria() {
    let result: boolean = false;

    const id = await this.auth.GetIdByUsername();

    if (this.CategoriaModifica.IDPadre === -1) {
      result = await this.userStore.EditCategoria(this.NuovaCategoria.trim(), this.CategoriaModifica.ID, id);
    } else {
      result = await this.userStore.EditSottoCategoria(this.NuovaCategoria.trim(), this.CategoriaModifica.ID, this.CategoriaModifica.IDPadre, id);
    }

    if (result) {
      this.modalService.hide();
      this.alert.success("Categoria modificata con successo");
      this.Categorie = await this.userStore.GetListino();
      this.NuovaCategoria = '';
    } else {
      this.alert.danger("Modifica non effettuata");
    }
  }


  public async RemoveCategoria() {


    let result: boolean = false;

    const id = await this.auth.GetIdByUsername();
    
    if (this.CategoriaElimina.IDPadre === -1) {
      result = await this.userStore.DeleteCategoria(this.CategoriaElimina.ID, id);
    } else {
      result = await this.userStore.DeleteSottoCategoria(this.CategoriaElimina.ID, this.CategoriaElimina.IDPadre, id);
    }

    if (result) {
      this.modalService.hide();
      this.alert.success("Categoria eliminata con successo");
      this.Categorie = await this.userStore.GetListino();
      this.NuovaCategoria = '';
    } else {
      this.alert.danger("Modifica non effettuata");
    }
  }

  public async ChangeVisibileCategoria(categoria: Categoria) {
    const id = await this.auth.GetIdByUsername();

    const result: boolean = await this.userStore.EditVisibilitaCategoria(categoria, id);

    return result;
  }

  public async OpenModalIva(ivaSettings: TemplateRef<any>) {
    this.IVA = await this.userStore.GetIva();
    this.modalService.show(ivaSettings)
  }

  public async UpdateIva() {

    const id = await this.auth.GetIdByUsername();

    const result = await this.userStore.SetIva(this.IVA, id);
    if (result) {
      this.modalService.hide();
      this.alert.success("IVA Aggiornata");
      return;
    }
    this.alert.danger("Errore aggiornamento IVA");
  }

  /*
    INDIVIDUO L'INDICE DELL'OGGETTO ALL'INTERNO DELLA LISTA PER INDIVIDUARE L'INDICE DELL'ELEMENTO SUCCESSIVO
  */

  public async OrdineDown(elem: SoftwareListino, categoria: Categoria) {

    const index: number = categoria.Elementi.indexOf(elem);
    const indexNext: number = categoria.Elementi.indexOf(elem) + 1;

    const down: boolean = await this.userStore.OrdineDown(elem.CodiceArticolo) ;
    const up: boolean = await this.userStore.OrdineUp(categoria.Elementi[indexNext].CodiceArticolo);

    //Switch all'interno dell'array FrontEnd
    [ categoria.Elementi[index], categoria.Elementi[indexNext] ] = [ categoria.Elementi[indexNext], categoria.Elementi[index] ] ;

    return down && up;

  }

  public async OrdineUp(elem: SoftwareListino, categoria: Categoria) {
    const index: number = categoria.Elementi.indexOf(elem);
    const indexBefore: number = categoria.Elementi.indexOf(elem) - 1;

    const down: boolean = await this.userStore.OrdineDown(categoria.Elementi[indexBefore].CodiceArticolo);
    const up: boolean = await this.userStore.OrdineUp(elem.CodiceArticolo);

    //Switch all'interno dell'array FrontEnd
    [ categoria.Elementi[index], categoria.Elementi[indexBefore] ] = [ categoria.Elementi[indexBefore], categoria.Elementi[index] ] ;

    return down && up;
  }

}
