import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
  } from '@angular/common/http';
  import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
   
  export class AddHeaderInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //GET TOKEN
        const token: string = localStorage.getItem('token');

        if (token === null) {
          return next.handle(req)
        }
        
        const clonedRequest = req.clone({ headers: req.headers.append('Authorization', `Bearer ${token}`) });
  
        return next.handle(clonedRequest);
    }
  }

