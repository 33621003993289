import { waitForAsync } from '@angular/core/testing';
import { IsDev } from '../../global';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Role, User} from 'src/app/Models/User';
import { UserService } from '../UserService/user.service';
import { ChangeDetectorRef } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //FLAG PER CONTROLLO SE SCHERMATA LOGIN O NO
  public IsLogin: boolean = true;
  public IsPassword: boolean = false;


  //SE HO GIA' FATTO L'ACCESSO E VERIFICATO, NON FACCIO IL CHECK DI NUOVO
  public IsValidationDone: boolean = false;
  public IsStatsLoad: boolean = false;

  //FLAG CHE INDICA SE LA PAGINA E' CARICATA
  public Ready: boolean = false;

  public AuthUser: User = new User();

  //VARIABILE USATA PER DEFINIRE IL NOME DELL'ADMIN
  public AdminUsername: string = "";
  public AdminID: number = 0;

  //FLAG SIDEBAR
  public ShowShop: boolean = false;

  //CASHSERVIZI
  public IsCashServizi: boolean = false;

  


 

  constructor(private http: HttpClient, private router: Router, private user: UserService) {}

  public async Login(username: string, password: string): Promise<boolean> {
    //LOGIN
    //console.log(IsDev)

    try {
      const result: any = await this.http.post('/api/login', {username, password }).toPromise();
      if (result.response !== "ko") {
        localStorage.setItem('token', result.token.token);
        localStorage.setItem('expiration', result.token.expirationTime)

        //USER LOCALSTORAGE DATA
        localStorage.setItem("id", result.response.id);
        localStorage.setItem("username", username);

        //////////////////////////////////
        /**/await this.GetUserInformation();/**/
        /////////////////////////////////
        this.IsValidationDone = true;
        return true;
      }
      return false;
    } catch (e) {
      return null;
    }

  }

  public async ValidateLogin() {

    const result: any = await this.http.post('/api/login', {username: localStorage.getItem('username'), password: null}, {}).toPromise();
    if (result.response === 'ok') {

      //PRELEVO LE INFORMAZIONI
      await this.GetUserInformation();

      return true;
    }
    return false;
  }


  public Logout() {
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    this.IsLogin = true;
    this.IsStatsLoad = false;
    this.IsValidationDone = false;
    this.Ready = false;
    this.AdminUsername = "";
    this.AdminID = 0;
    this.router.navigate(["login"]);
    this.AuthUser = new User();
    //this.IsCashServizi = false;
  }

  /*
  Se esiste il token, possono mantenere l'accesso al sito
  Altrimenti, devo accedere e generarne uno nuovo
  */
  public IsLoggedIn(): boolean {
    const token: string = localStorage.getItem('token');
    if (token === null) {
      return false;
    } else {
      return true;
    }
  }

  public IsLoggedOut(): boolean {
    return !this.IsLoggedIn();
  }

  private async GetUserInformation() {
    this.AuthUser = new User();


    let params: HttpParams = new HttpParams()
    .set("username", localStorage.getItem('username'))
    .set("id", localStorage.getItem("id"));
    let result: any = await this.http.get('/api/users/single', {params}).toPromise();

    //USERNAME
    this.AuthUser.Username = localStorage.getItem('username').toLowerCase();
    //ID
    this.AuthUser.ID = Number(localStorage.getItem('id'));
    //ROLE
    if (result.userRole[0] !== undefined) {
      this.AuthUser.Role = result.userRole[0];
    }
    if (result.userRole[1] !== undefined) {
      this.AuthUser.Role2 = result.userRole[1];
    }

    // SE SONO UN ADMIN MA NON SONO IL SUPERADMIN
    let AdminPermessi: string = "";
    if (this.AuthUser.Role == Role.ADMIN && result.id === 10) {
      this.AdminID = result.id;
      this.AdminUsername = result.username;
    }


    if (this.AuthUser.Role == Role.ADMIN && result.id !== 10) {
      AdminPermessi = result.permessi;
      this.AuthUser.Username = 'gab';
      this.AuthUser.ID = 10;
      this.AuthUser.Role = 0;
      this.AuthUser.Role2 = 4;
      this.AdminID = result.id;
      this.AdminUsername = result.username;
      
      params = new HttpParams()
      .set("username", "gab")
      .set("id", String(10));
      result = await this.http.get('/api/users/single', {params}).toPromise();
    }
    

    //CREATORE
    this.AuthUser.Creatore = result.creatore;
    if (result.softwareAbbinatiCreatore.length > 0) {
      this.AuthUser.SoftwareAbbinatiCreatore = result.softwareAbbinatiCreatore;
      const splitString: string[] = (result.softwareAbbinatiCreatore as string).split('|');
        for (let i = 0; i < splitString.length; ++i) {
          if (splitString[i] === "vfood") this.AuthUser.SoftwareAbbinatiCreatoreBoolean.VisualFood = true;
          if (splitString[i] === "vretail") this.AuthUser.SoftwareAbbinatiCreatoreBoolean.VisualRetail = true;
          if (splitString[i] === "vself") this.AuthUser.SoftwareAbbinatiCreatoreBoolean.VisualSelf = true;
          if (splitString[i] === "vshop") this.AuthUser.SoftwareAbbinatiCreatoreBoolean.VisualShop = true;
        }
    }


    if (this.AuthUser.Role === 1 || this.AuthUser.Role2 === 1 || this.AuthUser.Role === 4 || this.AuthUser.Role2 === 4) {
      this.AuthUser.Tipo = result.tipo;
      this.AuthUser.Sconto = this.user.DecodeSconto(result.sconto);
      this.AuthUser.NumeroLicenzePacchetto = result.numeroLicenzePacchetto;

      //Software abbinati
      if (result.softwareAbbinati.length > 0) {
        this.AuthUser.SoftwareAbbinati = result.softwareAbbinati;
        const splitString: string[] = (result.softwareAbbinati as string).split('|');
        for (let i = 0; i < splitString.length; ++i) {
          if (splitString[i] === "vfood") this.AuthUser.SoftwareAbbinatiBoolean.VisualFood = true;
          if (splitString[i] === "vretail") this.AuthUser.SoftwareAbbinatiBoolean.VisualRetail = true;
          if (splitString[i] === "vself") this.AuthUser.SoftwareAbbinatiBoolean.VisualSelf = true;
          if (splitString[i] === "vshop") this.AuthUser.SoftwareAbbinatiBoolean.VisualShop = true;
        }
      }

      if (this.AuthUser.Tipo.includes('S') || this.AuthUser.Tipo.includes('P')) {
        this.ShowShop = true;
      }

    }

    //PERMESSI
    if (this.AuthUser.Role === 0) {
      if (AdminPermessi.length > 0) {
        this.AuthUser.Permessi = AdminPermessi;
      } else {
        this.AuthUser.Permessi = result.permessi;
      }
      this.AuthUser.DecryptPermessi();
    }

    this.AuthUser.PIVA = result.piva;
    this.AuthUser.Stato = result.stato;

    this.AuthUser.SoftCodes = result.softCodes;

    //Visual Intelligence

    //this.AuthUser.VisualIntelligence = (information[3] === '1') ? true: false;
    //Visual Reservation
    //this.AuthUser.VisualReservation = (information[4]s === '1') ? true: false;
    //Visual Order
    //this.AuthUser.VisualOrder = (information[5] === '1') ? true: false;

    if (this.AuthUser.Creatore === 'cashmatic' || this.AuthUser.Username === 'cashmatic') {
      this.IsCashServizi = true;
    }

  }


  public async CompleteUserInfo() {

    //SE SONO ADMIN, PASSO DEI DATI DIVERSI

    const params: HttpParams = new HttpParams()
    .set("username", ((this.AuthUser.Role === 0 || this.AuthUser.Role2 === 0) && this.AdminID !== 10) ? this.AdminUsername : this.AuthUser.Username)
    .set("id", ((this.AuthUser.Role === 0 || this.AuthUser.Role2 === 0) && this.AdminID !== 10) ? String(this.AdminID) : String(this.AuthUser.ID));
    
    const result: any = await this.http.get('/api/users/completeprofile', {params}).toPromise();

    const stringSplit: string[] = result.response.split("<>");

    this.AuthUser.RagioneSociale = stringSplit[0];
    this.AuthUser.Email = stringSplit[1];
    this.AuthUser.PEC = stringSplit[2];
    this.AuthUser.Citta = stringSplit[3];
    this.AuthUser.Via = stringSplit[4];
    this.AuthUser.SDI = stringSplit[5];
    this.AuthUser.IBAN = stringSplit[6];

  }

  public async GetIdByUsername() {

    const params: HttpParams = new HttpParams()
    .set("username", this.AdminUsername);
    
    if (this.AdminUsername === "") {
      return this.AuthUser.ID;
    } else {
      const result: any = await this.http.get('/api/users/id', {params}).toPromise();

      return result.response as number;
    }
  }


  public ControlloPartitaIva(sz_Codice){
    if (sz_Codice.length != 11) return false;
  }


  ControlloIban(iban: string) {
    let reg = /^IT\d{2}[ ][a-zA-Z]\d{3}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{3}|IT\d{2}[a-zA-Z]\d{22}$/;
    if (!reg.test(iban)) {
      return false
    } else {
     return true;
  }
  }
}
