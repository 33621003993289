import { ZipCode } from './ZipCode';
import { Center } from './Center';
export class SellPoint {
    id: number;
    name: string;
    uuid: string;
    address: string;
    street: string;
    zipCodeId: string;
    zipCode: ZipCode;
    phone: string;
    phonePrefix: string;
    mobile: string;
    mobilePrefix: string;
    email: string;
    contact: string;
    planningType: PlanningType;
    startHour: string;
    finishHour: string;
    center: Center;
    centerName: string;
    note: string;
  }

  export enum PlanningType
  {
        OPERATOR, CABIN, OPERATOR_AND_CABIN
  }