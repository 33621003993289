export class Admin {
    public id: number;
    public uuid: string;
    public username: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public sequence: number;
    public mobile: string;
    public password: string;
    public groupId: number;
}


/*

private Long id;
    private String uuid;
    private String username;
    private String firstName;
    private String lastName;
    private String email;
    private long sequence;
    private String mobile;
    private String mobilePrefix;
    private String password;
    private Long groupId;
    */