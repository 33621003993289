import { AuthService } from './../../Services/AuthService/auth.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShopService} from '../../Services/ShopService/shop.service';
import {UserStoreService} from '../../Services/Admin/user-store.service';
import {AlertService} from 'ngx-alerts';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Location} from '@angular/common';
import {Contenitore} from '../tipologialicenza/tipologialicenza.component';
import {IsDev} from '../../global';
import {HttpClient} from '@angular/common/http';
import {BsModalService} from 'ngx-bootstrap/modal';

import {Role} from '../../Models/User';
import { HostListener } from '@angular/core';
import { faTrash, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import Categoria from 'src/app/Models/Categoria';


@Component({
  selector: 'app-shopheader',
  templateUrl: './shopheader.component.html',
  styleUrls: ['./shopheader.component.scss']
})
export class ShopheaderComponent implements OnInit, OnDestroy {
  Url: string;
  location:any;
  categorie: Categoria[];
  errorProductsNotFound:string;
  categoriaMobile:boolean = false;
  //ICONS
  public faTrash: IconDefinition = faTrash;

  constructor( public ShopService: ShopService,public modalService: BsModalService,
  public userStoreService:UserStoreService,private alertService: AlertService, private router: Router, private _location: Location, private http: HttpClient, public auth: AuthService) {
    this.location = _location;
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.Url = event.url;
        this.setUXbooleans(this.Url);
      });
    router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
         this.categoriaMobile = false;
        }
      });
  }
  @HostListener('window:popstate', ['$event'])

  async ngOnInit() {
    this.categorie = await this.userStoreService.GetListino();
  }
  ngOnDestroy(){
    this.categoriaMobile = false;
  }

  public Indietro(){
    this.ShopService.Stripe = false;
    this.ShopService.Paypal = false;
    this.ShopService.Bonifico = false;
    this.ShopService.errorProductsNotFound = '';
    this.categoriaMobile = false;
    this.location.back();
  }
  public setUXbooleans(url){
    /*
    * GESTISCO TUTTE LE CASISTICHE POSSIBILI PER LA VISUALIZZAZIONE
    * 1) /home/shop
    * 2) /home/shop/acquisto-licenza-singola
    * 3) /home/shop/acquisto-licenza-multipla
    * 4) /home/shop/selezione-quantita-pacchetti
    * 5) /home/shop/cart
    *
    *  */
    // 1
    if(url === "/shop"){
      this.ShopService.ShowTipologiaLicenza = true;
      this.ShopService.ShowAcquistoSingolo = false;
      this.ShopService.ShowAcquistoPacchetti = false;
      this.ShopService.ShowSelezioneQuantitaPacchetti = false;
      this.ShopService.ShowCarrello = false;
      this.ShopService.ShowSceltaCategorie = false;
      return;
    }
    if (url === "/shop/tipologia-licenza") {
      this.ShopService.ShowTipologiaLicenza = false;
      this.ShopService.ShowAcquistoPacchetti = false;
      this.ShopService.ShowCarrello = true;
      this.ShopService.ShowAcquistoSingolo = false;
      this.ShopService.ShowSelezioneQuantitaPacchetti = false;
      this.ShopService.ShowSceltaCategorie = false;
      return;
    }




    // 4
    if (url === "/shop/selezione-quantita-pacchetti") {
      this.ShopService.Reset();
      this.ShopService.ShowTipologiaLicenza = false;
      this.ShopService.ShowAcquistoPacchetti = false;
      this.ShopService.ShowCarrello = false;
      this.ShopService.ShowAcquistoSingolo = false;
      this.ShopService.ShowSelezioneQuantitaPacchetti = true;
      this.ShopService.ShowSceltaCategorie = false;

      return;
    }
    // 5
    if (url === "/shop/cart") {
      this.ShopService.ShowTipologiaLicenza = false;
      this.ShopService.ShowAcquistoPacchetti = false;
      this.ShopService.ShowCarrello = true;
      this.ShopService.ShowAcquistoSingolo = false;
      this.ShopService.ShowSelezioneQuantitaPacchetti = false;
      this.ShopService.ShowSceltaCategorie = false;

      return;
    }
    // 6
    if (url.includes("/shop/acquisto-licenza-singola")) {
      if (url === "/shop/acquisto-licenza-singola") {
        this.ShopService.ShowTipologiaLicenza = false;
        this.ShopService.ShowAcquistoPacchetti = false;
        this.ShopService.ShowCarrello = false;
        this.ShopService.ShowAcquistoSingolo = true;
        this.ShopService.ShowSelezioneQuantitaPacchetti = false;
        this.ShopService.ShowSceltaCategorie = true;
        this.ShopService.DettaglioProdotto = false;
        return;
      } else{
        this.ShopService.ShowTipologiaLicenza = false;
        this.ShopService.ShowAcquistoPacchetti = false;
        this.ShopService.ShowCarrello = true;
        this.ShopService.ShowAcquistoSingolo = true;
        this.ShopService.ShowSelezioneQuantitaPacchetti = false;
        this.ShopService.ShowSceltaCategorie = false;
        this.ShopService.DettaglioProdotto = true;

        return;

      }

    }
    // 7
    if (url.includes("/shop/acquisto-licenza-multipla")) {
      // 3
      if (url === "/shop/acquisto-licenza-multipla") {
        this.ShopService.ShowTipologiaLicenza = false;
        this.ShopService.ShowAcquistoPacchetti = true;
        this.ShopService.ShowCarrello = false;
        this.ShopService.ShowAcquistoSingolo = false;
        this.ShopService.ShowSelezioneQuantitaPacchetti = false;
        this.ShopService.ShowSceltaCategorie = true;
        this.ShopService.DettaglioProdotto = false;


        return;
      }else{
        this.ShopService.ShowTipologiaLicenza = false;
        this.ShopService.ShowAcquistoPacchetti = true;
        this.ShopService.ShowCarrello = false;
        this.ShopService.ShowAcquistoSingolo = false;
        this.ShopService.ShowSelezioneQuantitaPacchetti = false;
        this.ShopService.ShowSceltaCategorie = false;
        this.ShopService.DettaglioProdotto = true;
        return;
      }
    }
  }
  async goToCategoria(id){
    let categoriaTrovata;
    let listino = await this.userStoreService.GetListino();
    for(let cat = 0; cat < listino.length; cat++){
      if(listino[cat].ID == id){
        categoriaTrovata = listino[cat].Nome;
        this.ShopService.prodottiMostrati = listino[cat].Elementi;
        break;
      }
    }
    if(this.ShopService.prodottiMostrati.length === 0){
      this.ShopService.errorProductsNotFound = 'PRODOTTI NON TROVATI';
    } else{
      this.errorProductsNotFound = '';
    }
  }
  openCategorie() {
    this.categoriaMobile = !this.categoriaMobile;
  }
  async goToSottocategoria(id){

    let sottoCategoriaTrovata;
    let listino = await this.userStoreService.GetListino();
    for(let cat = 0; cat < listino.length; cat++){
      for (let sottocat = 0; sottocat < listino[cat].SottoCategorie.length; sottocat++){
        if(listino[cat].SottoCategorie[sottocat].ID == id) {
          sottoCategoriaTrovata = listino[cat].SottoCategorie[sottocat].Nome;
          this.ShopService.prodottiMostrati = listino[cat].SottoCategorie[sottocat].Elementi;
          break;
        }
      }
    }
    if(this.ShopService.prodottiMostrati.length == 0){
      this.ShopService.errorProductsNotFound = 'PRODOTTI NON TROVATI'
    } else{
      this.ShopService.errorProductsNotFound = '';
    }
  }


  openSottoCategorie(ID: number) {
    if(document.getElementById(String(ID)).style.display === 'none'){
      document.getElementById(String(ID)).style.display = 'block'
    } else{
      document.getElementById(String(ID)).style.display = 'none'
    }
  }
}
