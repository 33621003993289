import { catchError } from 'rxjs/operators';
import { SellPoint } from '../../Models/Cloud/SellPoint';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthResponse } from './../../Models/Security/AuthResponse';
import { Group } from '../../Models/Cloud/Group';
import { Center } from '../../Models/Cloud/Center';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { CloudLicenseDate } from 'src/app/Models/Cloud/CloudLicense';
import { Admin } from 'src/app/Models/Cloud/SuperAdmin';
import { lastValueFrom, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  public AuthResponse: AuthResponse;

  constructor(private http: HttpClient) { 

  }

  //FUNZIONE CHE CONTATTA LE API DI VANITY/NEW SOFTWARE AL FINE DI RICEVERE IL TOKEN
  public async InitToken() {

    
    if (this.AuthResponse !== undefined) {
      return;
    }

    this.AuthResponse = await lastValueFrom(this.http.post<AuthResponse>('/api/cloud/auth', {}, {}));

    return;
  }

  public getAllGroups() {
    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", this.AuthResponse.token);

    return this.http.get<Group[]>('/api/cloud/groups', {headers}).pipe(map(groups => {
      groups.forEach(group => {
        const startDate = new Date(group.startDate);
        const endDate = new Date(group.expirationDate);

        group.startDate = startDate;
        group.expirationDate = endDate;
      });

      return groups;
    }));
  }

  public getGroup(id: number) {
    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", this.AuthResponse.token);

    return this.http.get<Group>('/api/cloud/groups/' + id, {headers})
  }

  public EditGroupDate(cloudLicenseDate: CloudLicenseDate) {
    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", this.AuthResponse.token);

    return this.http.put<boolean>('/api/cloud/license/date', cloudLicenseDate, {headers});
  }

  public ToggleGroupState(id: number) {
    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", this.AuthResponse.token);

    return this.http.put<boolean>('/api/cloud/groups/toggle', {id}, {headers});
  }

  public ToggleGroupDisabled(id: number) {
    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", this.AuthResponse.token);

    const params: HttpParams = new HttpParams()
    .set("id", String(id));

    return this.http.delete<boolean>('/api/cloud/groups/toggle', {headers, params});
  }

  public EditGroupLicense(group: Group) {
    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", this.AuthResponse.token);

    return this.http.put<boolean>('/api/cloud/groups', group, {headers});   
  }

  public NewGroup(group: Group) {
    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", this.AuthResponse.token);

    return this.http.post<boolean>('/api/cloud/groups', group, {headers}).pipe(catchError((error => {
      return throwError(() => new Error(error.message));
    })));
  }

  public NewOrEditAdmin(admin: Admin) {
    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", this.AuthResponse.token);

    return this.http.post<boolean>('/api/cloud/groups/admin', admin, {headers});   
  }

  public DeleteAdmin(admin: Admin) {
    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", this.AuthResponse.token);

    const params: HttpParams = new HttpParams()
    .set("id", String(admin.id));

    return this.http.delete<boolean>('/api/cloud/groups/admin', {headers, params});  
  }
}
