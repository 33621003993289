import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { AuthService } from './Services/AuthService/auth.service';
import {filter} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Location} from '@angular/common';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'GABServizi';
  Url: string;
  location:any;
  headerName:string;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  //Tido: HTMLScriptElement = document.querySelector('#srcTIDO');
  
  constructor(public auth: AuthService, public modalService: BsModalService, public router: Router, public _location: Location, private ccService: NgcCookieConsentService, private titleService: Title) {
    this.location = _location;
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.Url = event.url;
        this.setNameHeader(this.Url);
      });
    /*
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const browserRefresh = !router.navigated;
        if (browserRefresh) {
          //this.router.navigateByUrl('/');
        }
      }
    });
    */
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {
    // Cookie Section
    this.ccService.popupClose$.subscribe(() => {
      //this.ccService.getConfig().onStatusChange
    });
    let str: string = window.location.origin;
    if (str.toUpperCase().indexOf("CASHMATIC") > -1) {
      this.auth.IsCashServizi = true;
    }
    if(this.auth.IsCashServizi == true){
      this.titleService.setTitle("CashmaticServizi");
      this.favIcon.href = 'https://cashmatic.it/favicon.ico';
      //this.Tido.src = '';
    } else {
      this.titleService.setTitle("GABServizi");
      //this.Tido.src = '//code.tidio.co/m2rg0ye3guregsz7ntxjcyrtqa8jwwyf.js';
      let chatScript = document.createElement('script');
      chatScript.type = 'text/javascript';
      chatScript.async = true;
      chatScript.src = '//code.tidio.co/m2rg0ye3guregsz7ntxjcyrtqa8jwwyf.js';
      document.body.appendChild(chatScript);
    }
  }

  ngOnDestroy() {
   
  }

  public setNameHeader(url: string) {
    if(url.includes("acquisto-licenza-singola") || url.includes("acquisto-licenza-multipla") || url.includes("shop")){
      this.headerName = "Shop";
      return
    }

    if (url === "/licenses") {
      this.headerName = "Licenze";
      return
    }
    if (url === "/shop") {
      this.headerName = "Shop";
      return
    }

    // 2
    if (url === "/manuals") {
      this.headerName = "Manuali";
      return
    }
    // 3
    if (url === "/lists") {
      this.headerName = "Listini";
      return
    }

    if (url === "/updates") {
      this.headerName = "Aggiornamenti";
      return
    }
    if (url === "/users") {
      this.headerName = "Utenti";
      return
    }
    if (url === "/census") {
      this.headerName = "Censimenti";
      return
    }
    this.headerName = "Home";
  }
}
