import { VERSION } from './../global';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from 'ngx-alerts';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { faBook, faSignOutAlt, faKey, faEye, faSync, faDesktop, faUsersCog,  faHome, faCloud, faClipboardList, faUsers, faShoppingCart, faCashRegister, faUser, IconDefinition, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../Services/AuthService/auth.service';
import { ShopService } from '../Services/ShopService/shop.service';

import { HostListener } from "@angular/core";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() headerName: string;
  public faHome: IconDefinition = faHome;
  public faBook: IconDefinition = faBook;
  public faSignOut: IconDefinition = faSignOutAlt;
  public faEye: IconDefinition = faEye;
  public faDesktop: IconDefinition = faDesktop;
  public faKey: IconDefinition = faKey;
  public faSync: IconDefinition = faSync;
  public faShoppingCart: IconDefinition = faShoppingCart;
  public faClipboardList: IconDefinition = faClipboardList;
  public faUsers: IconDefinition = faUsers;
  public faCashRegister: IconDefinition = faCashRegister;
  public faUser: IconDefinition = faUser;
  public faInfo: IconDefinition = faInfoCircle;
  public faUserCog: IconDefinition = faUsersCog;
  public faCloud: IconDefinition = faCloud;
  public version: string = VERSION;
  public isCloudEnabled: boolean = false;
  public mobileSidebar: boolean;

  screenWidth: number;

  constructor(public auth: AuthService, public shopService: ShopService, public router: Router, public alert: AlertService, public modalService: BsModalService) {
    this.getScreenSize();
  }

  ngOnInit(): void {
  }

  public Logout() {
    this.auth.Logout();
    this.shopService.Logout();
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1279 && this.mobileSidebar == false) {
      document.getElementById("hamburger1").click();
    }
  }

  public OpenChangelog(template: TemplateRef<any>) {
    this.modalService.show(template, {class: "modal-lg"});
  }

  public ShowMobileSidebar() {
    if (this.screenWidth < 1279) {
      document.getElementById("hamburger1").click();
      this.mobileSidebar = !this.mobileSidebar;

      if (this.mobileSidebar == true) {

        let trasformString: string = "translate3d(0, 0, 0)";
        let transitionString: string = "all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1)";
        document.getElementById("sidebartransition").style.transform = trasformString;
        document.getElementById("sidebartransition").style.transition = transitionString;

      } else {
        let trasformString: string = "translate3d(-2000px, 0, 0)";
        let transitionString: string = "all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1)"
        document.getElementById("sidebartransition").style.transform = trasformString;
        document.getElementById("sidebartransition").style.transition = transitionString;
      }
    } else if (this.screenWidth > 1279 && this.mobileSidebar == false) {
      let trasformString: string = "translate3d(0, 0, 0)";
      let transitionString: string = "all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1)";
      document.getElementById("sidebartransition").style.transform = trasformString;
      document.getElementById("sidebartransition").style.transition = transitionString;
    }


  }
  public HideMobileSidebar() {
    if (this.screenWidth < 1279) {
      document.getElementById("hamburger1").click();
    }
    /*let trasformString: string = "translate3d(-262px, 0, 0)";
    let transitionString: string = "all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1)"
    document.getElementById("sidebartransition").style.transform = trasformString;
    document.getElementById("sidebartransition").style.transition = transitionString;
    this.mobileSidebar = false;
    console.log(this.mobileSidebar);*/

  }

  public RedirectTo(url: string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([url]));
  }
}
