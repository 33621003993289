import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StatsService } from './../Services/StatsService/stats.service';
import { IsDev } from './../global';

import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {faMoneyBill, faSignal, faBusinessTime, faFlask, IconDefinition} from '@fortawesome/free-solid-svg-icons';

//CHART JS
import * as Chart from 'chart.js';
import { Router } from '@angular/router';
import { AuthService } from '../Services/AuthService/auth.service';
import { Role } from '../Models/User';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  constructor(public auth: AuthService, private router: Router, private statsService: StatsService, private modalService: BsModalService) {
    
  }


  private async Init() {

    this.auth.Ready = false;
    //AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName("li");
    for (let i = 0; i < sidebarElements.length; ++i) {
      if (sidebarElements[i].id !== 'home') {
        sidebarElements[i].classList.remove('active');
      } else {
        sidebarElements[i].classList.add('active');
      }
    }

    if (this.auth.IsValidationDone === false) {
      await this.LocalValidation();
    }

    await this.InitValues();

    this.auth.Ready = true;

    if (document.getElementsByClassName('card-body')[0] === undefined) {
      window.location.reload();
    }

  }

  private async LocalValidation() {
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));

      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if(valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.Ready = false;
        this.auth.IsLogin = true;
        this.auth.Logout();
      }
    } else {
      await this.router.navigate(['login']);
    }
  }

  public VPCount: string;
  public VPUsed: string;
  public B2BCount: string;
  public ExpiringLicenses: string;
  public ExpiringLicensesN: number;

  public Ready: boolean = false;

  //FAICON
  public faMoney: IconDefinition = faMoneyBill;
  public faSignal: IconDefinition = faSignal;
  public faBusiness: IconDefinition = faBusinessTime;
  public faFlask: IconDefinition = faFlask;

  async ngOnInit() {


    if (this.auth.AuthUser.Role === 2) {
      //REINDERIZZO A SHOP
      document.getElementById('home').style.display = "none";
      this.router.navigate(["shop"]);
      return;
    }
    
    await this.Init();
  }

  private async InitValues() {
    this.VPCount = await this.GetVisualPosCount();  //VISUAL POS FOR REVENDOR
    this.VPUsed = await this.GetVisualPosUsed();  //VISUAL POS USED LAST 30 DAYS
    this.B2BCount = await this.GetB2BCount(); //B2B COUNT
    this.ExpiringLicenses = await this.GetExpiringCount();  //EXPIRING LICENSES
    this.ExpiringLicensesN = Number(this.ExpiringLicenses);


    await this.GetVisualPosUsage();
    await this.GetModuleUsage();

    this.Ready = true;
  }

  //GET VISUALPOS STATS
  private async GetVisualPosCount(): Promise<string> {
    const resultValue: string = await this.statsService.GetVpCount();

    return resultValue;
  }

  private async GetVisualPosUsed(): Promise<string> {
    const resultValue: string = await this.statsService.GetVpUsed();

    return resultValue;
  }

  private async GetB2BCount(): Promise<string> {
    const resultValue: string = await this.statsService.GetB2BCount(); 

    return resultValue;
  }

  private async GetExpiringCount(): Promise<string> {
    const resultValue: string = await this.statsService.GetExpiringLicenses(); 

    return resultValue;
  }

  public statChanged(event: string) {
    this.ExpiringLicenses = event;
  }

  private async GetVisualPosUsage() {
    const result: any = await this.statsService.GetVisualPosUsage();

    //GEN BAR CHART
    new Chart('barchart', {
      type: 'bar',
      data: {
          labels: result.map(f => f.name),
          datasets: [{
              label: "Versioni installate",
              data: result.map(f => f.total),
              backgroundColor: result.map(f => this.getRandomColor())
          }],
      },
      options: {
          responsive: true,
          legend: {
            display: false
          }
      }
    });
  }

  private async GetModuleUsage() {
    const result: any = await this.statsService.GetModuleUsage();

    const chart: Chart = new Chart('pie', {
        type: 'pie',
        data: {
            labels: result.map(f => f.name),
            datasets: [{
              label: "Moduli Attivi",
              data: result.map(f => f.total),
              backgroundColor: result.map(f => this.getRandomColor())
            }]
        },
        options: {
          responsive: true,
          legend: {
            display: false
          },
          legendCallback: (chart: Chart) => {
            let legend: string = "";  //Colori
            legend += '<ul id="pie">';
            for (let i = 0; i < chart.data.datasets[0].data.length; i++) {
              legend += '<li><span style="backgroud-color: #000000"></span>';
              if (chart.data.labels[i]) {
                  legend += chart.data.labels[i];
              }
              legend += '</li>';
            }
            legend += '</ul>';
            return legend;
          }
        }
    });

    /*
    const legend: HTMLElement = document.getElementById("legend");

    legend.innerHTML = chart.generateLegend();
    legend.style.height = document.getElementById('table').style.height;

    const legendItems = legend.getElementsByTagName('li');

    for (var i = 0; i < legendItems.length; i += 1) {
      legendItems[i].addEventListener("click", (event) => {
        const target: EventTarget = event.target;
        const parent: HTMLElement =  ( <HTMLElement>target ).parentElement;

        const index = Array.prototype.slice.call(parent.children).indexOf(target);
        const meta = chart.getDatasetMeta(0);

        const item = meta.data[index];

        if (item.hidden === null || item.hidden === false) {
            item.hidden = true;
            ( <HTMLElement>target ).classList.add('hidden');
        } else {
          ( <HTMLElement>target ).classList.remove('hidden');
            item.hidden = null;
        }
        chart.update();
      }, false);
    }
    */


  }

  public OpenUpdateModal(template: TemplateRef<any>) {
    if (this.auth.AuthUser.Role !== Role.ADMIN && this.auth.AuthUser.Role !== Role.USER) {
      this.OpenExpiringLicenses(template);
    }

    if (this.auth.AuthUser.Role === Role.ADMIN) {
      if (this.auth.AuthUser.SettingsPermessi.Licenze.Rinnovo) {
        this.OpenExpiringLicenses(template);
      }
    }


    //if (this.auth.AuthUser.Role === Role.ADMIN )
  }    

  private getRandomColor(): string {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color.toLowerCase();
  }

  public OpenExpiringLicenses(template: TemplateRef<any>) {
    this.modalService.show(template, {class: 'modal-xl'});
  }
}

