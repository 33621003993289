import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CloudLicense, CloudLicenseDate } from 'src/app/Models/Cloud/CloudLicense';
import { GroupService } from './group.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseCloudService {

  constructor(private http: HttpClient, private groupService: GroupService) {}

  public GetLicenses() {
    const token = this.groupService.AuthResponse.token;
    
    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    return this.http.get<CloudLicense[]>('/api/cloud/license', {headers});
  }
  public CreateLicense(licenseCloud: CloudLicense) {
    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    return this.http.post<boolean>('/api/cloud/license', licenseCloud, {headers});
  }

  public EditLicense(licenseCloud: CloudLicense) {
    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    return this.http.put<boolean>('/api/cloud/license', licenseCloud, {headers});
  }

  public DeleteLicense(licenseCloudId: number) {
    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    const params: HttpParams = new HttpParams()
    .set("id", String(licenseCloudId));

    return this.http.delete<boolean>('/api/cloud/license', {headers, params});
  }

  

}
