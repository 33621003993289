import { element } from 'protractor';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {IsDev} from '../../global';
import Categoria from '../../Models/Categoria';
import SoftwareListino from '../../Models/SoftwareListino';
import {AlertService} from 'ngx-alerts';

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {


  listino:any;
  constructor(private http: HttpClient, private alert: AlertService) { }

  public async CreateCategoria(nome: string, idCreatore: number): Promise<boolean> {

    const result: any = await this.http.post('/api/shopconfig/categoria', {nome, idCreatore}, {}).toPromise();

    return result.response === "ok";
  }

  public async CreateSottoCategoria(nome: string, idCat: number, idCreatore: number): Promise<boolean> {
    const result: any = await this.http.post('/api/shopconfig/sottocategoria', {nome, id: idCat, idCreatore}, {}).toPromise();

    return result.response === "ok";
  }

  public async UploadListino(softwareListino: SoftwareListino, idCreatore: number): Promise<boolean> {

    const formData: FormData = new FormData();
    formData.append("idCreatore", String(idCreatore));
    formData.append("codice", softwareListino.CodiceArticolo);
    formData.append("descrizione", softwareListino.Descrizione);
    formData.append("costo", String(softwareListino.Costo));
    formData.append("testo", softwareListino.Testo);

    if (softwareListino.Img !== null) {
      formData.append("file", softwareListino.Img, softwareListino.Img.name);
    }

    formData.append("idCat", String(softwareListino.IdCat));
    formData.append("idSub", String(softwareListino.IdSubCat));

    const result: any = await this.http.post('/api/shopconfig/listino', formData, {}).toPromise();

    return result.response === "ok"
  }


  public async EditCategoria(nome: string, id: number, idCreatore: number): Promise<boolean> {
    const result: any = await this.http.put('/api/shopconfig/categoria', {nome, id, idCreatore}, {}).toPromise();
    return result.response === "ok";
  }

  public async EditSottoCategoria(nome: string, id: number, id_cat: number, idCreatore: number): Promise<boolean> {
    const result: any = await this.http.put('/api/shopconfig/sottocategoria', {nome, id, id_cat, idCreatore}, {}).toPromise();
    return result.response === "ok";
  }

  public async EditListino(softwareListino: SoftwareListino, idCreatore: number): Promise<boolean> {
    const formData: FormData = new FormData();

    formData.append("codice", softwareListino.CodiceArticolo);
    formData.append("descrizione", softwareListino.Descrizione);
    formData.append("costo", String(softwareListino.Costo));
    formData.append("testo", softwareListino.Testo);
    formData.append("idCreatore", String(idCreatore));
    

    if (softwareListino.Img !== null) {
      formData.append("file", softwareListino.Img, softwareListino.Img.name);
    }

    formData.append("idCat", String(softwareListino.IdCat));
    formData.append("idSub", String(softwareListino.IdSubCat));

    const result: any = await this.http.put('/api/shopconfig/listino', formData, {}).toPromise();

    return result.response === "ok";
  }


  public async DeleteCategoria(id: number, idCreatore: number): Promise<boolean> {

    const params: HttpParams = new HttpParams()
    .set("id", String(id))
    .set("idCreatore", String(idCreatore));

    const result: any = await this.http.delete('/api/shopconfig/categoria', {params}).toPromise();
    return result.response === "ok";
  }

  public async DeleteSottoCategoria(id: number, idCat: number, idCreatore: number): Promise<boolean> {

    const params: HttpParams = new HttpParams()
    .set("id", String(id))
    .set("idCat", String(idCat))
    .set("idCreatore", String(idCreatore));

    const result: any = await this.http.delete('/api/shopconfig/sottocategoria', {params}).toPromise();
    return result.response === "ok";
  }

  public async EditVisibilitaCategoria(categoria: Categoria, idCreatore: number): Promise<boolean> {

    const object = {
      categoria, idCreatore
    }
    
    const content: string = JSON.stringify(object);

    const result: any = await this.http.post('/api/shopconfig/categoria/visibilita', content, {headers: {
      'Content-Type': 'application/json'
    }}).toPromise();

    return result.response === "ok";
  }


  public async DeleteListino(codiceArticolo: string, idCat: number, idSub: number, ordine: number, idCreatore: number): Promise<boolean> {

    const params: HttpParams = new HttpParams()
    .set("codiceArticolo", codiceArticolo)
    .set("idCat", String(idCat))
    .set("idSub", String(idSub))
    .set("ordine", String(ordine))
    .set("idCreatore", String(idCreatore));

    const result: any = await this.http.delete('/api/shopconfig/listino', {params}).toPromise();
    return result.response === "ok";
  }




  public async GetCategorie(): Promise<Categoria[]> {
    const result: any = await this.http.get('/api/shopconfig/categorie', {}).toPromise();
 
    const Categorie: Categoria[] = [];

    for (let i = 0; i < result.length; ++i) {

      const categoria: Categoria = new Categoria(result[i].id, result[i].nome);
      categoria.Visibile = result[i].visibile;


      //ELEMENTI DELLA CATEGORIA
      if (result[i].elementi.length > 0) {
        const elementi: SoftwareListino[] = [];

        for (let j = 0; j < result[i].elementi.length; ++j) {
          const softwareListino: SoftwareListino = new SoftwareListino();
          softwareListino.CodiceArticolo = result[i].elementi[j].codiceArticolo;
          softwareListino.Descrizione = result[i].elementi[j].descrizione;
          softwareListino.Costo = result[i].elementi[j].costo;
          softwareListino.ImgName = result[i].elementi[j].img;
          softwareListino.IdCat = result[i].elementi[j].idCat;
          softwareListino.IdSubCat = result[i].elementi[j].idSubCat;
          softwareListino.Testo = result[i].elementi[j].testo;
          softwareListino.Ordine = result[i].elementi[j].ordine;
          elementi.push(softwareListino);
        }

        categoria.Elementi = elementi;
      }

      //SOTTOCATEGORIE
      if (result[i].sottoCategoria.length > 0) {
        const subcategorie: Categoria[] = [];
        for (let j = 0; j < result[i].sottoCategoria.length; ++j) {
          const subcategoria: Categoria = new Categoria(result[i].sottoCategoria[j].id, result[i].sottoCategoria[j].nome);
          subcategoria.Visibile = result[i].sottoCategoria[j].visibile;
          subcategoria.IDPadre = categoria.ID;
          const elementi: SoftwareListino[] = [];

          //ELEMENTI DELLA SOTTOCATEGORIA
          for (let k = 0; k < result[i].sottoCategoria[j].elementi.length; ++k) {
            const softwareListino: SoftwareListino = new SoftwareListino();
            softwareListino.CodiceArticolo = result[i].sottoCategoria[j].elementi[k].codiceArticolo;
            softwareListino.Descrizione = result[i].sottoCategoria[j].elementi[k].descrizione;
            softwareListino.Costo = result[i].sottoCategoria[j].elementi[k].costo;
            softwareListino.ImgName = result[i].sottoCategoria[j].elementi[k].img;
            softwareListino.IdCat = result[i].sottoCategoria[j].elementi[k].idCat;
            softwareListino.IdSubCat = result[i].sottoCategoria[j].elementi[k].idSubCat;
            softwareListino.Testo = result[i].sottoCategoria[j].elementi[k].testo;
            softwareListino.Ordine = result[i].sottoCategoria[j].elementi[k].ordine;
            elementi.push(softwareListino);
          }

          subcategoria.Elementi = elementi

          subcategorie.push(subcategoria);
        }
        categoria.SottoCategorie = subcategorie;
      }
      Categorie.push(categoria);
    }
    
    return Categorie;
  }

  public async GetListino(): Promise<Categoria[]> {

    
    const Categorie: Categoria[] = await this.GetCategorie();

    this.listino = Categorie;

    return Categorie;
  }


  public async GetIva(): Promise<number> {
    return await this.http.get('/api/shopconfig/iva', {}).toPromise() as number;
  }

  public async SetIva(iva: number, idCreatore: number): Promise<boolean> {

    const object = {
      iva, idCreatore
    }

    const body: string = JSON.stringify(object);
    const result: any = await this.http.post('/api/shopconfig/iva', body, {headers: {
        'Content-Type': 'application/json'
      }}).toPromise();

    return result.response === "ok";
  }

  public async OrdineUp(codiceArticolo: string): Promise<boolean> {
    const result: any = await this.http.post('/api/shopconfig/listino/up', {codiceArticolo}, {}).toPromise();

    return result.response === "ok";
  }

  public async OrdineDown(codiceArticolo: string): Promise<boolean> {
    const result: any = await this.http.post('/api/shopconfig/listino/down', {codiceArticolo}, {}).toPromise();

    return result.response === "ok";
  }

}
