import Categoria  from 'src/app/Models/Categoria';
import { Component, OnInit } from '@angular/core';
import {ShopService} from "../../Services/ShopService/shop.service"
import SoftwareListino from '../../Models/SoftwareListino';
import {ActivatedRoute, Router} from '@angular/router';
import {IsDev} from '../../global';
import {Contenitore} from '../tipologialicenza/tipologialicenza.component';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../Services/AuthService/auth.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AlertService} from 'ngx-alerts';
import {UserStoreService} from '../../Services/Admin/user-store.service';
import { Role } from 'src/app/Models/User';

@Component({
  selector: 'app-buysinglelicense',
  templateUrl: './buysinglelicense.component.html',
  styleUrls: ['./buysinglelicense.component.scss']
})
export class BuysinglelicenseComponent implements OnInit {

  listino: SoftwareListino[] = [];
  errorProductsNotFound: string;
  constructor(private http: HttpClient, public auth: AuthService, private route:ActivatedRoute, private router: Router,
              public ShopService: ShopService, public modalService: BsModalService, private alertService: AlertService, public userStoreService:UserStoreService) { }

  async ngOnInit(): Promise<void> {
    let categorie: Categoria[] = await this.userStoreService.GetListino();

    for (let i = 0; i < categorie.length; ++i) {
      //CASO UTENTE
      if (categorie[i].Visibile !== 0 && this.auth.AuthUser.Role === Role.USER) {
        for (let j = 0; j < categorie[i].Elementi.length; j++)
        {
          this.listino.push(categorie[i].Elementi[j]);
        }
        break;
      } else if (this.auth.AuthUser.Role !== Role.USER && categorie[i].Visibile !== 1) {
        for (let j = 0; j < categorie[i].Elementi.length; j++)
        {
          this.listino.push(categorie[i].Elementi[j]);
        }
        break;
      }
    }
  if(this.listino.length === 0){
    this.errorProductsNotFound = 'PRODOTTI NON TROVATI';
  }else{
    this.ShopService.prodottiMostrati = this.listino;
  }
  }
  ShowDettaglioProdotto(software: SoftwareListino ) {
    this.router.navigate(["shop/acquisto-licenza-singola", software.CodiceArticolo], {state:software});
    this.ShopService.ShowAcquistoSingolo = true;
    this.ShopService.ShowAcquistoPacchetti = false;
    //this.router.navigate(['/shop',software.CodiceArticolo]);
  }

  incrementare(i: number) {
    let value = parseInt((<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value, 10);
    if (isNaN(value)){
      value = 1;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value = String(value);
    }
    if(value < 999){
      value = isNaN(value) ? 0 : value;
      value++;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value = String(value);
    }
  }
  decrementare(i: number) {
    let value = parseInt((<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value, 10);
    if (isNaN(value)){
      value = 0;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value = String(value);
    }
    if (value > 1){
      value = isNaN(value) ? 0 : value;
      value--;
      (<HTMLInputElement>document.getElementById('quantita'+'-'+'articolo'+'-'+i)).value = String(value);
    }
  }
  /**
   * AGGIUNTA AL CARRELLO PRODOTTO SINGOLO
   **/


  getProductImage(software: SoftwareListino) {
    if (IsDev.active){
      return 'https://localhost/ImgListino/' + software.ImgName;
    } else{
      return 'https://www.gabservizi.it/ImgListino/' + software.ImgName;
    }
  }

  ImageErrorHandler(codice:any) {
    document.getElementById(codice).setAttribute('src', 'assets/img/GabImageNotFound.png');
  }
}
