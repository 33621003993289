<div id="login-container">
  <div class="census-card-mobile-header" fxLayout="row">
    <div style="padding: 0.5rem !important;">
      <img class="profile-img" [src]="imageSrc">
    </div>
    <div style="width: 100%;padding: 0.1rem">
      <h1 *ngIf="RAGSOC === ''; else RAG_SOC_Exists">
        senza_rag.soc.
      </h1>
    </div>

  </div>

    <ng-template #RAG_SOC_Exists>
        <h1>
            {{RAGSOC}}
        </h1>
    </ng-template>
    <div class="tableFixHead">
        <table>
            <thead>
                <tr>
                    <th>Rivenditore</th>
                    <th>{{RIVENDITORE}}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>N/C</td>
                    <td>{{NMAG}}/{{NCASSA}}</td>
                </tr>
                <tr>
                    <td>Visual Update</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <td>Ultimo Avvio</td>
                    <td>{{LASTDATASTRING}}</td>
                </tr>
                <!--CLIENTE-->
                <tr>
                    <td><b>Cliente</b></td>
                </tr>
                <tr>
                    <td>P.Iva</td>
                    <td>{{PIVA}}</td>
                </tr>
                <tr>
                    <td>Via</td>
                    <td>{{VIA}}</td>
                </tr>
                <tr>
                    <td>Localita</td>
                    <td>{{LOC}}</td>
                </tr>
                <tr>
                    <td><b>Software</b></td>
                </tr>
                <tr (click)="OpenModalUpdatePos(modalVP, censimento)">
                    <td>VisualPos</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <td>VisualB2B</td>
                    <td>{{VB}}</td>
                </tr>

                <tr (click)="OpenModalUpdatePos(modalDriver, censimento)">
                    <td>Driver</td>
                    <td>{{DR}}</td>
                </tr>
                <tr>
                    <td>Software</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <div style="padding: 8px 16px;"><b>Fiscale</b></div>
                </tr>
                <tr>
                    <td>Matricola</td>
                    <td>{{MATRICOLA}}</td>
                </tr>
                <tr>
                    <td>Azzeramento Fiscale</td>
                    <td>{{AZZER}}</td>
                </tr>
                <tr>
                    <td>RT</td>
                    <td>{{GIART}}</td>
                </tr>
                <tr>
                    <td>lotteria</td>
                    <td>{{LOTTERIA}}</td>
                </tr>
                <tr>
                  <td>XML7</td>
                  <td>{{XML7}}</td>
                </tr>
                <tr>
                  <td>FW</td>
                  <td>{{RT_FW}}</td>
                </tr>
                <tr>
                  <td>Modello</td>
                  <td>{{RT_MODEL}}</td>
                </tr>
                <tr>
                  <td>Matricola</td>
                  <td>{{RE_MATR}}</td>
                </tr>
                <tr>
                    <td><b>Fatturazione Elettronica</b></td>
                </tr>
                <tr>
                    <td>Servizio B2B</td>
                    <td>{{SERVB2B}}</td>
                </tr>
                <tr>
                    <td>Trasmittente B2B</td>
                    <td>{{IDTRAB2B}}</td>
                </tr>
                <tr>
                    <td>MODULI</td>
                    <td></td>
                </tr>
                <tr>
                    <td><b>Hardware</b></td>
                </tr>
                <tr>
                    <td>CPU</td>
                    <td>{{CPU}}</td>
                </tr>
                <tr>
                    <td>MAC</td>
                    <td>{{MAC}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- <div style="max-height: 288px;">
        <table class="scroll">
            <tbody>
                <tr>
                    <td>Rivenditore</td>
                    <td>{{RIVENDITORE}}</td>
                </tr>
                <tr>
                    <td>N/C</td>
                    <td>{{NMAG}}/{{NCASSA}}</td>
                </tr>
                <tr>
                    <td>Visual Update</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <td>Ultimo Avvio</td>
                    <td>{{LASTDATASTRING}}</td>
                </tr>
                <tr>
                    <td>Visual Update</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <td>Visual Update</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <td>Visual Update</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <td>Visual Update</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <td>Visual Update</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <td>Visual Update</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <td>Visual Update</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <td>Visual Update</td>
                    <td>{{VU}}</td>
                </tr>
                <tr>
                    <td>Visual Update</td>
                    <td>{{VU}}</td>
                </tr>

            </tbody>
        </table>
    </div>  -->




    <footer>
        <div class="likes">
        </div>
        <div class="projects">
            <p>Censimento</p>
            <p>{{nCensimento + 1}}</p>
        </div>
    </footer>
</div>


<ng-template #modalVP>
  <div class="modal-body">
    <table class="table table-hover">
      <thead>
      <tr>
        <th>Software</th>
        <th>Versione</th>
        <th>Azioni</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>VisualPos</td>
        <td>{{this.LastVP.version}}</td>
        <td><a role="button">
          <fa-icon [icon]="faPen" (click)="UpdateVisualPos()"></fa-icon>
        </a></td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #modalDriver>
  <div class="modal-body">
    <table class="table table-hover">
      <thead>
      <tr>
        <th>Software</th>
        <th>Versione</th>
        <th>Azioni</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Driver</td>
        <td>{{this.LastDriver.version}}</td>
        <td><a role="button">
          <fa-icon [icon]="faPen" (click)="UpdateDriver()"></fa-icon>
        </a></td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-template>
