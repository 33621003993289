<div class="modal-content">
    <div class="modal-body" style="max-height: calc(100% - 3.5rem);" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">
        <div class="modal-title" style="padding-top: 0;">
            <h4>SellPoint</h4>
        </div>
        <ng-container #Informazioni>
            <div class="row">
                <div class="col"><label class="form-header">Info</label></div>
            </div>
            <hr style="margin: 0;">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Nome</label>
                        <input type="text" class="form-control" placeholder="Inserire ragione sociale *" required="required" [(ngModel)]="sellPoint.name"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Orario Iniziale</label>
                        <input type="time" class="form-control" placeholder="Inserire Partita IVA *" required="required" [(ngModel)]="sellPoint.startHour"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Orario Finale</label>
                        <input type="time" class="form-control" placeholder="Inserire Partita IVA *" required="required" [(ngModel)]="sellPoint.finishHour"/>
                    </div>
                </div>
            </div>
        </ng-container>
        
        <ng-container #Indirizzo>
            <div class="row">
                <div class="col"><label class="form-header">Indirizzo</label></div>
            </div>
            <hr style="margin: 0;">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Indirizzo</label>
                        <input type="text" class="form-control" [(ngModel)]="sellPoint.address"/>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <label>Numero Civico</label>
                        <input type="text" class="form-control" [(ngModel)]="sellPoint.street"/>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container #Contatti>
            <div class="row">
                <div class="col"><label class="form-header">Contatti</label></div>
            </div>
            <hr style="margin: 0;">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Telefono</label>
                        <input type="text" class="form-control" [(ngModel)]="sellPoint.phone"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Cellulare</label>
                        <input type="text" class="form-control" [(ngModel)]="sellPoint.mobile"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>E-mail</label>
                        <input type="text" class="form-control" [(ngModel)]="sellPoint.email"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Referente</label>
                        <input type="text" class="form-control" [(ngModel)]="sellPoint.contact"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Descrizione</label>
                        <textarea class="form-control" [(ngModel)]="sellPoint.note" rows="3"></textarea>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col">
                <div class="btn btn-danger" (click)="this.modalService.hide()">Esci</div>
            </div>
            <div class="col">
                <div class="btn btn-success" (click)="this.EditSellPoint()" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">Salva</div>
            </div>
        </div>
    </div>
</div>