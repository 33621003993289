import { AuthService } from './../../Services/AuthService/auth.service';
import { Component, OnInit } from '@angular/core';
import { ShopService } from '../../Services/ShopService/shop.service';

import {Role, User} from '../../Models/User';

import {faTrash, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { IsDev } from 'src/app/global';
import SoftwareListino from "../../Models/SoftwareListino";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  public faTrash: IconDefinition = faTrash;

  public userRole: Role = Role.USER;

  //URL Immagine
  public urlImg: string;


  constructor(public ShopService: ShopService, public auth: AuthService) {
    if (IsDev.active) {
      this.urlImg = "https://localhost/ImgListino/"
    } else {
      this.urlImg = "https://www.gabservizi.it/ImgListino/";
    }
  }

  ngOnInit(): void {
    for(let i= 0; i < this.ShopService.Carrello.length; i++){
      let TotalePrezzoScontato = 0;
      if(this.ShopService.Carrello[i].Tipo === 'P'){
        for (let j=0; j < this.ShopService.Carrello[i].Contenuto.length;j++){
          TotalePrezzoScontato += this.ShopService.Carrello[i].Contenuto[j].PrezzoScontato;
        }
        this.ShopService.Carrello[i].TotalePrezzoScontato = TotalePrezzoScontato;
      }
    }
  }

  getProductImageSingolo(contenitore: any) {
    if (IsDev.active){
      return 'https://localhost/ImgListino/' + contenitore.Contenuto[0].ImgName;
    } else{
      return 'https://www.gabservizi.it/ImgListino/' + contenitore.Contenuto[0].ImgName;
    }
  }
  ImageErrorHandlerSingolo(id){
    document.getElementById(id).setAttribute('src', '../../../assets/img/GabImageNotFound.png');
  }

}
