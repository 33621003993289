import { UserService } from './../../Services/UserService/user.service';
import { LicenseService } from './../../Services/LicenseService/license.service';
import { AlertService } from 'ngx-alerts';
import { AuthService } from './../../Services/AuthService/auth.service';
import { HttpClient } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LicenseClient, LicenseInfo } from './../../Models/LicenzeStat';
import { License } from 'src/app/Models/LicenzeStat';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IsDev } from 'src/app/global';

import {faCheck, IconDefinition } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-license-upgrade',
  templateUrl: './license-upgrade.component.html',
  styleUrls: ['./license-upgrade.component.scss']
})
export class LicenseUpgradeComponent implements OnInit {

  @Input() public License: LicenseInfo;
  @Input() public CanRetail: any =  {
    CanRetail: false,
    Tipo: {
      Canone: false,
      Start: false,
      Pro: false
    }
  }

  @Input() public Client: boolean;

  @Output() refreshStats: EventEmitter<string> = new EventEmitter();

  public Moduli: ModuleInfo[] = []; //CONTIENE I MODULI ACQUISTABILI

  public LicenseUpgrade: LicenseUpgrade = new LicenseUpgrade(); //CONTIENE LE LICENZE POTENZIALMENTE UPGRADIBILI

  public LicenseClient: number = 0;

  public Upgradable = {
    VisualFood: '',
    VisualShop: '',
    VisualKiosk: '',
    VisualSelf: '',
    VisualBeach: '',
    VisualRetail: '',
  }

  //VARIABILE CHE DEFINISCE SE SONO STATI SCELTI DEI MODULI
  public upgradeModuli = false;

  //ICON
  public faCheck: IconDefinition = faCheck;
  public IsUpgrading: boolean = false;

  constructor(public modalService: BsModalService, private http: HttpClient, public alert: AlertService, private LicenseService: LicenseService, public auth: AuthService, public userService: UserService) {

  }

  ngOnInit(): void {
    //MODULI
    //CONVERTO LA SEZIONE MODULI IN UN ARRAY DI INTERI. SE ZERO LO VISUALIZZO
    this.CheckModuli();
    this.CheckUpgrade();
  }

  private CheckModuli() {
    const modules: string[] = this.License.License.Modules.substring(5).split('');
    for (let i = 0; i < modules.length; ++i) {
      let description: string = "";

      switch (i) {
        case 0: {
          description = "Collegamento Cassetto Automatico"
          break;
        }
        case 1: {
          description = "Promo Cassa"
          break;
        }
        case 2: {
          description = "Celiachia"
          break;
        }
        case 3: {
          description = "Modulo 730"
          break;
        }
        case 4: {
          description = "Tabacchi"
          break;
        }
        case 5: {
          description = "Cooki"
          break;
        }
        case 6: {
          description = "Regiondo"
          break;
        }
        case 7: {
          description = "Visual B2B"
          break;
        }
      }

      if (modules[i] === '0') {
        const module: ModuleInfo = new ModuleInfo(Number(modules[i]), description);
        this.Moduli.push(module);
      }

    }
  }

  private CheckUpgrade() {

    const license: string[] = this.License.License.Modules.substring(1,5).split('');

    for (let i = 0; i < license.length; ++i) {
      //VISUAL FOOD
      if (i === 0) {
        switch(license[i]) {
          case 'A': {
            this.LicenseUpgrade.VisualFood.Upgradable = true;
            this.LicenseUpgrade.Upgradable = true;

            this.LicenseUpgrade.VisualFood.Installed = true;

            this.LicenseUpgrade.VisualFood.Start = true;
            this.LicenseUpgrade.VisualFood.Pro = false;
            this.LicenseUpgrade.VisualFood.Gold = false;
            break;
          }
          case 'B': {
            this.LicenseUpgrade.VisualFood.Upgradable = true;
            this.LicenseUpgrade.Upgradable = true;
            this.LicenseUpgrade.VisualFood.Installed = true;

            this.LicenseUpgrade.VisualFood.Start = true;
            this.LicenseUpgrade.VisualFood.Pro = true;
            this.LicenseUpgrade.VisualFood.Gold = false;
            break;
          }
          case 'C': {
            this.LicenseUpgrade.VisualFood.Upgradable = false
            break;
          }
          case '0': {
            break;
          }
        }
      }
      //VISUAL SHOP
      if (i === 1) {
        switch(license[i]) {
          case 'A': {
            this.LicenseUpgrade.VisualShop.Upgradable = true;
            this.LicenseUpgrade.Upgradable = true;
            this.LicenseUpgrade.VisualShop.Installed = true;

            this.LicenseUpgrade.VisualShop.Start = true;
            this.LicenseUpgrade.VisualShop.Pro = false;
            break;
          }
          case 'B': {
            this.LicenseUpgrade.VisualShop.Upgradable = false;
            break;
          }
          case '0': {
            break;
          }
        }
      }
      //VISUAL SELF
      if (i === 2) {
        switch(license[i]) {
          // VISUAL KIOSK
          case 'A': {
            this.LicenseUpgrade.VisualKiosk.Upgradable = true;
            this.LicenseUpgrade.Upgradable = true;
            this.LicenseUpgrade.VisualKiosk.Installed = true;

            this.LicenseUpgrade.VisualKiosk.Start = true;
            this.LicenseUpgrade.VisualKiosk.Pro = false;
            this.LicenseUpgrade.VisualKiosk.Gold = false;
            break;
          }
          case 'B': {
            this.LicenseUpgrade.VisualKiosk.Upgradable = true;
            this.LicenseUpgrade.Upgradable = true;
            this.LicenseUpgrade.VisualKiosk.Installed = true;

            this.LicenseUpgrade.VisualKiosk.Start = true;
            this.LicenseUpgrade.VisualKiosk.Pro = true;
            this.LicenseUpgrade.VisualKiosk.Gold = false;
            break;
          }
          case 'C': {
            this.LicenseUpgrade.VisualKiosk.Upgradable = false;
            break;
          }

          // VISUAL SELF
          case 'G': {
            this.LicenseUpgrade.VisualSelf.Upgradable = true;
            this.LicenseUpgrade.Upgradable = true;
            this.LicenseUpgrade.VisualSelf.Installed = true;

            this.LicenseUpgrade.VisualSelf.Start = true;
            this.LicenseUpgrade.VisualSelf.Pro = false;
            //this.LicenseUpgrade.VisualSelf.Gold = false;
            break;
          }
          case 'H': {
            this.LicenseUpgrade.VisualSelf.Upgradable = false;

            //this.LicenseUpgrade.Upgradable = true;
            //this.LicenseUpgrade.VisualSelf.Installed = true;

            //this.LicenseUpgrade.VisualSelf.Start = true;
            //this.LicenseUpgrade.VisualSelf.Pro = true;
            //this.LicenseUpgrade.VisualSelf.Gold = false;
            break;
          }
          /*
          case 'I': {
            this.LicenseUpgrade.VisualSelf.Upgradable = false;
            break;
          }
          */

          // VISUAL BEACH
          case 'M': {
            this.LicenseUpgrade.VisualBeach.Upgradable = true;
            this.LicenseUpgrade.Upgradable = true;
            this.LicenseUpgrade.VisualBeach.Installed = true;

            this.LicenseUpgrade.VisualBeach.Start = true;
            this.LicenseUpgrade.VisualBeach.Pro = false;
            //this.LicenseUpgrade.VisualBeach.Gold = false;
            break;
          }
          case 'N': {
            this.LicenseUpgrade.VisualBeach.Upgradable = false;

            //this.LicenseUpgrade.Upgradable = true;
            //this.LicenseUpgrade.VisualBeach.Installed = true;

            //this.LicenseUpgrade.VisualBeach.Start = true;
            //this.LicenseUpgrade.VisualBeach.Pro = true;
            //this.LicenseUpgrade.VisualBeach.Gold = false;
            break;
          }
          /*
          case 'O': {
            this.LicenseUpgrade.VisualBeach.Upgradable = false;
            break;
          }
          */
          case '0': {
            break;
          }
        }
      }
      //VISUAL RETAIL
      if (i === 3) {
        switch(license[i]) {
          case 'A': {
            this.LicenseUpgrade.VisualRetail.Upgradable = true;
            this.LicenseUpgrade.Upgradable = true;
            this.LicenseUpgrade.VisualRetail.Installed = true;

            this.LicenseUpgrade.VisualRetail.Start = true;
            this.LicenseUpgrade.VisualRetail.Pro = false;
            break;
          }
          case 'B': {
            this.LicenseUpgrade.VisualRetail.Upgradable = false;
            break;
          }
          case '0': {
            break;
          }
        }
      }
    }
  }

  public CheckModuliUpgrade() {
    this.upgradeModuli = false;
    for (let i = 0; i < this.Moduli.length; ++i) {
      if (this.Moduli[i].value === 0 && this.Moduli[i].toUpgrade) {
        this.upgradeModuli = true;
        break;
      }
    }
  }

  public async Upgrade() {

    this.IsUpgrading = true;

    this.upgradeModuli = false;
    /*
      CHECK: SE AGGIORNO LA LICENZA LA AGGIORNO ANCHE LATO BACKENDS
    */
    let upgrade = false;

    if (this.Upgradable.VisualFood !== "" || this.Upgradable.VisualShop !== "" || this.Upgradable.VisualKiosk !== "" || this.Upgradable.VisualSelf !== "" || this.Upgradable.VisualBeach !== "" || this.Upgradable.VisualRetail !== "") {
      upgrade = true;
    }

    for (let i = 0; i < this.Moduli.length; ++i) {
      if (this.Moduli[i].value === 0 && this.Moduli[i].toUpgrade) {
        upgrade = true;
        break;
      }
    }


    let success = false;

    //SEZIONE LICENZA MADRE
    if (upgrade) {

      // const idAdmin = await this.auth.GetIdByUsername();
      const id = await this.userService.GetIdByUsername(this.License.Creatore);

      const result: any = await this.LicenseService.UpgradeLicense(this.Upgradable, this.Moduli, this.License.License.License, id, this.CanRetail);

      if (result === "ko") {
        this.modalService.hide();
        this.alert.danger("Licenza non modificata. Contatta il tuo distributore");
      } else {
        this.License.License.Modules = result;

        this.UpgradeMainPage();
        success = true;
      }
    }

    //SEZIONE LICENZE CLIENT
    if (this.LicenseClient > 0) {

      // const userIdAdmin = await this.auth.GetIdByUsername();
      const id = await this.userService.GetIdByUsername(this.License.Creatore);


      for (let i = 0; i < this.LicenseClient; ++i) {

        const clientLicense: any = await this.LicenseService.GenerateLicenseClient(id, this.License.License.License);

        const moduleClient: string = this.License.License.Modules.substring(0, 5) + "00000000";

        const licenseClient: LicenseClient = new LicenseClient({license: clientLicense, module: moduleClient}, new Date().toISOString(), 0);
        licenseClient.CreationDate = licenseClient.CreationDate.substr(8,2) + "/" + licenseClient.CreationDate.substr(5,2) + "/" + licenseClient.CreationDate.substr(0,4);

        this.License.LicenseClient.push(licenseClient);
      }

      this.LicenseService.SendMailClient(this.License, this.LicenseClient);

      success = true;
    }

    this.modalService.hide();
    if (success) {
      this.alert.success("Licenza modificata");
    }

    this.refreshStats.emit();
  }

  private UpgradeMainPage() {
    const descrizioneObject = {
      primo: (this.License.License.Modules as string).charAt(1),
      secondo: (this.License.License.Modules as string).charAt(2),
      terzo: (this.License.License.Modules as string).charAt(3),
      quarto: (this.License.License.Modules as string).charAt(4),
    };

    this.License.Descrizione = "";

    //UPGRADE FRONT-END
    switch(descrizioneObject.primo) {
      case 'A': {
        this.License.Descrizione += "Visual Food Start, ";
        break;
      }
      case 'B': {
        this.License.Descrizione += "Visual Food Pro, ";
        break;
      }
      case 'C': {
        this.License.Descrizione += "Visual Food Gold, ";
        break;
      }
    }


    switch(descrizioneObject.secondo) {
      case 'A': {
        this.License.Descrizione += "Visual Shop Start, ";
        break;
      }
      case 'B': {
        this.License.Descrizione += "Visual Shop Pro, ";
        break;
      }
    }


    switch(descrizioneObject.terzo) {
      case 'A': {
        this.License.Descrizione += "Visual Kiosk Start, ";
        break;
      }
      case 'B': {
        this.License.Descrizione += "Visual Kiosk Pro, ";
        break;
      }
      case 'C': {
        this.License.Descrizione += "Visual Kiosk Gold, ";
        break;
      }
      case 'G': {
        this.License.Descrizione += "Visual Self Start, ";
        break;
      }
      case 'H': {
        this.License.Descrizione += "Visual Self Pro, ";
        break;
      }
      case 'I': {
        this.License.Descrizione += "Visual Self Gold, ";
        break;
      }
      case 'M': {
        this.License.Descrizione += "Visual Beach Start, ";
        break;
      }
      case 'N': {
        this.License.Descrizione += "Visual Beach Pro, ";
        break;
      }
      case 'O': {
        this.License.Descrizione += "Visual Beach Gold, ";
        break;
      }
    }


    switch(descrizioneObject.quarto) {
      case 'A': {
        this.License.Descrizione += "Visual Retail Start, ";
        break;
      }
      case 'B': {
        this.License.Descrizione += "Visual Retail Pro, ";
        break;
      }
    }

    this.License.Descrizione = this.License.Descrizione.substring(0, this.License.Descrizione.length - 2);
  }

  //CHECK DISABLED
  public IsNotDisabled(): boolean {
    //CHECK LICENZE
    if (this.Upgradable.VisualFood !== '') return true;
    if (this.Upgradable.VisualRetail !== '') return true;
    if (this.Upgradable.VisualShop !== '') return true;
    if (this.Upgradable.VisualSelf !== '') return true;
    if (this.Upgradable.VisualKiosk !== '') return true;
    if (this.Upgradable.VisualBeach !== '') return true;

    //CHECK MODULI
    for (let i = 0; i < this.Moduli.length; ++i) {
      if (this.Moduli[i].toUpgrade) return true;
    }

    //CHECK LICENZE CLIENT
    if (this.LicenseClient > 0) return true;

    return false;
  }
}


export class ModuleInfo {
  public value: number;
  public description: string;
  public toUpgrade: boolean = false;

  public constructor(value: number, description: string) {
    this.value = value;
    this.description = description;
  }
}

class LicenseUpgrade {

  public Upgradable: boolean = false;

  public VisualFood: Visual = new Visual();
  public VisualShop: Visual = new Visual();
  public VisualKiosk: Visual = new Visual();
  public VisualSelf: Visual = new Visual();
  public VisualBeach: Visual = new Visual();
  public VisualRetail: Visual = new Visual();
}


class Visual {
  public Upgradable: boolean
  public Installed: boolean = false;
  public Start: boolean
  public Pro: boolean
  public Gold: boolean;
}



