<div class="content fade-in">
    <div class="row">
        <div class="col-md-12">
            <div class="card strpied-tabled-with-hover" id="cardTicket" *ngIf="this.auth.AuthUser.Role === 0 && this.auth.AuthUser.SettingsPermessi.Manuali.Inserimento">
                <div class="card-header">
                    <h4 class="card-title">Aggiungi manuale</h4>
                </div>
                <div class="card-body table-full-width table-responsive">

                    <div class="messages"></div>

                    <div class="controls">

                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label for="form_name">Prodotto *</label>
                                    <input id="prodotto" type="text" name="prodotto" class="form-control"
                                        placeholder="Inserire il nome del prodotto *" required="required" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="form_lastname">Versione *</label>
                                    <input id="versione" type="text" name="versione" class="form-control"
                                        placeholder="Inserire la versione *" required="required" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="form_email">Codice Software *</label>
                                    <select id="softcode" type="text" name="softcode" class="form-control"
                                        placeholder="Inserire il codice software *" required="required" *ngIf="ready">
                                        <option *ngFor="let softcode of this.SoftCodes" (value)="softcode[1]">
                                            {{softcode[0]}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input id="file-upload" type="file" (change)="FileInput($event.target.files)">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <span class="btn btn-primary btn-fill" style="float: right;"
                                    (click)="UploadManual()">UPLOAD</span>
                            </div>
                        </div>
                    </div>
                    <alert style="width: 100%; margin-top: 10%;" type="danger" [dismissible]="true"
                        [isOpen]="alertOpen">
                        <strong style="text-align: center;">{{alertMsg}}</strong>
                    </alert>
                    <alert style="width: 100%; margin-top: 10%;" type="success" [dismissible]="true"
                        [isOpen]="alertOpenSuccess">
                        <strong style="text-align: center;">{{alertMsg}}</strong>
                    </alert>
                </div>
            </div>

            <div class="card strpied-tabled-with-hover" id="cardTicket">
                <div class="card-header">
                    <h4 class="card-title">Lista Manuali</h4>
                    <div class="md-form active-pink active-pink-2 mb-3 mt-1">
                        <input class="form-control" type="text" placeholder="Search" id="search" aria-label="Search"
                            (keyup)="Filter()" />
                    </div>
                </div>
                <div class="card-body table-full-width table-responsive">

                    <div class="messages"></div>

                    <div class="controls">
                        <table class="table table-hover table-striped" id="table">
                            <thead>
                                <tr>
                                    <th>Prodotto</th>
                                    <th>Versione</th>
                                    <th>Codice Software</th>
                                    <th>Nome File</th>
                                    <th></th>
                                    <th *ngIf="this.auth.AuthUser.Role === 0 && this.auth.AuthUser.SettingsPermessi.Manuali.Eliminazione"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let manual of this.Manuals; trackBy: fileName">
                                    <td (click)="this.FileService.DownloadManual(manual.downloadFileName)">{{manual.product}}</td>
                                    <td (click)="this.FileService.DownloadManual(manual.downloadFileName)">{{manual.version}}</td>
                                    <td (click)="this.FileService.DownloadManual(manual.downloadFileName)">{{manual.softCode}}</td>
                                    <td (click)="this.FileService.DownloadManual(manual.downloadFileName)">{{manual.downloadFileName}}</td>
                                    <td><a role="button"
                                            (click)="this.FileService.DownloadManual(manual.downloadFileName)">
                                            <fa-icon [icon]="faDownload" size="2x"></fa-icon>
                                        </a></td>
                                    <td *ngIf="this.auth.AuthUser.Role === 0 && this.auth.AuthUser.SettingsPermessi.Manuali.Eliminazione"><a role="button"
                                            (click)="this.OpenModalDelete(modalElimina, manual)">
                                            <fa-icon [icon]="faTrash" size="2x"></fa-icon>
                                        </a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #modalElimina>
    <!--Body-->
    <div class="modal-body body-custom">
        <div class="text-center">
            <i class="fa fa-trash-o fa-4x mb-3 animated rotateIn fa-custom"></i>
            <p>Sei sicuro di voler eliminare il manuale?</p>
        </div>
    </div>

    <!--Footer-->
    <div class="modal-footer justify-content-center">
        <a role="button" class="btn btn-outline-danger waves-effect" (click)="this.modalService.hide()">No</a>
        <a role="button" class="btn btn-danger"
            (click)="this.DeleteManual(this.ManualDaEliminare.product, this.ManualDaEliminare.softCode, this.ManualDaEliminare.downloadFileName)"
            style="margin-left: 5%;">Si</a>
    </div>
</ng-template>
