export default class Update {
    public softwareName: string;
    public version: string;
    public softCode: string;
    public downloadFileName: string;
    public releaseDate: string;
    public releaseDatePrint: string;
    public downloading: boolean = false;

    public IsSubmenu: boolean = false;

    constructor(softwareName: string, version: string, softCode: string, downloadFileName: string, releaseData: string) {
        this.softwareName = softwareName;
        this.version = version;
        this.softCode = softCode;
        this.downloadFileName = downloadFileName;
        this.releaseDate = releaseData;
        this.releaseDatePrint = this.ConvertDate(releaseData);

        //CONVERTO DATA
    }

    private ConvertDate(releaseData: string): string {
        return releaseData.substr(8, 2) + "/" + releaseData.substr(5, 2) + "/" +  releaseData.substr(0, 4)
    }
}