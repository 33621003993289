import { UserService } from './../../Services/UserService/user.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from './../../Services/AuthService/auth.service';
import { ClipboardService } from 'ngx-clipboard';
import { AlertService } from 'ngx-alerts';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { IsDev } from 'src/app/global';
import { License, LicenseInfo, LicenseStat } from 'src/app/Models/LicenzeStat';
import { User } from 'src/app/Models/User';
import { IconDefinition, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { LicenseService } from 'src/app/Services/LicenseService/license.service';

@Component({
  selector: 'app-license-genera',
  templateUrl: './license-genera.component.html',
  styleUrls: ['./license-genera.component.scss']
})
export class LicenseGeneraComponent implements OnInit {


  @ViewChild('licenseModal') licenseTemplate: TemplateRef<any>

  @Output() LoadLicenses: EventEmitter<boolean> = new EventEmitter();

  @Input()  public licenzeStats: LicenseStat[]
  @Input() public Licenses: LicenseInfo[];

  public UserConcessionari: User[] = [];
  public UserSegnalatori: User[] = [];
  public UserIDLicenza: number = 10;
  public UserIDSegnalatore: number = 10;

  public TipoGenerazione: string = '';
  public TipoGenerazioneAdmin: string = 'Annuale';
  public demo: boolean = false;

  public sceltaLicenzaOBJ = {
    VisualFood: {
      Start: false,
      Pro: false,
      Gold: false
    },
    VisualShop: {
      Start: false,
      Pro: false
    },
    VisualKiosk: {
      Start: false,
      Pro: false,
      Gold: false,
    },
    VisualSelf: {
      Start: false,
      Pro: false,
      Gold: false
    },
    VisualBeach: {
      Start: false,
      Pro: false,
      Gold: false
    },
    VisualRetail: {
      Start: false,
      Pro: false
    }
  }

  public sceltaModuli: any = {
    cassetto: false,
    tabacchi: false,
    promotion: false,
    verify: false,
    celiachia: false,
    turn: false,
    modulo730: false,
    b2b: false
  }

  public loading: boolean = false;
  public LicenseGenerationCompleted: boolean = false;
  public alertMsg: string = "";
  public alertOpen = false;
  public generated: boolean = false;
  public popupInfo: string = ""
  public licenseGenerated: string = '';
  //DATA DI SCADENZA
  public faInfo: IconDefinition = faInfoCircle;
  public bsConfig: Partial<BsDatepickerConfig>;
  public DataScadenzaBoolean: boolean = false;
  public DataScadenza: Date;

  constructor(public auth: AuthService, public modalService: BsModalService,
              private http: HttpClient, private clipboard: ClipboardService, private alertService: AlertService,
              private userService: UserService, private localeService: BsLocaleService, private licenseService: LicenseService) { }

  async ngOnInit() {
    const users: User[] = await this.userService.LoadUsers(this.auth.AuthUser.Username);

    for (let i = 0; i < users.length; ++i) {
      if (users[i].Role === 1 || users[i].Role2 === 1) {
        this.UserConcessionari.push(users[i]);
      }
      if (users[i].Role === 3 || users[i].Role2 === 3) {
        this.UserSegnalatori.push(users[i]);
      }
    }

    //DEFINIZIONE DATA DI SCADENZA
    //const temp: Date = new Date();

    this.localeService.use('it');

    this.DataScadenza = new Date(2097, 11, 31);
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', showWeekNumbers: false});
  }


  //CHECK GLI ELEMENTI SELEZIONATI
  public SelectedToObj() {

    // VISUAL FOOD
    this.sceltaLicenzaOBJ.VisualFood.Start = (document.getElementById("vfoodstart") as HTMLInputElement).checked ? true : false;
    this.sceltaLicenzaOBJ.VisualFood.Pro = (document.getElementById("vfoodpro") as HTMLInputElement).checked ? true : false;
    this.sceltaLicenzaOBJ.VisualFood.Gold = (document.getElementById("vfoodgold") as HTMLInputElement).checked ? true : false;

    // VISUAL SHOP
    this.sceltaLicenzaOBJ.VisualShop.Start = (document.getElementById("vshopstart") as HTMLInputElement).checked ? true : false;
    this.sceltaLicenzaOBJ.VisualShop.Pro = (document.getElementById("vshoppro") as HTMLInputElement).checked ? true : false;

    // VISUAL KIOSK
    this.sceltaLicenzaOBJ.VisualKiosk.Start = (document.getElementById("vkioskstart") as HTMLInputElement).checked ? true : false;
    this.sceltaLicenzaOBJ.VisualKiosk.Pro = (document.getElementById("vkioskpro") as HTMLInputElement).checked ? true : false;
    this.sceltaLicenzaOBJ.VisualKiosk.Gold = (document.getElementById("vkioskgold") as HTMLInputElement).checked ? true : false;

    // VISUAL SELF
    this.sceltaLicenzaOBJ.VisualSelf.Start = (document.getElementById("vselfstart") as HTMLInputElement).checked ? true : false;
    this.sceltaLicenzaOBJ.VisualSelf.Pro = (document.getElementById("vselfpro") as HTMLInputElement).checked ? true : false;
    // this.sceltaLicenzaOBJ.VisualSelf.Gold = (document.getElementById("vselfgold") as HTMLInputElement).checked ? true : false;

    // VISUAL BEACH
    this.sceltaLicenzaOBJ.VisualBeach.Start = (document.getElementById("vbeachstart") as HTMLInputElement).checked ? true : false;
    this.sceltaLicenzaOBJ.VisualBeach.Pro = (document.getElementById("vbeachpro") as HTMLInputElement).checked ? true : false;
    // this.sceltaLicenzaOBJ.VisualBeach.Gold = (document.getElementById("vbeachgold") as HTMLInputElement).checked ? true : false;

    // VISUAL RETAIL
    this.sceltaLicenzaOBJ.VisualRetail.Start = (document.getElementById("vretailstart") as HTMLInputElement).checked ? true : false;
    this.sceltaLicenzaOBJ.VisualRetail.Pro = (document.getElementById("vretailpro") as HTMLInputElement).checked ? true : false;
  }

  private SelectedOBJToStrings(): string[] {
    const strings: string[] = [];

    // VISUAL FOOD
    if (this.sceltaLicenzaOBJ.VisualFood.Start) strings.push("vfoodstart");
    if (this.sceltaLicenzaOBJ.VisualFood.Pro) strings.push("vfoodpro");
    if (this.sceltaLicenzaOBJ.VisualFood.Gold) strings.push("vfoodgold");

    // VISUAL SHOP
    if (this.sceltaLicenzaOBJ.VisualShop.Start) strings.push("vshopstart");
    if (this.sceltaLicenzaOBJ.VisualShop.Pro) strings.push("vshoppro");

    // VISUAL KIOSK
    if (this.sceltaLicenzaOBJ.VisualKiosk.Start) strings.push("vkioskstart");
    if (this.sceltaLicenzaOBJ.VisualKiosk.Pro) strings.push("vkioskpro");
    if (this.sceltaLicenzaOBJ.VisualKiosk.Gold) strings.push("vkioskgold");

    // VISUAL SELF
    if (this.sceltaLicenzaOBJ.VisualSelf.Start) strings.push("vselfstart");
    if (this.sceltaLicenzaOBJ.VisualSelf.Pro) strings.push("vselfpro");
    // if (this.sceltaLicenzaOBJ.VisualSelf.Gold) strings.push("vselfgold");

    // VISUAL BEACH
    if (this.sceltaLicenzaOBJ.VisualBeach.Start) strings.push("vbeachstart");
    if (this.sceltaLicenzaOBJ.VisualBeach.Pro) strings.push("vbeachpro");
    // if (this.sceltaLicenzaOBJ.VisualBeach.Gold) strings.push("vbeachgold");

    // VISUAL RETAIL
    if (this.sceltaLicenzaOBJ.VisualRetail.Start) strings.push("vretailstart");
    if (this.sceltaLicenzaOBJ.VisualRetail.Pro) strings.push("vretailpro");

    return strings;
  }

  //Funzione per stampare correttamente i dati
  public SelectedOBJToStringHTML(): string[] {

    const strings: string[] = [];
    if (this.sceltaLicenzaOBJ.VisualFood.Start) strings.push("Visual Food Start");
    if (this.sceltaLicenzaOBJ.VisualFood.Pro) strings.push("Visual Food Pro");
    if (this.sceltaLicenzaOBJ.VisualFood.Gold) strings.push("Visual Food Gold");

    if (this.sceltaLicenzaOBJ.VisualShop.Start) strings.push("Visual Shop Start");
    if (this.sceltaLicenzaOBJ.VisualShop.Pro) strings.push("Visual Shop Pro");

    if (this.sceltaLicenzaOBJ.VisualKiosk.Start) strings.push("Visual Kiosk Start");
    if (this.sceltaLicenzaOBJ.VisualKiosk.Pro) strings.push("Visual Kiosk Pro");
    if (this.sceltaLicenzaOBJ.VisualKiosk.Gold) strings.push("Visual Kiosk Gold");

    if (this.sceltaLicenzaOBJ.VisualSelf.Start) strings.push("Visual Self Start");
    if (this.sceltaLicenzaOBJ.VisualSelf.Pro) strings.push("Visual Self Pro");
    if (this.sceltaLicenzaOBJ.VisualSelf.Gold) strings.push("Visual Self Gold");

    if (this.sceltaLicenzaOBJ.VisualBeach.Start) strings.push("Visual Beach Start");
    if (this.sceltaLicenzaOBJ.VisualBeach.Pro) strings.push("Visual Beach Pro");
    if (this.sceltaLicenzaOBJ.VisualBeach.Gold) strings.push("Visual Beach Gold");

    if (this.sceltaLicenzaOBJ.VisualRetail.Start) strings.push("Visual Retail Start");
    if (this.sceltaLicenzaOBJ.VisualRetail.Pro) strings.push("Visual Retail Pro");

    return strings;
  }

  public ModuleToString(): string[] {
    const returnString: string[] = [];

    if (this.sceltaModuli.cassetto) returnString.push("Collegamento Cassetto Automatico");
    if (this.sceltaModuli.tabacchi) returnString.push("Tabacchi");
    if (this.sceltaModuli.promotion) returnString.push("Promo Cassa");
    if (this.sceltaModuli.verify) returnString.push("Cooki");
    if (this.sceltaModuli.celiachia) returnString.push("Celiachia");
    if (this.sceltaModuli.turn) returnString.push("Regiondo");
    if (this.sceltaModuli.modulo730) returnString.push("Modulo 730");
    if (this.sceltaModuli.b2b) returnString.push("Visual B2B");

    return returnString;

  }

  private GetCountSelected(): number {
    let count: number = 0;
    if (this.sceltaLicenzaOBJ.VisualFood.Start) count++;
    if (this.sceltaLicenzaOBJ.VisualFood.Pro) count++;
    if (this.sceltaLicenzaOBJ.VisualFood.Gold) count++;

    if (this.sceltaLicenzaOBJ.VisualShop.Start) count++;
    if (this.sceltaLicenzaOBJ.VisualShop.Pro) count++;

    if (this.sceltaLicenzaOBJ.VisualKiosk.Start) count++;
    if (this.sceltaLicenzaOBJ.VisualKiosk.Pro) count++;
    if (this.sceltaLicenzaOBJ.VisualKiosk.Gold) count++;

    if (this.sceltaLicenzaOBJ.VisualSelf.Start) count++;
    if (this.sceltaLicenzaOBJ.VisualSelf.Pro) count++;
    if (this.sceltaLicenzaOBJ.VisualSelf.Gold) count++;

    if (this.sceltaLicenzaOBJ.VisualBeach.Start) count++;
    if (this.sceltaLicenzaOBJ.VisualBeach.Pro) count++;
    if (this.sceltaLicenzaOBJ.VisualBeach.Gold) count++;

    if (this.sceltaLicenzaOBJ.VisualRetail.Start) count++;
    if (this.sceltaLicenzaOBJ.VisualRetail.Pro) count++;

    return count;
  }

  public Reset() {
    // VISUAL FOOD
    (document.getElementById("vfoodstart") as HTMLInputElement).checked = false;
    (document.getElementById("vfoodpro") as HTMLInputElement).checked = false;
    (document.getElementById("vfoodgold") as HTMLInputElement).checked = false;

    // VISUAL SHOP
    (document.getElementById("vshopstart") as HTMLInputElement).checked = false;
    (document.getElementById("vshoppro") as HTMLInputElement).checked = false;

    // VISUAL KIOSK
    (document.getElementById("vkioskstart") as HTMLInputElement).checked = false;
    (document.getElementById("vkioskpro") as HTMLInputElement).checked = false;
    (document.getElementById("vkioskgold") as HTMLInputElement).checked = false;

    // VISUAL SELF
    (document.getElementById("vselfstart") as HTMLInputElement).checked = false;
    (document.getElementById("vselfpro") as HTMLInputElement).checked = false;
    // (document.getElementById("vselfgold") as HTMLInputElement).checked = false;

    // VISUAL BEACH
    (document.getElementById("vbeachstart") as HTMLInputElement).checked = false;
    (document.getElementById("vbeachpro") as HTMLInputElement).checked = false;
    // (document.getElementById("vbeachgold") as HTMLInputElement).checked = false;

    // VISUAL RETAIL
    (document.getElementById("vretailstart") as HTMLInputElement).checked = false;
    (document.getElementById("vretailpro") as HTMLInputElement).checked = false;

    this.sceltaLicenzaOBJ = {
      VisualFood: {
        Start: false,
        Pro: false,
        Gold: false
      },
      VisualShop: {
        Start: false,
        Pro: false
      },
      VisualKiosk: {
        Start: false,
        Pro: false,
        Gold: false,
      },
      VisualSelf: {
        Start: false,
        Pro: false,
        Gold: false
      },
      VisualBeach: {
        Start: false,
        Pro: false,
        Gold: false
      },
      VisualRetail: {
        Start: false,
        Pro: false
      }
    }

    this.sceltaModuli = {
      cassetto: false,
      tabacchi: false,
      promotion: false,
      verify: false,
      celiachia: false,
      turn: false,
      modulo730: false,
      b2b: false
    }
  }

  public async GenerateLicense() {
    this.loading = true;
    this.LicenseGenerationCompleted = false;
    if (this.demo ) {

      const result: any = await this.licenseService.GetLicenseDemoCount();

      if (result >= 10 && this.auth.AuthUser.Role !== 0) {
        this.alertMsg = "Hai generato più di 10 licenze demo";
        this.alertOpen = true;
        setTimeout(() => {
          this.alertOpen = false;
        }, 5000)
        return;
      } else {

        // const id = await this.auth.GetIdByUsername();

        const license: any = await this.licenseService.GenerateLicenseDemo();
         this.loading = false;
         this.licenseGenerated = license.license;
        this.OpenLicenseModal(this.licenseTemplate);

        this.LoadLicenses.emit(true);

        this.LicenseGenerationCompleted =true;

        await this.ngOnInit();
        return;
      }
    }


    //SE NON HO SELEZIONATO NIENTE, ALERT E RETURN
    if (this.GetCountSelected() < 1 && !this.demo) {
      this.alertService.danger("Seleziona un elemento per generare la licenza");
      this.loading = false;
      return;
    }


    //SCELGO UNA COMBINAZIONE DI LICENZE
    //this.sceltaLicenza, this.sceltaModuli

    //SCELGO SE SOTTRARLO AL COUNTER DELLE LICENZE ACQUISTATE O DAL CANONE, SE ESISTE -->
    //this.TipoGenerazione (Canone, Acquistato)

    //NEL CASO DEL CANONE, GENERO SEMPLICEMENTE LA LICENZA
    if (this.auth.AuthUser.Tipo.includes('C') && !this.auth.AuthUser.Tipo.includes('P') && !this.auth.AuthUser.Tipo.includes('S')) {
      this.TipoGenerazione = "Canone";
    }

    if (!this.auth.AuthUser.Tipo.includes('C') && (this.auth.AuthUser.Tipo.includes('P') || this.auth.AuthUser.Tipo.includes('S'))) {
      this.TipoGenerazione = "Acquistate";
    }

    if (this.auth.AuthUser.Username === 'gab') {

        const id = await this.auth.GetIdByUsername();

        const selected: Selected = new Selected();

        //SCORRO LE CHIAVI DELL'OGGETTO E LE SOTTOCHIAVI, SUCCESSIVAMENTE
        selected.Visual = this.SelectedOBJToStrings();
        selected.Modules = this.ModuleToString();
        selected.UserID = 10;
        selected.Username = this.auth.AuthUser.Username;
        for (let i = 0; i < selected.Visual.length; ++i) {
          selected.CodiceArticolo.push(this.VisualToCodiceArticolo(selected.Visual[i]))
        }

        const ScadenzaLicenza = (this.DataScadenzaBoolean) ? this.DataScadenza : new Date(2001, 1, 1);

        const result = await this.licenseService.GenerateLicense(selected, this.TipoGenerazioneAdmin, this.UserIDLicenza, this.UserIDSegnalatore, ScadenzaLicenza);

        this.loading = false;

        const stat: LicenseStat = this.licenzeStats.find((stat) => stat.CodiceArticolo === "CANONE");
        stat.LicenzeGenerate = stat.LicenzeGenerate + selected.Visual.length;

        //APRO IL MODAL DELLA LICENZA
        this.licenseGenerated = (result as any).license;
        this.OpenLicenseModal(this.licenseTemplate);
        //AGGIUNGO IL MODAL ALLA LISTA DELLE LICENZE
        this.LoadLicenses.emit(true);

        this.LicenseGenerationCompleted = true;
        return;
    }


    if (this.TipoGenerazione === "Canone") {
      const stat: LicenseStat = this.licenzeStats.find((stat) => stat.CodiceArticolo === "CANONE");

      //CHECK SE POSSO ATTIVARE OPPURE NO
      const stringSplit: string[] = this.auth.AuthUser.SoftwareAbbinati.split('|'); //software attivabili
      const stringLicenses: string[] = this.SelectedOBJToStrings();

      let countCheck: number = 0;
      //CHECK, SE NON CONTIENE GLI ELEMENTI, IL CONTROLLO DIVENTA FALSO
      for (let i = 0; i < stringLicenses.length; ++i) {
        for (let j = 0; j < stringSplit.length; ++j) {
          if (stringLicenses[i].includes(stringSplit[j])) {
            countCheck++;
          }
        }
      }

      if (countCheck !== stringLicenses.length) {
        this.alertService.danger("Licenza non attivabile. Contatta il tuo distributore per ulteriori informazioni");
        this.loading = false;
        return;
      }

      if ((stat.LicenzeGenerate + stringLicenses.length) <= stat.NumeroLicenze || this.auth.AuthUser.Username === 'gab' || stat.NumeroLicenze === -1) {
        const selected: Selected = new Selected();
        selected.Visual = this.SelectedOBJToStrings();
        selected.Modules = this.ModuleToString();
        selected.UserID = this.auth.AuthUser.ID;
        selected.Username = this.auth.AuthUser.Username;
        for (let i = 0; i < selected.Visual.length; ++i) {
          selected.CodiceArticolo.push(this.VisualToCodiceArticolo(selected.Visual[i]))
        }

        const result = await this.licenseService.GenerateLicense(selected, this.TipoGenerazione);


        this.loading = false;
         //APRO IL MODAL DELLA LICENZA
        stat.LicenzeGenerate = stat.LicenzeGenerate + 1;
        this.licenseGenerated = (result as any).license;
        this.OpenLicenseModal(this.licenseTemplate);
        this.LoadLicenses.emit(true);

        this.LicenseGenerationCompleted =true;



      } else {
        this.alertService.danger("Numero limite di licenze generabili raggiunto");
      }
    }

    //NEL CASO DEL TIPO ACQUISTATO, GENERO LA LICENZA SOLO SE LA POSSIEDO
    else if (this.TipoGenerazione === "Acquistate") {

      const codiciArticolo: string[] = [];
      const visuals: string[] = this.SelectedOBJToStrings();

      for (let i = 0; i < visuals.length; ++i) {
        codiciArticolo.push(this.VisualToCodiceArticolo(visuals[i]))
      }

      const stat: LicenseStat = this.licenzeStats.find((stat) => stat.CodiceArticolo === codiciArticolo[0]);

      let stat2: LicenseStat = null;

      //SE LI HO SELEZIONATI ENTRAMBI, CONTROLLO. ALTRIMENTI VALE NULL
      if (codiciArticolo.length > 1) {
        stat2 = this.licenzeStats.find((stat) => stat.CodiceArticolo === codiciArticolo[1]);
      }

      //SE MAGGIORE DI 2, IL SECONDO E' SEMPRE VISUAL RETAIL

      //CASO IN CUI NON ESISTE NIENTE
      if (stat === undefined && stat2 === null) {
        this.alertService.danger("Licenza non acquistata");
        this.loading = false;
        return;
      }
      //CASO IN CUI ESISTE IL PRIMO MA NON IL SECONDO PERCHè NON SELEZIONATO
      else if (stat !== undefined && stat2 === null) {
        //POSSO GENERARE, HO SPAZIO
        if (stat.LicenzeGenerate + 1 <= stat.NumeroLicenze) {
          const selected: Selected = new Selected();
          selected.Visual = [visuals[0]];
          selected.Modules = this.ModuleToString();
          selected.UserID = this.auth.AuthUser.ID;
          selected.Username = this.auth.AuthUser.Username;
          selected.CodiceArticolo = codiciArticolo;

          const result: any = await this.licenseService.GenerateLicense(selected, this.TipoGenerazione);

          this.loading = false;
          //APRO IL MODAL DELLA LICENZA
          stat.LicenzeGenerate = stat.LicenzeGenerate + 1;
          this.licenseGenerated = (result as any).license;
          this.OpenLicenseModal(this.licenseTemplate);

          this.LoadLicenses.emit(true);

          this.LicenseGenerationCompleted = true;

        } else {
          this.alertService.danger("Numero limite di licenze generabili raggiunto");
          this.loading = false;
        }
      }
      //CASO IN CUI ESISTE IL PRIMO ED ANCHE IL SECONDO
      else if (stat !== undefined && stat2 !== undefined) {
        if (stat.LicenzeGenerate + 1 <= stat.NumeroLicenze && stat2.LicenzeGenerate + 1 <= stat2.NumeroLicenze) {
          const selected: Selected = new Selected();
          selected.Visual = visuals;
          selected.Modules = this.ModuleToString();
          selected.UserID = this.auth.AuthUser.ID;
          selected.Username = this.auth.AuthUser.Username;
          selected.CodiceArticolo = codiciArticolo;

        const result: any = await this.licenseService.GenerateLicense(selected, this.TipoGenerazione);
         this.loading = false;
         //APRO IL MODAL DELLA LICENZA
         stat.LicenzeGenerate = stat.LicenzeGenerate + 1;
         stat2.LicenzeGenerate = stat2.LicenzeGenerate + 1;
          this.licenseGenerated = result.license;
          this.OpenLicenseModal(this.licenseTemplate);

          this.LoadLicenses.emit(true);

          this.LicenseGenerationCompleted = true;

        } else {
          this.alertService.danger("Numero limite di licenze generabili raggiunto");
          this.loading = false;
        }
      } else {
        this.alertService.danger("Licenza non acquistata");
        this.loading = false;
        return;
      }
    }
    else {
      this.alertService.danger("Seleziona un tipo di acquisto per continuare")
      this.loading = false;
    }
  }

  public OpenLicenseModal(template: TemplateRef<any>) {
    this.generated = true;
    this.modalService.show(template);
  }




  public toggleDemo() {
    this.demo = !this.demo;
    //this.reset();
  }

  public closeLicenseModal() {
    this.modalService.hide();
    //this.reset();
  }

  public copyClickboard() {
    this.clipboard.copyFromContent(this.licenseGenerated);
    this.alertMsg = "Licenza copiata nella clipboard";
    this.alertOpen = true;
    setTimeout(() => {
        this.alertOpen = false;
    }, 5000)
  }

  public VisualToCodiceArticolo(string: string) {
    if (string === 'vfoodstart') return "SWG000003001";
    if (string === 'vfoodpro') return "SWG000003002";
    if (string === 'vfoodgold') return "SWG000003003";

    if (string === 'vshopstart') return "SWG000002001";
    if (string === 'vshoppro') return "SWG000002002";

    if (string === 'vkioskstart') return "SWG000009001";
    if (string === 'vkioskpro') return "SWG000009002";
    if (string === 'vkioskgold') return "SWG000009003";

    if (string === 'vselfstart') return "SWG000013001";
    if (string === 'vselfpro') return "SWG00013002";
    if (string === 'vselfgold') return "SWG000013003";

    if (string === 'vbeachstart') return "SWG000014001";
    if (string === 'vbeachpro') return "SWG000014002";
    if (string === 'vbeachgold') return "SWG000014003";


    if (string === 'vretailstart') return "SWG000001001";
    if (string === 'vretailpro') return "SWG000001002";
  }
}

export class Selected {
  public Modules = [];
  public Visual: string[] = [];

  public UserID: number;
  public Username: string;
  public CodiceArticolo: string[] = [];
  public UserIdAdmin: number;
}

