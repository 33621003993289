import { IsDev, VERSION } from 'src/app/global';
import { AuthService } from './../../Services/AuthService/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit {

  public version: string = VERSION;
  
  public year: number = new Date().getFullYear();
  constructor(public auth: AuthService) { }

  ngOnInit(): void {

  }

}
