<div class="modal-content">
    <div class="modal-body" style="max-height: calc(100% - 3.5rem);" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">
        <div class="modal-title" style="padding-top: 0;">
            <h4>Centro</h4>
        </div>
        <ng-container #Informazioni>
            <div class="row">
                <div class="col"><label class="form-header">Info</label></div>
            </div>
            <hr style="margin: 0;">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Ragione Sociale</label>
                        <input type="text" class="form-control" placeholder="Inserire ragione sociale *" required="required" [(ngModel)]="center.companyName"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Partita IVA</label>
                        <input type="text" class="form-control" placeholder="Inserire Partita IVA *" required="required" [(ngModel)]="center.vatNumber"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Codice Fiscale</label>
                        <input type="text" class="form-control" placeholder="Inserire cofice fiscale" [(ngModel)]="center.fiscalCode"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Codice SDI</label>
                        <input type="text" class="form-control" placeholder="Inserire codice SDI" [(ngModel)]="center.sdiCode"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>R.E.A</label>
                        <input type="text" class="form-control" placeholder="Inserire R.E.A" [(ngModel)]="center.rea"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <div class="form-group">
                        <label>Tipo di Attività</label>
                        <select class="form-control" [(ngModel)]="center.business" [compareWith]="compareByID">
                            <option *ngFor="let company of companyBusinesses" [ngValue]="company">{{company.description}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </ng-container>
        
        <!--
        <ng-container #Indirizzo>
            <div class="row">
                <div class="col"><label class="form-header">Indirizzo</label></div>
            </div>
            <hr>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Indirizzo</label>
                        <input type="text" class="form-control" placeholder="Inserire indirizzo" [(ngModel)]="center.address"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Civico</label>
                        <input type="text" class="form-control" placeholder="Inserire civico" [(ngModel)]="center.street"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Nazione</label>
                        <input type="text" class="form-control" placeholder="Inserire nazione" [(ngModel)]="center.street"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>CAP</label>
                        <input type="text" class="form-control" placeholder="Inserire CAP" />
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Città</label>
                        <input type="text" class="form-control"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Provincia</label>
                        <input type="text" class="form-control"/>
                    </div>
                </div>
            </div>
        </ng-container>
        -->
        
        <ng-container #Contatto>
            <div class="row">
                <div class="col"><label class="form-header">Contatto</label></div>
            </div>
            <hr style="margin: 0;">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Telefono</label>
                        <input type="text" class="form-control" [(ngModel)]="center.phone"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Cellulare</label>
                        <input type="text" class="form-control" [(ngModel)]="center.mobile"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Fax</label>
                        <input type="text" class="form-control"  [(ngModel)]="center.fax"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>E-mail</label>
                        <input type="text" class="form-control" [(ngModel)]="center.email"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Referente</label>
                        <input type="text" class="form-control"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Sito web</label>
                        <input type="text" class="form-control" [(ngModel)]="center.website"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <label>Indirizzo</label>
                        <input type="text" class="form-control" placeholder="Inserire indirizzo" [(ngModel)]="center.address"/>
                    </div>
                </div>
                <div class="col-1">
                    <div class="form-group">
                        <label>Civico</label>
                        <input type="text" class="form-control" placeholder="Inserire civico" [(ngModel)]="center.street"/>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container #Banca>
            <div class="row">
                <div class="col"><label class="form-header">Banca</label></div>
            </div>
            <hr style="margin: 0;">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Banca</label>
                        <input type="text" class="form-control" [(ngModel)]="center.bank"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Filiale Banca</label>
                        <input type="text" class="form-control" [(ngModel)]="center.branchBank"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>IBAN</label>
                        <input type="text" class="form-control" [(ngModel)]="center.iban"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>CIN</label>
                        <input type="text" class="form-control" [(ngModel)]="center.cin"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>ABI</label>
                        <input type="text" class="form-control" [(ngModel)]="center.abi"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>CAB</label>
                        <input type="text" class="form-control" [(ngModel)]="center.cab"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Note</label>
                        <textarea class="form-control" [(ngModel)]="center.note" rows="3"></textarea>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col">
                <div class="btn btn-danger" (click)="this.modalService.hide()">Esci</div>
            </div>
            <div class="col">
                <div class="btn btn-success" (click)="this.EditCenter()" [class.disabled]="!this.auth.AuthUser.SettingsPermessi.Licenze.Generazione && this.auth.AuthUser.Role === 0">Salva</div>
            </div>
        </div>
    </div>
</div>