<div>
  <div class="product-content-right">
    <div style="font-size: 13px; padding: 0.5rem">
      <a>Home&nbsp;/&nbsp;</a>
      <a>Shop&nbsp;/&nbsp;</a>
      <a>{{CodiceArticolo}}</a>
    </div>

    <div fxLayout="row" fxLayout.lt-lg="column" class="product-detail-inner">
      <div fxFlex="50%" fxFlex.lt-lg="100%">
        <div class="product-images" style="text-align: center;">
          <div class="product-main-img">
            <img [src]="this.ImmagineUrl" id="immagine-prodotto" class="product-img" (error)="ImageErrorHandler($event)">
          </div>
          <!--
          <div class="product-gallery">
            <img src="img/product-thumb-1.jpg" alt="">
            <img src="img/product-thumb-2.jpg" alt="">
            <img src="img/product-thumb-3.jpg" alt="">
          </div>
          -->
        </div>
      </div>
      <div fxFlex="50%" fxFlex.lt-lg="100%">
        <div class="product-inner">
          <hr>
          <h2 class="product-name ">{{NomeProdotto}}</h2>
          <div class="product-inner-price">
            <ins>Prezzo: {{Costo}}€</ins>
          </div>

          <!--
          <div class="product-inner-category">
            <p>Category: <a href="">SOFTWARE</a>
              <. Tags: <a href="">awesome</a>, <a href="">best</a>, <a href="">sale</a>, <a href="">shoes</a>. </p>
          </div>

          -->
          <div class="quantity buttons_added paddingtopandbottom"  *ngIf="ShopService.ShowAcquistoSingolo">
            <input type="button" class="btn btn-primary bottonivalue"   value="-" (click)="decrementare()">
            <input id="quantita-articolo-1" type="number" size="4" class="input-text qty text bottonivalue btn btn-primary" style="margin: 0px 6px; background-color: white;color: black" title="Qty" value="1" min="1" max="999" step="1">
            <input type="button" class="btn btn-primary bottonivalue"  value="+" (click)="incrementare()">
          </div>
          <div  class="btn btn-primary" (click)="ShopService.AggiuntaAlCarrelloProdottoSingolo(software,1)" *ngIf="ShopService.ShowAcquistoSingolo" style="margin-top:10px;width: 171px">
            Aggiungi al carrello
          </div>
          <div role="tabpanel" *ngIf="Descrizione.length > 0">
                <h2 class="product-description">Descrizione:</h2>
                <div>
                  {{this.Descrizione}}
                </div>



          <!--
              <div role="tabpanel" class="tab-pane fade" id="profile">
                <h2>Reviews</h2>
                <div class="submit-review">
                  <p><label for="name">Name</label> <input name="name" type="text"></p>
                  <p><label for="email">Email</label> <input name="email" type="email"></p>
                  <div class="rating-chooser">
                    <p>Your rating</p>

                    <div class="rating-wrap-post">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </div>
                  </div>
                  <p><label for="review">Your review</label> <textarea name="review" id="" cols="30" rows="10"></textarea></p>
                  <p><input type="submit" value="Submit"></p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="related-products-wrapper">
      <h2 class="related-products-title">Related Products</h2>
      <div class="related-products-carousel">
        <div class="single-product">
          <div class="product-f-image">
            <img src="img/product-1.jpg" alt="">
            <div class="product-hover">
              <a href="" class="add-to-cart-link"><i class="fa fa-shopping-cart"></i> Add to cart</a>
              <a href="" class="view-details-link"><i class="fa fa-link"></i> See details</a>
            </div>
          </div>

          <h2><a href="">Sony Smart TV - 2015</a></h2>

          <div class="product-carousel-price">
            <ins>$700.00</ins> <del>$100.00</del>
          </div>
        </div>
        <div class="single-product">
          <div class="product-f-image">
            <img src="img/product-2.jpg" alt="">
            <div class="product-hover">
              <a href="" class="add-to-cart-link"><i class="fa fa-shopping-cart"></i> Add to cart</a>
              <a href="" class="view-details-link"><i class="fa fa-link"></i> See details</a>
            </div>
          </div>

          <h2><a href="">Apple new mac book 2015 March :P</a></h2>
          <div class="product-carousel-price">
            <ins>$899.00</ins> <del>$999.00</del>
          </div>
        </div>
        <div class="single-product">
          <div class="product-f-image">
            <img src="img/product-3.jpg" alt="">
            <div class="product-hover">
              <a href="" class="add-to-cart-link"><i class="fa fa-shopping-cart"></i> Add to cart</a>
              <a href="" class="view-details-link"><i class="fa fa-link"></i> See details</a>
            </div>
          </div>

          <h2><a href="">Apple new i phone 6</a></h2>

          <div class="product-carousel-price">
            <ins>$400.00</ins> <del>$425.00</del>
          </div>
        </div>
        <div class="single-product">
          <div class="product-f-image">
            <img src="img/product-4.jpg" alt="">
            <div class="product-hover">
              <a href="" class="add-to-cart-link"><i class="fa fa-shopping-cart"></i> Add to cart</a>
              <a href="" class="view-details-link"><i class="fa fa-link"></i> See details</a>
            </div>
          </div>

          <h2><a href="">Sony playstation microsoft</a></h2>

          <div class="product-carousel-price">
            <ins>$200.00</ins> <del>$225.00</del>
          </div>
        </div>
        <div class="single-product">
          <div class="product-f-image">
            <img src="img/product-5.jpg" alt="">
            <div class="product-hover">
              <a href="" class="add-to-cart-link"><i class="fa fa-shopping-cart"></i> Add to cart</a>
              <a href="" class="view-details-link"><i class="fa fa-link"></i> See details</a>
            </div>
          </div>

          <h2><a href="">Sony Smart Air Condtion</a></h2>

          <div class="product-carousel-price">
            <ins>$1200.00</ins> <del>$1355.00</del>
          </div>
        </div>
        <div class="single-product">
          <div class="product-f-image">
            <img src="img/product-6.jpg" alt="">
            <div class="product-hover">
              <a href="" class="add-to-cart-link"><i class="fa fa-shopping-cart"></i> Add to cart</a>
              <a href="" class="view-details-link"><i class="fa fa-link"></i> See details</a>
            </div>
          </div>

          <h2><a href="">Samsung gallaxy note 4</a></h2>

          <div class="product-carousel-price">
            <ins>$400.00</ins>
          </div>
        </div>
      </div>
    </div>
  </div>-->
</div>
        </div>
      </div>
    </div>






    <div>


<!--
    <div class="a-section a-spacing-none ameyal-product-shoveler" id="WJTMMhPd2UlaJ7JvJroEPw">
      <div id="5fda90b2-afc9-4c11-be89-a0b4ec74ce55"
           class="a-section a-spacing-none shogun-widget card-lite product-shoveler aui-desktop fresh-shoveler">
        <div class="a-section as-title-block">
          <h2 class="as-title-block-left">
            <span class="a-color-base text-pre-carousel">Ti potrebbero interessare anche...</span>
          </h2>

        </div>
        <div class="a-section a-spacing-none feed-carousel first-carousel hovering">
          <div class="a-section feed-carousel-viewport">
            <ul class="a-unordered-list a-nostyle a-horizontal feed-carousel-shelf" role="list"
                style="margin-left: 0px; left: 0px;">
              <li data-sgproduct="{&quot;asin&quot;:&quot;B07R6JV6FP&quot;}" class="feed-carousel-card"
                  aria-posinset="1"
                  aria-hidden="false" role="listitem" aria-setsize="13"
                  style="display: inline-block; margin-right: 10px;"><span class="a-list-item"><a class="a-link-normal"
                                                                                                  href="/RestivOil-Restivoil-Fisiologico-400ml/dp/B07R6JV6FP/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="RestivOil Fisiologico Shampoo Nutriente per Capelli, Olio Fisiologico con Azione Idratante Protettiva e Riparatrice, per..."
                src="assets/img/bundle.png" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/71tidzCZHJL._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B07NPMFN5Y&quot;}" class="feed-carousel-card"
                  aria-setsize="13"
                  aria-posinset="2" aria-hidden="false" role="listitem"
                  style="display: inline-block; margin-right: 10px;"><span class="a-list-item"><a class="a-link-normal"
                                                                                                  href="/LIERAC-BODY-HYDRA-Latte-idro-rimpolpante/dp/B07NPMFN5Y/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="Lierac Body Hydra+ Latte Idratante e Rimpolpante Corpo con Acido Ialuronico, per Tutti i Tipi di Pelle, Formato da 400 ml"
                src="assets/img/bundle.png" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/51Mjgx7ItnL._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B08892BTHX&quot;}" class="feed-carousel-card"
                  aria-setsize="13"
                  aria-posinset="3" aria-hidden="false" role="listitem"
                  style="margin-right: 10px; display: inline-block;"><span class="a-list-item"><a class="a-link-normal"
                                                                                                  href="/N-Semplicit%C3%A0-Ecologico-Estratti-Biologici/dp/B08892BTHX/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="N.A.E. Shampoo Solido Semplicità, Shampoo Bar Ecologico e Vegano per Capelli Normali, con Estratti di Lavanda Biologici,..."
                src="assets/img/bundle.png" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/71zQYlGB5+L._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B08891HK3G&quot;}" class="feed-carousel-card"
                  aria-setsize="13"
                  style="margin-right: 10px; display: inline-block;" aria-posinset="4" aria-hidden="false"
                  role="listitem"><span class="a-list-item"><a class="a-link-normal"
                                                               href="/N-Equilibrio-Ecologico-Estratti-Biologici/dp/B08891HK3G/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="N.A.E. Shampoo Solido per Capelli Grassi Equilibrio, Shampoo Bar Ecologico e Vegano con Estratti di Salvia e Menta..."
                src="assets/img/bundle.png" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/71+FHrk+IBL._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B0758B9SVQ&quot;}" class="feed-carousel-card"
                  aria-setsize="13"
                  style="margin-right: 10px; display: inline-block;" aria-posinset="5" aria-hidden="false"
                  role="listitem"><span class="a-list-item"><a class="a-link-normal"
                                                               href="/TEMPO-Fazzoletti-box-pz-moschino/dp/B0758B9SVQ/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="TEMPO Fazzoletti box * 60 pz. &quot;moschino&quot; - fazzoletti di carta"
                src="assets/img/bundle.png" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/81fDWAZw9FL._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B00F3UE4OU&quot;}" class="feed-carousel-card"
                  aria-setsize="13"
                  style="margin-right: 10px; display: inline-block;" aria-posinset="6" aria-hidden="false"
                  role="listitem"><span class="a-list-item"><a class="a-link-normal"
                                                               href="/Weleda-Baby-Calendula-Crema-Viso/dp/B00F3UE4OU/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="Weleda Italia Baby Calendula Crema Viso"
                src="assets/img/bundle.png" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/71l3Udteo7L._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B00C3K1YJG&quot;}" class="feed-carousel-card"
                  aria-setsize="13"
                  style="margin-right: 10px; display: inline-block;" aria-posinset="7" aria-hidden="false"
                  role="listitem"><span class="a-list-item"><a class="a-link-normal"
                                                               href="/Unguento-CALENDULA-lenitivo-escoriazioni-superficiali/dp/B00C3K1YJG/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="Weleda Italia Calendula Unguento Lenitivo ed Emolliente - 25 gr."
                src="https://m.media-amazon.com/images/I/31RXQAPC2lL._AC_SY200_.jpg" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/71nGbwvOebL._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B01D3TK70M&quot;}" class="feed-carousel-card"
                  aria-posinset="8"
                  aria-hidden="false" role="listitem" aria-setsize="13"
                  style="margin-right: 10px; display: inline-block;"><span class="a-list-item"><a class="a-link-normal"
                                                                                                  href="/Ritocco-Perfetto-Colorazione-Ricrescita-Capelli/dp/B01D3TK70M/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="L'Oréal Paris Ritocco Perfetto, Spray Istantaneo Correttore per Radici e Capelli Bianchi, Colore: Bruno, 75 ml"
                src="https://m.media-amazon.com/images/I/41vdyir8nHL._AC_SY200_.jpg" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/61gtYfv610L._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B01NB975KS&quot;}" class="feed-carousel-card"
                  aria-setsize="13"
                  aria-posinset="9" aria-hidden="false" role="listitem"
                  style="margin-right: 10px; display: inline-block;"><span class="a-list-item"><a class="a-link-normal"
                                                                                                  href="/AIESI-Vaselina-barattolo-Dermatologico-tatuaggio/dp/B01NB975KS/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="AIESI® Vaselina bianca filante pura BP-USP barattolo da 1 kg per uso Medicale Dermatologico e Professionale # Made in Italy"
                src="https://m.media-amazon.com/images/I/41UCZUrrFoL._AC_SY200_.jpg" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/71N7Ry3F86L._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B00D3HVVLY&quot;}" class="feed-carousel-card"
                  aria-setsize="13"
                  aria-posinset="10" aria-hidden="false" role="listitem"
                  style="margin-right: 10px; display: inline-block;"><span class="a-list-item"><a class="a-link-normal"
                                                                                                  href="/Bionike-8029041211253-triderm-bagno-oleato-500-ml/dp/B00D3HVVLY/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="BIONIKE Triderm Bagnoleato - 500 ml."
                src="https://m.media-amazon.com/images/I/31S8Y530NzL._AC_SY200_.jpg" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/61WEYUC-0KL._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B07VKNY3XV&quot;}" class="feed-carousel-card"
                  aria-setsize="13"
                  aria-posinset="11" aria-hidden="false" role="listitem"
                  style="margin-right: 10px; display: inline-block;"><span class="a-list-item"><a class="a-link-normal"
                                                                                                  href="/Kite-Girls-Skate-Time-organica/dp/B07VKNY3XV/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="Kite Skate time bowband" src="https://m.media-amazon.com/images/I/31AlsKX3RnL._AC_SY200_.jpg"
                class="product-image" height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/718NQmaNrSL._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B00OZLT5YM&quot;}" class="feed-carousel-card"
                  aria-setsize="13"
                  aria-posinset="12" aria-hidden="false" role="listitem"
                  style="margin-right: 10px; display: inline-block;"><span class="a-list-item"><a class="a-link-normal"
                                                                                                  href="/Nivea-Essentials-Idratante-Uniformante-Confezione/dp/B00OZLT5YM/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="NIVEA Essentials BB Cream Super Idratante 24H Uniformante, Crema Giorno Viso SPF 15, Colore Dorato, 50 ml"
                src="https://m.media-amazon.com/images/I/41jQHQV6jPL._AC_SY200_.jpg" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/61lDNDi5t7L._AC_SY400_.jpg"></a></span></li>
              <li data-sgproduct="{&quot;asin&quot;:&quot;B00YCX76OE&quot;}" class="feed-carousel-card"
                  aria-setsize="13"
                  aria-posinset="13" aria-hidden="false" role="listitem"
                  style="margin-right: 10px; display: inline-block;"><span class="a-list-item"><a class="a-link-normal"
                                                                                                  href="/Kaloderma-Olio-Bagno-Doccia-Nutre/dp/B00YCX76OE/?_encoding=UTF8&amp;pd_rd_w=2xTEz&amp;pf_rd_p=ac269130-1d69-4fc0-acba-11205e00bdd8&amp;pf_rd_r=1KVYW1G7NK5QTR2570V0&amp;pd_rd_r=908e5619-6eef-435c-b124-919e53b20d73&amp;pd_rd_wg=36bJD&amp;ref_=pd_gw_unk"><img
                alt="Kaloderma Olio Bagno Doccia Nutre - 500 gr"
                src="https://m.media-amazon.com/images/I/41HpEJQmBcL._AC_SY200_.jpg" class="product-image"
                height="200px"
                data-a-hires="https://m.media-amazon.com/images/I/61DbIG38xvL._AC_SY400_.jpg"></a></span></li>
            </ul>
            <div class="spinner" aria-hidden="true" style="display: none;"><i class="gw-spinner"
                                                                              role="presentation"></i></div>
          </div>

          <span
          class="feed-scrollbar"><span class="feed-scrollbar-track">
            <span class="feed-scrollbar-thumb" style="width: 877.459px; left: 0px; display: inline; margin-left: 0px;"></span>
          </span>
          </span>
        </div>

      </div>
    </div>
  -->

  </div>
    </div>

  </div>
