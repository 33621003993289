import { CloudComponent } from './cloud/cloud.component';
import { UserPersonalComponent } from './user-personal/user-personal.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CensusComponent } from './census/census.component';
import { LicensesComponent } from './licenses/licenses.component';
import { ListsComponent } from './lists/lists.component';
import { LoginComponent } from './login/login.component';
import { ManualsComponent } from './manuals/manuals.component';
import { ShopComponent } from './shop/shop.component';
import { StatsComponent } from './stats/stats.component';
import { UpdatesComponent } from './updates/updates.component';
import { UsersComponent } from './users/users.component';
import { AdminComponent } from './admin/admin.component';
import {DettaglioProdottoComponent} from './shop/dettaglio-prodotto/dettaglio-prodotto.component';
import {BuysinglelicenseComponent} from './shop/buysinglelicense/buysinglelicense.component';
import {BuypackagelicenseComponent} from './shop/buypackagelicense/buypackagelicense.component';
import {CartComponent} from './shop/cart/cart.component';
import {ChoosepackageComponent} from './shop/choosepackage/choosepackage.component';
import {TipologialicenzaComponent} from './shop/tipologialicenza/tipologialicenza.component';
import {CheckoutComponent} from './shop/checkout/checkout.component';
import { GroupManagerComponent } from './cloud/group-manager/group-manager.component';

const routes: Routes = [

  {path: "", component: StatsComponent},
  {path: "userinfo", component: UserPersonalComponent},
      {path: "shop", component: ShopComponent, children:[
          {path: "", component:TipologialicenzaComponent},
          {path: "tipologia-licenza", component:TipologialicenzaComponent},
          {path: "acquisto-licenza-singola", component:BuysinglelicenseComponent},
          {path: "acquisto-licenza-singola/:id", component:DettaglioProdottoComponent},
          {path: "selezione-quantita-pacchetti", component:ChoosepackageComponent},
          {path: "acquisto-licenza-multipla", component:BuypackagelicenseComponent},
          {path: "acquisto-licenza-multipla/:id", component:DettaglioProdottoComponent},
          {path: "cart", component:CartComponent},
          {path: "checkout", component:CheckoutComponent},
        ]},
    {path: "census", component: CensusComponent},
    {path: "users", component: UsersComponent},
    {path: "updates", component: UpdatesComponent},
    {path: "lists", component: ListsComponent},
    {path: "licenses", component: LicensesComponent},
    {path: "cloud", component: GroupManagerComponent},
    {path: "manuals", component: ManualsComponent},
    {path: "", component: StatsComponent},
    {path: "admin", component: AdminComponent},
    {
      path: 'cloud',
      component: CloudComponent,
      children: [
        {
          path: '',
          loadChildren: () => import('./cloud/cloud.module').then(m => m.CloudModule)
        }
      ]
    },


    /*
  {path: "home", children: [
    {path: "userinfo", component: UserPersonalComponent},
      {path: "shop", component: ShopComponent, children:[
          {path: "", component:TipologialicenzaComponent},
          {path: "tipologia-licenza", component:TipologialicenzaComponent},
          {path: "acquisto-licenza-singola", component:BuysinglelicenseComponent},
          {path: "acquisto-licenza-singola/:id", component:DettaglioProdottoComponent},
          {path: "selezione-quantita-pacchetti", component:ChoosepackageComponent},
          {path: "acquisto-licenza-multipla", component:BuypackagelicenseComponent},
          {path: "acquisto-licenza-multipla/:id", component:DettaglioProdottoComponent},
          {path: "cart", component:CartComponent},
          {path: "checkout", component:CheckoutComponent},
        ]},
    {path: "census", component: CensusComponent},
    {path: "users", component: UsersComponent},
    {path: "updates", component: UpdatesComponent},
    {path: "lists", component: ListsComponent},
    {path: "licenses", component: LicensesComponent},
    {path: "cloud", component: GroupManagerComponent},
    {path: "manuals", component: ManualsComponent},
    {path: "", component: StatsComponent},
    {path: "admin", component: AdminComponent},
    {
      path: 'cloud',
      component: CloudComponent,
      children: [
        {
          path: '',
          loadChildren: './group-manager/cloud.module.ts#CloudModule',
        }
      ]
    },
  ], canActivate: [AuthGuardGuard]},
  */
  {path: "**", redirectTo:""},
  {path: "login", component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
