<!-- Sidebar normale-->
<div class="sidebar" id="sidebartransition" data-color="blue">
    <div class="sidebar-wrapper">
        <div class="logo" *ngIf="!this.auth.IsCashServizi">
            <img src="assets/img/logosvg.svg" draggable="false" class="img-fluid imgLogo" />
        </div>
        <div class="logo" *ngIf="this.auth.IsCashServizi">
            <img src="assets/img/cashmatic.png" draggable="false" class="img-fluid imgLogo zoom"/>
        </div>
        <ul class="nav" id="menu">
            <li class="nav-item" id="userpersonal" *ngIf="!this.auth.IsCashServizi">
                <a role="button" class="nav-link"
                    (click)="auth.Ready === true ? this.RedirectTo('userinfo') : this.alert.danger('Attendi il caricamento di GAB Servizi')"
                    (click)="HideMobileSidebar()">
                    <fa-icon [icon]="faUser" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Profilo</p>
                </a>
            </li>
            <li class="nav-item" id="home">
                <a role="button" class="nav-link"
                    (click)="auth.Ready === true ? this.RedirectTo('') : this.alert.danger('Attendi il caricamento di GAB Servizi')"
                    (click)="HideMobileSidebar()">
                    <fa-icon [icon]="faHome" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Home</p>
                </a>
            </li>
            <li class="nav-item" id="licenses"
                *ngIf="this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role === 1 || this.auth.AuthUser.Role === 4 ">
                <a role="button" class="nav-link" (click)="HideMobileSidebar()"
                    (click)="auth.Ready === true ? this.RedirectTo('licenses') : this.alert.danger('Attendi il caricamento della pagina')">
                    <fa-icon [icon]="faKey" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Licenze</p>
                </a>
            </li>
            <!-- *ngIf="isCloudEnabled === true && (this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role === 1 || this.auth.AuthUser.Role === 4) && !this.auth.IsCashServizi"> -->
            <li class="nav-item" id="cloud"
                *ngIf="(this.auth.AuthUser.Role === 0) && !this.auth.IsCashServizi">
                <a role="button" class="nav-link" (click)="HideMobileSidebar()"
                    (click)="auth.Ready === true ? this.RedirectTo('cloud') : this.alert.danger('Attendi il caricamento della pagina')">
                    <fa-icon [icon]="faCloud" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Licenze Cloud</p>
                </a>
            </li>
            <li class="nav-item" id="shop" *ngIf="this.auth.AuthUser.Role !== 3 && !this.auth.IsCashServizi">
                <a role="button" class="nav-link" (click)="HideMobileSidebar()" *ngIf="this.auth.AuthUser.Role !== 2"
                    (click)="auth.Ready === true ? this.RedirectTo('shop') : this.alert.danger('Attendi il caricamento della pagina')">
                    <fa-icon [icon]="faShoppingCart" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Shop</p>
                </a>
                <a role="button" class="nav-link" (click)="HideMobileSidebar()" *ngIf="this.auth.AuthUser.Role === 2"
                    (click)="auth.Ready === true ? this.RedirectTo('shop/acquisto-licenza-singola') : this.alert.danger('Attendi il caricamento della pagina')">
                    <fa-icon [icon]="faShoppingCart" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Shop</p>
                </a>
            </li>
            <li class="nav-item" id="manuals" *ngIf="this.auth.AuthUser.Role !== 2">
                <a role="button" class="nav-link" (click)="HideMobileSidebar()"
                    (click)="auth.Ready === true ? this.RedirectTo('manuals') : this.alert.danger('Attendi il caricamento della pagina')">
                    <fa-icon [icon]="faBook" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Manuali</p>
                </a>
            </li>
            <li class="nav-item" id="lists" *ngIf="this.auth.AuthUser.Role !== 2 && !this.auth.IsCashServizi">
                <a role="button" class="nav-link" (click)="HideMobileSidebar()"
                    (click)="auth.Ready === true ? this.RedirectTo('lists') : this.alert.danger('Attendi il caricamento della pagina')">
                    <fa-icon [icon]="faClipboardList" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Listini</p>
                </a>
            </li>
            <li class="nav-item" id="updates" *ngIf="this.auth.AuthUser.Role !== 2">
                <a role="button" class="nav-link" (click)="HideMobileSidebar()"
                    (click)="auth.Ready === true ? this.RedirectTo('updates') : this.alert.danger('Attendi il caricamento della pagina')">
                    <fa-icon [icon]="faSync" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Aggiornamenti</p>
                </a>
            </li>

            <li class="nav-item" id="users"
                *ngIf="this.auth.AuthUser.Role === 0 || (this.auth.AuthUser.Role === 1 && !this.auth.IsCashServizi) || this.auth.AuthUser.Role === 4">
                <a role="button" class="nav-link " (click)="HideMobileSidebar()"
                    (click)="auth.Ready === true ? this.RedirectTo('users') : this.alert.danger('Attendi il caricamento della pagina')">
                    <fa-icon [icon]="faUsers" size="2x"></fa-icon>
                  <p style="margin-left: 8%;">Utenti</p>
                </a>
            </li>

            <li class="nav-item" id="census"
                *ngIf="this.auth.AuthUser.Role === 0 || this.auth.AuthUser.Role === 1 || this.auth.AuthUser.Role === 4">
                <a role="button" class="nav-link" (click)="HideMobileSidebar()"
                    (click)="auth.Ready === true ? this.RedirectTo('census') : this.alert.danger('Attendi il caricamento della pagina')">
                    <fa-icon [icon]="faDesktop" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Censimenti</p>
                </a>
            </li>

            <li class="nav-item" id="admin"
                *ngIf="this.auth.AuthUser.Role === 0 && !this.auth.IsCashServizi">
                <a role="button" class="nav-link" (click)="HideMobileSidebar()"
                    (click)="auth.Ready === true ? this.RedirectTo('admin') : this.alert.danger('Attendi il caricamento della pagina')">
                    <fa-icon [icon]="faUserCog" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Admin</p>
                </a>
            </li>

            <li class="nav-item" (click)="auth.Ready === true ? Logout() : ''">
                <a class="nav-link" id="ExitBtn" role="button">
                    <fa-icon [icon]="faSignOut" size="2x"></fa-icon>
                    <p style="margin-left: 8%;">Esci</p>
                </a>
            </li>
            <li class="nav-item version">
                <a role="button" class="nav-link " 
                    (click)="HideMobileSidebar()"
                    (click)="OpenChangelog(changelog)">
                    <fa-icon [icon]="faInfo" size="2x"></fa-icon>
                    
                </a>
            </li>
        </ul>
    </div>
</div>
<!-- Sidebar hamburger-->
<div class="content" style="height:70px;z-index: 3" fxHide fxShow.lt-lg>
    <nav>
        <input type="checkbox" id="hamburger1" (click)="ShowMobileSidebar()" />
        <label for="hamburger1"></label>
        <div style="float: right">
        <div  *ngIf="this.headerName==='Shop'" style="padding-right: 25px;" (click)="this.shopService.ApriCarrello()">
          <span style="color: white">{{this.shopService.GetCarrelloTotal().toFixed(2)}}€&nbsp;-&nbsp;</span>
          <div class="item">
            <a>
              <span class="notify-badge">{{this.shopService.GetCarrelloSize()}}</span>
            <img src="assets/img/shopping-cart.png" style="height: 30px;width: 30px;filter: brightness(0) invert(1);">
            </a>
          </div>

        </div>

      </div>
    </nav>
</div>


<ng-template #changelog>
    <div class="modal-body table-responsive modal-lg" >
        <changelog></changelog>
    </div>
</ng-template>