import { IsDev } from './../global';


import { HttpClient, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import Item from '../Models/Item';
import { AuthService } from '../Services/AuthService/auth.service';
import { FileService } from '../Services/FileService/file.service';

import {faDownload, faTrash, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss']
})
export class ListsComponent implements OnInit {

  public Lists: Item[] = [];
  
  public SoftCodes: string[2][] = [];
  public ready: boolean = false;

  //ALERT
  public alertOpen: boolean = false;
  public alertOpenSuccess: boolean = false;
  public alertMsg: string = "";

  //ICONS
  public faTrash: IconDefinition = faTrash;
  public faDownload: IconDefinition = faDownload;

  //ELIMINA
  public modalRef: BsModalRef;
  public ListaDaEliminare: Item;

  constructor(private http: HttpClient, public auth: AuthService, private router: Router, public FileService: FileService, private changeDetectorRef: ChangeDetectorRef, public modalService: BsModalService) { 
    
  }
  
  private async Init() {
    this.auth.Ready = false;
    //AGGIORNO LA SIDEBAR
    const sidebarElements = document.getElementsByTagName("li");
    for (let i = 0; i < sidebarElements.length; ++i) {
      if (sidebarElements[i].id !== 'lists') {
        sidebarElements[i].classList.remove('active');
      } else {
        sidebarElements[i].classList.add('active');
      }
    }
    

    await this.LoadLists();

            
        

        this.auth.Ready = true;

        if (this.auth.IsValidationDone === false) {
          await this.LocalValidation();
        }

  }

  public fileToUpload: File = null;

  private async LocalValidation() {
    if (this.auth.IsLoggedIn()) {
      const expirationDate: Date = new Date(localStorage.getItem('expiration'));
      
      if (new Date().getTime() > expirationDate.getTime()) {
        return this.auth.Logout();
      }
      const valid: boolean = await this.auth.ValidateLogin();
      if(valid) {
        this.auth.IsLogin = false;
        this.auth.IsValidationDone = true;

      } else {
        this.auth.IsLogin = true;
        this.auth.Ready = false;
        this.auth.Logout();
      }
    } else {
      this.router.navigateByUrl('login');
    }
  }

  async ngOnInit() {
    await this.Init();
  }

  public async LoadLists() {
    this.Lists = await this.FileService.GetLists();

    return;
    
  }

  private GenerateList(result: any): Item {
    return new Item(result.product, result.version, "", result.downloadFileName);
  }

  public FileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    //this.FileService.FileUpload(this.fileToUpload);
  }

  
  public async UploadList() {
    const prodotto: string = (document.getElementById('prodotto') as HTMLInputElement).value;
    const version: string = (document.getElementById('versione') as HTMLInputElement).value;

    if (prodotto === "" || version === "" || this.fileToUpload === null) {
      this.alertMsg = "Inserire tutti i campi per continuare";
      this.alertOpen = true;
      setTimeout(() => {
        this.alertOpen = false;
      }, 5000)
    } else {
      await this.FileService.ListUpload(this.fileToUpload, prodotto, version);
      this.alertMsg = "Caricamento completato";
      this.alertOpenSuccess = true;
      setTimeout(() => {
        this.alertOpenSuccess = false;
      }, 5000)
      this.Lists.push(this.GenerateList({product: prodotto, version: version, downloadFileName: this.fileToUpload.name}));
    }
  }


  public OpenModalDelete(template: TemplateRef<any>, list: Item) {
    this.ListaDaEliminare = list;
    this.modalRef = this.modalService.show(template);
  }

  
  //(click)="this.DeleteList(list.product, list.downloadFileName)"
  public async DeleteList(product: string, fileName: string) {

    const result: boolean = await this.FileService.DeleteList(product, fileName);
    
    //SE SUCCESS, ELIMINO LA RIGA
    if (result) {
      for (let i = 0; i < this.Lists.length; ++i) {
        if (this.Lists[i].downloadFileName === fileName && this.Lists[i].product === product) {
          this.Lists.splice(i, 1);  //Elimino la riga
          this.changeDetectorRef.detectChanges();
          this.modalService.hide();
          return;
        }
      }
    }
    
  }
  
  public fileName(i: number, ) {
    return i;
  }

  public Filter() {
    const input: string = (document.getElementById("search") as HTMLInputElement).value;
    
    const table: HTMLTableElement = document.getElementById("table") as HTMLTableElement;
    const tr: HTMLCollectionOf<HTMLTableRowElement> = table.getElementsByTagName("tr");

    for (let i = 0; i < tr.length; i++) {
      const td: HTMLTableDataCellElement = tr[i].getElementsByTagName("td")[0];
      
      if (td) {
          const txtValue: string = td.innerHTML;

          if (txtValue.toUpperCase().includes(input.toUpperCase())) {
              tr[i].style.display = "";
          } else {
              tr[i].style.display = "none";
          }
      }
    }
  }




}
