import { AuthService } from './../AuthService/auth.service';
import { IsDev } from '../../global';

import { AlertService } from 'ngx-alerts';
import { HttpClient, HttpEvent, HttpEventType, HttpParams, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Update from 'src/app/Models/Update';
import Item from 'src/app/Models/Item';

@Injectable({
  providedIn: 'root'
})
export class FileService {


  public Progress: number = 0;

  constructor(private http: HttpClient, private alertService: AlertService, private auth: AuthService) {
    
   }

  public async GetManuals() {
    const result: any = await this.http.get('/api/manuals', {}).toPromise();
    
    const list: Item[] = [];
    
    for (let i = 0; i < result.length; ++i) {

      const item: Item = this.GenerateList(result[i]);

      list.push(item)
    }

    return list;

  }
  public async ManualUpload(fileToUpload: File, productName: string, version: string, softcode: string) {

    // const id = await this.auth.GetIdByUsername();

    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    formData.append("productName", productName);
    formData.append("version", version);
    formData.append("softCode", softcode);

    const result: any = await this.http.post('/api/manuals/upload', formData, {}).toPromise();

    return result.response === true;
  }

  public async ListUpload(fileToUpload: File, productName: string, version: string) {
    
    // const id = await this.auth.GetIdByUsername();
    
    const formData: FormData = new FormData();
    // formData.append("id", String(id));
    formData.append("file", fileToUpload, fileToUpload.name);
    formData.append("productName", productName);
    formData.append("version", version);

    const result: any = await this.http.post('/api/lists/upload', formData, {}).toPromise();

    return result.response === true;
  }

  public async UpdateUpload(fileToUpload: File, softwareName: string, version: string, softcode: string, releaseDate: string) {
    
    // const id = await this.auth.GetIdByUsername();
    
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    formData.append("softwareName", softwareName);
    formData.append("softCode", softcode);
    formData.append("version", version);
    formData.append("releaseDate", releaseDate)

    const result: any = await this.http.post('/api/updates/upload', formData, {}).toPromise();

    return (result.response === true) ? true: false;
  }

  public async GetUpdates(): Promise<Update[]> {

    const params: HttpParams = new HttpParams()
    .set("id", String(this.auth.AuthUser.ID));

    const result: any = await this.http.get('/api/updates', {params}).toPromise();

    const updates: Update[] = [];
    
    for (let i = 0; i < result.length; ++i) {

      const update: Update = this.GenerateUpdate(result[i]);

      updates.push(update)
    }

    return updates;

  }

  private GenerateUpdate(result: any): Update {
    return new Update(result.softwareName, result.version, result.softCode, result.downloadFileName, result.releaseDate);
  }

  public async InitSoftcodes() {
    return await this.http.get('/api/softcodes').toPromise();
  }

  public async DeleteManual(product: string, softCode: string, fileName: string) {

    // const id = await this.auth.GetIdByUsername();

    const params: HttpParams = new HttpParams()
    .set("product", product)
    .set("softCode", softCode)
    .set("fileName", encodeURIComponent(fileName));
    
    await this.http.delete('/api/manuals/delete', {params}).toPromise();

    return true;
  }

  public async GetLists() {
    const result: any = await this.http.get('/api/lists', {}).toPromise();
    
    const list: Item[] = [];
    
    for (let i = 0; i < result.length; ++i) {

      const item: Item = this.GenerateList(result[i]);

      list.push(item)
    }

    return list;
  }
  private GenerateList(result: any): Item {
    return new Item(result.product, result.version, "", result.downloadFileName);
  }

  public async DeleteList(product: string, fileName: string) {

    const id = await this.auth.GetIdByUsername();

    const params: HttpParams = new HttpParams()
    .set("product", product)
    .set("fileName", encodeURI(fileName));


    await this.http.delete('/api/lists/delete', {params}).toPromise();


    return true;
  }

  public async DeleteUpdate(softwareName: string, fileName: string, version: string, date: string) {

    // const id = await this.auth.GetIdByUsername();

    const params: HttpParams = new HttpParams()
    .set("softwareName", softwareName)
    .set("fileName", encodeURIComponent(fileName))
    .set("version", version)
    .set("date", date)

    await this.http.delete('/api/updates/delete', {params}).toPromise();

    return true;
  }

  public async DownloadManual(fileName: string) {
    const params: HttpParams = new HttpParams()
    .set("fileName", encodeURIComponent(fileName))

    const result: Blob =  await this.http.get('/api/manuals/download', {params, responseType: 'blob'}).toPromise();  //File

    //GENERATION FILE DOWNLOAD
    const a: HTMLAnchorElement = document.createElement('a');
    const fileURL: string = URL.createObjectURL(result);
    
    a.download = fileName;
    a.href = fileURL;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public async DownloadList(fileName: string) {
    const params: HttpParams = new HttpParams()
    .set("fileName", encodeURIComponent(fileName));

    const result: Blob =  await this.http.get('/api/lists/download', {params, responseType: 'blob'}).toPromise();  //File

    //GENERATION FILE DOWNLOAD
    const a: HTMLAnchorElement = document.createElement('a');
    const fileURL: string = URL.createObjectURL(result);
    
    a.download = fileName;
    a.href = fileURL;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public async DownloadUpdate(update: Update) {

    this.Progress = 0;

    // const id = await this.auth.GetIdByUsername();
    
    const params: HttpParams = new HttpParams()
    .set("fileName", encodeURIComponent(update.downloadFileName));

    this.alertService.info("Download in corso")
    update.downloading = true;

    this.http.get('/api/updates/download', 
    {params, responseType: 'blob', reportProgress: true, observe: 'events',}).subscribe(result => {

      if (result.type === HttpEventType.DownloadProgress) {
        this.Progress = Math.round((100 * result.loaded) / result.total);
      }
      if (result.type === HttpEventType.Response) {
        update.downloading = false;
        this.alertService.success("Download completato")

        //GENERATION FILE DOWNLOAD
        const a: HTMLAnchorElement = document.createElement('a');
        const fileURL: string = URL.createObjectURL(result.body);
    
        a.download = update.downloadFileName;
        a.href = fileURL;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  }
}