import SoftwareListino from './SoftwareListino';


//CLASSE PER GESTIRE LE STATISTICHE DELLE LICENZE
export class LicenseStat {
    public NumeroLicenze: number = 0;
    public LicenzeGenerate: number = 0;
    public CodiceArticolo: string;
    public Data: Date;
    public Descrizione: string = '';

    public GetDescrizione(listino: SoftwareListino[]) {
      for (let i = 0; i < listino.length; ++i) {
        if (this.CodiceArticolo === listino[i].CodiceArticolo) {
          this.Descrizione = listino[i].Descrizione;
          return;
        }
      }
    }
}
  
export class LicenseInfo {
    public ActivationDate: string;
    public CreationDate: string;
    public ExpirationDate: string;
    public ActivationDateLimit: string;

    public License: License;
    public Descrizione: string;
    public Stato: number;
    public Creatore: string;
    public CreatoreID?: number;
    public Segnalatore: string;
    public LicenseClient: LicenseClient[] = [];
    public ShowClient: boolean = false;
  }

export class License {
    public License: string;
    public Modules: string;
  
    constructor(license: string, modules: string) {
      this.License = license;
      this.Modules = modules;
    }
}

export class LicenseClient {
  public License: License;
  public CreationDate: string;
  public ActivationDate?: string;
  public ExpirationDate?: string;
  public Creatore: string;
  public Descrizione: string;
  public Master: string;
  public Stato: number;
  constructor(license, creationDate: string, stato: number) {
    this.License = new License(license.license, license.module);
    this.CreationDate = creationDate;
    this.Stato = stato;
  }
}