import { CensisService } from './../Services/CensisService/censis.service';
import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import Censimento from '../Models/Censimento';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {faTelegramPlane} from '@fortawesome/free-brands-svg-icons';
import { faTrash, faAngleRight, faAngleDown, faPen, faCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {IsDev} from '../global';
import { AlertService } from 'ngx-alerts';
import {HttpClient, HttpParams} from '@angular/common/http';
import CensimentoAggiorna from '../Models/CensimentoAggiorna';
import {AuthService} from '../Services/AuthService/auth.service';
@Component({
  selector: 'app-censur-card',
  templateUrl: './censur-card.component.html',
  styleUrls: ['./censur-card.component.scss']
})
export class CensurCardComponent implements OnInit {
  @Input() censimento;
  @Input() imageSrc;
  @Input() RAGSOC;
  @Input() DESMAG;
  @Input() RIVENDITORE;
  @Input() NMAG;
  @Input() NCASSA;
  @Input() VU;
  @Input() LASTDATASTRING;
  @Input() CLIENTE;
  @Input() PIVA;
  @Input() LOC;
  @Input() VP;
  @Input() VB;
  @Input() DR;
  @Input() MATRICOLA;
  @Input() AZZER;
  @Input() GIART;
  @Input() AVVIORT;
  @Input() SERVB2B;
  @Input() IDTRAB2B;
  @Input() CPU;
  @Input() MAC;
  @Input() VIA;
  @Input() RT_FW;
  @Input() RT_MODEL;
  @Input() RE_MATR;
  @Input() nCensimento;
  @Input() LastVP;
  @Input() LastDriver;
  @Input() LOTTERIA;
  @Input() XML7;

  public Status: CensimentoAggiorna[] = [];

  public modalRef: BsModalRef;
  public SelectedCensimento: Censimento;
  public CensimentoDaEliminare: Censimento;


  // ICONS
  public faTrash: IconDefinition = faTrash;
  public faAngleDown: IconDefinition = faAngleDown;
  public faAngleRight: IconDefinition = faAngleRight;
  public faPen: IconDefinition = faTelegramPlane;
  public faCircle: IconDefinition = faCircle;

  constructor( public modalService: BsModalService, public alert: AlertService, private http: HttpClient, public auth: AuthService, public censisService: CensisService) { }

  ngOnInit(): void {

  }
  public OpenModalUpdatePos(template: TemplateRef<any>, censimento: Censimento): void {
    this.SelectedCensimento = censimento;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  public OpenModalUpdateDriver(template: TemplateRef<any>, censimento: Censimento): void {
    this.SelectedCensimento = censimento;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }
  public async UpdateVisualPos() {
    const result: any = await this.censisService.UpdateVisualPos(this.SelectedCensimento);
    if (result !== 'ok') {
      this.alert.danger('Errore!');
    } else {
      this.alert.success('Software in coda per l\'aggiornamento');
      await this.LoadStatus();
    }
  }

  public async UpdateDriver() {
    const result: any = await this.censisService.UpdateDriver(this.SelectedCensimento);

    if (result !== 'ok') {
      this.alert.danger('Errore!');
    } else {
      this.alert.success('Software in coda per l\'aggiornamento');
      await this.LoadStatus();
    }
  }
  public async LoadStatus(): Promise<void> {
    
    this.Status = await this.censisService.GetStatus();
    
    return;
  }

}
