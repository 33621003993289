import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { start } from 'repl';
import { Log, LogRow } from '../../Models/Log';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private http: HttpClient) { }

  public async GetLogs(startDate: Date, endDate: Date): Promise<Log[]> {


    //CREO UNA STRINGA DATA IN FORMATO YYYY-MM-DD, SOTTRAENDO ALLA DATA L'OFFSET UTC
    // getTimezoneOffset() * 60000 --> Minuti per Millisecondi

    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const startDateString = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
    const endDateString = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
    

    const params: HttpParams = new HttpParams()
    .set("startDate", startDateString)
    .set("endDate", endDateString);
    
    const logs: Log[] = await this.http.get<Log[]>('/api/admin/log', {params}).toPromise();

    return logs.map(l => {

      const log: Log = new Log();
      log.Date = (l as any).date;
      log.Logs = (l as any).logs.map(lr=> {
        const logRow: LogRow = new LogRow();

        const datetime: string[] = ((lr as any).date as string).split('T');
        const date: string[] = datetime[0].split('-');
        const time: string[] = datetime[1].split(':');
        
        logRow.Date = `${date[2]}/${date[1]}/${date[0]}`;
        logRow.Time = `${time[0]}:${time[1]}`;
        logRow.IP = (lr as any).ip;
        logRow.OP = (lr as any).op;
        logRow.User = (lr as any).user;

        logRow.License = (lr as any).license;

        return logRow;
      })
      
      return log;

    });

  }

}
