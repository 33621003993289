import { IsDev } from '../../global';

import {Component, Input, OnInit, TemplateRef} from '@angular/core';

import { AuthService } from '../../Services/AuthService/auth.service';
import { ShopService } from '../../Services/ShopService/shop.service';
import Acquisto from '../../Models/Acquisto';

import {IconDefinition, faCartArrowDown, faShoppingCart, faTrash } from '@fortawesome/free-solid-svg-icons';
import SoftwareListino from '../../Models/SoftwareListino';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AlertService } from 'ngx-alerts';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { HttpClient } from '@angular/common/http';
import { Output, EventEmitter } from '@angular/core';
import {UserStoreService} from '../../Services/Admin/user-store.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-shop-acquisto',
  templateUrl: './shop-acquisto.component.html',
  styleUrls: ['./shop-acquisto.component.scss']
})
export class ShopAcquistoComponent implements OnInit {

  //ICONS
  public faTrash: IconDefinition = faTrash;




  constructor(public ShopService: ShopService, public UserStoreService:UserStoreService, public auth: AuthService, public modalService: BsModalService, private alertService: AlertService, private http: HttpClient, private router:Router) {

  }

  ngOnInit(): void {
  }







}
