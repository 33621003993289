import { Admin } from './SuperAdmin';
import { Account } from "./Account";
import { Center } from "./Center";

export class Group {
    id?: number;
    uuid?: string;
    name: string;
    description: string;
    active: boolean;
    startDate: Date;
    expirationDate: Date;
    expired: boolean;
    features?: string[];
    createdAt?: Date;
    updateAt?: Date;
    confirmed: boolean;
    deleted: boolean;
    qrCode: string;
    superAdmins: Admin[];
    centers: Center[];
    licenseConfigurationId: number;
    public billingType: BillingType;


    public constructor() {
      this.superAdmins = [];
      this.billingType = BillingType.YEAR
    }

  }

  export enum BillingType
  {
    MONTH, QUARTER, YEAR
  }