<div class="content fade-in">
    <div class="container-fluid">

        <div class="row">

            <div [ngClass]="!this.auth.IsCashServizi ? 'col-md-6 col-xl-3 mb-3' : 'col-md-6 col-xl-4 mb-4'">
                <div class="card text-white bg-primary mb-3" style="height: 100%;background-color: #4285f4!important;">
                    <div class="card-body">
                        <fa-icon class="center" [icon]="faMoney" size="3x"></fa-icon>
                        <div *ngIf="this.Ready">

                            <div class="row center" style="text-align: center;">
                                <h3 class="white-text" *ngIf="this.Ready"><strong>{{!this.auth.IsCashServizi ? 'VISUALPOS INSTALLATI' : 'APPagato INSTALLATI'}}</strong></h3>
                            </div>
                            <div class="row center">
                                <h3 class="white-text" *ngIf="this.Ready"><strong>{{VPCount}}</strong></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="!this.auth.IsCashServizi ? 'col-md-6 col-xl-3 mb-3' : 'col-md-6 col-xl-4 mb-4'">
                <div class="card text-white bg-warning mb-3" style="height: 100%;">
                    <div class="card-body" style="max-height: 70%;">
                        <fa-icon class="center" [icon]="faSignal" size="3x"></fa-icon>
                        <div *ngIf="this.Ready">
                            <div class="row center" style="text-align: center;">
                                <h3 class="white-text" *ngIf="this.Ready"><strong>{{!this.auth.IsCashServizi ? 'VISUALPOS UTILIZZATI' : 'APPagato UTILIZZATI'}}</strong></h3>
                            </div>
                            <div class="row center">
                                <h3 class="white-text" *ngIf="this.Ready"><strong>{{VPUsed}}</strong></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="!this.auth.IsCashServizi ? 'col-md-6 col-xl-3 mb-3' : 'col-md-6 col-xl-4 mb-4'" *ngIf="!this.auth.IsCashServizi">
                <div class="card text-white bg-success mb-3" style="height: 100%;">
                    <div class="card-body">
                        <fa-icon class="center" [icon]="faBusiness" size="3x"></fa-icon>
                        <div *ngIf="this.Ready" style="text-align: center;">
                            <div class="row center">
                                <h3 class="white-text" *ngIf="this.Ready"><strong>B2B INSTALLATI</strong></h3>
                            </div>
                            <div class="row center">
                                <h3 class="white-text" *ngIf="this.Ready"><strong>{{B2BCount}}</strong></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [ngClass]="!this.auth.IsCashServizi ? 'col-md-6 col-xl-3 mb-3' : 'col-md-6 col-xl-4 mb-4'" (click)="(ExpiringLicensesN > 0) ? OpenUpdateModal(expiring) : ''">
                <div class="card text-white bg-danger mb-3" style="height: 100%;">
                    <div class="card-body" style="cursor: pointer;">
                        <fa-icon class="center" [icon]="faFlask" size="3x"></fa-icon>
                        <div *ngIf="this.Ready">
                            <div class="row center">
                                <h3 class="white-text" *ngIf="this.Ready" style="text-align: center;"><strong>LICENZE IN SCADENZA</strong>
                                </h3>
                            </div>
                            <div class="row center">
                                <h3 class="white-text" *ngIf="this.Ready"><strong>{{ExpiringLicenses}}</strong></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div fxLayout="row" fxLayoutGap="30px" fxLayout.lt-lg="column" fxLayoutGap.lt-lg="0">
            <div fxFlex="50%">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{!this.auth.IsCashServizi ? 'VisualPOS' : 'APPagato'}}</h4>
                        <p class="card-category">Versioni in circolazione</p>
                    </div>
                    <div class="card-body">
                        <div>
                            <canvas id="barchart"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div fxFlex="50%">

                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Moduli</h4>
                        <p class="card-category">Moduli clienti</p>
                    </div>
                    <div class="card-body">
                        <div>
                            <canvas id="pie"></canvas>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- <div class="row">
            <div class="col-6">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">VisualPOS</h4>
                        <p class="card-category">Versioni in circolazione</p>
                    </div>
                    <div class="card-body">
                        <div>
                            <canvas id="barchart"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Moduli</h4>
                        <p class="card-category">Moduli clienti</p>
                    </div>
                    <div class="card-body">
                        <div>
                            <canvas id="pie"></canvas>
                        </div>
                    </div>
                </div>
            </div>-->














        <!--
            <div class="col-md-2">
                <div class="card">
                    <div class="card-header">
                        <p class="card-category">Moduli attivi</p>
                    </div>
                    <div class="card-body">
                        <div id="legend" class="scrollbar"></div>
                    </div>
                </div>
            </div>
            -->



    </div>
</div>

<ng-template #expiring>
    <app-licenses-expiring (refreshStats)="statChanged($event)" [expiringLicense]="this.ExpiringLicenses"  ></app-licenses-expiring>
</ng-template>