import { UserService } from './../../Services/UserService/user.service';
import { FileService } from './../../Services/FileService/file.service';
import { AuthService } from './../../Services/AuthService/auth.service';
import { AlertService } from 'ngx-alerts';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { IsDev } from 'src/app/global';

@Component({
  selector: 'app-user-software-config',
  templateUrl: './user-software-config.component.html',
  styleUrls: ['./user-software-config.component.scss']
})
export class UserSoftwareConfigComponent implements OnInit {

  @Input() public Username: string;

  public Edited: boolean = false;
  public SoftCodes: SoftCodes[] = [];
  public NewSoftCodes: SoftCodes[] = [];

  constructor(public modalService: BsModalService, private alert: AlertService, public auth: AuthService, private fileService: FileService, private UserService: UserService) { }

  async ngOnInit() {
    await this.InitSoftCodes();
  }

  public async SetSoftwareConfig() {

    //SOFTCODES
    const softCodes: string[] = [];
    for (let i = 0; i < this.NewSoftCodes.length; ++i) {
      if (this.NewSoftCodes[i].active) {
        softCodes.push(this.NewSoftCodes[i].key);
      }
    }

    // const id = await this.auth.GetIdByUsername();

    await this.UserService.SetSoftwareConfig(this.Username, softCodes);

    this.Edited = false;

    //this.modalService.hide();
    this.alert.success("Modifiche salvate");
  }

  public CheckSame() {

    //CONFRONTO SOFTCODE E NEWSOFTCODE
    for (let i = 0; i < this.SoftCodes.length; ++i) {
      if (this.SoftCodes[i].active !== this.NewSoftCodes[i].active) {
        this.Edited = true;
        return;
      } 
    }

    this.Edited = false;
    return;
  }

  private async InitSoftCodes() {
    
    //Inizializzo Softcodes
    let result: any = await this.fileService.InitSoftcodes();

    for (let i = 0; i < result.length; ++i) {
      this.SoftCodes.push(new SoftCodes(result[i][1], result[i][0]));
    }

    //GET per ogni softcode
    const softcodesUser: string[] = await this.UserService.GetSoftwareConfig(this.Username);
    for (let i = 0; i < softcodesUser.length; ++i) {
      const softcode: SoftCodes = this.SoftCodes.find((softcode) => softcode.key === softcodesUser[i])

      if (softcode !== undefined) {
        softcode.active = true;
      }
    }
    
    this.NewSoftCodes = this.SoftCodes.map(softcode => {
      return Object.assign({}, softcode);
    })
  }

  public async Close() {
    this.modalService.hide();
    this.alert.success("Software Aggiornati");
  }


}

class SoftCodes {
  public key: string;
  public description: string;
  public active: boolean;

  public constructor(key: string, descriptor: string) {
    this.key = key;
    this.description = descriptor;
    this.active = false;
  }

}
