import Acquisto  from 'src/app/Models/Acquisto';
import CFG from 'src/app/Models/CFG';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, Role } from 'src/app/Models/User';
import Ordine from '../../Models/Ordine';
import { Payment } from 'src/app/Models/Payment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public async CreateUser(formData: FormData): Promise<boolean> {
    const result: any = await this.http.post('/api/users/create', formData, {}).toPromise();

    return result.response === "ok";
  }

  public async RegisterUser(formData: FormData): Promise<boolean> {
    const result: any = await this.http.post('/api/users/register', formData, {}).toPromise();

    return result.response === "ok";
  }

  public async RecoverPass(username: string) {
    const result: any = await this.http.post('/api/users/recoverpass', {username}, {}).toPromise();

    return result.response === "ok";

  }

  public async DeleteUser(id: number, username: string, piva: string): Promise<boolean> {
    
    const params: HttpParams = new HttpParams()
      .set("id", String(id))
      .set("username", username)
      .set("piva", piva)

    const result: any = await this.http.delete('/api/users/delete', {params}).toPromise();

    return result.response === "ok";

  }

  public async UpdateUser(user: User, userTipo, id: number, vecchieLicenze: number, idCreatore: number): Promise<boolean> {


    //AGGIORNO CREDENZIALI
    const result: any = await this.http.post('/api/users/update', {
      id: user.ID, username: user.Username, password: user.Password, piva: user.PIVA, rsociale: user.RagioneSociale,
      citta: user.Citta, via: user.Via, email: user.Email, pec: user.PEC, sdi: user.SDI, iban: user.IBAN,
      intelligence: user.VisualIntelligence, order: user.VisualOrder, reservation: user.VisualReservation,
      idCreatore: idCreatore
    }, {}).toPromise();

    let softwareAbb: string = "";
    if (user.SoftwareAbbinatiBoolean.VisualFood) softwareAbb += "vfood|";
    if (user.SoftwareAbbinatiBoolean.VisualRetail) softwareAbb += "vretail|";
    if (user.SoftwareAbbinatiBoolean.VisualSelf) softwareAbb += "vself|";
    if (user.SoftwareAbbinatiBoolean.VisualShop) softwareAbb += "vshop|";

    if (vecchieLicenze !== null) {
      if (user.Tipo !== undefined ) {
        user.Tipo = '';
        if (userTipo.S) user.Tipo += 'S';
        if (userTipo.P) user.Tipo += 'P';
        if (userTipo.C) user.Tipo += 'C';


        user.ScontoEncrypted = this.EncodeSconto(user.Tipo, user.Sconto);
      }
      user.ScontoCloudEncrypted = this.EncodeScontoCloud(user.ScontoCloud);
      //Concessionario, niente
      if (user.Role === 1 || user.Role === 4 || user.Role2 === 1 || user.Role2 === 4) {

        await this.http.post('/api/users/update/tariffe', {
          id: user.ID, tipo: user.Tipo, numeroLicenze: user.NumeroLicenzePacchetto,
          numeroLicenzeVecchio: vecchieLicenze,
          sconto: user.ScontoEncrypted, canone: user.Canone,
          software: softwareAbb.substr(0, softwareAbb.length - 1), idCreatore: id,
          username: user.Username, piva: user.PIVA, scontorinnovo: user.ScontoRinnovo + '|' + user.ScontoModuli, scontoCloud: user.ScontoCloudEncrypted
        }, {}).toPromise();
      }
    }

    
    if (user.Role === 3 || user.Role2 === 3) {

      const body = {
        id: user.ID,
        username: user.Username,
        piva: user.PIVA,
        venditasoftware: (user.Provvigione.Software ? user.Provvigione.VenditaSoftware : -1),
        rinnovosoftware: (user.Provvigione.Software ? user.Provvigione.RinnovoSoftware : -1),
        venditahardware: (user.Provvigione.Hardware ? user.Provvigione.VenditaHardware : -1)
      }

      await this.http.post('/api/users/update/segnalatore', body, {}).toPromise();
    }


    let userRole = [];
    if (user.Role2 !== undefined) {
      userRole = [user.Role, user.Role2];
    } else {
      userRole = [user.Role]
    }

    /*
    await this.http.post('/api/sendmailupdate',
    {
      id: user.ID, username: user.Username, password: user.Password, piva: user.PIVA, RagioneSociale: user.RagioneSociale,
       citta: user.Citta, via: user.Via, email: user.Email, pec: user.PEC, sdi: user.SDI, IBAN: user.IBAN,
       tipo: user.Tipo, NumeroLicenzePacchetto: user.NumeroLicenzePacchetto,
       sconto: user.ScontoEncrypted, canone: user.Canone,
       SoftwareAbbinati: softwareAbb.substr(0, softwareAbb.length - 1), Creatore: creatore, UserRole: userRole,
       VisualIntelligence: user.VisualIntelligence, VisualOrder: user.VisualOrder, VisualReservation: user.VisualReservation

    }, {}).toPromise();
    */

    return (result.response === "ok") ? true: false;

  }

  public DecodeSconto(sconto: string) {

    const scontoReturn = {
      S: 0,
      SBonus: 0,
      P: {
        Cinque: 0,
        Dieci: 0,
        Quindici: 0,
        Venti: 0
      },
      PBonus: {
        Cinque: 0,
        Dieci: 0,
        Quindici: 0,
        Venti: 0
      },
      C: 0,
      CBonus: 0,
    };

    //CONDIZIONI
    //LI CONTIENE TUTTI E 3

    const sconti: string[] = sconto.split('-');
    for (let i = 0; i < sconti.length; ++i) {
      const scontiSplitted: string[] = sconti[i].split(':');
      //SINGOLA
      if (scontiSplitted[0] === 'S') {
        const scontoSingolo: string[] = scontiSplitted[1].split('+');
        scontoReturn.S = Number(scontoSingolo[0])
        scontoReturn.SBonus = Number(scontoSingolo[1]);
      }
      //PACCHETTI
      if (scontiSplitted[0] === 'P') {
        const scontoPacchetti: string[] = scontiSplitted[1].split('|');
        for (let i = 0; i < scontoPacchetti.length; ++i) {
          const scontoPacchettoSingolo: string[] = scontoPacchetti[i].split('&')[1].split('+');
          if (i === 0) {
            scontoReturn.P.Cinque = Number(scontoPacchettoSingolo[0]);
            scontoReturn.PBonus.Cinque = Number(scontoPacchettoSingolo[1]);
          }
          if (i === 1) {
            scontoReturn.P.Dieci = Number(scontoPacchettoSingolo[0]);
            scontoReturn.PBonus.Dieci = Number(scontoPacchettoSingolo[1]);
          }
          if (i === 2) {
            scontoReturn.P.Quindici = Number(scontoPacchettoSingolo[0]);
            scontoReturn.PBonus.Quindici = Number(scontoPacchettoSingolo[1]);
          }
          if (i === 3) {
            scontoReturn.P.Venti = Number(scontoPacchettoSingolo[0]);
            scontoReturn.PBonus.Venti = Number(scontoPacchettoSingolo[1]);
          }
        }
      }
      //CANONE
      if (scontiSplitted[0] === 'C') {
        const scontoCanone: string[] = scontiSplitted[1].split('+');
        scontoReturn.C = Number(scontoCanone[0])
        scontoReturn.CBonus = Number(scontoCanone[1]);
      }
    }

    return scontoReturn;
  }

  public DecodeScontoCloud(sconto: string) {
    const scontoCloud = {
      PrimoScaglione: {
        SX: 0,
        DX: 0,
        PrimoAnno: 0,
        PrimoAnnoBonus: 0,
        SecondoAnno: 0,
        SecondoAnnoBonus: 0,
      },
      SecondoScaglione: {
        SX: 0,
        DX: 0,
        PrimoAnno: 0,
        PrimoAnnoBonus: 0,
        SecondoAnno: 0,
        SecondoAnnoBonus: 0,
      },
      TerzoScaglione: {
        SX: 0, 
        DX: -1,
        PrimoAnno: 0,
        PrimoAnnoBonus: 0,
        SecondoAnno: 0,
        SecondoAnnoBonus: 0,
      }
    };

    const sconti: string[] = sconto.split('&');

    //PRIMO SCAGLIONE
    const primoScaglione: string = sconti[0];
    let range: string = primoScaglione.split('>')[0];
    let primoAnno: string = primoScaglione.split('>')[1].split('|')[0];
    let secondoAnno: string = primoScaglione.split('>')[1].split('|')[1];
    
    scontoCloud.PrimoScaglione.SX = Number(range.split(':')[0]);
    scontoCloud.PrimoScaglione.DX = Number(range.split(':')[1]);
    scontoCloud.PrimoScaglione.PrimoAnno = Number(primoAnno.split('+')[0]);
    scontoCloud.PrimoScaglione.PrimoAnnoBonus = Number(primoAnno.split('+')[1]);
    scontoCloud.PrimoScaglione.SecondoAnno = Number(secondoAnno.split('+')[0]);
    scontoCloud.PrimoScaglione.SecondoAnnoBonus = Number(secondoAnno.split('+')[1]);

    //SECONDO SCAGLIONE
    const secondoScaglione: string = sconti[1];
    range = secondoScaglione.split('>')[0];
    primoAnno = secondoScaglione.split('>')[1].split('|')[0];
    secondoAnno = secondoScaglione.split('>')[1].split('|')[1];

    scontoCloud.SecondoScaglione.SX = Number(range.split(':')[0]);
    scontoCloud.SecondoScaglione.DX = Number(range.split(':')[1]);
    scontoCloud.SecondoScaglione.PrimoAnno = Number(primoAnno.split('+')[0]);
    scontoCloud.SecondoScaglione.PrimoAnnoBonus = Number(primoAnno.split('+')[1]);
    scontoCloud.SecondoScaglione.SecondoAnno = Number(secondoAnno.split('+')[0]);
    scontoCloud.SecondoScaglione.SecondoAnnoBonus = Number(secondoAnno.split('+')[1]);

    //TERZO SCAGLIONE
    const terzoScaglione: string = sconti[2];
    range = terzoScaglione.split('>')[0];
    primoAnno = terzoScaglione.split('>')[1].split('|')[0];
    secondoAnno = terzoScaglione.split('>')[1].split('|')[1];

    scontoCloud.TerzoScaglione.SX = Number(range.split(':')[0]);
    scontoCloud.TerzoScaglione.DX = Number(range.split(':')[1]);
    scontoCloud.TerzoScaglione.PrimoAnno = Number(primoAnno.split('+')[0]);
    scontoCloud.TerzoScaglione.PrimoAnnoBonus = Number(primoAnno.split('+')[1]);
    scontoCloud.TerzoScaglione.SecondoAnno = Number(secondoAnno.split('+')[0]);
    scontoCloud.TerzoScaglione.SecondoAnnoBonus = Number(secondoAnno.split('+')[1]);

    return scontoCloud;
  }

  public EncodeSconto(tipo: string, decryptedSconto: any) {
    /*
    const scontoReturn = {
        S: 0,
        SBonus: 0,
        P: {
          Cinque: 0,
          Dieci: 0,
          Quindici: 0,
          Venti: 0
        },
        PBonus: {
          Cinque: 0,
          Dieci: 0,
          Quindici: 0,
          Venti: 0
        },
        C: 0,
        CBonus: 0,
    };
    */


    let sconto: string = "";

    if (tipo.includes('S')) {
      sconto += "S:" + decryptedSconto.S + "+" + decryptedSconto.SBonus;
      sconto += '-';
    }

    if (tipo.includes('P')) {
      sconto += "P:";
      //5
      sconto += "5&" + decryptedSconto.P.Cinque + "+" + decryptedSconto.PBonus.Cinque + "|";
      //10
      sconto += "10&" + decryptedSconto.P.Dieci + "+" + decryptedSconto.PBonus.Dieci + "|";
      //15
      sconto += "15&" + decryptedSconto.P.Quindici + "+" + decryptedSconto.PBonus.Quindici + "|";
      //20
      sconto += "20&" + decryptedSconto.P.Venti + "+" + decryptedSconto.PBonus.Venti;

      sconto += '-';
    }
    if (tipo.includes('C')) {
      sconto += "C:" + decryptedSconto.C + "+" + decryptedSconto.CBonus;
      sconto += '-';
    }
    return sconto.substring(0, sconto.length - 1);
  }

  public EncodeScontoCloud(decriptedSconto: any): string {
    let sconto: string = "";

    //PRIMO SCAGLIONE
    sconto += `${decriptedSconto.PrimoScaglione.SX}:${decriptedSconto.PrimoScaglione.DX}>${decriptedSconto.PrimoScaglione.PrimoAnno}+${decriptedSconto.PrimoScaglione.PrimoAnnoBonus}|${decriptedSconto.PrimoScaglione.SecondoAnno}+${decriptedSconto.PrimoScaglione.SecondoAnnoBonus}&`

    //SECONDO SCAGLIONE
    sconto += `${decriptedSconto.SecondoScaglione.SX}:${decriptedSconto.SecondoScaglione.DX}>${decriptedSconto.SecondoScaglione.PrimoAnno}+${decriptedSconto.SecondoScaglione.PrimoAnnoBonus}|${decriptedSconto.SecondoScaglione.SecondoAnno}+${decriptedSconto.SecondoScaglione.SecondoAnnoBonus}&`
    
    //TERZO SCAGLIONE
    sconto += `${decriptedSconto.TerzoScaglione.SX}:${decriptedSconto.TerzoScaglione.DX}>${decriptedSconto.TerzoScaglione.PrimoAnno}+${decriptedSconto.TerzoScaglione.PrimoAnnoBonus}|${decriptedSconto.TerzoScaglione.SecondoAnno}+${decriptedSconto.TerzoScaglione.SecondoAnnoBonus}`
    
    return sconto;
  }


  //Cambio lo stato dell'USER...SOLO GAB
  //0: attivo, 1: sospeso, 2:disattivo
  public async SetUserState(user: User): Promise<boolean> {
    
    if (user.Stato === 0) {
      const result: any = await this.http.post('/api/users/state', {id: user.ID, stato: '1'}, {}).toPromise();

      user.Stato = 1;

      return result.response === "ok";
    }
    if (user.Stato === 1) {

      const result: any = await this.http.post('/api/users/state',  {id: user.ID, stato: '2'}, {}).toPromise();

      user.Stato = 2;

      return result.response === "ok";
    }
    if (user.Stato === 2) {

      const result: any = await this.http.post('/api/users/state', {id: user.ID, stato: '0'}, {}).toPromise();

      user.Stato = 0;

      return result.response === "ok";
    }
  }

  //GETACQUISTI
  public async GetStorico(user: User): Promise<Ordine[] | Acquisto[]> {

    const params: HttpParams = new HttpParams()
    .set("username", user.Username)
    .set("piva", user.PIVA)
    .set("id", String(user.ID))
    .set("role", String(user.Role));

    const result: any = await this.http.get('/api/users/storico', {params}).toPromise();
    if (user.Role === Role.USER) {
      const list: Ordine[] = [];

      for (let i = 0; i < result.length; ++i) {
        const ordine: Ordine = new Ordine();

        ordine.ID = result[i].id;
        ordine.Username = result[i].Username;
        ordine.Piva = result[i].piva;
        ordine.NumeroLicenze = result[i].numeroLicenze;

        ordine.CodiceArticolo = result[i].codiceArticolo;
        ordine.DataOrdine = result[i].dataOrdine;

        ordine.CodiceOrdine = result[i].codiceOrdine;
        ordine.Totale = result[i].totale;

        ordine.Descrizione = result[i].descrizione;

        list.push(ordine);

      }


      return list;
    } else {
      const list: Acquisto[] = [];

      for (let i = 0; i < result.length; ++i) {
        const acquisto: Acquisto = new Acquisto();

        acquisto.ID = result[i].id;
        acquisto.Username = result[i].username;
        acquisto.PIVA = result[i].piva;
        acquisto.CodiceArticolo = result[i].codiceArticolo;
        acquisto.Descrizione = result[i].descrizione;
        acquisto.DataOrdine = result[i].dataOrdine;
        acquisto.NumeroLicenze = result[i].numeroLicenze;
        acquisto.PrezzoScontato = result[i].prezzoScontato;
        acquisto.PrezzoTotale = result[i].prezzoTotale;
        acquisto.Tipo = result[i].tipo;

        list.push(acquisto);
      }

      return list;
    }
  }

  public async GetCFG(piva: string) {
    const params: HttpParams = new HttpParams()
    .set("piva", piva);

    return await this.http.get<CFG[]>('/api/users/cfg', {params}).toPromise();
  }

  public async DeleteCFG(piva: string, id: number) {
    const params: HttpParams = new HttpParams()
    .set("piva", piva).set('id', String(id));

    return await this.http.delete<boolean>('/api/users/cfg', {params}).toPromise();
  
  }

  public async SaveCFG(piva: string, cfg: CFG) {
    cfg.piva = piva;
  

    return await this.http.post<boolean>('/api/users/cfg', cfg, {}).toPromise();
  }

  public async UpdateCFG(piva: string, cfg: CFG) {
    cfg.piva = piva;

    return await this.http.put<boolean>('/api/users/cfg', cfg, {}).toPromise();
  }

  //GETSTORICO
  public async GetPagamenti(user: User): Promise<Payment[]> {
    const params: HttpParams = new HttpParams()
    .set("username", user.Username)
    .set("piva", user.PIVA)
    .set("id", String(user.ID));

    const result: any = await this.http.get('/api/users/pagamenti', {params}).toPromise();

    const list: Payment[] = [];

    for (let i = 0; i < result.length; ++i) {
      const payment: Payment = new Payment(result[i].iD_ut, result[i].username_ut, result[i].piva_ut, result[i].totale, result[i].tipo);
      payment.Data = result[i].data;

      list.push(payment);
    }

    return list;
  }

  public async UpdatePassword(id: number, oldPassword: string, newPassword: string): Promise<boolean> {
    const object = {
      id, oldPassword, newPassword
    }

    const body: string = JSON.stringify(object);

    const result: any = await this.http.post('/api/users/updatepassword', body, {headers: {
      'Content-Type': 'application/json'
    }}).toPromise();

    if (result.response === 'ko') {
      return false;
    } else {
      return true;
    }
  }

  public async LoadUsers(creatore: string): Promise<User[]> {
    const params: HttpParams = new HttpParams()
    .set('creatore', creatore);

    const result: any = await this.http.get('/api/users', {params}).toPromise();

    const Users: User[] = [];

    for (let i = 0; i < result.length; ++i) {

      const user: User = this.GenerateUser(result[i]);
      Users.push(user)
    }

    return Users;
  }

  private GenerateUser(result: any): User {
    const user: User = new User();
    user.CreateUser(result.username, result.password, result.userRole[0], result.userRole[1], result.piva, result.ragioneSociale, result.citta, result.via, result.email, result.pec, result.sdi, result.iban);

    user.Key = result.key;

    user.ID = result.id;
    user.Creatore = result.creatore;
    user.Segnalatore = result.segnalatore;

    user.VisualIntelligence = result.visualIntelligence;
    user.VisualOrder = result.visualOrder;
    user.VisualReservation = result.visualReservation;

    if (user.Role === 0) {
      user.Permessi = result.permessi;
      user.DecryptPermessi();
    }

    if (user.Role === 3 || user.Role2 === 3) {
      const provvigioni: string[] = result.provvigioni.split('|');

      // PROVVIGIONI SOFTWARE
      if (+provvigioni[0] === -1 && +provvigioni[1] === -1) {
        user.Provvigione.Software = false;
        user.Provvigione.VenditaSoftware = 0;
        user.Provvigione.RinnovoSoftware = 0;
      } else {
        user.Provvigione.Software = true;
        user.Provvigione.VenditaSoftware = +provvigioni[0];
        user.Provvigione.RinnovoSoftware = +provvigioni[1];
      }
      // PROVVIGIONI HARDWARE
      if (+provvigioni[2] === -1) {
        user.Provvigione.Hardware = false;
        user.Provvigione.VenditaHardware = 0;
      } else {
        user.Provvigione.Hardware = true;
        user.Provvigione.VenditaHardware = +provvigioni[2];
      }
    }

    if (user.Role === 1 || user.Role2 === 1 || user.Role === 4 || user.Role2 === 4) {
      user.Tipo = result.tipo;
      //SCONTO
      user.Sconto = this.DecodeSconto(result.sconto);
      //SCONTO CLOUD
      if (result.scontoCloud !== "") {
        user.ScontoCloud = this.DecodeScontoCloud(result.scontoCloud);
      }
      //CANONE
      user.NumeroLicenzePacchetto = result.numeroLicenzePacchetto;

      //SCONTO RINNOVO
      const scontiSpeciali: string[] = result.scontiSpeciali.split('|');
      user.ScontoRinnovo = scontiSpeciali[0];
      user.ScontoModuli = scontiSpeciali[1];


      if (user.Tipo.includes('C')) {
        user.Canone = result.canone;

        //SOFTWARE ABBINATI
        user.SoftwareAbbinati = result.softwareAbbinati;
        const stringsplit : string[] = user.SoftwareAbbinati.split('|');

        for (let i = 0; i < stringsplit.length; ++i) {
          if (stringsplit[i] === 'vfood') {
            user.SoftwareAbbinatiBoolean.VisualFood = true;
          }
          if (stringsplit[i] === 'vself') {
            user.SoftwareAbbinatiBoolean.VisualSelf = true;
          }
          if (stringsplit[i] === 'vretail') {
            user.SoftwareAbbinatiBoolean.VisualRetail = true;
          }
          if (stringsplit[i] === 'vshop') {
            user.SoftwareAbbinatiBoolean.VisualShop = true;
          }
        }

      }
    }
    user.Stato = result.stato;

    return user;
  }

  public async GetIdByUsername(username: string): Promise<number> {

    const params: HttpParams = new HttpParams()
    .set("username", username);
    
    const result: any = await this.http.get('/api/users/id', {params}).toPromise();

    return result.response as number;
  }

  public async SetPermessi(user: User) {

    return await this.http.post('/api/admin/permessi', 
      {permessi: user.Permessi, id: user.ID}).toPromise();
  }

  public async GetSoftwareConfig(Username: string): Promise<string[]> {
    const params: HttpParams = new HttpParams()
    .set("username", Username);
    
    const result: any = await this.http.get('/api/users/softwareconfig', {params}).toPromise();

    return result.softcodes;
  }

  public async SetSoftwareConfig(Username: string, softCodes: string[]) {
    return await this.http.post('/api/users/softwareconfig', 
      {username: Username, softcodes: softCodes}).toPromise();
  }

  

}

