import {Component, Input, OnInit} from '@angular/core';
import SoftwareListino from "../../../Models/SoftwareListino";
import {AuthService} from "../../../Services/AuthService/auth.service";
import {Router} from "@angular/router";
import {BsModalService} from "ngx-bootstrap/modal";
import {UserStoreService} from "../../../Services/Admin/user-store.service";
import {AlertService} from "ngx-alerts";
import {PaymentService} from "../../../Services/Payment/payment.service";

@Component({
  selector: 'app-crea-elemento',
  templateUrl: './crea-elemento.component.html',
  styleUrls: ['./crea-elemento.component.scss']
})
export class CreaElementoComponent implements OnInit {
  @Input() NuovoListino;
  @Input() Categorie;
  url: any;
  CodiceListino:string="";
  ErroreCodiceListino:boolean = false;
  ErroreCodiceListinoSpazi: boolean = false;
  NomeProdotto:string="";
  ErroreNomeProdotto:boolean = false;
  CostoProdotto:number;
  ErroreCostoProdottoNull:boolean = false;
  ErroreCostoProdottoZero:boolean = false;
  ErroreCostoProdottoLettere:boolean = false;
  DescrizioneProdotto:string = "";
  ErroreDescrizioneProdotto:boolean = false;
  ErroreDescrizioneProdottoLength:boolean = false;
  ErroreRisoluzioneImmagine:boolean= false;
  ErroreFormatoImmagine: boolean = false;
  tastiPremuti:number;
  constructor(private auth: AuthService, private router: Router, public modalService: BsModalService, private userStore: UserStoreService, private alert: AlertService, public payment: PaymentService) { }

  ngOnInit(): void {

    this.tastiPremuti = 0;
    this.NomeProdotto = '';
    this.CodiceListino = this.NuovoListino.CodiceArticolo;
    this.DescrizioneProdotto = '';
    this.CostoProdotto = 1;
  }
  onSelectFile(event) { // called each time file input changes
    let img = new Image()
    try {
      img.src = window.URL.createObjectURL(event.target.files[0]);
      img.onload = () => {
        if((img.width/img.height) === 1) {
          if (img.width >= 200 && img.height >= 200){
            this.NuovoListino.Img = event.target.files[0];
            this.ErroreRisoluzioneImmagine = false;
            this.ErroreFormatoImmagine = false;
          }else{
            this.ErroreRisoluzioneImmagine = true;
            this.ErroreFormatoImmagine = false;
            let resetForm = <HTMLFormElement>document.getElementById('uploadImageForm');
            resetForm.reset();
            this.NuovoListino.Img =null;
          }
          // upload logic here
        } else {
          this.ErroreRisoluzioneImmagine = false;
          this.ErroreFormatoImmagine = true;
          let resetForm = <HTMLFormElement>document.getElementById('uploadImageForm');
          resetForm.reset();
          this.NuovoListino.Img =null;
        }
      }
    } catch (e) {
      
    }
  }
  InputCodiceListino(){
    this.tastiPremuti++;
    if (this.CodiceListino.length === 0){
      this.ErroreCodiceListino = true;
      this.ErroreCodiceListinoSpazi = false;
    }else if(this.CodiceListino.includes(' ')){
      this.ErroreCodiceListinoSpazi = true;
      this.ErroreCodiceListino = false;
    } else {
      this.NuovoListino.CodiceArticolo = this.CodiceListino;
      this.ErroreCodiceListinoSpazi = false;
      this.ErroreCodiceListino = false;
    }
  }
  InputNomeProdotto(){
    this.tastiPremuti++;
    if(this.NomeProdotto.length === 0){
      this.ErroreNomeProdotto = true;
    }else{
      this.NuovoListino.Descrizione= this.NomeProdotto;
      this.ErroreNomeProdotto = false
    }
  }
  InputCostoProdotto(){
    this.tastiPremuti++;
    let CostoProdottoTemp = String(this.CostoProdotto).replace(/[e\+\-]/gi, "");
    this.CostoProdotto = Number(CostoProdottoTemp);
    let roundedString = this.CostoProdotto.toFixed(2); //Arrotondamento
    this.CostoProdotto = Number(roundedString);
    if(isNaN(this.CostoProdotto)){
        this.ErroreCostoProdottoNull = true;
        this.ErroreCostoProdottoZero = false;
        this.ErroreCostoProdottoLettere = false;

      }else if(this.CostoProdotto === 0){
        this.ErroreCostoProdottoNull = false;
        this.ErroreCostoProdottoZero = true;
        this.ErroreCostoProdottoLettere = false;

      }else{
        this.NuovoListino.Costo = this.CostoProdotto;
        this.ErroreCostoProdottoNull = false;
        this.ErroreCostoProdottoZero = false;
        this.ErroreCostoProdottoLettere = false;
      }

  }
  InputDescrizioneProdotto(){
    this.tastiPremuti++;
    if(this.DescrizioneProdotto.length == 0){
      this.ErroreDescrizioneProdotto = true;
    } else if(this.DescrizioneProdotto.length > 5000){
      this.ErroreDescrizioneProdottoLength= true;
      this.ErroreDescrizioneProdotto = false;
    }else{
      this.NuovoListino.Testo = this.DescrizioneProdotto;
      this.ErroreDescrizioneProdottoLength= false;
      this.ErroreDescrizioneProdotto = false;
    }
  }
  public async Upload() {
    //CHECK...SE NON HO INSERITO TUTTI I CAMPI, ALERT.

    const id = await this.auth.GetIdByUsername();

    const result: boolean = await this.userStore.UploadListino(this.NuovoListino, id);
    if (result) {
      this.modalService.hide();
      this.alert.success("Elemento aggiunto con successo");
      const ListinoAdd = Object.assign({}, this.NuovoListino);
      this.Categorie.find(cat => cat.ID === ListinoAdd.IdCat).Elementi.push(ListinoAdd);
      this.NuovoListino = new SoftwareListino();
    } else {
      this.alert.danger("Il codice listino esiste già");
    }
  }
  async checkFormValidity(){
    if(this.tastiPremuti === 0){
      this.ErroreCodiceListino = true;
      this.ErroreNomeProdotto = true;
      this.ErroreCostoProdottoZero = true;
      this.ErroreDescrizioneProdotto = true;
    }else{
      let errori = 0;

      if (this.CodiceListino === ''){
        this.ErroreCodiceListino = true;
        errori++;
      }
      if(this.NomeProdotto  === ''){
        this.ErroreNomeProdotto = true;
        errori++;
      }
      if(this.CostoProdotto === 0){
        this.ErroreCostoProdottoZero = true;
        errori++;
      }
      if(this.DescrizioneProdotto === ''){
        this.ErroreDescrizioneProdotto = true;
        errori++;
      }
      if(this.ErroreCodiceListino || this.ErroreNomeProdotto || this.ErroreCostoProdottoNull || this.ErroreCostoProdottoLettere || this.ErroreCostoProdottoZero
        || this.ErroreDescrizioneProdotto || this.ErroreDescrizioneProdottoLength){
          this.alert.danger('Campi mancanti');
      } else{
        await this.Upload();

      }
    }
  }
}
