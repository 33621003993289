import { SellPoint } from 'src/app/Models/Cloud/SellPoint';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupService } from './group.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SellpointsService {

  constructor(private http: HttpClient, private groupService: GroupService) { }

  public GetSellPoint(centerId: number) {

    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    const params: HttpParams = new HttpParams()
    .set("id", String(centerId));

    return this.http.get<SellPoint[]>('/api/cloud/sellpoints', {headers, params});
  }

  public async EditSellPoint(sellPoint: SellPoint) {

    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    const returnSellPoint: SellPoint = await lastValueFrom(this.http.put<SellPoint>('/api/cloud/sellpoints', sellPoint, {headers}))

    return returnSellPoint;
  }

  public async CreateSellPoint(sellPoint: SellPoint) {

    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    const returnSellPoint: SellPoint = await lastValueFrom(this.http.post<SellPoint>('/api/cloud/sellpoints', sellPoint, {headers}))
    
    return returnSellPoint;
  }

  public async DeleteSellPoint(sellPoint: SellPoint) {

    const token = this.groupService.AuthResponse.token;

    const headers: HttpHeaders = new HttpHeaders()
    .set("Authorization-Cloud", token);

    const params: HttpParams = new HttpParams()
    .set("id", String(sellPoint.id))
    .set("groupId", String(sellPoint.center.group.id));

    return await lastValueFrom(this.http.delete<boolean>('/api/cloud/sellpoints', {headers, params}))
  }
}
